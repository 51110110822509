import React from "react";
import { Select } from "antd";
import { StyleSheet } from "../../models/StyleSheet";
import cssStyle from "../styles/multipleSelect.module.css";

export const MultipleSelect = ({
  placeholder,
  value,
  setValue,
  options,
}: {
  placeholder: string;
  value: string[];
  setValue: React.Dispatch<React.SetStateAction<string[]>>;
  options: { value: string | number; label?: string }[];
}) => {
  return (
    <Select
      mode="multiple"
      dropdownStyle={styles.option}
      style={styles.select}
      placeholder={placeholder}
      value={value}
      className={cssStyle.input}
      onChange={(v, labels) => {
        setValue(v);
      }}
      options={options}
    />
  );
};

const styles = StyleSheet.create({
  option: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    letterSpacing: "0.07em",
  },
  select: {
    // width: "150px",
    width: "100%",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    letterSpacing: "0.07em",
    alignItems: "center",
    margin: "5px 0px",
  },
});
