import React, { useState } from "react";
import { Button } from "antd";
import Icon from "../../../assets/images/Category-Icon.png";
import Style from "./style.module.css";
import NewStyle from "./newstyle.module.css";
import { useNavigate } from "react-router";

export interface CategoryItemProps {
  id: number;
  title: string;
  isActive: boolean;
  swiperDesign: boolean;
  catImage: string;
}

const Category = ({
  title,
  id,
  isActive,
  swiperDesign,
  catImage,
}: CategoryItemProps) => {
  const navigate = useNavigate();
  const onCategoryClick = () => {
    navigate(`/categories/${id}`);
  };

  return (
    <>
      {/* Swiper Slider Design */}
      {swiperDesign && (
        <Button
          disabled={!isActive}
          onClick={onCategoryClick}
          className={Style.buttonContainer}
        >
          <img src={catImage} alt="Icon" className={Style.categoryIcon} />
          <p className={Style.categoryName}>{title ?? ""}</p>
        </Button>
      )}
      {/* Div Design */}
      {!swiperDesign && (
        <Button
          disabled={!isActive}
          onClick={onCategoryClick}
          className={NewStyle.buttonContainer}
        >
          <span className={NewStyle.iconContainer}>
            <img src={catImage} alt="Icon" className={NewStyle.categoryIcon} />
          </span>
          <p className={NewStyle.categoryName}>{title ?? ""}</p>
        </Button>
      )}
    </>
  );
};

export default Category;
