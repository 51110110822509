import React, { useState, useEffect } from "react";
import style from "./styles/style.module.css";
import { Checkbox } from "antd";

type Props = {
  id: number;
  title: string;
  icon?: string;
  fullCheck: boolean;
  partialCheck: boolean;
  onCatCheck: (id: number, isCheck: boolean) => void;
};

const TreeHeader = (props: Props) => {
  const { id, title, icon, fullCheck, partialCheck, onCatCheck } = props;

  const [isPartial, setIsPartial] = useState(partialCheck);
  useEffect(() => {
    setIsPartial(partialCheck);
  }, [partialCheck]);

  return (
    <div className={style.treeContainer}>
      <div className={style.nodeRow}>
        <Checkbox
          className={style.checkbox}
          checked={fullCheck}
          onChange={({ target: { checked } }) => {
            onCatCheck(id, checked);
          }}
        >
          {/* <div className={`${style.partialCheckLayer} ${style.opacity1}`}></div> */}
          <div
            className={`${style.partialCheckLayer} ${
              isPartial ? style.opacity1 : ""
            }`}
          ></div>
          <div className={style.chickBoxUpperLayer} />
        </Checkbox>
        {/* categoryIcon */}
        {/* {icon && (
          <div className={style.iconBox}>
            <img src={icon} alt="pic" className={style.icon} />
          </div>
        )} */}
        {/* title */}
        <h2 className={style.title}>{title}</h2>
        {/* transparent clickable box on the pannel header */}
        <div
          className={style.panelTransparentClickBox}
          onClick={(event) => {
            const { target } = event;
            if (target) {
              const plusIconButton = (target as HTMLElement).nextElementSibling;
              const child = (plusIconButton as HTMLElement).childNodes[0];
              (child as HTMLElement).classList.toggle(`${style.yLineRotate}`);
            }
          }}
        />
        {/* plus icon */}
        <div
          className={style.plusSwitchIcon}
          onClick={(event) => {
            const { target } = event;
            if (target) {
              const child = (target as HTMLElement).childNodes[0];
              (child as HTMLElement).classList.toggle(`${style.yLineRotate}`);
            }
          }}
        >
          <div className={style.yLine}></div>
          <div className={style.xLine}></div>
        </div>
      </div>
    </div>
  );
};

export default TreeHeader;
