import { LeftOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import React, { useEffect, useState } from "react";
import style from "./styles/style.module.css";
import { useTranslation } from "../../../hooks/useTranslation";
import { OrganisationsData } from "../../../hooks/api/Organisations";

type Props = {
  organisation: OrganisationsData | undefined;
};

type serviceAreaType = {
  id: number;
  service: string;
  fullCheck: boolean;
  partialCheck: boolean;
  totalCatCheck: number;
  cities: { id: number; index: number; title: string; isCheck: boolean }[];
}[];

const Services = (props: Props) => {
  const { t } = useTranslation("supplierProfilePage");
  const { organisation } = props;
  const [areas, setAreas] = useState<string[]>();

  useEffect(() => {
    const serviceAreasArr = organisation?.services?.split(",");
    setAreas(serviceAreasArr);
    localStorage.setItem("serviceAreas", JSON.stringify(serviceAreasArr));
    const selectedServiceAreaData = localStorage.getItem(
      "selectedServiceAreas"
    );
    if (selectedServiceAreaData) {
      const areaData = JSON.parse(selectedServiceAreaData) as serviceAreaType;
      const trimedServiceAreasArr = serviceAreasArr?.map((item) => item.trim());
      areaData.forEach((item) => {
        for (let i = 0; i < item.cities.length; i++) {
          item.cities[i].isCheck = false;
        }
        item.totalCatCheck = 0;
        item.fullCheck = false;
        item.partialCheck = false;
      });
      areaData.forEach((item) => {
        let checkCounter = 0;
        for (let i = 0; i < item.cities.length; i++) {
          if (trimedServiceAreasArr?.includes(item.cities[i].id.toString())) {
            item.cities[i].isCheck = true;
            checkCounter++;
          }
        }
        item.totalCatCheck = checkCounter;
        if (checkCounter === item.cities.length && checkCounter !== 0) {
          item.fullCheck = true;
          item.partialCheck = false;
        }
        if (checkCounter > 0 && checkCounter < item.cities.length) {
          item.partialCheck = true;
          item.fullCheck = false;
        }
        if (checkCounter === 0 || checkCounter === item.cities.length) {
          item.partialCheck = false;
        }
      });
      localStorage.setItem("selectedServiceAreas", JSON.stringify(areaData));
      // remove the already selected service areas in local storage
      localStorage.removeItem("sendingServiceAreas");
    }
  }, [organisation]);

  return (
    <>
      <Collapse
        bordered={false}
        className={style.collapsePan}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <LeftOutlined
            rotate={isActive ? 270 : 180}
            className={style.loadMoreArrow}
          />
        )}
      >
        <Collapse.Panel
          key={1}
          header={
            <div className={style.mediaTopContainer}>
              <p className={style.categoryHeading}>{t("serviceArea")}</p>
            </div>
          }
        >
          <div className={style.capsuleBox}>
            {areas?.map((area, index) => {
              if (index % 2 === 0) {
                return <></>;
              }
              return (
                <div key={area} className={style.capsuleBtn}>
                  <span>{area}</span>
                  <div className={style.closeIcon}></div>
                </div>
              );
            })}
          </div>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default Services;
