import React from "react";
import { StyleSheet } from "../../../models/StyleSheet";
import { useTranslation } from "../../../hooks/useTranslation";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { ComingSoon } from "../../../components/wrapers/ComingSoon";
import SampleImage from "../../../assets/screenshots/dashboard.png";
import { DefaultButton } from "../../../components/buttons/DefaultButton";
import { useNavigate } from "react-router";

dayjs.extend(weekOfYear);

export function Dashboard() {
  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");
  const week = dayjs().week() - 1;
  return (
    <>
      <div style={styles.topBar}>
        <div>
          <p style={styles.topBarMainText}>{t("dashboardHeader")}</p>
          <p style={styles.topBarSecText}>{t("week", { week })}</p>
        </div>
        <div style={styles.btnDiv}>
          <DefaultButton
            text={t("getMoreReviews")}
            style={styles.btn}
            variant={"primary"}
            onClick={() => navigate("/invite-to-review")}
          />
        </div>
      </div>
      <ComingSoon fontSize={50}>
        <img width={"100%"} src={SampleImage} />
      </ComingSoon>
    </>
  );
}

const styles = StyleSheet.create({
  topBar: {
    padding: "32px 161px 32px 161px",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    margin: "0px -24px 0px -24px",
  },
  topBarMainText: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "35px",
    letterSpacing: "0.07em",
    color: "#000000",
    margin: "0px",
    marginBottom: "8px",
  },
  topBarSecText: {
    margin: "0px",
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#3F3F3F",
  },
  btn: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#FFFFFF",
    backgroundColor: "#07285D",
    borderRadius: "4px",
    padding: "7px 16px 8px 16px",
    border: "0",
  },
  btnDiv: {
    display: "flex",
    alignItems: "flex-end",
  },
});
