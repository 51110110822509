import React from "react";
import RateStarFilled from "../../../assets/icons/RateStarFilled.svg";
import RateStarHalf from "../../../assets/icons/RateStarHalf.svg";
import { StyleSheet } from "../../../models/StyleSheet";

export const ShowStars = ({ number }: { number: number }) => {
  const renderedStars = Array.from({ length: Math.floor(number) }).map(
    (star, i) => <img src={RateStarFilled} style={styles.star} key={i} />
  );
  const fullNumber = number - Math.floor(number) === 0;

  return (
    <div style={styles.container}>
      <span style={styles.text}>
        {Math.floor(number)}.{fullNumber ? 0 : 5}
      </span>
      {renderedStars}
      {fullNumber ? "" : <img src={RateStarHalf} style={styles.star} />}
    </div>
  );
};

const styles = StyleSheet.create({
  star: {
    filter:
      "invert(13%) sepia(25%) saturate(5332%) hue-rotate(204deg) brightness(94%) contrast(102%)",
    border: "5px",
    margin: "3px",
  },
  container: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  text: {
    fontFamily: "d-din",
    width: "20px",
    color: "#07285D",
  },
});
