import category1 from "../../../assets/icons/categoryIcons/Trash.png";
import category2 from "../../../assets/icons/categoryIcons/Home.png";
import category3 from "../../../assets/icons/categoryIcons/Blue Print.png";
import category4 from "../../../assets/icons/categoryIcons/Delivery.png";
import category5 from "../../../assets/icons/categoryIcons/Affiliate.png";
import category6 from "../../../assets/icons/categoryIcons/Hanger Fashion.png";
import category7 from "../../../assets/icons/categoryIcons/Study lamp.png";
import category8 from "../../../assets/icons/categoryIcons/User Security.png";
import category9 from "../../../assets/icons/categoryIcons/Medicine.png";
import category10 from "../../../assets/icons/categoryIcons/Tools.png";
import category11 from "../../../assets/icons/categoryIcons/Trees.png";
import category12 from "../../../assets/icons/categoryIcons/GasStation.png";
import category13 from "../../../assets/icons/categoryIcons/Hockey stick growth.png";
import category14 from "../../../assets/icons/categoryIcons/Technology.png";
import category15 from "../../../assets/icons/categoryIcons/Cutlery.png";
import category16 from "../../../assets/icons/categoryIcons/Website.png";
import category17 from "../../../assets/icons/categoryIcons/Boy Graduation.png";

export default [
  category1,
  category2,
  category3,
  category4,
  category5,
  category6,
  category7,
  category8,
  category9,
  category10,
  category11,
  category12,
  category13,
  category14,
  category15,
  category16,
  category17,
];
