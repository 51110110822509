import { useMutation, useQuery } from "react-query";
import {
  dataToSendInterface,
  optionalDataToSendInterface,
} from "../../containers/Buyer/CreateReview/createReviewForm.utils";
import { API } from "../../services/API";
import { EditReviewInterface } from "../../containers/Buyer/EditReview/EditReviewForm.utils";

const postReview = async (data: dataToSendInterface) => {
  const url = "/reviews";
  const { data: response } = await API.post(url, data);
  return response;
};
export const usePostReview = () => {
  return useMutation(postReview);
};

const editReview = async (data: EditReviewInterface) => {
  const url = `/reviews/${data.id}`;
  const { data: response } = await API.put(url, data);
  return response;
};
export const useEditReview = () => {
  return useMutation(editReview);
};

const OptionalReview = async (
  data: optionalDataToSendInterface,
  reviewId: number
) => {
  const url = `/reviews/optionalReview/${reviewId}`;
  const { data: response } = await API.put(url, data);
  return response;
};
export const useOptionalReview = () => {
  return useMutation(
    (data: { data: optionalDataToSendInterface; reviewId: number }) =>
      OptionalReview(data.data, data.reviewId)
  );
};

export interface reviewsInterface {
  id: number;
  overallRating: number;
  isAnonymous: boolean;
  isHiddenOnProfile: boolean;
  text: string;
  createdAt: Date;
  updatedAt: Date;
  buyer?: {
    county: string | null;
    community: string | null;
    user: {
      id: number;
      firstName: string;
      lastName: string;
    };
  };
  ReviewLikes?: {
    id: number;
    buyerId: number;
    reviewId: number;
    createdAt: Date;
    updatedAt: Date;
  }[];
}

interface Review {
  id: number;
  buyerId: number;
  organisationId: number;
  suntCategoryId: number;
  categoryId: number;
  agreementId: number | null;
  overallRating: number;
  qualityRating: number;
  pricingRating: number;
  customerServiceRating: number;
  sustainabilityRating: number;
  innovationRating: number;
  deliverCapacity: number;
  conditionsRating: number;
  recommendation: number;
  isAnonymous: boolean;
  isHiddenOnProfile: boolean;
  text: string;
  verified: boolean;
  createdAt: Date;
  updatedAt: Date;
  organisation: {
    name: string;
  };
  agreement: {
    estimatedValue: number;
    endDate: Date;
    startDate: Date;
    id: number;
    identifier: string;
  };
}

interface QueryParamsReview {
  suntCategoryId: number;
  organisationId: number;
  categoryIds?: number[];
  reviewGrades?: number[];
  position?: string[];
  founded_date_filter_start?: string;
  founded_date_filter_end?: string;
}
const getReviews = async (params: QueryParamsReview) => {
  const url = `/categories/${params.suntCategoryId}/organisations/${params.organisationId}/reviews`;
  const { data }: { data: reviewsInterface[] } = await API.get(url, {
    params,
  });
  return data;
};
export const useGetReviews = (params: QueryParamsReview) => {
  return useQuery(["getReviews", params], () => getReviews(params));
};

const getPositionToOrganisation = async (
  suntCategoryId: string,
  id: string
) => {
  const url = `/organisations/${id}/${suntCategoryId}/get-position`;
  const { data } = await API.get<unknown, { position: string }[]>(url);
  return data;
};
export const useGetPositionToOrganisation = (
  suntCategoryId: string,
  id: string
) => {
  return useQuery(["getPositionToOrganisation", suntCategoryId, id], () =>
    getPositionToOrganisation(suntCategoryId, id)
  );
};

interface likeOrUnlikeReviews {
  reviewId: number;
  buyerId: number;
  action: string;
}

const likeOrUnlikeReviews = async (data: likeOrUnlikeReviews) => {
  const url = `/reviews/${data.buyerId}/likeReview/${data.reviewId}`;
  const { data: response } = await API.post(url, data);
  return response;
};
export const useLikeOrUnlikeReviews = () => {
  return useMutation(likeOrUnlikeReviews);
};

interface getSupplierReviews {
  sortBy: string;
  orderBy: string;
  page?: number;
  size?: number;
}

const getSupplierReviews = async (params: getSupplierReviews) => {
  const url = "/reviews";
  const { data } = await API.get(url, { params });
  return data;
};

export const useGetSupplierReviews = (params: getSupplierReviews) => {
  return useQuery(["getSupplierReviews", params], () =>
    getSupplierReviews(params)
  );
};

const getSupplierReview = async (reviewId: number | undefined) => {
  if (!reviewId) {
    return null;
  }
  const url = `/reviews/${reviewId}`;
  const { data }: { data: Review } = await API.get(url);

  return data;
};

export const useGetSupplierReview = (reviewId: number | undefined) => {
  return useQuery(["getSupplierReview", reviewId], () =>
    getSupplierReview(reviewId)
  );
};

const postSupplierReviewReply = async (data: {
  supplierId: number;
  reviewId: number;
  text?: string;
}) => {
  const url = `/reviews/answer?supplierId=${data.supplierId}&reviewId=${data.reviewId}`;
  const { data: response } = await API.post(url, { text: data.text });
  return response;
};
export const usePostSupplierReviewReply = () => {
  return useMutation(postSupplierReviewReply);
};

// get reviews related to organisation on supplier side
const getOrganisationReivews = async (organisationId: string) => {
  const url = `/reviews/organisation/${organisationId}`;
  const { data } = await API.get(url);
  return data;
};
export const useGetOrganisationReviews = (organisationId: string) => {
  return useQuery(["getOrganisationReviews", organisationId], () =>
    getOrganisationReivews(organisationId)
  );
};
