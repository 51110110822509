import React from "react";
import { StyleSheet } from "../../../../models/StyleSheet";

export const Stage = ({
  children,
  label,
  stage,
  onClick,
}: {
  children: JSX.Element;
  label: string;
  stage: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <div>
      <div style={styles.stage} onClick={onClick}>
        {children}
        <p style={styles.label}>{label}</p>
      </div>
      <p style={styles.text}>{stage}</p>
    </div>
  );
};

const styles = StyleSheet.create({
  stage: {
    width: "152px",
    height: "120px",
    backgroundColor: "#F2F2F5",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    paddingTop: "20px",
    alignItems: "center",
  },
  label: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontHeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    color: "#808795",
    marginTop: "5px",
    textAlign: "center",
  },
  text: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontHeight: "700",
    fontSize: "24px",
    lineHeight: "26px",
    color: "#008593",
    textAlign: "center",
    marginTop: "30px",
  },
});
