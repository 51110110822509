import React, { useMemo } from "react";
import { useState } from "react";
import { useTranslation } from "../../../../hooks/useTranslation";
import { useNavigate } from "react-router";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../../services/notificationService";
import style from "../styles/style.module.css";
import { useAddSuntCategory } from "../../../../hooks/api/Admin";
import { message, Select, UploadProps } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { PlusCircleFilled } from "@ant-design/icons";
import { useSuntGetCategories } from "../../../../hooks/api/Category";

const SuntCategory = () => {
  const [suntCategory, setSuntCategory] = useState("");
  const [suntCategoryName, setSuntCategoryName] = useState("");

  const { t } = useTranslation("addCategory");
  const navigate = useNavigate();

  const { mutate: suntCategoryMutate } = useAddSuntCategory();

  const { data: categoryNamesData } = useSuntGetCategories();
  const renderedCategoryOptions = useMemo(
    () =>
      categoryNamesData
        ?.filter((category) => !!category.name)
        .map((category) => {
          return { value: category.name, id: category.id };
        }),
    [categoryNamesData]
  );
  const suntCategoryId = useMemo(
    () =>
      categoryNamesData?.find((option) => option.name === suntCategoryName)?.id,
    [suntCategoryName, categoryNamesData]
  );

  const props: UploadProps = {
    name: "file",
    disabled: suntCategoryName == "" ? true : false,
    multiple: true,
    action: `https://api-dev.sunt.euvic.cloud/api/supplier/upload-sunt-category-logo/${
      suntCategoryId ? suntCategoryId : 0
    }`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        // await message.success(`${info.file.name} file uploaded successfully.`);
        DisplaySuccessNotification(t("UploadLogoSuccess"));
        window.location.reload();
      } else if (status === "error") {
        // await message.error(`${info.file.name} file upload failed.`);
        DisplayErrorNotification(t("UploadLogoError"));
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleSuntCategorySubmit = () => {
    const dataToSend = {
      suntCategory,
    };

    suntCategoryMutate(dataToSend, {
      onSuccess: () => {
        DisplaySuccessNotification(t("addCategorySuccess"));
        navigate("/dashboard");
      },
      onError: () => {
        DisplayErrorNotification(t("addCategoryError"));
      },
    });
  };

  return (
    <>
      <p className={style.heading}>Add Sunt Category</p>

      <div className={style.row}>
        <div className={style.row}>
          <div className={style.col}>
            <p className={style.inputLabel}>Enter Sunt category name</p>
            <input
              className={style.input}
              type="text"
              placeholder="Enter sunt category"
              value={suntCategory}
              onChange={(e) => {
                setSuntCategory(e.target.value);
              }}
            />
          </div>

          <div className={style.col}>
            <button
              className={style.SuntCategoryBtn}
              type="submit"
              onClick={() => {
                handleSuntCategorySubmit();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <p className={style.heading}>Upload Sunt Category Logo</p>

      <div className={style.row}>
        <div className={style.col}>
          <p className={style.inputLabel}>Select Sunt Category</p>
          <div className={style.supplierBox}>
            <div className={style.box}>
              <div className={`${style.agreementSelect}`}>
                <Select
                  className={style.select}
                  size={"large"}
                  onChange={(value) => setSuntCategoryName(value)}
                  value={suntCategoryName}
                  options={
                    renderedCategoryOptions ? renderedCategoryOptions : []
                  }
                  dropdownClassName={style.categoryDropdown}
                ></Select>
              </div>
            </div>
          </div>
        </div>
        <div className={style.col}>
          <p className={style.inputLabel}>Upload Sunt Category Logo</p>
          <div className={suntCategoryName ? "" : style.border}>
            <Dragger {...props}>
              <div className={style.uploadInput}>
                <p className="ant-upload-drag-icon">
                  <PlusCircleFilled />
                </p>
                <p className="ant-upload-text">
                  Browse eller drag PNG, JPG 150px x 150px...
                </p>
              </div>
            </Dragger>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuntCategory;
