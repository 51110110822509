import React, { useMemo, useState } from "react";
import SubCategory from "./components/SubCategory";
import SuntCategory from "./components/SuntCategory";

export const AddSubCategory = () => {
  return (
    <>
      <SuntCategory />
      <SubCategory />
    </>
  );
};
