export enum AccountType {
  Buyer = "Buyer",
  Supplier = "Supplier",
  Admin = "Admin",
}

export enum AuthStatus {
  Pending = "pending",
  Error = "error",
  Success = "success",
}

export enum Gender {
  Male = "male",
  Female = "female",
  Undisclosed = "undisclosed",
}

export enum SubscriptionStatus {
  Active = "active",
  Canceled = "canceled",
  Default = "default",
}
