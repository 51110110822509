import React, { useEffect, useState } from "react";
import { StyleSheet } from "../../../models/StyleSheet";
import { ReviewItem } from "./components/ReviewItem";
import { useGetReviews } from "../../../hooks/api/Reviews";
import { useTranslation } from "../../../hooks/useTranslation";
import { QueryInterface } from "./index";
import { Conditional, SpinnerLoader, StarRating } from "../../../components";
import { ReviewsFilter } from "../ReviewsFilter";
import { Col, Row } from "antd";
import cssStyle from "./styles/style.module.css";
import { StarFilled } from "@ant-design/icons";
import { colors } from "../../../constants/colors";

export const ReviewsList = ({
  suntCategoryId,
  organisationId,
}: {
  suntCategoryId: string;
  organisationId: string;
}) => {
  const [query, setQuery] = useState<[QueryInterface, boolean | undefined]>([
    {},
    true,
  ]);

  const [catNames, setCatNames] = useState<string[]>([]);

  const { data: reviews, isLoading } = useGetReviews({
    suntCategoryId: parseInt(suntCategoryId),
    organisationId: parseInt(organisationId),
    categoryIds: query[0] && query[0].categoryIds ? query[0].categoryIds : [],
    reviewGrades:
      query[0] && query[0].reviewsGrades ? query[0].reviewsGrades : [],
    position: query[0] && query[0].position ? query[0].position : [],
    founded_date_filter_start:
      query[0] && query[0].founded_date_filter_start
        ? query[0].founded_date_filter_start
        : "1970-01-01",
    founded_date_filter_end:
      query[0] && query[0].founded_date_filter_end
        ? query[0].founded_date_filter_end
        : new Date(Date.now() + 3600 * 1000 * 24).toISOString().split("T")[0],
  });

  const { t } = useTranslation("supplierReviews");

  return (
    <div style={styles.container}>
      <Row>
        <Col span={5}>
          <ReviewsFilter setQuery={setQuery} setCatNames={setCatNames} />
        </Col>
        <Col span={19}>
          {(query[0].categoryIds?.length !== 0 ||
            query[0].reviewsGrades?.length !== 0 ||
            query[0].position?.length !== 0 ||
            query[0].founded_date_filter_start ||
            query[0].founded_date_filter_end) && (
            <div className={cssStyle.capsuleBox}>
              {catNames &&
                catNames.map((names) => {
                  return (
                    <div key={names} className={cssStyle.capsuleBtn}>
                      <span>{names}</span>
                    </div>
                  );
                })}
              {query[0]?.reviewsGrades &&
                query[0]?.reviewsGrades.map((reviewsGrades) => {
                  return (
                    <div key={reviewsGrades} className={cssStyle.capsuleBtn}>
                      <StarRating
                        disabled={true}
                        value={reviewsGrades}
                        color={colors.primary}
                        size={12}
                      />
                      <span style={styles.reviewGrade}>{reviewsGrades}</span>
                    </div>
                  );
                })}
              {query[0]?.position &&
                query[0]?.position.map((position) => {
                  return (
                    <div key={position} className={cssStyle.capsuleBtn}>
                      <span>{position}</span>
                    </div>
                  );
                })}
              {query[0]?.founded_date_filter_start && (
                <div className={cssStyle.capsuleBtn}>
                  <span>Date from: {query[0]?.founded_date_filter_start}</span>
                </div>
              )}
              {query[0]?.founded_date_filter_end && (
                <div className={cssStyle.capsuleBtn}>
                  <span>Date to: {query[0]?.founded_date_filter_end}</span>
                </div>
              )}
            </div>
          )}
          <div style={styles.reviewsContainer}>
            <Conditional
              condition={isLoading}
              trueRender={<SpinnerLoader />}
              falseRender={
                <>
                  {reviews &&
                    reviews.map((review) => (
                      <ReviewItem key={review.id} review={review} />
                    ))}
                </>
              }
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    marginTop: "15px",
  },
  option: {
    width: "50%",
    textAlign: "center",
    padding: "15px",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
  },
  reviewsContainer: {
    // minWidth: "1219px",
    marginLeft: "20px",
  },
  filterBox: {
    padding: "25px 0px 25px 25px",
  },
  reviewGrade: { marginLeft: "10px" },
});
