import React from "react";
import { Input as AntInput, InputProps as AntInputProps } from "antd";
import { StyleSheet } from "../../models/StyleSheet";
import { SearchIcon } from "../../assets";

interface SearchbarProps extends AntInputProps {
  placeholder: string;
}

export function Searchbar({ placeholder, onChange, value }: SearchbarProps) {
  return (
    <div style={styles.searchbar}>
      <div style={styles.icon}>
        <SearchIcon width={16} />
      </div>
      <AntInput
        style={styles.input}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </div>
  );
}

const styles = StyleSheet.create({
  searchbar: {
    height: 32,
    position: "relative",
  },
  icon: {
    top: 4,
    left: 8,
    position: "absolute",
    zIndex: 100,
  },
  input: {
    paddingLeft: 48,
    fontFamily: "D-DIN",
    height: 32,
    width: 280,
    borderRadius: 4,
  },
});
