import React, { useMemo, useState } from "react";
import { LinkedinIcon, FacebookIcon, GoogleIcon, ArrowLeft } from "./icons";
import Icon, {
  ArrowLeftOutlined,
  RightOutlined,
  HomeOutlined,
  PhoneOutlined,
  GlobalOutlined,
  EnvironmentOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Col, Row } from "antd";
import SDStyle from "./style.module.css";
import { StarRating } from "../../../components";
import { colors } from "../../../constants/colors";
import { useLocation, useNavigate } from "react-router";
import { OrganisationsData } from "../../../hooks/api/Organisations";
import { InviteSupplier } from "../components/InviteSupplier";
import { useTranslation } from "../../../hooks/useTranslation";

// import { isObject } from "formik";

const TopContainer = ({
  organisation,
}: {
  organisation: OrganisationsData | undefined;
}) => {
  const [viewNumber, setViewNumber] = useState(false);
  const [viewWebsite, setViewWebsite] = useState(false);
  const { t } = useTranslation("supplierProfilePage");
  const founded = organisation?.registrationDate?.substring(0, 4);

  const reviewsNumber = useMemo(
    () => organisation?.reviews.length || 0,
    [organisation]
  );

  const overallRate = useMemo(() => {
    if (organisation?.reviews) {
      return (
        Math.round(
          (organisation?.reviews
            .map((review) => review.overallRating)
            .reduce((a, b) => a + b, 0) /
            organisation?.reviews.length) *
            2
        ) / 2
      );
    }
    return 0;
  }, [organisation]);

  const backHandler = () => {
    history.back();
  };

  const numberHandler = () => {
    if (viewNumber === true) {
      setViewNumber(false);
    } else {
      setViewNumber(true);
    }
  };
  const webisteHandler = () => {
    if (viewWebsite === true) {
      setViewWebsite(false);
    } else {
      setViewWebsite(true);
    }
  };
  return (
    <>
      <div className={SDStyle.topContainerColumn}>
        <div
          onClick={backHandler}
          style={{ cursor: "pointer" }}
          className={SDStyle.navigationContainer}
        >
          <ArrowLeftOutlined size={40} className={SDStyle.navigationArrow} />
          <p className={SDStyle.navigationText}>Tillbaka</p>
        </div>
        <Row>
          <Col md={19} lg={19}>
            <div>
              <div className={SDStyle.topLeftContainer}>
                <div>
                  <Avatar
                    src={organisation?.logoURL}
                    style={{
                      background: organisation?.logoURL ? "white" : "#D9D9D9",
                      width: "89px",
                      height: "89px",
                    }}
                    shape="circle"
                    size={40}
                  >
                    <span className={SDStyle.iconText}>
                      {organisation?.name[0]}
                    </span>
                  </Avatar>
                </div>
                <div>
                  <p className={SDStyle.supplierName}>{organisation?.name}</p>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <EnvironmentOutlined
                      size={40}
                      className={SDStyle.detailsIcon}
                    />
                    <p className={SDStyle.text}>
                      {organisation?.streetName}
                      {", "}
                      {organisation?.postalCode}
                      {"  "} {organisation?.city}
                    </p>
                  </div>
                  <div className={SDStyle.contactDetails}>
                    <PhoneOutlined size={40} className={SDStyle.detailsIcon} />
                    {!viewNumber && (
                      <>
                        <p className={SDStyle.number} onClick={numberHandler}>
                          {t("viewPhoneNumber")}
                        </p>
                      </>
                    )}
                    {viewNumber && (
                      <>
                        <p className={SDStyle.text} onClick={numberHandler}>
                          {organisation?.phoneNumber}
                        </p>
                      </>
                    )}
                    <LinkOutlined className={SDStyle.detailsIcon} />
                    {!viewWebsite && (
                      <>
                        <p
                          className={SDStyle.websiteText}
                          onClick={webisteHandler}
                        >
                          {t("website")}
                        </p>
                      </>
                    )}
                    {viewWebsite && (
                      <>
                        {organisation?.websiteURL !== "Information saknas" ? (
                          <a
                            className={SDStyle.websiteText}
                            onClick={() => {
                              if (organisation?.websiteURL) {
                                parent.open(
                                  `https://${organisation?.websiteURL}/`
                                );
                              }
                              webisteHandler();
                            }}
                          >
                            {organisation?.websiteURL}
                          </a>
                        ) : (
                          <p className={SDStyle.text} onClick={webisteHandler}>
                            Information saknas!
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={5} lg={5} className={SDStyle.rightContainer}>
            <div className={SDStyle.columnContainer}>
              <div className={SDStyle.ratingContainer}>
                <StarRating
                  color={colors.primary}
                  disabled={true}
                  size={24}
                  value={isNaN(overallRate) ? 0.0 : overallRate}
                />
                <p className={SDStyle.ratingText}>
                  {isNaN(overallRate) ? "0" : overallRate}
                </p>
                <span className={SDStyle.ratingText}>
                  {"("}
                  {reviewsNumber <= 1000
                    ? reviewsNumber
                    : `${Math.round(reviewsNumber / 1000)}k`}
                  {")"}
                </span>
              </div>
              <InviteSupplier />
            </div>
          </Col>
        </Row>
      </div>
      {/* middleContainer */}

      <div className={SDStyle.topMiddleContainer}>
        <Row>
          <Col xs={24} sm={15} md={17} lg={17}>
            <div className={SDStyle.topMiddleLeftContainer}>
              <p className={SDStyle.heading}> {t("description")}</p>
              <p className={SDStyle.content}>{organisation?.description}</p>
            </div>
          </Col>
          <Col xs={24} sm={9} md={7} lg={7} className={SDStyle.rightContainer}>
            <div className={SDStyle.columnContainer}>
              <p className={SDStyle.socialHeading}> {t("socials")}</p>
              <div className={SDStyle.topMiddleRightContainer}>
                <Icon
                  component={() => (
                    <img src={LinkedinIcon} className={SDStyle.socialIcons} />
                  )}
                />
                <Icon
                  component={() => (
                    <img src={FacebookIcon} className={SDStyle.socialIcons} />
                  )}
                />
                <Icon
                  component={() => (
                    <img src={GoogleIcon} className={SDStyle.socialIcons} />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* Last Container */}
      <div className={SDStyle.topLastContainer}>
        <div className={SDStyle.topLastContainerContent}>
          <p className={SDStyle.bottomHeading}>{t("founded")}</p>
          <p className={SDStyle.bottomText}>{founded}</p>
        </div>
        <div className={SDStyle.topLastContainerContent}>
          <p className={SDStyle.bottomHeading}>{t("organisationNumber")}</p>
          <p className={SDStyle.bottomText}>
            {organisation?.organisationNumber}
          </p>
        </div>
        <div className={SDStyle.topLastContainerContent}>
          <p className={SDStyle.bottomHeading}>{t("companySize")}</p>
          <p className={SDStyle.bottomText}>{organisation?.employeesNumber}</p>
        </div>
        <div className={SDStyle.topLastContainerContent}>
          <p className={SDStyle.bottomHeading}>{t("annualRevenue")}</p>
          <p className={SDStyle.bottomText}>{organisation?.turnover}</p>
        </div>
        <div className={SDStyle.topLastContainerContent}>
          <p className={SDStyle.bottomHeading}>{t("companyType")}</p>
          <p className={SDStyle.bottomText}>{organisation?.organisationForm}</p>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default TopContainer;
