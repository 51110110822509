import React from "react";
import { StyleSheet } from "../../../models/StyleSheet";
import { Field } from "formik";
export const Input = ({
  name,
  label,
  // placeholder,
  type,
  value,
  handleChange,
  error,
  touched,
}: {
  name: string;
  label: string;
  // placeholder: string;
  type: string;
  value: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string | undefined;
  touched?: boolean | undefined;
}) => {
  return (
    <>
      <div style={styles.inputContainer}>
        <label style={styles.label} htmlFor={name}>
          {label}
        </label>
        <Field
          style={
            error && touched
              ? { ...styles.input, ...styles.errorInput }
              : styles.input
          }
          type={type}
          placeholder={""}
          id={name}
          name={name}
          value={value}
          onChange={handleChange}
        />
      </div>
      {error && touched ? <div style={styles.error}>{error}</div> : null}
    </>
  );
};
const styles = StyleSheet.create({
  inputContainer: {
    textAlign: "left",
    padding: "5px 8px",
  },
  label: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "22px",
    letterSpacing: "0.07em",
    textAlign: "left",
  },
  input: {
    width: "100%",
    height: "40px",
    outline: "none",
    border: "1px solid rgba(100, 104, 111, 0.25)",
    borderRadius: "6px",
    padding: "8px 16px",
  },
  errorInput: {
    boxShadow: "0px 1px 10px rgba(256, 0, 0, 0.5)",
  },
  error: {
    textAlign: "left",
    marginLeft: "8px",
    color: "red",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
  },
});
