import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import "../../../../css/mainContainer.css";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import {
  Layout,
  Button,
  Row,
  Menu,
  Avatar,
  Select,
  Space,
  Divider,
  Image,
  AutoComplete,
} from "antd";
import Icon, {
  LogoutOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import cssStyle from "./styles/topbar.module.css";
import { useAuthState } from "../../../../hooks/useAuthState";
import {
  // MessagesIcon,
  // NotificationIcon,
  SettingsIcon,
} from "../../../../assets";
import { colors } from "../../../../constants/colors";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "../../../../hooks/useTranslation";
import { StyleSheet } from "../../../../models/StyleSheet";
import { H3, Searchbar } from "../../../../components";
import LogoImage from "../../../../assets/images/LOGO.png";
import BetaImage from "../../../../assets/images/BETA.png";
import SampleImage from "../../../../assets/images/SAMPLE_IMAGE.png";
import { string } from "yup";
import {
  useSuntGetCategories,
  useGetAllSuppliers,
} from "../../../../hooks/api/Category";
import { storage } from "../../../../services/storage";
import SupplierSearch from "../../../../components/notGeneral/SupplierSearch";

const { Header } = Layout;

export function TopBar() {
  const [searchValue, setSearchValue] = useState("");
  const { data: categoriesData } = useSuntGetCategories();
  const [showSearchbar, setShowSearchbar] = useState(false);
  const { t } = useTranslation("topBar");
  const { logout, accountType, user } = useAuthState();
  const navigate = useNavigate();
  const User = storage.getAuth();

  const show =
    window.location.href.endsWith("/categories") || User.role === "Admin";

  useEffect(() => {
    setShowSearchbar(!show);
  }, [show]);

  const handleSupport = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const embedURL = "/spaces?layout=default";
      const iframeSrc = `https://community.sunt.tech/api/auth/sso?jwt=${token}&redirect_uri=${encodeURIComponent(
        embedURL
      )}`;
      window.location.href = iframeSrc;
    }
  };

  // custom logout icon
  const logoutSvg = () => (
    <svg
      width="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6153 5.53339L25 13.0001M25 13.0001L17.6153 20.4667M25 13.0001L6.53843 13M11.0219 1L4.69225 1C2.58236 1 0.99994 2.33333 0.99994 4.46667L0.999939 21.5333C0.999939 23.6667 2.58236 25 4.69225 25H11.0219"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const LogoutCustomIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={logoutSvg} {...props} />
  );

  return (
    <Header className={cssStyle.header}>
      <div className={cssStyle.topbar} style={styles.topBar}>
        <div
          style={styles.imgContainer}
          onClick={() => {
            navigate("/categories");
          }}
        >
          <img src={LogoImage} height={32} style={styles.logoImage} />
          <img src={BetaImage} height={10} style={styles.betaImage} />
        </div>
        {/* <Link to={"/category"}>
          <H3 text={t("home")} style={styles.link} />
        </Link> */}
        {/* <a href={"https://community.sunt.tech/faq-c19ina0b"}>
          <H3 text={t("Support & Feedback")} style={styles.link} />
        </a> */}
        {/* Navigating to Sunt Community */}
        {/* <a onClick={handleSupport}>
          <H3 text={t("Support & Feedback")} style={styles.link} />
        </a> */}

        {/* <a>
          <H3 text={t("Support & Feedback")} style={styles.link} />
        </a> */}
        {accountType !== "Supplier" && (
          <>
            <a href={""}>
              <H3 text={t("categories")} style={styles.link} />
            </a>
            {/* <a onClick={handleSupport}>
              <H3 text={t("community")} style={styles.link} />
            </a> */}
          </>
        )}
      </div>
      {/* <div style={styles.searchBar}>
        <Searchbar placeholder={t("searchbarText")} />
      </div> */}
      {/* <div className={cssStyle.searchBox}>
        <Select
          showSearch
          placeholder="Sök leverantör..."
          optionFilterProp="children"
          onChange={onChange}
          onSearch={onSearch}
          filterOption={(input, option): boolean => {
            return (option?.label ?? "")
              .toLowerCase()
              .includes(input.toLowerCase());
          }}
          className={cssStyle.searchDropdown}
          dropdownRender={(menu) => (
            <>
              <Input
                  placeholder="Please enter item"
                  ref={inputRef}
                  value={name}
                  onChange={onNameChange}
                />
              <div className={cssStyle.searchBoxItem}>
                <Avatar src="https://joeschmoe.io/api/v1/random" />
                <p>dfdewipo sdl</p>
              </div>
              <div className={cssStyle.searchBoxItem}>
                <Avatar src="https://joeschmoe.io/api/v1/random" />
                <p>dfdewipo sdl</p>
              </div>
              <div className={cssStyle.searchBoxItem}>
                <Avatar src="https://joeschmoe.io/api/v1/random" />
                <p>dfdewipo sdl</p>
              </div>
            </>
          )}
          options={[
            {
              value: "jack",
              label: "Jack",
            },
            {
              value: "lucy",
              label: "Lucy",
            },
            {
              value: "tom",
              label: "Tom",
            },
          ]}
        />
        <span className={cssStyle.iconBox}>
          <SearchOutlined className={cssStyle.icon} />
        </span>
      </div> */}
      {/* <div className={cssStyle.searchBox}></div> */}
      {showSearchbar && accountType !== "Supplier" ? (
        <SupplierSearch
          style={cssStyle}
          width={370}
          onSelect={(selected: string) => {
            const arr = selected.split("//");
            navigate(
              `/supplier-reviews/categories/${arr[1]}/organisations/${arr[2]}`
            );
          }}
        />
      ) : (
        // <div className={cssStyle.searchBar}>
        //   <div className={cssStyle.inputContainer}>
        //     <AutoComplete
        //       // className={Style.input}
        //       style={{ width: 370 }}
        //       placeholder="Sök leverantör..."
        //       onChange={setSearchValue}
        //       filterOption={true}
        //       options={newOptions}
        //       bordered={false}
        //       value={searchValue}
        //       dropdownClassName={cssStyle.searchBarDropdown}
        //       onSelect={(selected: string) => {
        //         setSearchValue("");
        //         const arr = selected.split("//");
        //         navigate(
        //           `/supplier-reviews/categories/${arr[1]}/organisations/${arr[2]}`
        //         );
        //       }}
        //     />
        //   </div>
        // </div>
        <></>
      )}
      <Row className={cssStyle.topRightContainer}>
        <>
          {/* {profileImage()} */}
          {/* <Button type="text" style={styles.topRightButton}>
            <NotificationIcon style={styles.icon} />
          </Button> */}
          {/* <Button type="text" style={styles.topRightButton}>
            <MessagesIcon style={styles.icon} />
          </Button> */}

          {/* <Menu
            mode="horizontal"
            style={{ ...styles.subMenu }}
            items={items}
          ></Menu> */}
          <div className={cssStyle.btn}>
            <a href={"https://www.sunt.tech/pris-buyers"}>
              {t("Upgrade account")}
            </a>
          </div>
          {/* <div
            className={cssStyle.logoutBtn}
            onClick={() => {
              logout();
            }}
          ></div> */}
          <div
            className={cssStyle.logoutBtn}
            onClick={() => {
              logout();
            }}
          >
            <LogoutCustomIcon />
          </div>
        </>
      </Row>
    </Header>
  );
}

const styles = StyleSheet.create({
  topBar: {
    backgroundColor: colors.primary,
  },
  imgContainer: {
    marginRight: "70px",
    cursor: "pointer",
  },
  logoImage: {
    position: "absolute",
    top: 15,
    left: 26,
  },
  betaImage: {
    position: "absolute",
    top: 50,
    left: 21,
  },
  link: {
    color: "white",
    fontSize: "16px",
    marginLeft: "32px",
    marginRight: "32px",
    display: "flex",
    justifyContent: "center",
  },
  topRightContainer: {
    width: "100%",
    // justifyContent: "end",
    alignItems: "center",
    border: "1px solid red",
  },
  profileImage: {
    width: 32,
    height: 32,
    borderRadius: "50%",
    cursor: "pointer",
    marginLeft: 10,
    marginRight: 10,
  },
  topRightButton: {
    marginLeft: 10,
    marginRight: 10,
  },
  icon: {
    width: "100%",
  },
  searchBar: {
    height: 32,
    marginBottom: 32,
    display: "flex",
    alignItems: "center",
    lineHeight: "64px",
  },
  subMenu: {
    backgroundColor: colors.primary,
    alignItems: "center",
    lineHeight: "64px",
  },
  menuItemContainer: {},
  settingsIcon: {
    width: "100%",
    marginTop: 25,
  },
});
