import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { AccountType } from "../constants/enums";
import { storage } from "./storage";

const url = process.env.REACT_APP_API_URL ?? "";

const API = axios.create({
  baseURL: url,
  timeout: 10000,
});

API.interceptors.request.use((config) => {
  const { token } = storage.getAuth() as { token: string | null };
  if (token && config.headers != null) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const originalReq = err.config;

      if (
        err.response &&
        err.response.status === 401 &&
        err.config &&
        !err.config.__isRetryRequest
      ) {
        originalReq._retry = true;
        const { token, refreshToken } = storage.getAuth();

        fetch(`${url}/auth/refresh-token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token, refreshToken }),
        })
          .then((res) => res.json())
          .then(
            (res: {
              token: string;
              refreshToken: string;
              role: AccountType;
            }) => {
              if (res.token && res.refreshToken && res.role) {
                storage.setAuth(res.token, res.refreshToken, res.role);
                originalReq.headers.Authorization = `Bearer ${res.token}`;

                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return resolve(API(originalReq));
              } else {
                storage.clearStorage();
                window.location.reload();
              }
            }
          )
          .catch(() => {});
      }
      return reject(err);
    });
  }
);

const get = async <REQ, RES = undefined>(
  url: string,
  config?: AxiosRequestConfig | undefined
): Promise<AxiosResponse<RES>> => {
  return await API.get<REQ, AxiosResponse<RES>>(url, config);
};

const post = async <REQ, RES = undefined>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig | undefined
): Promise<AxiosResponse<RES>> => {
  return await API.post<REQ, AxiosResponse<RES>>(url, data, config);
};

const put = async <REQ, RES = undefined>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig | undefined
): Promise<AxiosResponse<RES>> => {
  return await API.put<REQ, AxiosResponse<RES>>(url, data, config);
};

const patch = async <REQ, RES = undefined>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig | undefined
): Promise<AxiosResponse<RES>> => {
  return await API.patch<REQ, AxiosResponse<RES>>(url, data, config);
};

const api = { get, post, put, patch };

export { api as API };
