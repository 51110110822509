/*eslint-disable*/
import { useQuery } from "react-query";
import { API } from "../../services/API";
import { ListResponseData as OrganisationData } from "./Organisations";

export interface SuntCategoriesData {
  id: number;
  name: string;
  isActive: boolean;
  logoURL: string;
}

interface QueryParams {
  suntCategoryId?: number;
  name?: string;
  sort_by?: string | null;
  order_by?: string;
  page?: number;
  size?: number;
  s?: number[];
  supplier_filter?: boolean | null;
  cpv_filter?: string[];
  cat_name_filter?: string[];
  city_filter?: string[];
  tags_filter?: string[];
  role_filter?: string[];
  review_filter?: number;
  company_type_filter?: string[];
  review_count_filter?: number;
  employee_count_filter_min?: number | null;
  employee_count_filter_max?: number | null;
  founded_date_filter_start?: string | null;
  founded_date_filter_end?: string | null;
  turnover_filter_min?: number | null;
  turnover_filter_max?: number | null;
  public_sector_filter?: string[];
  sme_filter?: string[];
}

const SIZE_PER_PAGE = 100;

const getSuntCategories = async (params?: QueryParams) => {
  const url = "/categories";
  const { data } = await API.get<unknown, SuntCategoriesData[]>(url, {
    params,
  });
  return data;
};

export function useSuntGetCategories(params?: QueryParams) {
  return useQuery(
    ["getCategories", params],
    ({ pageParam = 0 }: { pageParam?: number }) =>
      getSuntCategories({ page: pageParam, size: SIZE_PER_PAGE, ...params })
  );
}

const getAllDetailedCategories = async () => {
  const url = "/categories/detailed-categories";
  const { data } = await API.get<
    unknown,
    { suntCategoryDetailed: string; id: number }[]
  >(url);
  return data;
};

export const useGetAllDetailedCategories = () => {
  return useQuery(["getAllDetailedCategories"], () =>
    getAllDetailedCategories()
  );
};

const getCategoryOrganisations = async (
  suntCategoryId: number,
  params?: QueryParams
) => {
  const url = `/categories/${suntCategoryId}/organisations/include-reviews`;
  const { data } = await API.get<unknown, OrganisationData>(url, {
    params,
  });
  return data;
};

export function useGetCategoryOrganisations(
  suntCategoryId: number,
  params?: QueryParams
) {
  return useQuery(
    ["getCategoryOrganisations", params, suntCategoryId],
    ({ pageParam = 0 }: { pageParam?: number }) =>
      getCategoryOrganisations(suntCategoryId, {
        page: pageParam,
        ...params,
      })
  );
}

interface SupplierList {
  data: { idsAndNames: { id: number; name: string }[]; ids: { id: number }[] };
}

const getCategoryOrganisationsSupplierList = async (
  suntCategoryId: number,
  params?: QueryParams
) => {
  const url = `/categories/${suntCategoryId}/organisations/include-reviews`;
  const { data } = await API.get<unknown, SupplierList>(url, {
    params,
  });
  return data;
};

export function useGetCategoryOrganisationsSupplierList(
  suntCategoryId: number,
  params?: QueryParams
) {
  return useQuery(
    ["getCategoryOrganisationsSupplierList", params, suntCategoryId],
    ({ pageParam = 0 }: { pageParam?: number }) =>
      getCategoryOrganisationsSupplierList(suntCategoryId, {
        page: pageParam,
        ...params,
      })
  );
}

interface Supplier_List {
  data: {
    id: number;
    name: string;
    logoURL: string;
    organisationNumber: string;
    organisationsCategories: [{ category: { suntCategoryId: number } }];
  }[];
}

const getSupplierList = async (query: string) => {
  if (!query) {
    return { data: [] };
  }
  const url = `/categories/search/${query}`;
  const { data } = await API.get<unknown, Supplier_List>(url);

  return data;
};

export function useGetAllSuppliers(query: string) {
  return useQuery(["getAllSuppliers", query], () => getSupplierList(query));
}

interface CategoryInterface {
  id: number;
  suntCategoryId: number;
  suntCategoryDetailed: string;
  swedishName: string;
  iconName: string;
  cpvCode: string;
  isActive: boolean;
  suntCategory: {
    id: number;
    name: string;
  };
}

const getSuntCategory = async (suntCategoryId: number) => {
  const url = `/categories/${suntCategoryId}`;
  const { data }: { data: CategoryInterface } = await API.get(url);
  return data;
};
export const useGetSuntCategory = (suntCategoryId: number) => {
  return useQuery(["getCategory", suntCategoryId], () =>
    getSuntCategory(suntCategoryId)
  );
};

const getCategoryNames = async (suntCategoryId: number) => {
  const url = "/categories/get-names-category";
  const { data } = await API.get<
    unknown,
    { suntCategoryDetailed: string; id: number }[]
  >(url, { params: { suntCategoryId } });
  return data;
};

export const useGetCategoryNames = (suntCategoryId: number) => {
  return useQuery(["getCategoryName", suntCategoryId], () =>
    getCategoryNames(suntCategoryId)
  );
};
const getCategoryCPVCode = async (suntCategoryId: number) => {
  const url = "/categories/get-cpv-category";
  const { data } = await API.get<unknown, { cpvCode: string }[]>(url, {
    params: { suntCategoryId },
  });
  return data;
};

export const useGetCategoryCPVCode = (suntCategoryId: number) => {
  return useQuery(["getCategoryCPV", suntCategoryId], () =>
    getCategoryCPVCode(suntCategoryId)
  );
};

const getNamesCategoryToSuntCategory = async (
  suntCategoryId: string,
  organisationId: string
) => {
  const url = `/categories/${suntCategoryId}/organisation/${organisationId}/get-names-category`;
  const { data } = await API.get<
    unknown,
    { suntCategoryDetailed: string; id: number }[]
  >(url, {
    params: { suntCategoryId, organisationId },
  });
  return data;
};

export const useGetNamesCategoryToSuntCategory = (
  suntCategoryId: string,
  organisationId: string
) => {
  return useQuery(
    ["getNamesCategoryToSuntCategory", suntCategoryId, organisationId],
    () => getNamesCategoryToSuntCategory(suntCategoryId, organisationId)
  );
};

const getAllCategories = async () => {
  const url = "categories/get/all-categories";
  const { data } = await API.get(url);
  return data;
};

export const useGetAllCategories = () => {
  return useQuery(["getAllCategories"], () => getAllCategories());
};

const getAllSniDepartments = async () => {
  const url = "sni/departments";
  const { data } = await API.get(url);
  return data;
};

export const useGetAllSniDepartments = () => {
  return useQuery(["getAllSniDepartments"], () => getAllSniDepartments(), {
    select: (response: any) => {
      // let allSNIs = [];
      return response.map((el: any, i: number) => ({
        id: el.id,
        title: `${el.department} - ${el.name}`,
        key: `0-${i}`,
        children: [],
      }));
    },
  });
};

// Get Levels
export const getSniLevels = async (level: string, previousLevelId: string) => {
  const url = `sni/${level}?previousLevelId=${previousLevelId}`;
  const { data } = await API.get(url);
  return data;
};

// Get complete SNI data
const getAllSniData = async (searchQuery?: any) => {
  const url = searchQuery ? `sni/all?searchQuery=${searchQuery}` : "sni/all";
  console.log(url);
  const { data } = await API.get(url);
  return data;
};

export const useGetAllSniData = (searchQuery?: any) => {
  return useQuery(
    ["getAllSniData", searchQuery],
    () => getAllSniData(searchQuery),
    {
      select: (response: any) => {
        let data = [];
        for (let i = 0; i < response.length; i++) {
          let currentDepart = response[i];
          let currentDepartData: any = {
            id: currentDepart.id,
            title: `${currentDepart.department} - ${currentDepart.name}`,
            key: `0-${i}`,
            children: [],
          };
          if (currentDepart.SniLevel1.length) {
            for (let j = 0; j < currentDepart.SniLevel1.length; j++) {
              let currentFirstLevel = currentDepart.SniLevel1[j];
              currentDepartData.children.push({
                id: currentFirstLevel.id,
                title: `${currentFirstLevel.code} - ${currentFirstLevel.name}`,
                code: currentFirstLevel.code,
                key: `0-${i}-${j}`,
                children: [],
              });
              if (currentFirstLevel.SniLevel2.length) {
                for (let k = 0; k < currentFirstLevel.SniLevel2.length; k++) {
                  let currentSecondLevel = currentFirstLevel.SniLevel2[k];
                  currentDepartData.children[j].children.push({
                    id: currentSecondLevel.id,
                    title: `${currentSecondLevel.code} - ${currentSecondLevel.name}`,
                    code: currentSecondLevel.code,
                    key: `0-${i}-${j}-${k}`,
                    children: [],
                  });
                  if (currentSecondLevel.SniLevel3.length) {
                    for (
                      let l = 0;
                      l < currentSecondLevel.SniLevel3.length;
                      l++
                    ) {
                      let currentThirdLevel = currentSecondLevel.SniLevel3[l];
                      currentDepartData.children[j].children[k].children.push({
                        id: currentThirdLevel.id,
                        title: `${currentThirdLevel.code} - ${currentThirdLevel.name}`,
                        code: currentThirdLevel.code,
                        key: `0-${i}-${j}-${k}-${l}`,
                        children: [],
                      });
                      if (currentThirdLevel.SniLevel4.length) {
                        for (
                          let m = 0;
                          m < currentThirdLevel.SniLevel4.length;
                          m++
                        ) {
                          let currentFourthLevel =
                            currentThirdLevel.SniLevel4[m];
                          currentDepartData.children[j].children[k].children[
                            l
                          ].children.push({
                            id: currentFourthLevel.id,
                            title: `${currentFourthLevel.code} - ${currentFourthLevel.name}`,
                            code: currentFourthLevel.code,
                            key: `0-${i}-${j}-${k}-${l}-${m}`,
                            children: [],
                          });
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          data.push(currentDepartData);
        }
        return data;
      },
    }
  );
};
