import React from "react";
import { Typography } from "antd";
import { CSSProperties } from "react";

const { Text } = Typography;

interface H5Props {
  text: string;
  style?: CSSProperties;
}

export function H5({ text, style }: H5Props) {
  return (
    <Text style={{ fontSize: 9, fontFamily: "D-DIN", ...style }}>{text}</Text>
  );
}
