import React, { useEffect } from "react";
import style from "./styles/style.module.css";
import { useState } from "react";
import { SupplierData, useSaveData } from "../../../hooks/api/Suppliers";
// import { usePostSupplierContactInfo } from "../../../hooks/api/Suppliers";
import { storage } from "../../../services/storage";
import {
  useGetCategoryNames,
  useSuntGetCategories,
} from "../../../hooks/api/Category";
import { serviceAreasData } from "./serviceAreas";

import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../services/notificationService";

import BusinessInfo from "./BusinessInfo";
import Categories from "./Categories";
import ServiceArea from "./ServiceArea";
import RequestApproval from "./RequestApproval";
import EditTopBar from "../components/EditTopBar";
import FloatingButtons from "./component/FloatingButtons";
import {
  OrganisationsData,
  useGetOrganisation,
  useGetOrganisationsIncludeReviews,
} from "../../../hooks/api/Organisations";
import { useNavigate, useParams } from "react-router";

const SupplierProfileNew = () => {
  const { organisationId } = useParams();
  const { mutate: saveDataMutate } = useSaveData();
  const { data: organisation } = useGetOrganisation({
    id: parseInt(organisationId ?? ""),
  });

  const [sectionNo, setSectionNo] = useState<number>(0);
  const changeSectionNumber = (number: number) => {
    setSectionNo(number);
  };

  const [organisationForm, setOrganisationForm] = useState("");
  const [SME, setSme] = useState("");
  const [turnover, setTurnover] = useState(0);
  const [description, setDescription] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [facebookURL, setFacebookURL] = useState("");
  const [linkedinURL, setLinkedinURL] = useState("");
  const [googleURL, setGoogleURL] = useState("");
  const [services, setServices] = useState("");
  const [secondLevelCategories, setSecondLevelCategories] = useState("");
  const [branschSNI, setBranschSNI] = useState("");
  const [streetName, setStreetName] = useState("");
  const [city, setCity] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [mobile, setMobile] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [province, setProvince] = useState("");
  const [registrationDate, setRegistrationDate] = useState<Date | null>(null);

  const setFuncitons = {
    setOrganisationForm,
    setSme,
    setTurnover,
    setDescription,
    setWebsiteURL,
    setFacebookURL,
    setLinkedinURL,
    setGoogleURL,
    setStreetName,
    setCity,
    setPhoneNumber,
    setCountry,
    setMobile,
    setPostalCode,
    setProvince,
    setRegistrationDate,
  };

  const businessStates = {
    organisationForm,
    SME,
    turnover,
    description,
    websiteURL,
    facebookURL,
    linkedinURL,
    googleURL,
    streetName,
    city,
    phoneNumber,
    country,
    mobile,
    postalCode,
    province,
    registrationDate,
  };

  //contact
  // const [address, setAddress] = useState("");
  // const [zipCode, setZipCode] = useState("");
  // const [locality, setLocality] = useState("temp city");
  // const [province, setProvince] = useState("");
  // const [land, setLand] = useState("");
  // const [phone, setPhone] = useState("");
  // const [mobile, setMobile] = useState("");

  // const [description, setDescription] = useState("");
  const [companyTypes, setCompanytypes] = useState([
    { value: "Övriga bolagsformer", label: "Övriga bolagsformer" },
    { value: "Aktiebolag", label: "Aktiebolag" },
    {
      value: "Handelsbolag / Kommanditbolag",
      label: "Handelsbolag / Kommanditbolag",
    },
    {
      value: "Enskild firma",
      label: "Enskild firma",
    },
  ]);

  const [companySizes, setCompanySizes] = useState([
    { value: "Mikroföretag", label: "Mikroföretag" },
    {
      value: "Små företag",
      label: "Små företag",
    },
    {
      value: "Medelstora företag",
      label: "Medelstora företag",
    },
    { value: "Stora företag", label: "Stora företag" },
  ]);

  // const { data: categoriesData } = useSuntGetCategories();

  // const { data: CategoryNames } = useGetCategoryNames(
  //   parseInt(suntCategoryId || "")
  // );

  // ++++++++###############+++++++++++++++
  useEffect(() => {
    if (organisation) {
      const orgData: OrganisationsData = organisation;
      const data: SupplierData = {
        organisationId: storage.getUser()?.organisationId,
        organisationData: {
          organisationForm: orgData.organisationForm,
          SME: orgData.SME,
          turnover: orgData.turnover,
          description: orgData.description,
          websiteURL: orgData.websiteURL,
          facebookURL: orgData.facebookURL,
          linkedinURL: orgData.linkedinURL,
          googleURL: orgData.googleURL,
          // temporary
          // services: orgData.services,
          services: "",
          //// /////
          secondLevelCategories: orgData.secondLevelCategories,
          branschSNI: orgData.branschSNI,
          streetName: orgData.streetName,
          postalCode: orgData.postalCode,
          city: orgData.city,
          phoneNumber: orgData.phoneNumber,
          province: orgData.province,
          country: orgData.country,
          mobile: orgData.mobile,
          registrationDate: null,
        },
      };
      localStorage.setItem("supplierData", JSON.stringify(data));

      setDescription(orgData.description as string);
      setOrganisationForm(orgData.organisationForm as string);
      setSme(orgData.SME as string);
      setTurnover(orgData.turnover as number);
      setWebsiteURL(orgData.websiteURL as string);
      setFacebookURL(orgData.facebookURL as string);
      setLinkedinURL(orgData.linkedinURL as string);
      setGoogleURL(orgData.googleURL as string);
      setServices(orgData.services as string);
      setSecondLevelCategories(orgData.secondLevelCategories as string);
      setBranschSNI(orgData.branschSNI as string);
      setStreetName(orgData.streetName as string);
      setPostalCode(orgData.postalCode as string);
      setCity(orgData.city);
      setPhoneNumber(orgData.phoneNumber as string);
      setProvince(orgData.province as string);
      setCountry(orgData.country as string);
      setMobile(orgData.mobile as string);
      setRegistrationDate(null);
    }
  }, [organisation]);

  const handleSubmitSaveData = () => {
    const data: SupplierData = {
      organisationId: storage.getUser()?.organisationId,
      organisationData: {
        organisationForm,
        SME,
        turnover,
        description,
        websiteURL,
        facebookURL,
        linkedinURL,
        googleURL,
        services,
        secondLevelCategories,
        branschSNI,
        streetName,
        postalCode,
        city,
        phoneNumber,
        province,
        country,
        mobile,
        registrationDate,
      },
    };
    localStorage.setItem("supplierData", JSON.stringify(data));
    // saveDataMutate(data, {
    //   onSuccess: () => {
    //     DisplaySuccessNotification("Success");
    //   },
    //   onError: () => {
    //     DisplayErrorNotification("Error");
    //   },
    // });
  };

  const moveSectionFroward = () => {
    handleSubmitSaveData();
    if (sectionNo >= 0 && sectionNo < 3) {
      setSectionNo(sectionNo + 1);
    }
  };
  const moveSectionBackward = () => {
    if (sectionNo > 0 && sectionNo <= 3) {
      setSectionNo(sectionNo - 1);
    }
  };

  // experiment for smooth scroll
  const [isShowTopBar, setIsShowTopBar] = useState<boolean | null>(null);
  useEffect(() => {
    const eventListener = () => {
      breakme: if (window.scrollY >= 140.0) {
        if (isShowTopBar) break breakme;
        setIsShowTopBar(true);
      } else {
        if (!isShowTopBar) break breakme;
        setIsShowTopBar(false);
      }
    };
    window.addEventListener("scroll", eventListener);
    return () => {
      window.removeEventListener("scroll", eventListener);
    };
  }, [isShowTopBar]);

  // useEffect(() => {
  //   const localStoreData = localStorage.getItem("supplierData");
  //   if (localStoreData) {
  //     const { organisationData } = JSON.parse(localStoreData) as SupplierData;
  //     if (organisationData) {
  //       const {
  //         organisationForm,
  //         SME,
  //         turnover,
  //         description,
  //         websiteURL,
  //         facebookURL,
  //         linkedinURL,
  //         googleURL,
  //         services,
  //         secondLevelCategories,
  //         branschSNI,
  //         streetName,
  //         postalCode,
  //         city,
  //         phoneNumber,
  //         province,
  //         country,
  //         mobile,
  //         registrationDate,
  //       } = organisationData;
  //       description && setDescription(description);
  //       organisationForm && setOrganisationForm(organisationForm);
  //       SME && setSme(SME);
  //       turnover && setTurnover(turnover);
  //       websiteURL && setWebsiteURL(websiteURL);
  //       facebookURL && setFacebookURL(facebookURL);
  //       linkedinURL && setLinkedinURL(linkedinURL);
  //       googleURL && setGoogleURL(googleURL);
  //       services && setServices(services);
  //       secondLevelCategories &&
  //         setSecondLevelCategories(secondLevelCategories);
  //       branschSNI && setBranschSNI(branschSNI);
  //       streetName && setStreetName(streetName);
  //       postalCode && setPostalCode(postalCode);
  //       city && setCity(city);
  //       phoneNumber && setPhoneNumber(phoneNumber);
  //       province && setProvince(province);
  //       country && setCountry(country);
  //       mobile && setMobile(mobile);
  //       registrationDate && setRegistrationDate(registrationDate);
  //     }
  //     // console.log(organisationData?.description);
  //   }
  // }, []);

  return (
    <>
      {/* secondary topbar */}
      <div
        className={`${!isShowTopBar ? style.topBar1 : style.topBar2} ${
          !isShowTopBar ? style.topBarShow : style.topBarHide
        }`}
      >
        <EditTopBar sectionNo={sectionNo} navigation={changeSectionNumber} />
      </div>
      {/* floating buttons */}
      <FloatingButtons
        sectionNo={sectionNo}
        next={moveSectionFroward}
        back={moveSectionBackward}
      />
      {/* for smooth scroll on secondary top bar */}
      <div className={`${style.box} ${isShowTopBar ? style.show : style.hide}`}>
        <EditTopBar sectionNo={sectionNo} navigation={changeSectionNumber} />
      </div>
      {/* different sections */}
      {sectionNo === 0 && (
        <BusinessInfo
          setFunctions={setFuncitons}
          companySizes={companySizes}
          companyTypes={companyTypes}
          businessStates={businessStates}
        />
      )}
      {sectionNo === 1 && (
        <Categories
          categories={secondLevelCategories}
          catData={secondLevelCategories}
          onCategories={(catData: string) => {
            setSecondLevelCategories(catData);
          }}
        />
      )}
      {sectionNo === 2 && (
        <ServiceArea
          onServiceAreas={(data: string) => {
            setServices(data);
          }}
        />
      )}
      {sectionNo === 3 && <RequestApproval navigation={changeSectionNumber} />}
    </>
  );
};

export default SupplierProfileNew;
