import React from "react";
import style from "./styles/style.module.css";

type Props = {
  area: { id: number; cities: { code: number; title: string }[] };
};

const ServiceAreas = (props: Props) => {
  const { area } = props;
  return (
    <div className={style.capsuleBox}>
      {area.cities.map((item) => (
        <div key={item.code} className={style.capsuleBtn}>
          <span>{item.title}</span>
          <div className={style.closeIcon}></div>
        </div>
      ))}
    </div>
  );
};

export default ServiceAreas;
