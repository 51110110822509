/*eslint-disable*/
import React, { useEffect, useState } from "react";
import style from "./styles/style.module.css";
import catStyle from "./styles/categories.module.css";
import { Spin, Switch } from "antd";
import SubCategory from "./component/SubCategory";
import {
  useGetAllCategories,
  useGetAllSniData,
  useGetAllSniDepartments,
} from "../../../hooks/api/Category";
import SNITree from "./component/SNI";
import { useTranslation } from "../../../hooks/useTranslation";

type catType = {
  id: number;
  catTitle: string;
  icon: string;
  fullCheck: boolean;
  partialCheck: boolean;
  totalCatCheck: number;
  subCategory: { subCatId: number; title: string; isCheck: boolean }[];
}[];

// using this type for data which is coming from server
type catServerType = {
  id: number;
  isActive: boolean;
  logoURL: string;
  name: string;
  Category: { id: number; suntCategoryDetailed: string; cpvCode: string }[];
}[];

type CategoriesProps = {
  categories: string;
  catData: string;
  onCategories: (catData: string) => void;
};

const Categories = (props: CategoriesProps) => {
  const { categories, catData: catStateData, onCategories } = props;
  const [showOnlySni, setShowOnlySni] = useState(false);
  const [localStoredCatData, setLocalStoredCatData] = useState(catStateData);
  const [categoryData, setCategoryData] = useState<catServerType>();
  const [catData, setCatData] = useState<catType>();
  const { t } = useTranslation("supplierForm");

  const onChange = (checked: boolean) => {
    setShowOnlySni(checked);
  };

  const [data, setData] = useState([
    {
      id: 4,
      title: "Fordon, Transport & Resor",
      sniLetter: "Z",
      partialCheck: false,
      sniCode: [
        { level: 0, code: "01", isCheck: false },
        { level: 1, code: "019", isCheck: false },
        { level: 2, code: "0190", isCheck: false },
        { level: 3, code: "01900", isCheck: false },
      ],
    },
  ]);

  const clickOnCheckBox = (id: number, level: number, isCheck: boolean) => {
    // console.log("Clicked me");
    setData(
      data.map((item) => {
        if (item.id === id) {
          for (let i = level; i < 4; i++) {
            item.sniCode[i].isCheck = isCheck;
          }
          let checkFalseCounter = 0;
          for (let i = 1; i < 4; i++) {
            if (!item.sniCode[i].isCheck) {
              checkFalseCounter++;
            }
          }
          if (checkFalseCounter === 3) {
            item.partialCheck = false;
            item.sniCode[0].isCheck = false;
          } else if (checkFalseCounter > 0 && checkFalseCounter < 3) {
            item.partialCheck = true;
          }
          if (checkFalseCounter === 0) {
            item.partialCheck = false;
            item.sniCode[0].isCheck = true;
          }
          return item;
        } else {
          return item;
        }
      })
    );
  };

  const { data: catServerData } = useGetAllCategories();

  // fetching sni departments from db
  // const {data: sniDepartments, isLoading}: any = useGetAllSniData();

  useEffect(() => {
    setCategoryData(catServerData);
  }, [catServerData]);

  useEffect(() => {
    const newCatData = [] as catType;
    if (categoryData) {
      categoryData.forEach((item) => {
        const cat = {
          id: item.id,
          catTitle: item.name,
          icon: item.logoURL,
          fullCheck: false,
          partialCheck: false,
          totalCatCheck: 0,
          subCategory: [] as {
            subCatId: number;
            title: string;
            isCheck: boolean;
          }[],
        };
        item.Category.forEach((subCat) => {
          cat.subCategory.push({
            subCatId: subCat.id,
            title: subCat.suntCategoryDetailed,
            isCheck: false,
          });
        });
        newCatData.push(cat);
      });
      setCatData(newCatData);
      const localStoredCatData = localStorage.getItem("categoryData");
      if (localStoredCatData) {
        const catStateData = JSON.parse(localStoredCatData) as catType;
        setCatData(catStateData);
      }
    }
  }, [categoryData]);

  const saveCatData = () => {
    const sendingCatData: {
      parentId: number;
      subCats: { id: number; title: string }[];
    } = {
      parentId: 19,
      subCats: [],
    };
    if (catData) {
      catData[0].subCategory.forEach((data) => {
        if (data.isCheck) {
          sendingCatData.subCats.push({
            id: data.subCatId,
            title: data.title,
          });
        }
      });
    }
    localStorage.setItem("categoryData", JSON.stringify(catData));
    localStorage.setItem("sendingCatsData", JSON.stringify(sendingCatData));
    onCategories(JSON.stringify(sendingCatData));
  };

  const subCatCheckHandler = (
    id: number,
    subCatId: number,
    isCheck: boolean
  ) => {
    setCatData(
      catData?.map((item) => {
        if (item.id === id) {
          for (let i = 0; i < item.subCategory.length; i++) {
            if (item.subCategory[i].subCatId == subCatId) {
              item.subCategory[i].isCheck = isCheck;
            }
          }
          // item.subCategory[subCatId].isCheck = isCheck;
          let checkFalseCounter = 0;
          for (let i = 0; i < item.subCategory.length; i++) {
            if (item.subCategory[i].isCheck == false) {
              checkFalseCounter++;
            }
          }
          // item.totalCatCheck = item.subCat.length - checkFalseCounter;
          // checking partialChecker
          if (
            checkFalseCounter > 0 &&
            checkFalseCounter < item.subCategory.length
          ) {
            item.partialCheck = true;
            item.fullCheck = false;
          }
          if (
            checkFalseCounter === 0 ||
            checkFalseCounter === item.subCategory.length
          ) {
            item.partialCheck = false;
          }
          if (checkFalseCounter === 0) {
            item.fullCheck = true;
          }
        }
        return item;
      })
    );
    // localStorage.setItem("selectedCatData", JSON.stringify(catData));
    // onCategories(JSON.stringify(catData));
    saveCatData();
  };

  const catCheckHandler = (id: number, isCheck: boolean) => {
    setCatData(
      catData?.map((item) => {
        if (item.id === id) {
          for (let i = 0; i < item.subCategory.length; i++) {
            item.subCategory[i].isCheck = isCheck;
          }
          item.fullCheck = isCheck;
          item.partialCheck = false;
        }
        return item;
      })
    );
    saveCatData();
  };

  return (
    <div className={style.container}>
      <>
        {/* <h1 className={style.heading}>Välj Kategorier</h1> */}
        {/* <div className={style.subHeadingRow}>
                    <h2 className={style.subHeading}>Välja SNI Kod</h2>
                    <div className={catStyle.switchButtonBox}>
                        <span>View Only Sni</span>
                        <Switch className={catStyle.switchButton} onChange={onChange}/>
                    </div>
                </div> */}
        {/*<SNITree/>*/}

        {/*{sniData?.map((data) => (*/}
        {/*    <SNITree*/}
        {/*        id={data.id}*/}
        {/*        title={data.name}*/}
        {/*    />*/}
        {/*))}*/}

        {/*{data.map((data) => (*/}
        {/*    <TreeBox*/}
        {/*        key={data.id}*/}
        {/*        id={data.id}*/}
        {/*        title={data.title}*/}
        {/*        partialCheck={data.partialCheck}*/}
        {/*        sniLetter={data.sniLetter}*/}
        {/*        sniCode={data.sniCode}*/}
        {/*        sniOnly={showOnlySni}*/}
        {/*        onClick={clickOnCheckBox}*/}
        {/*    />*/}
        {/*))}*/}

        {/* {sniData?.map((data) => (
          <div key={data.id}>
            <span>{data.department}</span>
            <span>{data.name}</span>
          </div>
        ))} */}
        {/* divider */}
        {/* <div className={style.divider} /> */}
        <h1 className={style.heading}>{t("selectCategory")}</h1>
        {catData?.map((data) => (
          <SubCategory
            key={data.id}
            id={data.id}
            icon={data.icon}
            title={data.catTitle}
            fullCheck={data.fullCheck}
            partialCheck={data.partialCheck}
            subCategory={data.subCategory}
            onCatCheck={catCheckHandler}
            onSubCatCheck={subCatCheckHandler}
          />
        ))}
      </>
    </div>
  );
};
// This is a test
export default Categories;
