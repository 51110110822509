import * as Yup from "yup";
import { StyleSheet } from "../../../models/StyleSheet";
import { colors } from "../../../constants/colors";

export const createReviewInitValues = {
  agreement: "",
  text: "",
  isAnonymous: false,
  isHiddenOnProfile: false,
  recommendation: null,
};

const requiredWhenFalse = (field: string, message: string) =>
  Yup.string().when(field, {
    is: false,
    then: (schema) => schema.required(message),
    otherwise: (schema) => schema,
  });

export interface EditReviewInterface {
  id: number;
  categoryId: number;
  overallRating: number;
  text: string;
  isAnonymous: boolean;
  isHiddenOnProfile: boolean;
  qualityRating: number;
  customerServiceRating: number;
  deliverCapacity: number;
  sustainabilityRating: number;
  innovationRating: number;
  pricingRating: number;
  recommendation: number;
}

export const editReviewValidationSchema = Yup.object().shape({
  overallRating: Yup.number().required(),
  text: Yup.string().trim().max(1000),
  identifier: requiredWhenFalse("hideAgreementInputs", "Agreement is required"),
  startDate: requiredWhenFalse("hideAgreementInputs", "Start date is required"),
  endDate: requiredWhenFalse("hideAgreementInputs", "End date is required"),
  estimatedValue: requiredWhenFalse(
    "hideAgreementInputs",
    "Estimated value is required"
  ),
});

export const styles = StyleSheet.create({
  error: {
    margin: "12px 0 0 0",
    color: "red",
  },
  sliderContainer: {
    display: "flex",
  },
  sliderDiv: {
    padding: "10px 20px 10px 20px",
    width: "calc(25% - 40px)",
  },
  customBackground: {
    background: "rgba(196, 196, 196, 0.3)",
  },
  rate: {
    color: colors.primary,
  },
  submitBtn: {
    fontFamily: "D-Din",
    fontStyle: "normal",
    backgroundColor: "#07285D",
    borderRadius: "25px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#FFFFFF",
    width: "220px",
    border: "0",
    height: "32px",
    marginBottom: "20px",
    cursor: "pointer",
  },
  container: {
    marginTop: "32px",
    margin: "32px auto",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
    padding: "32px",
    backgroundColor: "#ffffff",
    minWidth: "900px",
    width: "90%",
  },
  containerRate: {
    marginBottom: "10px",
  },
  reviewSearch: {
    marginBottom: "32px",
  },
  reviewSearchHeader: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "26px",
    margin: "0",
    letterSpacing: "0.07em",
    marginBottom: "8px",
  },
  weakText: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    margin: "0",
    letterSpacing: "0.07em",
    color: "#808795",
    marginBottom: "32px",
  },
  rateValue: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "26px",
    color: "#07285D",
    height: "16px",
    marginLeft: "16px",
  },
  reviewSearchInputsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  reviewInput: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "118.1%",
    minWidth: "300px",
    maxWidth: "600px",
    width: "40%",
    height: "32px",
    padding: "0px 16px",
    border: "0px",
    borderRadius: "4px",
    backgroundColor: "#F2F2F5",
    outline: "none",
  },
  collapseContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  header: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "26px",
    margin: "25px 10px 15px 0",
  },
  collapseBtn: {
    marginTop: "7px",
  },
  icon: {
    marginLeft: "9.04px",
  },
  textArea: {
    width: "100%",
    minHeight: "97px",
    outline: "none",
    resize: "none",
    border: "0",
    background: "#F2F2F5",
    borderRadius: "4px",
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    color: "#808795",
    padding: "8px 15px",
    marginBottom: "64px",
  },
  radioDescription: {
    display: "flex",
    justifyContent: "space-between",
    width: "554px",
    marginTop: "15px",
    marginBottom: "25px",
  },
  radioDescriptionText: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    letterSpacing: "0.07em",
  },
  checkboxText: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#808795",
  },
  checkboxContainer: {
    marginTop: "32px",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "40px",
  },
  btn: {
    width: "160px",
    height: "32px",
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    borderRadius: "4px",
  },
  btnOutline: {
    color: "#07285D",
    border: "1px solid #07285D",
    backgroundColor: "#FFFFFF",
  },
  btnFill: {
    background: "#07285D",
    color: "#FFFFFF",
    marginLeft: "32px",
  },
  select: { width: "300px", marginBottom: "10px" },
});
