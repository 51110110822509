import { SubscriptionStatus } from "./enums";

export const colors = {
  primary: "#07285D", // dark blue
  secondary: "#4FBFC0", //light blue
  successColor: "#52c41a",
  errorColor: "#ff0000",
  textSecondary: "#A7A7A7",
};

export const StatusColors: { [x in SubscriptionStatus]: string } = {
  active: colors.successColor,
  canceled: "gray",
  default: "black",
};
