import React, { useEffect, useState } from "react";
import Attachments from "./Attachments";

import Categories from "./Categories";
import Media from "./Media";
import Services from "./Services";
import SDStyle from "./style.module.css";
import TopContainer from "./TopContainer";
import { useGetOrganisationsIncludeReviews } from "../../../hooks/api/Organisations";
import { useNavigate, useParams } from "react-router";

const SupplierProfileDetails = () => {
  const navigate = useNavigate();
  const { suntCategoryId, organisationId } = useParams();

  const { data, isError } = useGetOrganisationsIncludeReviews({
    id: parseInt(organisationId ?? ""),
    suntCategoryId: parseInt(suntCategoryId ?? ""),
  });
  if (isError) {
    navigate("/");
  }

  return (
    <>
      <div className={SDStyle.container}>
        <div className={SDStyle.topContainer}>
          <TopContainer organisation={data} />
        </div>
        <div className={SDStyle.categoryContainer}>
          <Categories organisation={data} />
        </div>
        <div className={SDStyle.servicesContainer}>
          <Services />
        </div>
        <div className={SDStyle.pdfAttachments}>
          {/* <Attachments organisation={data} /> */}
        </div>
        {/* <div className={SDStyle.mediaAttachments}>
          <Media />
        </div> */}
      </div>
    </>
  );
};

export default SupplierProfileDetails;
