import React from "react";
import { StyleSheet } from "../../models/StyleSheet";

export const DimmedLink = ({
  children,
  link,
  style,
  openInNewPage,
}: {
  children: string;
  link?: string;
  style?: React.CSSProperties;
  openInNewPage?: boolean;
}) => {
  if (openInNewPage) {
    return (
      <a
        style={{ ...styles.link, ...style }}
        target="_blank"
        rel="noopener noreferrer"
        href={link}
      >
        {children}
      </a>
    );
  }
  return (
    <a style={{ ...styles.link, ...style }} href={link}>
      {children}
    </a>
  );
};

const styles = StyleSheet.create({
  link: {
    textDecoration: "underline",
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#808795",
    marginTop: "29px",
  },
});
