export const cities = [
  {
    value: "114",
    label: "Upplands Väsby",
  },
  {
    value: "115",
    label: "Vallentuna",
  },
  {
    value: "117",
    label: "Österåker",
  },
  {
    value: "120",
    label: "Värmdö",
  },
  {
    value: "123",
    label: "Järfälla",
  },
  {
    value: "125",
    label: "Ekerö",
  },
  {
    value: "126",
    label: "Huddinge",
  },
  {
    value: "127",
    label: "Botkyrka",
  },
  {
    value: "128",
    label: "Salem",
  },
  {
    value: "136",
    label: "Haninge",
  },
  {
    value: "138",
    label: "Tyresö",
  },
  {
    value: "139",
    label: "Upplands-Bro",
  },
  {
    value: "140",
    label: "Nykvarn",
  },
  {
    value: "160",
    label: "Täby",
  },
  {
    value: "162",
    label: "Danderyd",
  },
  {
    value: "163",
    label: "Sollentuna",
  },
  {
    value: "180",
    label: "Stockholm",
  },
  {
    value: "181",
    label: "Södertälje",
  },
  {
    value: "182",
    label: "Nacka",
  },
  {
    value: "183",
    label: "Sundbyberg",
  },
  {
    value: "184",
    label: "Solna",
  },
  {
    value: "186",
    label: "Lidingö",
  },
  {
    value: "187",
    label: "Vaxholm",
  },
  {
    value: "188",
    label: "Norrtälje",
  },
  {
    value: "191",
    label: "Sigtuna",
  },
  {
    value: "192",
    label: "Nynäshamn",
  },
  {
    value: "305",
    label: "Håbo",
  },
  {
    value: "319",
    label: "Älvkarleby",
  },
  {
    value: "330",
    label: "Knivsta",
  },
  {
    value: "331",
    label: "Heby",
  },
  {
    value: "360",
    label: "Tierp",
  },
  {
    value: "380",
    label: "Uppsala",
  },
  {
    value: "381",
    label: "Enköping",
  },
  {
    value: "382",
    label: "Östhammar",
  },
  {
    value: "428",
    label: "Vingåker",
  },
  {
    value: "461",
    label: "Gnesta",
  },
  {
    value: "480",
    label: "Nyköping",
  },
  {
    value: "481",
    label: "Oxelösund",
  },
  {
    value: "482",
    label: "Flen",
  },
  {
    value: "483",
    label: "Katrineholm",
  },
  {
    value: "484",
    label: "Eskilstuna",
  },
  {
    value: "486",
    label: "Strängnäs",
  },
  {
    value: "488",
    label: "Trosa",
  },
  {
    value: "509",
    label: "Ödeshög",
  },
  {
    value: "512",
    label: "Ydre",
  },
  {
    value: "513",
    label: "Kinda",
  },
  {
    value: "560",
    label: "Boxholm",
  },
  {
    value: "561",
    label: "Åtvidaberg",
  },
  {
    value: "562",
    label: "Finspång",
  },
  {
    value: "563",
    label: "Valdemarsvik",
  },
  {
    value: "580",
    label: "Linköping",
  },
  {
    value: "581",
    label: "Norrköping",
  },
  {
    value: "582",
    label: "Söderköping",
  },
  {
    value: "583",
    label: "Motala",
  },
  {
    value: "584",
    label: "Vadstena",
  },
  {
    value: "586",
    label: "Mjölby",
  },
  {
    value: "604",
    label: "Aneby",
  },
  {
    value: "617",
    label: "Gnosjö",
  },
  {
    value: "642",
    label: "Mullsjö",
  },
  {
    value: "643",
    label: "Habo",
  },
  {
    value: "662",
    label: "Gislaved",
  },
  {
    value: "665",
    label: "Vaggeryd",
  },
  {
    value: "680",
    label: "Jönköping",
  },
  {
    value: "682",
    label: "Nässjö",
  },
  {
    value: "683",
    label: "Värnamo",
  },
  {
    value: "684",
    label: "Sävsjö",
  },
  {
    value: "685",
    label: "Vetlanda",
  },
  {
    value: "686",
    label: "Eksjö",
  },
  {
    value: "687",
    label: "Tranås",
  },
  {
    value: "760",
    label: "Uppvidinge",
  },
  {
    value: "761",
    label: "Lessebo",
  },
  {
    value: "763",
    label: "Tingsryd",
  },
  {
    value: "764",
    label: "Alvesta",
  },
  {
    value: "765",
    label: "Älmhult",
  },
  {
    value: "767",
    label: "Markaryd",
  },
  {
    value: "780",
    label: "Växjö",
  },
  {
    value: "781",
    label: "Ljungby",
  },
  {
    value: "821",
    label: "Högsby",
  },
  {
    value: "834",
    label: "Torsås",
  },
  {
    value: "840",
    label: "Mörbylånga",
  },
  {
    value: "860",
    label: "Hultsfred",
  },
  {
    value: "861",
    label: "Mönsterås",
  },
  {
    value: "862",
    label: "Emmaboda",
  },
  {
    value: "880",
    label: "Kalmar",
  },
  {
    value: "881",
    label: "Nybro",
  },
  {
    value: "882",
    label: "Oskarshamn",
  },
  {
    value: "883",
    label: "Västervik",
  },
  {
    value: "884",
    label: "Vimmerby",
  },
  {
    value: "885",
    label: "Borgholm",
  },
  {
    value: "980",
    label: "Gotland",
  },
  {
    value: "1060",
    label: "Olofström",
  },
  {
    value: "1080",
    label: "Karlskrona",
  },
  {
    value: "1081",
    label: "Ronneby",
  },
  {
    value: "1082",
    label: "Karlshamn",
  },
  {
    value: "1083",
    label: "Sölvesborg",
  },
  {
    value: "1214",
    label: "Svalöv",
  },
  {
    value: "1230",
    label: "Staffanstorp",
  },
  {
    value: "1231",
    label: "Burlöv",
  },
  {
    value: "1233",
    label: "Vellinge",
  },
  {
    value: "1256",
    label: "Östra Göinge",
  },
  {
    value: "1257",
    label: "Örkelljunga",
  },
  {
    value: "1260",
    label: "Bjuv",
  },
  {
    value: "1261",
    label: "Kävlinge",
  },
  {
    value: "1262",
    label: "Lomma",
  },
  {
    value: "1263",
    label: "Svedala",
  },
  {
    value: "1264",
    label: "Skurup",
  },
  {
    value: "1265",
    label: "Sjöbo",
  },
  {
    value: "1266",
    label: "Hörby",
  },
  {
    value: "1267",
    label: "Höör",
  },
  {
    value: "1270",
    label: "Tomelilla",
  },
  {
    value: "1272",
    label: "Bromölla",
  },
  {
    value: "1273",
    label: "Osby",
  },
  {
    value: "1275",
    label: "Perstorp",
  },
  {
    value: "1276",
    label: "Klippan",
  },
  {
    value: "1277",
    label: "Åstorp",
  },
  {
    value: "1278",
    label: "Båstad",
  },
  {
    value: "1280",
    label: "Malmö",
  },
  {
    value: "1281",
    label: "Lund",
  },
  {
    value: "1282",
    label: "Landskrona",
  },
  {
    value: "1283",
    label: "Helsingborg",
  },
  {
    value: "1284",
    label: "Höganäs",
  },
  {
    value: "1285",
    label: "Eslöv",
  },
  {
    value: "1286",
    label: "Ystad",
  },
  {
    value: "1287",
    label: "Trelleborg",
  },
  {
    value: "1290",
    label: "Kristianstad",
  },
  {
    value: "1291",
    label: "Simrishamn",
  },
  {
    value: "1292",
    label: "Ängelholm",
  },
  {
    value: "1293",
    label: "Hässleholm",
  },
  {
    value: "1315",
    label: "Hylte",
  },
  {
    value: "1380",
    label: "Halmstad",
  },
  {
    value: "1381",
    label: "Laholm",
  },
  {
    value: "1382",
    label: "Falkenberg",
  },
  {
    value: "1383",
    label: "Varberg",
  },
  {
    value: "1384",
    label: "Kungsbacka",
  },
  {
    value: "1401",
    label: "Härryda",
  },
  {
    value: "1402",
    label: "Partille",
  },
  {
    value: "1407",
    label: "Öckerö",
  },
  {
    value: "1415",
    label: "Stenungsund",
  },
  {
    value: "1419",
    label: "Tjörn",
  },
  {
    value: "1421",
    label: "Orust",
  },
  {
    value: "1427",
    label: "Sotenäs",
  },
  {
    value: "1430",
    label: "Munkedal",
  },
  {
    value: "1435",
    label: "Tanum",
  },
  {
    value: "1438",
    label: "Dals-Ed",
  },
  {
    value: "1439",
    label: "Färgelanda",
  },
  {
    value: "1440",
    label: "Ale",
  },
  {
    value: "1441",
    label: "Lerum",
  },
  {
    value: "1442",
    label: "Vårgårda",
  },
  {
    value: "1443",
    label: "Bollebygd",
  },
  {
    value: "1444",
    label: "Grästorp",
  },
  {
    value: "1445",
    label: "Essunga",
  },
  {
    value: "1446",
    label: "Karlsborg",
  },
  {
    value: "1447",
    label: "Gullspång",
  },
  {
    value: "1452",
    label: "Tranemo",
  },
  {
    value: "1460",
    label: "Bengtsfors",
  },
  {
    value: "1461",
    label: "Mellerud",
  },
  {
    value: "1462",
    label: "Lilla Edet",
  },
  {
    value: "1463",
    label: "Mark",
  },
  {
    value: "1465",
    label: "Svenljunga",
  },
  {
    value: "1466",
    label: "Herrljunga",
  },
  {
    value: "1470",
    label: "Vara",
  },
  {
    value: "1471",
    label: "Götene",
  },
  {
    value: "1472",
    label: "Tibro",
  },
  {
    value: "1473",
    label: "Töreboda",
  },
  {
    value: "1480",
    label: "Göteborg",
  },
  {
    value: "1481",
    label: "Mölndal",
  },
  {
    value: "1482",
    label: "Kungälv",
  },
  {
    value: "1484",
    label: "Lysekil",
  },
  {
    value: "1485",
    label: "Uddevalla",
  },
  {
    value: "1486",
    label: "Strömstad",
  },
  {
    value: "1487",
    label: "Vänersborg",
  },
  {
    value: "1488",
    label: "Trollhättan",
  },
  {
    value: "1489",
    label: "Alingsås",
  },
  {
    value: "1490",
    label: "Borås",
  },
  {
    value: "1491",
    label: "Ulricehamn",
  },
  {
    value: "1492",
    label: "Åmål",
  },
  {
    value: "1493",
    label: "Mariestad",
  },
  {
    value: "1494",
    label: "Lidköping",
  },
  {
    value: "1495",
    label: "Skara",
  },
  {
    value: "1496",
    label: "Skövde",
  },
  {
    value: "1497",
    label: "Hjo",
  },
  {
    value: "1498",
    label: "Tidaholm",
  },
  {
    value: "1499",
    label: "Falköping",
  },
  {
    value: "1715",
    label: "Kil",
  },
  {
    value: "1730",
    label: "Eda",
  },
  {
    value: "1737",
    label: "Torsby",
  },
  {
    value: "1760",
    label: "Storfors",
  },
  {
    value: "1761",
    label: "Hammarö",
  },
  {
    value: "1762",
    label: "Munkfors",
  },
  {
    value: "1763",
    label: "Forshaga",
  },
  {
    value: "1764",
    label: "Grums",
  },
  {
    value: "1765",
    label: "Årjäng",
  },
  {
    value: "1766",
    label: "Sunne",
  },
  {
    value: "1780",
    label: "Karlstad",
  },
  {
    value: "1781",
    label: "Kristinehamn",
  },
  {
    value: "1782",
    label: "Filipstad",
  },
  {
    value: "1783",
    label: "Hagfors",
  },
  {
    value: "1784",
    label: "Arvika",
  },
  {
    value: "1785",
    label: "Säffle",
  },
  {
    value: "1814",
    label: "Lekeberg",
  },
  {
    value: "1860",
    label: "Laxå",
  },
  {
    value: "1861",
    label: "Hallsberg",
  },
  {
    value: "1862",
    label: "Degerfors",
  },
  {
    value: "1863",
    label: "Hällefors",
  },
  {
    value: "1864",
    label: "Ljusnarsberg",
  },
  {
    value: "1880",
    label: "Örebro",
  },
  {
    value: "1881",
    label: "Kumla",
  },
  {
    value: "1882",
    label: "Askersund",
  },
  {
    value: "1883",
    label: "Karlskoga",
  },
  {
    value: "1884",
    label: "Nora",
  },
  {
    value: "1885",
    label: "Lindesberg",
  },
  {
    value: "1904",
    label: "Skinnskatteberg",
  },
  {
    value: "1907",
    label: "Surahammar",
  },
  {
    value: "1960",
    label: "Kungsör",
  },
  {
    value: "1961",
    label: "Hallstahammar",
  },
  {
    value: "1962",
    label: "Norberg",
  },
  {
    value: "1980",
    label: "Västerås",
  },
  {
    value: "1981",
    label: "Sala",
  },
  {
    value: "1982",
    label: "Fagersta",
  },
  {
    value: "1983",
    label: "Köping",
  },
  {
    value: "1984",
    label: "Arboga",
  },
  {
    value: "2021",
    label: "Vansbro",
  },
  {
    value: "2023",
    label: "Malung-Sälen",
  },
  {
    value: "2026",
    label: "Gagnef",
  },
  {
    value: "2029",
    label: "Leksand",
  },
  {
    value: "2031",
    label: "Rättvik",
  },
  {
    value: "2034",
    label: "Orsa",
  },
  {
    value: "2039",
    label: "Älvdalen",
  },
  {
    value: "2061",
    label: "Smedjebacken",
  },
  {
    value: "2062",
    label: "Mora",
  },
  {
    value: "2080",
    label: "Falun",
  },
  {
    value: "2081",
    label: "Borlänge",
  },
  {
    value: "2082",
    label: "Säter",
  },
  {
    value: "2083",
    label: "Hedemora",
  },
  {
    value: "2084",
    label: "Avesta",
  },
  {
    value: "2085",
    label: "Ludvika",
  },
  {
    value: "2101",
    label: "Ockelbo",
  },
  {
    value: "2104",
    label: "Hofors",
  },
  {
    value: "2121",
    label: "Ovanåker",
  },
  {
    value: "2132",
    label: "Nordanstig",
  },
  {
    value: "2161",
    label: "Ljusdal",
  },
  {
    value: "2180",
    label: "Gävle",
  },
  {
    value: "2181",
    label: "Sandviken",
  },
  {
    value: "2182",
    label: "Söderhamn",
  },
  {
    value: "2183",
    label: "Bollnäs",
  },
  {
    value: "2184",
    label: "Hudiksvall",
  },
  {
    value: "2260",
    label: "Ånge",
  },
  {
    value: "2262",
    label: "Timrå",
  },
  {
    value: "2280",
    label: "Härnösand",
  },
  {
    value: "2281",
    label: "Sundsvall",
  },
  {
    value: "2282",
    label: "Kramfors",
  },
  {
    value: "2283",
    label: "Sollefteå",
  },
  {
    value: "2284",
    label: "Örnsköldsvik",
  },
  {
    value: "2303",
    label: "Ragunda",
  },
  {
    value: "2305",
    label: "Bräcke",
  },
  {
    value: "2309",
    label: "Krokom",
  },
  {
    value: "2313",
    label: "Strömsund",
  },
  {
    value: "2321",
    label: "Åre",
  },
  {
    value: "2326",
    label: "Berg",
  },
  {
    value: "2361",
    label: "Härjedalen",
  },
  {
    value: "2380",
    label: "Östersund",
  },
  {
    value: "2401",
    label: "Nordmaling",
  },
  {
    value: "2403",
    label: "Bjurholm",
  },
  {
    value: "2404",
    label: "Vindeln",
  },
  {
    value: "2409",
    label: "Robertsfors",
  },
  {
    value: "2417",
    label: "Norsjö",
  },
  {
    value: "2418",
    label: "Malå",
  },
  {
    value: "2421",
    label: "Storuman",
  },
  {
    value: "2422",
    label: "Sorsele",
  },
  {
    value: "2425",
    label: "Dorotea",
  },
  {
    value: "2460",
    label: "Vännäs",
  },
  {
    value: "2462",
    label: "Vilhelmina",
  },
  {
    value: "2463",
    label: "Åsele",
  },
  {
    value: "2480",
    label: "Umeå",
  },
  {
    value: "2481",
    label: "Lycksele",
  },
  {
    value: "2482",
    label: "Skellefteå",
  },
  {
    value: "2505",
    label: "Arvidsjaur",
  },
  {
    value: "2506",
    label: "Arjeplog",
  },
  {
    value: "2510",
    label: "Jokkmokk",
  },
  {
    value: "2513",
    label: "Överkalix",
  },
  {
    value: "2514",
    label: "Kalix",
  },
  {
    value: "2518",
    label: "Övertorneå",
  },
  {
    value: "2521",
    label: "Pajala",
  },
  {
    value: "2523",
    label: "Gällivare",
  },
  {
    value: "2560",
    label: "Älvsbyn",
  },
  {
    value: "2580",
    label: "Luleå",
  },
  {
    value: "2581",
    label: "Piteå",
  },
  {
    value: "2582",
    label: "Boden",
  },
  {
    value: "2583",
    label: "Haparanda",
  },
  {
    value: "2584",
    label: "Kiruna",
  },
];
