import { useMutation } from "react-query";
import { AccountType } from "../../constants/enums";
import { API } from "../../services/API";

export interface LoginDto {
  email: string;
  password: string;
  rememberMe?: boolean;
}

interface LoginUserViewer {
  createdAt: Date;
  firstName: string;
  id: number;
  imageUrl: string;
  lastName: string;
  organisationId?: number;
  role: AccountType;
  updatedAt: Date;
  uuid: string;
}

export interface LoginViewer {
  firebaseToken: string;
  user: LoginUserViewer;
  token: string;
  refreshToken: string;
  isOnboarded: boolean;
}

export interface AuthUser {
  id: number;
  uuid: string;
  firstName: string;
  lastName: string;
  isOnboarded: boolean;
  organisationId?: number | undefined;
}

const login = async (reqData: LoginDto) => {
  const url = "/auth/login";
  const { data } = await API.post<LoginDto, LoginViewer>(url, reqData);
  return data;
};

export function useLogin() {
  return useMutation(login);
}

export interface ForgotPasswordDto {
  email: string;
}

const forgotPassword = async (reqData: ForgotPasswordDto) => {
  const url = "/auth/forgot-password";
  const { data } = await API.post(url, reqData);
  return data;
};

export function useForgotPassword() {
  return useMutation(forgotPassword);
}
