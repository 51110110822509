import React from "react";
import { useTranslation } from "../../../hooks/useTranslation";
import { StyleSheet } from "../../../models/StyleSheet";
import icon from "../../../assets/icons/PlantIcon.svg";
import arrow from "../../../assets/icons/SimpleArrowLeft.svg";
import { useNavigate } from "react-router";
import { DefaultButton } from "../../../components";

export function ForgotPasswordSummary({ email }: { email: string }) {
  const { t } = useTranslation("forgotPasswordPage");
  const navigate = useNavigate();
  const navigateToLogin = () => {
    navigate("/login");
  };

  return (
    <>
      <div style={styles.container}>
        <div style={styles.headerContainer}>
          <div style={styles.left}>
            <button style={styles.backButton} onClick={navigateToLogin}>
              <img src={arrow} style={styles.arrow} />
              {t("backButton")}
            </button>
          </div>
          <div style={styles.center}>
            <h1 style={styles.title} className="public-title">
              {t("resendTitle")}
            </h1>
          </div>
        </div>
        <p style={styles.description}>
          {t("resendDescription")} {email}
        </p>
        <a href="/forgot-password">
          <DefaultButton
            text={t("resendButton")}
            style={styles.button}
            type="submit"
            variant="primary"
          />
        </a>
        <div style={styles.dividerContainer}>
          <div style={styles.divider}></div>
          <img src={icon} style={styles.icon} />
          <div style={styles.divider}></div>
        </div>
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    textAlign: "center",
  },
  headerContainer: {
    width: "80%",
    margin: "0 auto",
    display: "flex",
  },
  left: {
    display: "flex",
    justifyContent: "left",
    width: "33%",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    width: "33%",
  },
  title: {
    fontFamily: "D-DIN",
    fontStyle: "bold",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "52px",
    color: "#07285D",
    width: "542px",
  },
  description: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "35.2px",
    letterSpacing: "0.07em",
    margin: "auto 10%",
    marginTop: "56px",
  },
  formContent: {
    height: "39px",
    width: "330px",
    margin: "0 auto",
    marginTop: "15px",
  },
  button: {
    marginTop: "35px",
    marginBottom: "50px",
    width: "315px",
    height: "55px",
    background: "#07285D",
    borderRadius: "4px",
  },
  backButton: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "22px",
    letterSpacing: "0.07em",
    color: "#07285D",
    border: "1px solid #07285D",
    backgroundColor: "#FFFFFF",
    padding: "7.5px",
    borderRadius: "4px",
    height: "55px",
    width: "160px",
  },
  dividerContainer: {
    display: "flex",
    justifyContent: "center",
  },
  divider: {
    width: "130px",
    height: "0px",
    border: "0.75px solid rgba(100, 104, 111, 0.25)",
    display: "flex",
    justifyContent: "center",
    marginTop: "25px",
  },
  icon: {
    width: "22.5px",
    height: "38.46px",
    margin: "0 15px",
  },
  arrow: {
    marginRight: "19px",
  },
});
