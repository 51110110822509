import { Menu } from "antd";
import React, { useState } from "react";
import { StyleSheet } from "../../../models/StyleSheet";
import cssStyle from "./styles/dropdown.module.css";

export const Dropdown = ({
  label,
  children,
  style,
  icon,
  iconActive,
  containerStyle,
}: {
  label: string | JSX.Element;
  children: JSX.Element | string;
  style?: object;
  containerStyle?: object;
  icon?: JSX.Element;
  iconActive?: JSX.Element;
}) => {
  const [open, setOpen] = useState(false);

  const items = [
    {
      label:
        icon && iconActive ? (
          <div style={styles.headerWithIcon}>
            <span style={styles.icon}>{open ? iconActive : icon}</span>
            <p style={styles.labelWithIcon}>{label}</p>
          </div>
        ) : (
          <>{label}</>
        ),
      key: 1,
      style: open
        ? {
            ...styles.menuHeader,
            ...styles.text,
            ...styles.menuHeaderActive,
            ...containerStyle,
          }
        : { ...styles.menuHeader, ...styles.text, ...containerStyle },
      children: [
        {
          label: <div style={style}>{children}</div>,
          key: 2,
          style: { ...styles.menuItem, ...styles.text },
        },
      ],
    },
  ];

  return (
    <div className={cssStyle.filterBoxMenu}>
      <Menu
        style={{ width: "100%" }}
        mode="inline"
        items={items}
        selectable={false}
        onOpenChange={() => {
          setOpen(!open);
        }}
        className={open ? cssStyle.item : ""}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    letterSpacing: "0.07em",
    color: "#000000",
  },
  menuHeader: {
    margin: "0",
    textAlign: "left",
    backgroundColor: "#FFFFFF",
    marginRight: "3px",
  },
  menuHeaderActive: {
    color: "#4FBFC0",
  },
  menuItem: {
    backgroundColor: "#FFFFFF",
    margin: "0",
    padding: "0 0 0 30px",
    height: "fit-content",
  },
  headerWithIcon: {
    display: "flex",
    alignItems: "center",
  },
  labelWithIcon: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    letterSpacing: "0.07em",
    margin: "0",
    marginLeft: "15px",
    wordBreak: "break-word",
    whiteSpace: "normal",
  },
  icon: {
    display: "flex",
    alignItems: "center",
  },
});
