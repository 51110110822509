import { useMutation } from "react-query";
import { API } from "../../services/API";

interface signUpBuyerParams {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  buyerOrganisation: string;
  password: string;
  county: string;
  community: string;
  position: string;
}
const signUpBuyer = async (buyerParams: signUpBuyerParams) => {
  const url = "/auth/register/buyer";
  const { data: response } = await API.post(url, buyerParams);
  return response;
};
export const useSignUpBuyer = () => {
  return useMutation(signUpBuyer);
};

interface signUpSupplierParams {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  organisationNumber: string;
  organisationName: string;
  password: string;
}
const signUpSupplier = async (supplierParams: signUpSupplierParams) => {
  const url = "/auth/register/supplier";
  const { data: response } = await API.post(url, supplierParams);
  return response;
};
export const useSignUpSupplier = () => {
  return useMutation(signUpSupplier);
};
