import React from "react";
import "antd/dist/antd.css";
import { notification } from "antd";
import {
  WarningTwoTone,
  SmileTwoTone,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { colors } from "../constants/colors";
import { i18n } from "../i18n";

export function DisplayErrorNotification(text: string) {
  notification.open({
    message: `${i18n.t("notificationService.error", { ns: "translation" })}`,
    description: text,
    icon: <WarningTwoTone twoToneColor={colors.errorColor} />,
  });
}

export function DisplayNotification(text: string) {
  notification.open({
    message: `${i18n.t("notificationService.message", { ns: "translation" })}`,
    description: text,
    icon: <SmileTwoTone />,
  });
}

export function DisplaySuccessNotification(text: string) {
  notification.open({
    message: `${i18n.t("notificationService.success", { ns: "translation" })}`,
    description: text,
    icon: <CheckCircleTwoTone twoToneColor={colors.successColor} />,
  });
}
