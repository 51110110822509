import * as Yup from "yup";
import { i18n } from "../../../../i18n";

export const initialValues = {
  subject: "",
  message: "",
  link: "",
};

export const validationSchema = Yup.object().shape({
  subject: Yup.string().required(
    i18n.t("validators.required", { ns: "translation" })
  ),
  link: Yup.string().required(
    i18n.t("validators.required", { ns: "translation" })
  ),
});
