import React from "react";
import { StyleSheet } from "../../models/StyleSheet";
import { Input } from "antd";
import cssStyles from "./styles/defaultTextArea.module.css";

const { TextArea } = Input;

export const DefaultTextArea = ({
  placeholder,
  name,
  onChange,
  value,
  defaultValue,
  style,
}: {
  placeholder?: string;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  value?: string;
  defaultValue?: string;
  style?: React.CSSProperties;
}) => {
  return (
    // <textarea
    //   name={name}
    //   onChange={onChange}
    //   value={value}
    //   placeholder={placeholder}
    //   style={{ ...styles.textarea, ...style }}
    //   defaultValue={defaultValue}
    // ></textarea>
    <TextArea
      className={cssStyles.textArea}
      name={name}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      style={{ ...styles.textarea, ...style }}
      defaultValue={defaultValue}
      showCount
      maxLength={1000}
    />
  );
};

const styles = StyleSheet.create({
  textarea: {
    resize: "none",
    width: "100%",
    outline: "none",
    border: "none",
    backgroundColor: "#F2F2F5",
    borderRadius: "4px",
    padding: "19px 24px",
    height: "225px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    // color: "#808795",
    color: "#000",
    maxWidth: "784px",
  },
});
