import { Checkbox } from "antd";
import { FormikErrors, FormikTouched } from "formik";
import React from "react";
import {
  BoldedText,
  DefaultButton,
  DefaultInput,
  DefaultRadio,
  DefaultTextArea,
  DimmedLink,
  DimmedText,
} from "../../../components";
import { SelectDetailedCategories } from "../../../components/notGeneral/SelectDetailedCategories";
import { useTranslation } from "../../../hooks/useTranslation";
import { StyleSheet } from "../../../models/StyleSheet";
import { valuesInterface } from "./TemplateDialogue.utils";
import { useNavigate } from "react-router";

export const TemplateDialogue = ({
  categoryId,
  setCategoryId,
  correctlyCompleted,
  setCorrectlyCompleted,
  values,
  errors,
  touched,
  handleChange,
  customOnSubmit,
  customOnClear,
}: {
  categoryId: number | null;
  setCategoryId: React.Dispatch<React.SetStateAction<number | null>>;
  correctlyCompleted: boolean | null;
  setCorrectlyCompleted: React.Dispatch<React.SetStateAction<boolean | null>>;
  values: valuesInterface;
  errors: FormikErrors<valuesInterface>;
  touched: FormikTouched<valuesInterface>;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  customOnSubmit?: React.MouseEventHandler<HTMLButtonElement>;
  customOnClear?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const { t } = useTranslation("interactDialogue");
  const navigate = useNavigate();

  const options = [
    {
      value: 1,
      boldLabel: t("level", { number: 1 }),
      label: t("level1"),
    },
    {
      value: 2,
      boldLabel: t("level", { number: 2 }),
      label: t("level2"),
    },
    {
      value: 3,
      boldLabel: t("level", { number: 3 }),
      label: t("level3"),
    },
    {
      value: 4,
      boldLabel: t("level", { number: 4 }),
      label: t("level4"),
    },
    {
      value: 5,
      boldLabel: t("level", { number: 5 }),
      label: t("level5"),
    },
  ];

  const subjectChange = (val: number | null) => {
    switch (val) {
      case 1:
        return t("level1");
      case 2:
        return t("level2");
      case 3:
        return t("level3");
      case 4:
        return t("level4");
      case 5:
        return t("level5");
      default:
        return "";
    }
  };
  const messageChange = (val: number | null) => {
    switch (val) {
      case 1:
        return t("messagePlaceholder1");
      case 2:
        return t("messagePlaceholder2");
      case 3:
        return t("messagePlaceholder3");
      case 4:
        return t("messagePlaceholder4");
      case 5:
        return t("messagePlaceholder5");
      default:
        return "";
    }
  };

  const changeValue = (val: valuesInterface, lvl: number) => {
    val.message = messageChange(lvl);
    val.subject = subjectChange(lvl);
  };

  const checkCategory = () => {
    if (categoryId) {
      setCorrectlyCompleted(true);
    } else {
      setCorrectlyCompleted(false);
    }
  };

  return (
    <>
      <div style={styles.textContainer}>
        <BoldedText style={styles.mainText}>
          {t("structureDialogue")}
        </BoldedText>
        <DimmedText>{t("chooseTypeDialogue")}</DimmedText>
      </div>
      <div style={styles.section}>
        <div style={styles.flex}>
          <div>
            <BoldedText style={styles.sectionTextHeader}>
              {t("structure")}
            </BoldedText>
            <DimmedText>{t("structureDescription")}</DimmedText>
          </div>
          <div>
            <SelectDetailedCategories
              categoryId={categoryId}
              setCategoryId={setCategoryId}
              correctlyCompleted={correctlyCompleted}
              onBlur={() => {
                checkCategory();
              }}
              placeholder={t("selectCategories")}
            />
          </div>
        </div>
        <DefaultRadio
          options={options}
          name="level"
          value={values.level}
          onChange={(v) => {
            handleChange(v);
            changeValue(values, Number(v.target.value));
          }}
          error={errors.level}
          touched={touched.level}
          errorText={t("required")}
        />
        <a href="#" style={styles.link}>
          {t("exploreCommunityLink")}
        </a>
      </div>
      <div style={styles.section}>
        <BoldedText style={styles.sectionTextHeader}>{t("subject")}</BoldedText>
        <DimmedText>{t("subjectDescription")}</DimmedText>
        <div style={{ display: "flex" }}>
          <DefaultInput
            type="text"
            style={{ marginRight: "96px" }}
            placeholder={t("write")}
            name="subject"
            value={values.subject}
            onChange={handleChange}
            error={errors.subject}
            touched={touched.subject}
          />
          <DefaultInput
            type="text"
            placeholder={t("linkPlaceholder")}
            name="link"
            value={values.link}
            onChange={handleChange}
          />
        </div>
      </div>
      <div style={styles.section}>
        <BoldedText style={styles.sectionTextHeader}>{t("message")}</BoldedText>
        <DimmedText>{t("writeMessage")}</DimmedText>
        <DefaultTextArea
          placeholder={t("write")}
          name="message"
          value={values.message}
          onChange={handleChange}
        />
      </div>
      <div style={styles.section}>
        <BoldedText style={styles.sectionTextHeader}>
          {t("integrity")}
        </BoldedText>
        <DimmedLink>{t("suntPolicy")}</DimmedLink>
        <div style={{ margin: "15px 0" }}>
          <Checkbox
            name="suntPolicy"
            checked={values.suntPolicy}
            onChange={handleChange}
          >
            <DimmedText>{t("privacyPolicy")}</DimmedText>
          </Checkbox>
          {errors.suntPolicy && touched.suntPolicy ? (
            <div style={styles.errorText}>{t("acceptPrivacyPolicy")}</div>
          ) : null}
        </div>
        <div style={styles.flex}>
          <DefaultButton
            style={styles.btn}
            text={t("backBtn")}
            variant="secondary"
            onClick={() => {
              navigate("/profile");
            }}
          />
          <div>
            <DefaultButton
              style={{ ...styles.btn, ...styles.marginRight }}
              text={t("clearBtn")}
              onClick={customOnClear}
              variant="secondary"
              type="reset"
            />
            <DefaultButton
              style={styles.btn}
              text={t("saveBtn")}
              variant="primary"
              type="submit"
              onClick={(e) => {
                customOnSubmit ? customOnSubmit(e) : null;
                checkCategory();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const styles = StyleSheet.create({
  container: {
    margin: "32px 50px",
  },
  textContainer: {
    display: "flex",
    alignItems: "flex-end",
    marginTop: "70px",
  },
  mainText: {
    marginRight: "20px",
  },
  marginRight: {
    marginRight: "32px",
  },
  section: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    padding: "44px 96px",
    margin: "32px 0",
  },
  sectionTextHeader: {
    // fontSize: "32px",
    // lineHeight: "22px",
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "35px",
    letterSpacing: "0.07em",
    color: "#808795",
    marginBottom: "26px",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
  },
  input: {
    width: "480px",
  },
  link: {
    fontFamily: "D-DIN",
    fontStyle: "italic",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    color: "#4FBFC0",
    textDecoration: "underline",
  },
  btn: {
    width: "220px",
    backgroundColor: "#07285D",
    borderRadius: "22px",
    fontFamily: "d-din",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#fff",
    border: "0px",
    height: "36px",
  },
  backIcon: {
    transform: "translateX(56px)",
  },
  errorText: {
    textAlign: "left",
    marginLeft: "8px",
    color: "red",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
  },
  errorInput: {
    boxShadow: "0px 1px 10px rgba(256, 0, 0, 0.5)",
  },
  error: {
    textAlign: "left",
    marginLeft: "8px",
    color: "red",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    marginTop: "10px",
  },
  smallerContainer: {
    marginBottom: "64px",
  },
  emailContainer: {
    marginBottom: "28px",
  },
  msgContainer: {
    marginBottom: "42px",
  },
  MessageLabel: {
    fontFamily: "D-DIN",
    marginBottom: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "15px",
    letterSpacing: "0.07em",
    color: "#808795",
  },
});
