import React from "react";
import { Typography } from "antd";
import { CSSProperties } from "react";

const { Text } = Typography;

interface H4Props {
  text: string;
  style?: CSSProperties;
}

export function H4({ text, style }: H4Props) {
  return (
    <Text style={{ fontSize: 12, fontFamily: "D-DIN", ...style }}>{text}</Text>
  );
}
