import React, { HTMLInputTypeAttribute } from "react";
import { StyleSheet } from "../../models/StyleSheet";

export const DefaultInput = ({
  type,
  placeholder,
  style,
  name,
  onChange,
  value,
  error,
  touched,
}: {
  type: HTMLInputTypeAttribute;
  placeholder?: string;
  style?: React.CSSProperties;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
  error?: string;
  touched?: boolean;
}) => {
  return (
    <input
      type={type}
      style={
        error && touched
          ? { ...styles.input, ...styles.inputError, ...style }
          : { ...styles.input, ...style }
      }
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      value={value}
    />
  );
};

const styles = StyleSheet.create({
  input: {
    width: "352px",
    height: "32px",
    backgroundColor: " #F2F2F5",
    borderRadius: "4px",
    outline: "none",
    border: "none",
    padding: "6px 18px",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#808795",
  },
  inputError: {
    boxShadow: "0px 1px 10px rgba(256, 0, 0, 0.5)",
  },
});
