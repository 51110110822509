import { Button } from "antd";
import React from "react";
import { StyleSheet } from "../../models/StyleSheet";

export const DefaultButton = ({
  text,
  style,
  onClick,
  type,
  variant,
  loading,
  disabled,
  className = "",
}: {
  text: string;
  style?: object;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  type?: "submit" | "reset" | "button";
  variant: "primary" | "secondary";
  loading?: boolean;
  disabled?: boolean;
}) => {
  return (
    <Button
      className={`pointer ${className}`}
      onClick={onClick}
      style={
        variant === "primary"
          ? { ...styles.btn, ...style }
          : { ...styles.btnSecondary, ...style }
      }
      htmlType={type}
      loading={loading}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

const styles = StyleSheet.create({
  btn: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#FFFFFF",
    border: "0",
    backgroundColor: "#07285D",
    padding: "7.5px",
    borderRadius: "4px",
    height: "32px",
  },
  btnSecondary: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#07285D",
    border: "1px solid #07285D",
    backgroundColor: "#FFFFFF",
    padding: "7.5px",
    borderRadius: "4px",
    height: "32px",
  },
});
