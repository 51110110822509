import React, { useState, useEffect, useMemo } from "react";
import { DefaultButton, DimmedLink, StarRating } from "../../../components";
import { Checkbox, Select, Popover, Input, Radio, DatePicker } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Formik, Form, useFormik } from "formik";
import "../../../css/createReviewForm.css";
import { colors } from "../../../constants/colors";
import {
  createReviewInitValues,
  styles,
  EditReviewInterface,
  editReviewValidationSchema,
} from "./EditReviewForm.utils";
import { useGetCategoryNames } from "../../../hooks/api/Category";
import {
  useGetSupplierReview,
  useOptionalReview,
} from "../../../hooks/api/Reviews";

import { useTranslation } from "../../../hooks/useTranslation";
import { useNavigate, useParams } from "react-router-dom";
import cssStyle from "./styles/style.module.css";
import { StyleSheet } from "../../../models/StyleSheet";
import SupplierSearch from "../../../components/notGeneral/SupplierSearch";
import { useObjectState } from "../../../hooks/useObjectState";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../services/notificationService";
import { useNavigateAndScroll } from "../../../hooks/useNavigateAndScroll";
import dayjs, { Dayjs } from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import moment from "moment";

export const EditReview = () => {
  const TERMS_AND_CONDITIONS_LINK = "https://www.sunt.tech/privacy";

  const { t } = useTranslation("createEditReview");

  const { reviewId = "" } = useParams();

  const [organizationError, setOrganizationError] = useState(false);

  const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);
  const RangePicker = DatePicker.RangePicker;

  const { data: reviewData } = useGetSupplierReview(parseInt(reviewId));

  const { data: categoryNamesData } = useGetCategoryNames(
    reviewData?.suntCategoryId ? reviewData.suntCategoryId : 0
  );

  const navigate = useNavigateAndScroll();

  const { mutate, isLoading } = useOptionalReview();

  useEffect(() => {
    if (!reviewData?.id) return;
  }, [reviewData]);

  const handleSubmit = () => {
    mutate(
      {
        data: {
          qualityRating: formik.values.qualityRating ?? null,
          customerServiceRating: formik.values.customerServiceRating ?? null,
          deliverCapacity: formik.values.deliverCapacity ?? null,
          sustainabilityRating: formik.values.sustainabilityRating ?? null,
          innovationRating: formik.values.innovationRating ?? null,
          pricingRating: formik.values.pricingRating ?? null,
          recommendation: formik.values.recommendation ?? null,
          isAnonymous: formik.values.isAnonymous ?? false,
          isHiddenOnProfile: formik.values.isHiddenOnProfile ?? false,
          categoryId: formik.values.categoryId ?? null,
          overallRating: formik.values.overallRating ?? null,
          text: formik.values.text ?? null,
          agreement: formik.values.hideAgreementInputs
            ? null
            : {
                identifier: formik.values.identifier ?? "",
                estimatedValue: formik.values.estimatedValue ?? 0,
                startDate:
                  formik.values.startDate instanceof Date
                    ? formik.values.startDate.toISOString()
                    : formik.values.startDate ?? "",
                endDate:
                  formik.values.endDate instanceof Date
                    ? formik.values.endDate.toISOString()
                    : formik.values.endDate ?? "",
              },
        },

        reviewId: parseInt(reviewId),
      },
      {
        onSuccess: () => {
          if (!reviewData) return;
          DisplaySuccessNotification(t("reviewUpdateSuccess"));
          navigate(
            `/supplier-reviews/categories/${parseInt(
              reviewData?.suntCategoryId.toString() || ""
            )}/organisations/${reviewData.organisationId}`
          );
        },
        onError: () => {
          DisplayErrorNotification(t("reviewAddError"));
        },
      }
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      categoryId: reviewData?.categoryId,
      overallRating: reviewData?.overallRating,
      text: reviewData?.text,
      isAnonymous: reviewData?.isAnonymous,
      isHiddenOnProfile: reviewData?.isHiddenOnProfile,
      qualityRating: reviewData?.qualityRating,
      customerServiceRating: reviewData?.customerServiceRating,
      deliverCapacity: reviewData?.deliverCapacity,
      sustainabilityRating: reviewData?.sustainabilityRating,
      innovationRating: reviewData?.innovationRating,
      pricingRating: reviewData?.pricingRating,
      recommendation: reviewData?.recommendation,
      hideAgreementInputs: reviewData?.agreementId ? false : true,
      ...(reviewData?.agreementId
        ? {
            ...reviewData?.agreement,
            estimatedValue: parseFloat(
              reviewData?.agreement?.estimatedValue.toString()
            ),
          }
        : {}),
    },
    validationSchema: editReviewValidationSchema,
    onSubmit: handleSubmit,
  });

  const renderedCategoryOptions = useMemo<
    { value: number | null; label: string }[]
  >(
    () => [
      {
        value: null,
        label: "Ingen kategori",
      },
      ...(categoryNamesData
        ?.filter((category) => !!category.suntCategoryDetailed)
        .map((category) => ({
          value: category.id,
          label: category.suntCategoryDetailed,
        })) || []),
    ],
    [categoryNamesData]
  );

  console.log("renderedCategoryOptions", renderedCategoryOptions);

  const popoverContent = (
    <div className={cssStyle.popoverBox} id="popvoerSuplierOverview">
      <p>Att tänka på när du skriver omdömen:</p>
      <ul>
        <li>Var konstruktiv</li>
        <li>Beskriv utförligt</li>
        <li>Var detaljerad</li>
        <li>Utelämna personuppgifter</li>
        <li>Var artig</li>
        <li>Ha rätt uppgifter</li> <li>Korrekturläs</li>
        <li>Justera vid behov</li>
      </ul>
    </div>
  );

  const popoverChange = () => {
    // try to access the popover parent node through the grand child
    // For change the border radius of the parent node.
    const element = document.querySelectorAll("#popvoerSuplierOverview");
    if (element.length > 0) {
      const popoverContainer = element[0]?.parentElement?.parentElement;
      if (popoverContainer) popoverContainer.style.borderRadius = "10px";
    } else {
      setTimeout(() => {
        const element = document.querySelectorAll("#popvoerSuplierOverview");
        const popoverContainer = element[0]?.parentElement?.parentElement;
        if (popoverContainer) popoverContainer.style.borderRadius = "10px";
      }, 500);
    }
  };

  const { TextArea } = Input;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={cssStyle.container}>
        <div>
          <h2 className={cssStyle.heading}>Uppdatera omdöme</h2>
          <div
            className={`${cssStyle.inputBox} ${cssStyle.organizationNameBox}`}
          >
            <h3 className={cssStyle.heading}>Leverantör att bedöma</h3>
            <SupplierSearch
              hideSearchIcon={true}
              disabled={true}
              defaultValue={reviewData?.organisation.name}
              width={510}
              style={cssStyle}
              onSelect={() => {}}
            />
          </div>
          <div className={cssStyle.inputBottomBox}>
            <p
              className={`${cssStyle.validationErrorMsg} ${
                organizationError ? cssStyle.show : cssStyle.hide
              }`}
            >
              {t("selectOrganisationFirst")}
            </p>
          </div>
          <div className={cssStyle.inputBox}>
            <h3 className={cssStyle.heading}>Kategori</h3>

            <div
              style={{ paddingLeft: "0px" }}
              className={`${cssStyle.agreementSelect}`}
            >
              <Select
                placeholder="Välj avtal..."
                className={cssStyle.select}
                size={"large"}
                onChange={(value) => {
                  void formik.setFieldValue("categoryId", value);
                }}
                value={formik.values.categoryId}
                options={renderedCategoryOptions}
                dropdownClassName={cssStyle.categoryDropdown}
              ></Select>
            </div>
            <div className={cssStyle.inputBottomBox}>
              <p className={`${cssStyle.validationErrorMsg} ${cssStyle.hide}`}>
                &lsquo
              </p>
            </div>
          </div>

          <div className={cssStyle.inputBox}>
            <h3 className={cssStyle.heading}>Välj avtal </h3>

            <Checkbox
              checked={formik.values.hideAgreementInputs}
              onChange={(e) =>
                void formik.setFieldValue(
                  "hideAgreementInputs",
                  e.target.checked
                )
              }
            >
              <span style={styles.checkboxText}>
                Saknar information om avtal
              </span>
            </Checkbox>
            <div className={cssStyle.inputBottomBox}>
              <p className={`${cssStyle.validationErrorMsg} ${cssStyle.hide}`}>
                &lsquo;
              </p>
            </div>
          </div>
          {!formik.values.hideAgreementInputs && (
            <>
              <div className={cssStyle.inputBox}>
                <h3 className={cssStyle.heading}>Avtalsnamn / avtalsnummer</h3>
                <input
                  className={`${cssStyle.agreementSelect}`}
                  type="text"
                  placeholder="Avtalsnamn / avtalsnummer"
                  name="identifier"
                  id="identifier"
                  value={formik.values.identifier}
                  onChange={formik.handleChange}
                />
                <div className={cssStyle.inputBottomBox}>
                  <p
                    className={`${cssStyle.validationErrorMsg} ${
                      (formik.touched.identifier || formik.submitCount > 0) &&
                      formik.errors.identifier
                        ? cssStyle.show
                        : cssStyle.hide
                    }`}
                  >
                    Avtalsnamn / avtalsnummer är obligatorisk
                  </p>
                </div>
              </div>

              <div className={cssStyle.inputBox}>
                <h3 className={cssStyle.heading}>Avtalsperiod</h3>
                <RangePicker
                  name="agreementPeriod"
                  id="agreementPeriod"
                  onChange={(dates) => {
                    void formik.setFieldValue(
                      "startDate",
                      dates?.[0]?.toISOString()
                    );
                    setTimeout(() => {
                      void formik.setFieldValue(
                        "endDate",
                        dates?.[1]?.toISOString()
                      );
                    }, 0);
                  }}
                  defaultValue={
                    formik.values.startDate && formik.values.endDate
                      ? [
                          dayjs(formik.values.startDate),
                          dayjs(formik.values.endDate),
                        ]
                      : undefined
                  }
                  placeholder={["Startdatum", "Slutdatum"]}
                  className={`${cssStyle.agreementSelect}`}
                  separator=""
                />

                <div className={cssStyle.inputBottomBox}>
                  <p
                    className={`${cssStyle.validationErrorMsg} ${
                      ((formik.touched.startDate || formik.submitCount > 0) &&
                        formik.errors.startDate) ||
                      ((formik.touched.endDate || formik.submitCount > 0) &&
                        formik.errors.endDate)
                        ? cssStyle.show
                        : cssStyle.hide
                    }`}
                  >
                    Avtalsperiod är obligatorisk
                  </p>
                </div>
              </div>

              <div className={cssStyle.inputBox}>
                <h3 className={cssStyle.heading}>
                  Avtalets uppskattade totala värde i SEK
                </h3>
                <input
                  className={`${cssStyle.agreementSelect}`}
                  type="number"
                  step="0.01"
                  placeholder="Estimerat värde för avtal"
                  name="estimatedValue"
                  id="estimatedValue"
                  value={formik.values.estimatedValue}
                  onChange={formik.handleChange}
                />
                <div className={cssStyle.inputBottomBox}>
                  <p
                    className={`${cssStyle.validationErrorMsg} ${
                      (formik.touched.estimatedValue ||
                        formik.submitCount > 0) &&
                      formik.errors.estimatedValue
                        ? cssStyle.show
                        : cssStyle.hide
                    }`}
                  >
                    Estimerat värde för avtal är obligatorisk
                  </p>
                </div>
              </div>
            </>
          )}

          <div className={cssStyle.inputBox}>
            <h3 className={cssStyle.heading}>
              Ge betyg baserat på dina erfarenheter
            </h3>
            <div className={cssStyle.ratingBox}>
              <StarRating
                key={formik.values.overallRating}
                disabled={false}
                value={formik.values.overallRating}
                style={styles.rate}
                color={colors.primary}
                size={24}
                setValue={(value) => {
                  void formik.setFieldValue("overallRating", value);
                }}
              />
              <span style={styles.rateValue}>
                {formik.values.overallRating}
              </span>
            </div>
          </div>
          <div className={`${cssStyle.experienceBox} ${cssStyle.inputBox}`}>
            <div className={cssStyle.row}>
              <h3 className={cssStyle.heading}>Berätta om dina erfarenheter</h3>
              <Popover
                content={popoverContent}
                placement="bottom"
                trigger="hover"
              >
                <InfoCircleOutlined
                  style={Iconstyle.infoIcon}
                  onMouseEnter={popoverChange}
                />
              </Popover>
            </div>
            <TextArea
              className={`${cssStyle.textarea} ${
                formik.touched.text && formik.errors.text ? cssStyle.error : ""
              } `}
              name="text"
              id="text"
              value={formik.values.text}
              onChange={(e) => {
                void formik.setFieldValue("text", e.target.value);
              }}
              placeholder="Hur var din upplevelse med denna leverantr?
Vad gr den här leverantren bra?
Kom ihåg att vara ärlig, hjälpsam och konstruktiv."
              autoSize={{ minRows: 4 }}
            />
            <div className={cssStyle.inputBottomBox}>
              <p
                className={`${cssStyle.validationErrorMsg} ${
                  formik.touched.text && formik.errors.text
                    ? cssStyle.show
                    : cssStyle.hide
                }`}
              >
                {t("maxdescriptionlength")}
              </p>
              <p className={cssStyle.textCounter}>
                {formik.values.text?.trim().length}/1000
              </p>
            </div>
            {/* {errors.text && (
                    <p
                      className={`${cssStyle.validationErrorMsg} ${
                        errors.text ? cssStyle.show : cssStyle.hide
                      }`}
                    >
                      {errors.text}
                    </p>
                  )} */}
          </div>
          <div className={cssStyle.checkBoxContainer}>
            <div>
              <Checkbox
                checked={formik.values.isAnonymous}
                onChange={(e) => {
                  void formik.setFieldValue("isAnonymous", e.target.checked);
                }}
                name="isAnonymous"
                id="isAnonymous"
              >
                <span style={styles.checkboxText}>
                  {t("dontShowMyProfile")}
                </span>
              </Checkbox>
              <br />
              <Checkbox
                checked={formik.values.isHiddenOnProfile}
                onChange={(e) => {
                  void formik.setFieldValue(
                    "isHiddenOnProfile",
                    e.target.checked
                  );
                }}
                name="isHiddenOnProfile"
                id="isHiddenOnProfile"
                style={{ marginTop: "12px" }}
              >
                <span style={styles.checkboxText}>
                  {t("dontShowMyOpinion")}
                </span>
              </Checkbox>
            </div>
            <div className={cssStyle.link}>
              <DimmedLink openInNewPage={true} link={TERMS_AND_CONDITIONS_LINK}>
                {t("integrityDescription")}
              </DimmedLink>
            </div>
          </div>
          <div className={cssStyle.inputBox}>
            <h3 className={cssStyle.heading}>Ge objektiv bedömning</h3>
            <div className={cssStyle.assessmentContainer}>
              <div className={cssStyle.assessmentBox}>
                <h2>Kvalitet</h2>
                <div className={cssStyle.row}>
                  <span
                    onClick={() => {
                      formik.values.qualityRating == 1
                        ? void formik.setFieldValue("qualityRating", 0)
                        : void formik.setFieldValue("qualityRating", 1);
                    }}
                    style={{
                      backgroundColor:
                        formik.values.qualityRating == 1 ? "#4FBFC0" : "white",
                      color:
                        formik.values.qualityRating == 1 ? "white" : "black",
                    }}
                    className={cssStyle.box}
                  >
                    Uppfyller ej avtalade
                    <br /> krav
                  </span>
                  <span
                    onClick={() => {
                      formik.values.qualityRating == 3
                        ? void formik.setFieldValue("qualityRating", 0)
                        : void formik.setFieldValue("qualityRating", 3);
                    }}
                    style={{
                      backgroundColor:
                        formik.values.qualityRating == 3 ? "#4FBFC0" : "white",
                      color:
                        formik.values.qualityRating == 3 ? "white" : "black",
                    }}
                    className={cssStyle.box}
                  >
                    Uppfyller troligtvis <br /> avtalade krav
                  </span>
                  <span
                    onClick={() => {
                      formik.values.qualityRating == 5
                        ? void formik.setFieldValue("qualityRating", 0)
                        : void formik.setFieldValue("qualityRating", 5);
                    }}
                    style={{
                      backgroundColor:
                        formik.values.qualityRating == 5 ? "#4FBFC0" : "white",
                      color:
                        formik.values.qualityRating == 5 ? "white" : "black",
                    }}
                    className={cssStyle.box}
                  >
                    Uppfyller avtalade <br /> krav
                  </span>
                </div>
              </div>
              <div className={cssStyle.assessmentBox}>
                <h2>Kundservice</h2>
                <div className={cssStyle.row}>
                  <span
                    onClick={() => {
                      formik.values.customerServiceRating == 1
                        ? void formik.setFieldValue("customerServiceRating", 0)
                        : void formik.setFieldValue("customerServiceRating", 1);
                    }}
                    style={{
                      backgroundColor:
                        formik.values.customerServiceRating == 1
                          ? "#4FBFC0"
                          : "white",
                      color:
                        formik.values.customerServiceRating == 1
                          ? "white"
                          : "black",
                    }}
                    className={cssStyle.box}
                  >
                    Uppfyller ej avtalade <br /> krav
                  </span>
                  <span
                    onClick={() => {
                      formik.values.customerServiceRating == 3
                        ? void formik.setFieldValue("customerServiceRating", 0)
                        : void formik.setFieldValue("customerServiceRating", 3);
                    }}
                    style={{
                      backgroundColor:
                        formik.values.customerServiceRating == 3
                          ? "#4FBFC0"
                          : "white",
                      color:
                        formik.values.customerServiceRating == 3
                          ? "white"
                          : "black",
                    }}
                    className={cssStyle.box}
                  >
                    Uppfyller troligtvis <br /> avtalade krav
                  </span>
                  <span
                    onClick={() => {
                      formik.values.customerServiceRating == 5
                        ? void formik.setFieldValue("customerServiceRating", 0)
                        : void formik.setFieldValue("customerServiceRating", 5);
                    }}
                    style={{
                      backgroundColor:
                        formik.values.customerServiceRating == 5
                          ? "#4FBFC0"
                          : "white",
                      color:
                        formik.values.customerServiceRating == 5
                          ? "white"
                          : "black",
                    }}
                    className={cssStyle.box}
                  >
                    Uppfyller avtalade <br /> krav
                  </span>
                </div>
              </div>
              <div className={cssStyle.assessmentBox}>
                <h2>Leveransförmåga</h2>
                <div className={cssStyle.row}>
                  <span
                    onClick={() => {
                      formik.values.deliverCapacity == 1
                        ? void formik.setFieldValue("deliverCapacity", 0)
                        : void formik.setFieldValue("deliverCapacity", 1);
                    }}
                    style={{
                      backgroundColor:
                        formik.values.deliverCapacity == 1
                          ? "#4FBFC0"
                          : "white",
                      color:
                        formik.values.deliverCapacity == 1 ? "white" : "black",
                    }}
                    className={cssStyle.box}
                  >
                    Uppfyller ej avtalade <br /> krav
                  </span>
                  <span
                    onClick={() => {
                      formik.values.deliverCapacity == 3
                        ? void formik.setFieldValue("deliverCapacity", 0)
                        : void formik.setFieldValue("deliverCapacity", 3);
                    }}
                    style={{
                      backgroundColor:
                        formik.values.deliverCapacity == 3
                          ? "#4FBFC0"
                          : "white",
                      color:
                        formik.values.deliverCapacity == 3 ? "white" : "black",
                    }}
                    className={cssStyle.box}
                  >
                    Uppfyller troligtvis <br /> avtalade krav
                  </span>
                  <span
                    onClick={() => {
                      formik.values.deliverCapacity == 5
                        ? void formik.setFieldValue("deliverCapacity", 0)
                        : void formik.setFieldValue("deliverCapacity", 5);
                    }}
                    style={{
                      backgroundColor:
                        formik.values.deliverCapacity == 5
                          ? "#4FBFC0"
                          : "white",
                      color:
                        formik.values.deliverCapacity == 5 ? "white" : "black",
                    }}
                    className={cssStyle.box}
                  >
                    Uppfyller avtalade <br /> krav
                  </span>
                </div>
              </div>
              <div className={cssStyle.assessmentBox}>
                <h2>Hållbarhet</h2>
                <div className={cssStyle.row}>
                  <span
                    onClick={() => {
                      formik.values.sustainabilityRating == 1
                        ? void formik.setFieldValue("sustainabilityRating", 0)
                        : void formik.setFieldValue("sustainabilityRating", 1);
                    }}
                    style={{
                      backgroundColor:
                        formik.values.sustainabilityRating == 1
                          ? "#4FBFC0"
                          : "white",
                      color:
                        formik.values.sustainabilityRating == 1
                          ? "white"
                          : "black",
                    }}
                    className={cssStyle.box}
                  >
                    Uppfyller ej avtalade <br /> krav
                  </span>
                  <span
                    onClick={() => {
                      formik.values.sustainabilityRating == 3
                        ? void formik.setFieldValue("sustainabilityRating", 0)
                        : void formik.setFieldValue("sustainabilityRating", 3);
                    }}
                    style={{
                      backgroundColor:
                        formik.values.sustainabilityRating == 3
                          ? "#4FBFC0"
                          : "white",
                      color:
                        formik.values.sustainabilityRating == 3
                          ? "white"
                          : "black",
                    }}
                    className={cssStyle.box}
                  >
                    Uppfyller troligtvis <br /> avtalade krav
                  </span>
                  <span
                    onClick={() => {
                      formik.values.sustainabilityRating == 5
                        ? void formik.setFieldValue("sustainabilityRating", 0)
                        : void formik.setFieldValue("sustainabilityRating", 5);
                    }}
                    style={{
                      backgroundColor:
                        formik.values.sustainabilityRating == 5
                          ? "#4FBFC0"
                          : "white",
                      color:
                        formik.values.sustainabilityRating == 5
                          ? "white"
                          : "black",
                    }}
                    className={cssStyle.box}
                  >
                    Uppfyller avtalade <br /> krav
                  </span>
                </div>
              </div>
              <div className={cssStyle.assessmentBox}>
                <h2>Innovation</h2>
                <div className={cssStyle.row}>
                  <span
                    onClick={() => {
                      formik.values.innovationRating == 1
                        ? void formik.setFieldValue("innovationRating", 0)
                        : void formik.setFieldValue("innovationRating", 1);
                    }}
                    style={{
                      backgroundColor:
                        formik.values.innovationRating == 1
                          ? "#4FBFC0"
                          : "white",
                      color:
                        formik.values.innovationRating == 1 ? "white" : "black",
                    }}
                    className={cssStyle.box}
                  >
                    Uppfyller ej avtalade <br /> krav
                  </span>
                  <span
                    onClick={() => {
                      formik.values.innovationRating == 3
                        ? void formik.setFieldValue("innovationRating", 0)
                        : void formik.setFieldValue("innovationRating", 3);
                    }}
                    style={{
                      backgroundColor:
                        formik.values.innovationRating == 3
                          ? "#4FBFC0"
                          : "white",
                      color:
                        formik.values.innovationRating == 3 ? "white" : "black",
                    }}
                    className={cssStyle.box}
                  >
                    Uppfyller troligtvis <br /> avtalade krav
                  </span>
                  <span
                    onClick={() => {
                      formik.values.innovationRating == 5
                        ? void formik.setFieldValue("innovationRating", 0)
                        : void formik.setFieldValue("innovationRating", 5);
                    }}
                    style={{
                      backgroundColor:
                        formik.values.innovationRating == 5
                          ? "#4FBFC0"
                          : "white",
                      color:
                        formik.values.innovationRating == 5 ? "white" : "black",
                    }}
                    className={cssStyle.box}
                  >
                    Uppfyller avtalade <br /> krav
                  </span>
                </div>
              </div>
              <div className={cssStyle.assessmentBox}>
                <h2>Kostnad</h2>
                <div className={cssStyle.row}>
                  <span
                    onClick={() => {
                      formik.values.pricingRating == 1
                        ? void formik.setFieldValue("pricingRating", 0)
                        : void formik.setFieldValue("pricingRating", 1);
                    }}
                    style={{
                      backgroundColor:
                        formik.values.pricingRating == 1 ? "#4FBFC0" : "white",
                      color:
                        formik.values.pricingRating == 1 ? "white" : "black",
                    }}
                    className={cssStyle.box}
                  >
                    Uppfyller ej avtalade <br /> krav
                  </span>
                  <span
                    onClick={() => {
                      formik.values.pricingRating == 3
                        ? void formik.setFieldValue("pricingRating", 0)
                        : void formik.setFieldValue("pricingRating", 3);
                    }}
                    style={{
                      backgroundColor:
                        formik.values.pricingRating == 3 ? "#4FBFC0" : "white",
                      color:
                        formik.values.pricingRating == 3 ? "white" : "black",
                    }}
                    className={cssStyle.box}
                  >
                    Uppfyller troligtvis <br /> avtalade krav
                  </span>
                  <span
                    onClick={() => {
                      formik.values.pricingRating == 5
                        ? void formik.setFieldValue("pricingRating", 0)
                        : void formik.setFieldValue("pricingRating", 5);
                    }}
                    style={{
                      backgroundColor:
                        formik.values.pricingRating == 5 ? "#4FBFC0" : "white",
                      color:
                        formik.values.pricingRating == 5 ? "white" : "black",
                    }}
                    className={cssStyle.box}
                  >
                    Uppfyller avtalade <br /> krav
                  </span>
                </div>
              </div>
            </div>

            <div className={cssStyle.inputBox}>
              <p className={cssStyle.heading}>{t("recommendationHeader")}</p>
              {reviewData?.organisation && (
                <p style={styles.weakText}>
                  {t("recommendationDescription", {
                    organizationName: reviewData.organisation.name,
                  })}
                  *
                </p>
              )}

              <Radio.Group
                value={formik.values.recommendation}
                onChange={(e) => {
                  void formik.setFieldValue(
                    "recommendation",
                    parseInt(e.target.value as string)
                  );
                }}
                className={cssStyle.radioContainer}
                name="recommendation"
                id="recommendation"
              >
                <Radio.Button value={1}>1</Radio.Button>
                <Radio.Button value={2}>2</Radio.Button>
                <Radio.Button value={3}>3</Radio.Button>
                <Radio.Button value={4}>4</Radio.Button>
                <Radio.Button value={5}>5</Radio.Button>
                <Radio.Button value={6}>6</Radio.Button>
                <Radio.Button value={7}>7</Radio.Button>
                <Radio.Button value={8}>8</Radio.Button>
                <Radio.Button value={9}>9</Radio.Button>
                <Radio.Button value={10}>10</Radio.Button>
              </Radio.Group>
              <div style={styles.radioDescription}>
                <span style={styles.radioDescriptionText}>
                  {t("notLikely")}
                </span>
                <span style={styles.radioDescriptionText}>
                  {t("mostLikely")}
                </span>
              </div>
            </div>
          </div>
          <DefaultButton
            type="submit"
            className={`${cssStyle.submitButton} ${
              formik.errors.text ? cssStyle.disabled : ""
            }`}
            text="Uppdatera"
            variant="primary"
            loading={isLoading}
            disabled={isLoading}
          />
        </div>
      </div>
    </form>
  );
};

const Iconstyle = StyleSheet.create({
  infoIcon: {
    fontSize: "20px",
    color: "#CDD4E2",
    padding: "1px 0px 0px 8px",
    cursor: "pointer",
  },
});
