import React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "../../../../hooks/useTranslation";
import { useNavigate } from "react-router";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../../services/notificationService";
import style from "../styles/style.module.css";
import {
  useAddSniLevel4,
  useGetAllSniLevel3,
  useGetCheckSni4,
} from "../../../../hooks/api/Admin";
import { Select } from "antd";

export const SniLevel4 = () => {
  const [sniLevel3Name, setSniLevel3Name] = useState("");
  const [code, setCode] = useState("");
  const [swedishName, setSwedishName] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const { t } = useTranslation("addSni");

  const navigate = useNavigate();

  const { mutate } = useAddSniLevel4();

  const { data: SniLevel3NamesData } = useGetAllSniLevel3();
  const { data: sniResult } = useGetCheckSni4(code);
  let chkError = "";
  if (sniResult === true) {
    chkError = "Sni already Exists";
  }

  function validate(code: string) {
    let errors = "";
    if (code && code.length !== 5) {
      errors = "Sni code must be exactly 5 character long";
    }
    return errors;
  }

  const renderedSniLevel3Options = useMemo(
    () =>
      SniLevel3NamesData?.filter((sni) => !!sni.name).map((sni) => {
        return {
          value: `${sni.code ? sni.code : ""} - ${sni.name ? sni.name : ""}`,
          id: sni.id,
        };
      }),
    [SniLevel3NamesData]
  );
  const sniLevel3Code = sniLevel3Name.split(" ")[0];
  const sniLevel3_Id = useMemo(
    () =>
      SniLevel3NamesData?.find((option) => option.code === sniLevel3Code)?.id,
    [sniLevel3Name, sniLevel3Code, SniLevel3NamesData]
  );

  const handleSniLevel4Submit = () => {
    if (name == "" || swedishName == "" || code == "" || !sniLevel3_Id) {
      DisplayErrorNotification(t("ProvideAllData"));
    } else {
      const newErrors = validate(code);
      setError(newErrors);

      if (newErrors === "" && chkError == "") {
        const dataToSend = {
          data: {
            sniLevel3_Id: sniLevel3_Id,
            code: code,
            name: name,
            swedishName: swedishName,
          },
        };

        mutate(dataToSend, {
          onSuccess: () => {
            DisplaySuccessNotification(t("addSniSuccess"));
            navigate("/dashboard");
          },
          onError: () => {
            DisplayErrorNotification(t("addSniError"));
          },
        });
      } else {
        if (chkError !== "") {
          DisplayErrorNotification(t("SniAlreadyExists"));
        } else {
          DisplayErrorNotification(t("WrongSniError"));
        }
      }
    }
  };
  return (
    <div className={style.lastContainer}>
      <p className={style.heading}>Add Sni Level 4</p>
      <div className={style.row}>
        <div className={style.col}>
          <p className={style.inputLabel}>Enter code</p>
          <input
            className={style.input}
            type="text"
            placeholder="Code"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
              const newErrors = validate(e.target.value);
              setError(newErrors);
            }}
          />
          {error && code.length !== 5 && (
            <div className={style.error}>{error}</div>
          )}
          {sniResult == true && <div className={style.error}>{chkError}</div>}
        </div>
        <div className={style.col}>
          <p className={style.inputLabel}>Enter name</p>
          <input
            className={style.input}
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div className={style.col}>
          <p className={style.inputLabel}>Enter swedish name</p>
          <input
            className={style.input}
            type="text"
            placeholder="Swedish Name"
            value={swedishName}
            onChange={(e) => {
              setSwedishName(e.target.value);
            }}
          />
        </div>
        <div className={style.col}>
          <p className={style.inputLabel}>Select SNI Level 3</p>
          <div className={style.supplierBox}>
            <div className={style.box}>
              <div className={`${style.agreementSelect}`}>
                <Select
                  className={style.select}
                  size={"large"}
                  onChange={(value) => setSniLevel3Name(value)}
                  value={sniLevel3Name}
                  options={
                    renderedSniLevel3Options ? renderedSniLevel3Options : []
                  }
                  dropdownClassName={style.Dropdown}
                ></Select>
              </div>
            </div>
          </div>
        </div>
        <div className={style.btnContainer}>
          <button
            className={style.btn}
            type="submit"
            onClick={() => {
              handleSniLevel4Submit();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
