import React, { useCallback, useMemo } from "react";
import { Button, Col, Row } from "antd";
import { colors } from "../../../../constants/colors";
import { StyleSheet } from "../../../../models/StyleSheet";
import { useState } from "react";
import { User } from "../showUsers.utils";

export function UserRow({ User }: { User: User }) {
  const { user } = User;

  return (
    <>
      <Button style={styles.button} type="text" block={true}>
        <Row style={styles.container}>
          <Col span={4}>
            <p style={styles.info}>
              {user.firstName ? user.firstName : "N/A"}{" "}
              {user.lastName ? user.lastName : "N/A"}
            </p>
          </Col>
          <Col span={2}>
            <p style={styles.info}>{user.role}</p>
          </Col>
          <Col span={4}>
            <p style={styles.info}>
              {user.credentials.email ? user.credentials.email : "N/A"}
            </p>
          </Col>
          <Col span={4}>
            <p style={styles.info}>
              {User.phoneNumber ? User.phoneNumber : "N/A"}
            </p>
          </Col>
          <Col span={2}>
            <p style={styles.info}>{User.community ? User.community : "N/A"}</p>
          </Col>
          <Col span={3}>
            <p style={styles.info}>{user.userVerified ? "true" : "false"}</p>
          </Col>
          <Col span={3}>
            <p style={styles.info}>{user.adminVerified ? "true" : "false"}</p>
          </Col>
          <Col span={2}>
            <p style={styles.info}>
              {User.reviews ? User.reviews.length : "N/A"}
            </p>
          </Col>
        </Row>
      </Button>
    </>
  );
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: "white",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.2)",
    color: "black",
    height: "62px",
    paddingLeft: "0",
    margin: "0px 5px",
    // paddingRight: 20,
  },
  container: {
    alignItems: "center",
  },
  heading: {
    fontSize: "16px",
    color: "black",
    letterSpacing: "0.07em",
  },
  info: {
    fontSize: "16px",
    paddingTop: "15px",
    color: "black",
    letterSpacing: "0.07em",
  },
});
