import React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "../../../../hooks/useTranslation";
import { useNavigate } from "react-router";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../../services/notificationService";
import style from "../styles/style.module.css";
import {
  useAddSniLevel2,
  useGetAllSniLevel1,
  useGetCheckSni2,
} from "../../../../hooks/api/Admin";
import { Select } from "antd";

export const SniLevel2 = () => {
  const [sniLevel1Name, setSniLevel1Name] = useState("");
  const [code, setCode] = useState("");
  const [swedishName, setSwedishName] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const { t } = useTranslation("addSni");

  const navigate = useNavigate();

  const { mutate } = useAddSniLevel2();

  const { data: SniLevel1NamesData } = useGetAllSniLevel1();

  const { data: sniResult } = useGetCheckSni2(code);
  let chkError = "";
  if (sniResult === true) {
    chkError = "Sni already Exists";
  }

  function validate(code: string) {
    let errors = "";
    if (code && code.length !== 3) {
      errors = "Sni code must be exactly 3 character long";
    }
    return errors;
  }

  const renderedSniLevel1Options = useMemo(
    () =>
      SniLevel1NamesData?.filter((sni) => !!sni.name).map((sni) => {
        return {
          value: `${sni.code ? sni.code : ""} - ${sni.name ? sni.name : ""}`,
          id: sni.id,
        };
      }),
    [SniLevel1NamesData]
  );
  const sniLevel1Code = sniLevel1Name.split(" ")[0];
  const sniLevel1_Id = useMemo(
    () =>
      SniLevel1NamesData?.find((option) => option.code === sniLevel1Code)?.id,
    [sniLevel1Name, sniLevel1Code, SniLevel1NamesData]
  );

  const handleSniLevel2Submit = () => {
    if (name == "" || swedishName == "" || code == "" || !sniLevel1_Id) {
      DisplayErrorNotification(t("ProvideAllData"));
    } else {
      const newErrors = validate(code);
      setError(newErrors);

      if (newErrors === "" && chkError == "") {
        const dataToSend = {
          data: {
            sniLevel1_Id: sniLevel1_Id,
            code: code,
            name: name,
            swedishName: swedishName,
          },
        };

        mutate(dataToSend, {
          onSuccess: () => {
            DisplaySuccessNotification(t("addSniSuccess"));
            navigate("/dashboard");
          },
          onError: () => {
            DisplayErrorNotification(t("addSniError"));
          },
        });
      } else {
        if (chkError !== "") {
          DisplayErrorNotification(t("SniAlreadyExists"));
        } else {
          DisplayErrorNotification(t("WrongSniError"));
        }
      }
    }
  };
  return (
    <div className={style.container}>
      <p className={style.heading}>Add Sni Level 2</p>
      <div className={style.row}>
        <div className={style.col}>
          <p className={style.inputLabel}>Enter code</p>
          <input
            className={style.input}
            type="text"
            placeholder="Code"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
              const newErrors = validate(e.target.value);
              setError(newErrors);
            }}
          />
          {error && code.length !== 3 && (
            <div className={style.error}>{error}</div>
          )}
          {sniResult == true && <div className={style.error}>{chkError}</div>}
        </div>
        <div className={style.col}>
          <p className={style.inputLabel}>Enter name</p>
          <input
            className={style.input}
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div className={style.col}>
          <p className={style.inputLabel}>Enter swedish name</p>
          <input
            className={style.input}
            type="text"
            placeholder="Swedish Name"
            value={swedishName}
            onChange={(e) => {
              setSwedishName(e.target.value);
            }}
          />
        </div>
        <div className={style.col}>
          <p className={style.inputLabel}>Select SNI Level 1</p>
          <div className={style.supplierBox}>
            <div className={style.box}>
              <div className={`${style.agreementSelect}`}>
                <Select
                  className={style.select}
                  size={"large"}
                  onChange={(value) => setSniLevel1Name(value)}
                  value={sniLevel1Name}
                  options={
                    renderedSniLevel1Options ? renderedSniLevel1Options : []
                  }
                  dropdownClassName={style.Dropdown}
                ></Select>
              </div>
            </div>
          </div>
        </div>
        <div className={style.btnContainer}>
          <button
            className={style.btn}
            type="submit"
            onClick={() => {
              handleSniLevel2Submit();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
