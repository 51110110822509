import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { InviteToDialogue } from "../containers/Supplier/InvitePage/InviteToDialogue";
import { Dashboard } from "../containers/Supplier/Dashboard";
import { InsightPage } from "../containers/Supplier/Insights";
import { SupplierProfile } from "../containers/Supplier/SupplierProfile";
import { InviteToReview } from "../containers/Supplier/InvitePage/InviteToReview";
import TopBar from "../containers/Supplier/components/TopBar";
import Reviews from "../containers/Supplier/Reviews";
import SupplierProfileNew from "../containers/Supplier/SupplierProfileNew";
import ProfileDetail from "../containers/Supplier/ProfileDetail";
import EditTopBar from "../containers/Supplier/components/EditTopBar";
import { storage } from "../services/storage";
import {
  OrganisationsData,
  useGetOrganisation,
} from "../hooks/api/Organisations";

export function SupplierRoutes() {
  const user = storage.getUser();

  return (
    <>
      <Routes>
        {/* <Route path="/*" element={<Navigate to="/dashboard" />} /> */}
        <Route
          path="/*"
          element={
            <Navigate
              to={`/profile/organisation/${
                user?.organisationId ? user.organisationId : 0
              }`}
            />
          }
        />
        <Route
          path="/"
          element={
            <Navigate
              to={`/profile/organisation/${
                user?.organisationId ? user.organisationId : 0
              }`}
            />
          }
        />
        <Route
          path="/dashboard"
          element={
            <>
              <TopBar />
              <Dashboard />
            </>
          }
        />
        <Route
          path="/reviews/:organisationId"
          element={
            <>
              <TopBar />
              <Reviews />
            </>
          }
        />
        <Route
          path="/insights"
          element={
            <>
              <TopBar />
              <InsightPage />
            </>
          }
        />
        <Route
          path="/invite-to-dialogue"
          element={
            <>
              <TopBar />
              <InviteToDialogue />
            </>
          }
        />
        <Route
          path="/profile/organisation/:organisationId/"
          element={
            <>
              <TopBar />
              <ProfileDetail />
            </>
          }
        />
        <Route
          path="/edit-profile/organisation/:organisationId/"
          element={
            <>
              <SupplierProfileNew />
            </>
          }
        />
        <Route
          path="/old-profile"
          element={
            <>
              <TopBar />
              <SupplierProfile />
            </>
          }
        />
        <Route
          path="/invite-to-review"
          element={
            <>
              {/* <TopBar /> */}
              <InviteToReview />
            </>
          }
        />
      </Routes>
    </>
  );
}
