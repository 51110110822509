import { Pagination as PaginationAntd } from "antd";
import React, { useEffect, useState } from "react";
import "../css/pagination.css";
import { StyleSheet } from "../models/StyleSheet";

export const Pagination = ({
  page,
  setPage,
  total,
}: {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  total?: number;
}) => {
  const [numberOfPages, setNumberOfPages] = useState(0);
  const elementsPerSite = 20;
  useEffect(() => {
    setNumberOfPages(Math.ceil(Number(total) / 20));
    if (numberOfPages !== 0 && numberOfPages < page) {
      setPage(numberOfPages);
    }
  }, [total, numberOfPages, page, setPage]);
  return (
    <div style={styles.container}>
      <PaginationAntd
        style={styles.pagination}
        current={page}
        total={total}
        onChange={setPage}
        pageSize={elementsPerSite}
        showSizeChanger={false}
        hideOnSinglePage={true}
      />
      <div style={styles.total}>{total} resultat</div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: { display: "flex", justifyContent: "center", marginTop: "20px" },
  pagination: {
    textAlign: "center",
  },
  total: {
    fontFamily: "d-din",
    display: "flex",
    fontSize: "25px",
    alignItems: "flex-start",
    marginLeft: "10px",
  },
});
