import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const ImageSvg = () => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.333984 5.90787C0.333984 4.4446 0.860769 3.04127 1.79845 2.00659C2.73613 0.971904 4.0079 0.390625 5.33398 0.390625H35.334C36.6601 0.390625 37.9318 0.971904 38.8695 2.00659C39.8072 3.04127 40.334 4.4446 40.334 5.90787V33.4941C40.334 34.9573 39.8072 36.3607 38.8695 37.3953C37.9318 38.43 36.6601 39.0113 35.334 39.0113H5.33398C4.0079 39.0113 2.73613 38.43 1.79845 37.3953C0.860769 36.3607 0.333984 34.9573 0.333984 33.4941V5.90787ZM2.83398 30.7355V33.4941C2.83398 34.2257 3.09738 34.9274 3.56622 35.4447C4.03506 35.9621 4.67094 36.2527 5.33398 36.2527H35.334C35.997 36.2527 36.6329 35.9621 37.1017 35.4447C37.5706 34.9274 37.834 34.2257 37.834 33.4941V23.8389L28.3915 18.4679C28.157 18.3383 27.8916 18.2933 27.6327 18.3394C27.3739 18.3854 27.1347 18.5201 26.949 18.7244L17.674 28.9589L11.024 24.0706C10.7839 23.8942 10.4959 23.8149 10.2088 23.8461C9.92177 23.8772 9.65332 24.017 9.44898 24.2417L2.83398 30.7355ZM15.334 12.8044C15.334 11.707 14.9389 10.6545 14.2356 9.87846C13.5324 9.10245 12.5785 8.66649 11.584 8.66649C10.5894 8.66649 9.6356 9.10245 8.93233 9.87846C8.22907 10.6545 7.83398 11.707 7.83398 12.8044C7.83398 13.9019 8.22907 14.9544 8.93233 15.7304C9.6356 16.5064 10.5894 16.9423 11.584 16.9423C12.5785 16.9423 13.5324 16.5064 14.2356 15.7304C14.9389 14.9544 15.334 13.9019 15.334 12.8044Z"
      fill="#808795"
    />
  </svg>
);

const ImageIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ImageSvg} {...props} />
);

export default ImageIcon;
