import { API } from "../../services/API";
import { useMutation, useQuery } from "react-query";
import { OrganisationsData } from "./Organisations";
import { dataToSendInterface } from "../../containers/Admin/PendingRequests/pendingRequests.utils";
import { number, string } from "yup";

async function approveRequest(data: dataToSendInterface) {
  const url = "/admin/approve-request";
  const { data: response } = await API.post(url, data);
  return response;
}
export const useApproveRequest = () => {
  return useMutation(approveRequest);
};

const rejectRequest = async (data: dataToSendInterface) => {
  const url = "/admin/reject-request";
  const { data: response } = await API.post(url, data);
  return response;
};
export const useRejectRequest = () => {
  return useMutation(rejectRequest);
};

interface verifyAccountInterface {
  email: string;
  pin: string;
}

const verifyAccount = async (data: verifyAccountInterface) => {
  const url = "/admin/user-verification";
  const { data: response } = await API.post(url, data);
  return response;
};
export const useVerifyAccount = () => {
  return useMutation(verifyAccount);
};

interface addCategory {
  suntCategory?: string;
  subCategory?: string;
  suntCategoryId?: number;
  swedishName?: string;
  cpv?: string;
}

const addSuntCategory = async (data: addCategory) => {
  const url = "/admin/add-sunt-category";
  const { data: response } = await API.post(url, data);
  return response;
};
export const useAddSuntCategory = () => {
  return useMutation(addSuntCategory);
};

const addSubCategory = async (data: unknown) => {
  const url = "/admin/add-sub-category";
  const { data: response } = await API.post(url, data);
  return response;
};
export const useAddSubCategory = () => {
  return useMutation(addSubCategory);
};

interface addSni {
  data: {
    department?: string;
    name?: string;
    swedishName?: string;
  };
}

const addDepartment = async (data: addSni) => {
  const url = "/admin/add-department";
  const { data: response } = await API.post(url, data);
  return response;
};
export const useAddDepartment = () => {
  return useMutation(addDepartment);
};

const addSniLevel1 = async (data: addSni) => {
  const url = "/admin/add-sni-level-1";
  const { data: response } = await API.post(url, data);
  return response;
};
export const useAddSniLevel1 = () => {
  return useMutation(addSniLevel1);
};

const addSniLevel2 = async (data: addSni) => {
  const url = "/admin/add-sni-level-2";
  const { data: response } = await API.post(url, data);
  return response;
};
export const useAddSniLevel2 = () => {
  return useMutation(addSniLevel2);
};

const addSniLevel3 = async (data: addSni) => {
  const url = "/admin/add-sni-level-3";
  const { data: response } = await API.post(url, data);
  return response;
};
export const useAddSniLevel3 = () => {
  return useMutation(addSniLevel3);
};

const addSniLevel4 = async (data: addSni) => {
  const url = "/admin/add-sni-level-4";
  const { data: response } = await API.post(url, data);
  return response;
};
export const useAddSniLevel4 = () => {
  return useMutation(addSniLevel4);
};

const adminVerify = async (id: number) => {
  const url = `/auth/verify-user/${id}`;
  const { data: response } = await API.put(url);
  return response;
};
export const useAdminVerify = () => {
  return useMutation(adminVerify);
};

const getPendingOrganisations = async (query: boolean) => {
  const url = "admin/get-all-pending-organisations";
  const { data } = await API.get<unknown, OrganisationsData[]>(url);
  return data;
};

export const useGetPendingOrganisations = (query: boolean) => {
  return useQuery(["getPendingOrganisations", query], () =>
    getPendingOrganisations(query)
  );
};

const getCheckCpv = async (cpv: string) => {
  const url = `admin/check-Cpv/${cpv}`;
  const { data } = await API.get<unknown, boolean>(url);
  return data;
};

export const useGetCheckCpv = (cpv: string) => {
  return useQuery(["getCheckCpv", cpv], () => getCheckCpv(cpv));
};

const getCheckDep = async (dep: string) => {
  const url = `admin/check-dep/${dep}`;
  const { data } = await API.get<unknown, boolean>(url);
  return data;
};

export const useGetCheckDep = (dep: string) => {
  return useQuery(["getCheckDep", dep], () => getCheckDep(dep));
};

const getCheckSni1 = async (sni: string) => {
  const url = `admin/check-Sni-1/${sni}`;
  const { data } = await API.get<unknown, boolean>(url);
  return data;
};

export const useGetCheckSni1 = (sni: string) => {
  return useQuery(["getCheckSni1", sni], () => getCheckSni1(sni));
};

const getCheckSni2 = async (sni: string) => {
  const url = `admin/check-Sni-2/${sni}`;
  const { data } = await API.get<unknown, boolean>(url);
  return data;
};

export const useGetCheckSni2 = (sni: string) => {
  return useQuery(["getCheckSni2", sni], () => getCheckSni2(sni));
};

const getCheckSni3 = async (sni: string) => {
  const url = `admin/check-Sni-3/${sni}`;
  const { data } = await API.get<unknown, boolean>(url);
  return data;
};

export const useGetCheckSni3 = (sni: string) => {
  return useQuery(["getCheckSni3", sni], () => getCheckSni3(sni));
};

const getCheckSni4 = async (sni: string) => {
  const url = `admin/check-Sni-4/${sni}`;
  const { data } = await API.get<unknown, boolean>(url);
  return data;
};

export const useGetCheckSni4 = (sni: string) => {
  return useQuery(["getCheckSni4", sni], () => getCheckSni4(sni));
};

interface Users {
  buyers: [];
  suppliers: [];
}

interface Query {
  value: string;
  searchBy: string;
}

const getAllUsers = async (searchValue: string, searchBy: string) => {
  const url = "admin/get-all-users";
  const { data } = await API.get<unknown, Users>(url, {
    params: { searchValue, searchBy },
  });
  return data;
};

export const useGetAllUsers = (searchValue: string, searchBy: string) => {
  return useQuery(["getAllUsers", searchValue, searchBy], () =>
    getAllUsers(searchValue, searchBy)
  );
};

const getUnverifiedUsers = async (searchValue: string, searchBy: string) => {
  const url = "admin/get-unverified-users";
  const { data } = await API.get<unknown, Users>(url, {
    params: { searchValue, searchBy },
  });
  return data;
};

export const useGetUnverifiedUsers = (
  searchValue: string,
  searchBy: string
) => {
  return useQuery(["getUnverifiedUsers", searchValue, searchBy], () =>
    getUnverifiedUsers(searchValue, searchBy)
  );
};
interface SniData {
  id?: number;
  department?: string;
  departmentId?: number;
  sniLevel1_Id?: number;
  sniLevel2_Id?: number;
  sniLevel3_Id?: number;
  sniLevel4_Id?: number;
  code?: string;
  name?: string;
  swedishName?: string;
}

const getAllDepartments = async () => {
  const url = "admin/get-all-departments";
  const { data } = await API.get<unknown, SniData[]>(url);
  return data;
};

export const useGetAllDepartments = () => {
  return useQuery(["getAllDepartments"], () => getAllDepartments());
};

const getAllSniLevel1 = async () => {
  const url = "admin/get-all-sni-level-1";
  const { data } = await API.get<unknown, SniData[]>(url);
  return data;
};

export const useGetAllSniLevel1 = () => {
  return useQuery(["getAllSniLevel1"], () => getAllSniLevel1());
};

const getAllSniLevel2 = async () => {
  const url = "admin/get-all-sni-level-2";
  const { data } = await API.get<unknown, SniData[]>(url);
  return data;
};

export const useGetAllSniLevel2 = () => {
  return useQuery(["getAllSniLevel2"], () => getAllSniLevel2());
};

const getAllSniLevel3 = async () => {
  const url = "admin/get-all-sni-level-3";
  const { data } = await API.get<unknown, SniData[]>(url);
  return data;
};

export const useGetAllSniLevel3 = () => {
  return useQuery(["getAllSniLevel3"], () => getAllSniLevel3());
};

const getAllSniLevel4 = async () => {
  const url = "admin/get-all-sni-level-4";
  const { data } = await API.get<unknown>(url);
  return data;
};

export const useGetAllSniLevel4 = () => {
  return useQuery(["getAllSniLevel4"], () => getAllSniLevel4());
};

// api to get name on the basis of id

// const getNames = async (query: {}) => {
//   const url = "admin/get-names";
//   const { data } = await API.get<unknown, OrganisationsData[]>(url);
//   return data;
// };

// export const useGetNames = (query: {}) => {
//   return useQuery(["getNames", query], () => getNames(query));
// };
