export const OrganisationFilterInitialValues = {
  name: [],
  cpv: [],
  city: [], //^
  reviewGrade: "",
  reviewsMin: 0,
  dateFrom: "", //
  dateTo: "", //
  employeesMin: "",
  employeesMax: "",
  turnoverMin: null,
  turnoverMax: null,
  turnoverMaxHardCode: 100,
  experience: [], //^
  role: [], //^
  tags: [],
  companyType: [],
  other: [],
  supplier_Ids: [],
};

export interface OrganisationFilterInterface {
  name: string[];
  cpv: string[];
  city: string[];
  reviewGrade: string;
  reviewsMin: number;
  dateFrom: string;
  dateTo: string;
  employeesMin: string;
  employeesMax: string;
  turnoverMin: number | null;
  turnoverMax: number | null;
  turnoverMaxHardCode?: number;
  experience: string[];
  role: string[];
  tags: string[];
  companyType: string[];
  other: string[];
  supplier_Ids: number[];
}
