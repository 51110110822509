import React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "../../../../hooks/useTranslation";
import { useNavigate } from "react-router";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../../services/notificationService";
import style from "../styles/style.module.css";
import {
  useAddSniLevel1,
  useGetAllDepartments,
  useGetCheckSni1,
} from "../../../../hooks/api/Admin";
import { Select } from "antd";

export const SniLevel1 = () => {
  const [departmentName, setDepartmentName] = useState("");
  const [code, setCode] = useState("");
  const [swedishName, setSwedishName] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const { t } = useTranslation("addSni");

  const navigate = useNavigate();

  const { mutate } = useAddSniLevel1();

  const { data: departmentNamesData } = useGetAllDepartments();

  const { data: sniResult } = useGetCheckSni1(code);
  let chkError = "";
  if (sniResult === true) {
    chkError = "Sni already Exists";
  }

  function validate(code: string) {
    let errors = "";
    if (code && code.length !== 2) {
      errors = "Sni code must be exactly 2 character long";
    }
    return errors;
  }

  const renderedDepartmentOptions = useMemo(
    () =>
      departmentNamesData
        ?.filter((department) => !!department.name)
        .map((department) => {
          return {
            value: `${department.department ? department.department : ""} - ${
              department.name ? department.name : ""
            }`,
            id: department.id,
          };
        }),
    [departmentNamesData]
  );
  const department = departmentName.split(" ")[0];
  const departmentId = useMemo(
    () =>
      departmentNamesData?.find((option) => option.department === department)
        ?.id,
    [departmentName, department, departmentNamesData]
  );

  const handleSniLevel1Submit = () => {
    if (name == "" || swedishName == "" || code == "" || !departmentId) {
      DisplayErrorNotification(t("ProvideAllData"));
    } else {
      const newErrors = validate(code);
      setError(newErrors);

      if (newErrors === "" && chkError == "") {
        const dataToSend = {
          data: {
            departmentId: departmentId,
            code: code,
            name: name,
            swedishName: swedishName,
          },
        };

        mutate(dataToSend, {
          onSuccess: () => {
            DisplaySuccessNotification(t("addSniSuccess"));
            navigate("/dashboard");
          },
          onError: () => {
            DisplayErrorNotification(t("addSniError"));
          },
        });
      } else {
        if (chkError !== "") {
          DisplayErrorNotification(t("SniAlreadyExists"));
        } else {
          DisplayErrorNotification(t("WrongSniError"));
        }
      }
    }
  };

  return (
    <div className={style.container}>
      <p className={style.heading}>Add Sni Level 1</p>
      <div className={style.row}>
        <div className={style.col}>
          <p className={style.inputLabel}>Enter code</p>
          <input
            className={style.input}
            type="text"
            placeholder="Code"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
              const newErrors = validate(e.target.value);
              setError(newErrors);
            }}
          />
          {error && code.length !== 2 && (
            <div className={style.error}>{error}</div>
          )}
          {sniResult == true && <div className={style.error}>{chkError}</div>}
        </div>
        <div className={style.col}>
          <p className={style.inputLabel}>Enter name</p>
          <input
            className={style.input}
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div className={style.col}>
          <p className={style.inputLabel}>Enter swedish name</p>
          <input
            className={style.input}
            type="text"
            placeholder="Swedish Name"
            value={swedishName}
            onChange={(e) => {
              setSwedishName(e.target.value);
            }}
          />
        </div>
        <div className={style.col}>
          <p className={style.inputLabel}>Select Department</p>
          <div className={style.supplierBox}>
            <div className={style.box}>
              <div className={`${style.agreementSelect}`}>
                <Select
                  className={style.select}
                  size={"large"}
                  onChange={(value) => setDepartmentName(value)}
                  value={departmentName}
                  options={
                    renderedDepartmentOptions ? renderedDepartmentOptions : []
                  }
                  dropdownClassName={style.Dropdown}
                ></Select>
              </div>
            </div>
          </div>
        </div>
        <div className={style.btnContainer}>
          <button
            className={style.btn}
            type="submit"
            onClick={() => {
              handleSniLevel1Submit();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
