import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { StyleSheet } from "../../../models/StyleSheet";
import { useGetIssue, usePostIssue } from "../../../hooks/api/Issues";
import { useTranslation } from "../../../hooks/useTranslation";
import { useNavigate } from "react-router";
import {
  DisplaySuccessNotification,
  DisplayErrorNotification,
} from "../../../services/notificationService";
import { useAuthState } from "../../../hooks/useAuthState";
import { storage } from "../../../services/storage";

const { Option } = Select;

export const MultiSelection = () => {
  const { data: issuesData } = useGetIssue();
  const { t } = useTranslation("onboardingPage");
  const [values, setValues] = useState<[]>([]);
  const navigate = useNavigate();
  const { user } = useAuthState();
  const [dataToSend, setDataToSend] = useState<{
    onboardingIssueIds: (number | undefined)[];
    customAnswers: (string | undefined)[];
  }>({ onboardingIssueIds: [], customAnswers: [] });

  useEffect(() => {
    const onboardingId = values
      .map((value) => {
        const currIssue = issuesData?.data.find(
          (issue) => issue.issue === value
        );
        return currIssue?.id;
      })
      .filter((id) => id);
    const customAns = values
      .map((value) => {
        const currIssue = issuesData?.data.find(
          (issue) => issue.issue === value
        );
        return currIssue ? undefined : value;
      })
      .filter((text) => text);
    setDataToSend({
      onboardingIssueIds: onboardingId,
      customAnswers: customAns,
    });
  }, [values, issuesData]);

  function handleChange(value: []) {
    if (value.length <= 3) {
      setValues(value);
    }
  }

  const { mutate } = usePostIssue();

  const handleSubmit = () => {
    if (user) {
      mutate(dataToSend, {
        onSuccess: () => {
          DisplaySuccessNotification(t("success"));
          user.isOnboarded = true;
          storage.setUser(user);
          window.location.reload();
        },
        onError: () => {
          DisplayErrorNotification(t("error"));
          navigate("/");
        },
      });
    }
  };

  return (
    <>
      <div style={styles.container}>
        <Select
          showSearch={false}
          menuItemSelectedIcon
          mode="multiple"
          style={styles.hiddenInput}
          defaultValue={values}
          onChange={handleChange}
          value={values}
          labelInValue={false}
          dropdownStyle={styles.option}
          dropdownAlign={{
            points: ["bl", "tl"],
            offset: [0, 70],
          }}
          open={true}
          maxTagTextLength={3}
        >
          {issuesData?.data.map((issue) => {
            return (
              <Option
                className="customSelect"
                key={issue.issue}
                value={issue.issue}
                label={issue.issue}
              >
                <div className="demo-option-label-item">{issue.issue}</div>
              </Option>
            );
          })}
        </Select>

        <Select
          showSearch={false}
          mode="tags"
          style={styles.select}
          placeholder={t("problem")}
          defaultValue={values}
          onChange={handleChange}
          value={values}
          open={false}
        ></Select>
      </div>
      <button className="pointer" onClick={handleSubmit} style={styles.btn}>
        {t("continue")}
      </button>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: "auto",
    marginTop: "100px",
    paddingBottom: "15px",
    width: "800px",
  },
  hiddenInput: {
    width: "600px",
    position: "static",
    top: "0",
    left: "0",
    marginTop: "190px",
    height: "0px",
    visibility: "hidden",
  },
  select: {
    width: "600px",
    overflow: "auto",
    textAlign: "left",
    boxShadow: "0",
    zIndex: "1",
  },
  option: {
    width: "600px",
    height: "320px",
    scrollbarColor: "#07285D",
    zIndex: "0",
    padding: "0",
  },
  btn: {
    fontFamily: "D-Din",
    fontStyle: "normal",
    backgroundColor: "#07285D",
    borderRadius: "4px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#FFFFFF",
    marginBottom: "10px",
    width: "156px",
  },
});
