import React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "../../../../hooks/useTranslation";
import { useNavigate } from "react-router";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../../services/notificationService";
import style from "../styles/style.module.css";
import { useAddSubCategory, useGetCheckCpv } from "../../../../hooks/api/Admin";
import { Select } from "antd";
import { useSuntGetCategories } from "../../../../hooks/api/Category";

const SubCategory = () => {
  const [subCategory, setSubCategory] = useState("");
  const [suntCategoryName, setSuntCategoryName] = useState("");
  const [swedishName, setSwedishName] = useState("");
  const [cpv, setCpv] = useState("");
  const [error, setError] = useState("");

  const { t } = useTranslation("addCategory");
  const navigate = useNavigate();

  const { mutate: subCategoryMutate } = useAddSubCategory();

  const { data: categoryNamesData } = useSuntGetCategories();
  const { data: cpvResult } = useGetCheckCpv(cpv);
  let chkError = "";
  if (cpvResult === true) {
    chkError = "Cpv already Exists";
  }

  function validate(cpv: string) {
    let errors = "";
    if (cpv && cpv.length !== 8) {
      errors = "cpv code must be exactly 8 character long";
    }
    return errors;
  }

  const renderedCategoryOptions = useMemo(
    () =>
      categoryNamesData
        ?.filter((category) => !!category.name)
        .map((category) => {
          return { value: category.name, id: category.id };
        }),
    [categoryNamesData]
  );
  const suntCategoryId = useMemo(
    () =>
      categoryNamesData?.find((option) => option.name === suntCategoryName)?.id,
    [suntCategoryName, categoryNamesData]
  );

  const handleSubCategorySubmit = () => {
    if (
      subCategory == "" ||
      swedishName == "" ||
      cpv == "" ||
      !suntCategoryId
    ) {
      DisplayErrorNotification(t("ProvideAllData"));
    } else {
      const newErrors = validate(cpv);
      setError(newErrors);

      if (newErrors === "" && chkError == "") {
        const dataToSend = {
          data: {
            suntCategoryId: suntCategoryId,
            suntCategoryDetailed: subCategory,
            swedishName: swedishName,
            cpvCode: cpv,
          },
        };
        console.log("1", dataToSend);

        subCategoryMutate(dataToSend, {
          onSuccess: () => {
            DisplaySuccessNotification(t("addCategorySuccess"));
            navigate("/dashboard");
          },
          onError: () => {
            DisplayErrorNotification(t("addCategoryError"));
          },
        });
      } else {
        if (chkError !== "") {
          DisplayErrorNotification(t("CpvAlreadyExists"));
        } else {
          DisplayErrorNotification(t("WrongCpvError"));
        }
      }
    }
  };

  return (
    <>
      <p className={style.heading}>Add Sub Category</p>
      <div className={style.row}>
        <div className={style.col}>
          <p className={style.inputLabel}>Enter Sub Category</p>
          <input
            className={style.input}
            type="text"
            placeholder="Sub category"
            value={subCategory}
            onChange={(e) => {
              setSubCategory(e.target.value);
            }}
          />
        </div>
        <div className={style.col}>
          <p className={style.inputLabel}>Enter swedish name</p>
          <input
            className={style.input}
            type="text"
            placeholder="Swedish Name"
            value={swedishName}
            onChange={(e) => {
              setSwedishName(e.target.value);
            }}
          />
        </div>
        <div className={style.col}>
          <p className={style.inputLabel}>Enter Cpv</p>
          <input
            className={style.input}
            type="text"
            placeholder="Cpv"
            value={cpv}
            onChange={(e) => {
              setCpv(e.target.value);
              const newErrors = validate(e.target.value);
              setError(newErrors);
            }}
          />
          {error && cpv.length !== 8 && (
            <div className={style.error}>{error}</div>
          )}
          {cpvResult == true && <div className={style.error}>{chkError}</div>}
        </div>
        <div className={style.col}>
          <p className={style.inputLabel}>Select Sunt Category</p>
          <div className={style.supplierBox}>
            <div className={style.box}>
              <div className={`${style.agreementSelect}`}>
                <Select
                  className={style.select}
                  size={"large"}
                  onChange={(value) => setSuntCategoryName(value)}
                  value={suntCategoryName}
                  options={
                    renderedCategoryOptions ? renderedCategoryOptions : []
                  }
                  dropdownClassName={style.categoryDropdown}
                ></Select>
              </div>
            </div>
          </div>
        </div>
        <div className={style.btnContainer}>
          <button
            className={style.SubCategoryBtn}
            type="submit"
            onClick={() => {
              handleSubCategorySubmit();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default SubCategory;
