import React, { useState } from "react";
import { Col } from "antd";
import { StyleSheet } from "../../../models/StyleSheet";
import { useTranslation } from "../../../hooks/useTranslation";
import Vector from "../../../assets/icons/Vector.svg";
import { boolean } from "yup";

interface TableHeaderSorterProps {
  alignLeft?: boolean;
  span: number;
  sorterName:
    | "name"
    | "employeesNumber"
    | "city"
    | "numberOfReviews"
    | "averageRating";
  sortType: {
    sortBy:
      | "name"
      | "employeesNumber"
      | "city"
      | "numberOfReviews"
      | "averageRating"
      | null;
    orderBy: "desc" | "asc";
  };
  setSortType: React.Dispatch<
    React.SetStateAction<{
      sortBy:
        | "name"
        | "employeesNumber"
        | "city"
        | "numberOfReviews"
        | "averageRating"
        | null;
      orderBy: "desc" | "asc";
    }>
  >;
  onClickDisabled?: boolean;
}

export const TableHeaderSorter = ({
  span,
  sorterName,
  sortType,
  setSortType,
  alignLeft = false,
  onClickDisabled,
}: TableHeaderSorterProps) => {
  const [hover, setHover] = useState(false);
  const { t } = useTranslation("suppliersTable");

  return (
    <Col
      span={span}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={
        alignLeft
          ? hover
            ? { ...styles.name, ...styles.hover }
            : styles.name
          : hover
          ? styles.hover
          : {}
      }
      onClick={() =>
        onClickDisabled
          ? null
          : setSortType(
              sortType.sortBy === sorterName
                ? sortType.orderBy === "desc"
                  ? {
                      sortBy: sorterName,
                      orderBy: "asc",
                    }
                  : {
                      sortBy: null,
                      orderBy: "asc",
                    }
                : {
                    sortBy: sorterName,
                    orderBy: "desc",
                  }
            )
      }
    >
      <span
        style={
          sortType.sortBy === sorterName ? styles.activeText : styles.otherText
        }
      >
        {t(sorterName)}
      </span>
      {sortType.sortBy === sorterName ? (
        <img
          src={Vector}
          style={
            sortType.orderBy === "asc" ? { transform: "rotate(180deg)" } : {}
          }
        />
      ) : (
        ""
      )}
    </Col>
  );
};

const styles = StyleSheet.create({
  name: {
    textAlign: "left",
  },
  activeText: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#4FBFC0",
  },
  otherText: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#000000",
  },
  hover: {
    cursor: "pointer",
  },
});
