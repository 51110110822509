import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const SendTextSvg = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L17.0833 9.77341L1 18.5455L3.19319 9.77341L1 1Z"
      fill="#808795"
    />
    <path
      d="M3.19319 9.77341H17.0833M3.19319 9.77341L1 18.5455L17.0833 9.77341M3.19319 9.77341L1 1L17.0833 9.77341"
      stroke="white"
      strokeWidth="1.46212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SendTextIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SendTextSvg} {...props} />
);

export default SendTextIcon;
