import React from "react";
import { MinimalistLogo } from "../components/MinimalistLogo";
import { StyleSheet } from "../../../models/StyleSheet";
import { DefaultButton } from "../../../components";
import { useTranslation } from "../../../hooks/useTranslation";
import illustration from "../../../assets/images/Illustration.svg";

export const VerifiedPage = () => {
  const { t } = useTranslation("signupPage");
  return (
    <>
      <div style={styles.container}>
        <MinimalistLogo style={styles.logo} />
      </div>
      <div style={styles.content}>
        <p style={styles.heading}>{t("verifedAccount")}</p>

        <div style={styles.image}>
          <img src={illustration} />
        </div>
        <p style={styles.contentText}>{t("verifedAccountDesc")}</p>
        <a href="https://www.sunt.tech/">
          <DefaultButton
            style={styles.button}
            text={t("verifedAccountBtn")}
            variant={"primary"}
          />
        </a>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  logo: {
    display: "inline",
    margin: "0px 15px",
    marginTop: "56px",
  },
  content: {
    textAlign: "center",
    marginTop: "56px",
  },
  heading: {
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "52px",
    color: "#07285d",
  },
  image: {
    width: "530px",
    height: "407px",
    marginLeft: "calc(50vw - 265px)",
    marginBottom: "30px",
  },
  contentText: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "35px",
    letterSpacing: "0.07em",
    margin: "auto 10vw",
    marginBottom: "15px",
  },
  button: {
    height: "55px",
    width: "530px",
    background: "#07285D",
    border: "0",
    borderRadius: "4px",
    fontWeight: "150",
    fontSize: "20px",
    lineHeight: "21px",
    letterSpacing: "0.07em",
    color: "#FFFFFF",
    marginTop: "30px",
  },
});
