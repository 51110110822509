import React from "react";
import "antd/dist/antd.css";
import "../../../css/mainContainer.css";
import { Layout } from "antd";
import { TopBar } from "./components/TopBar";
import { StyleSheet } from "../../../models/StyleSheet";

const { Content } = Layout;

export function BuyerContainer({ children }: React.PropsWithChildren<unknown>) {
  return (
    <Layout className="main-layout">
      <TopBar />
      <Layout>
        <Layout style={styles.mainContainer}>
          <Content>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export function SupplierContainer({
  children,
}: React.PropsWithChildren<unknown>) {
  return (
    <Layout className="main-layout">
      {/* <SupplierTopBar /> */}
      <TopBar />
      <Layout>
        <Layout style={styles.mainContainer}>
          <Content>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export function AdminContainer({ children }: React.PropsWithChildren<unknown>) {
  return (
    <Layout className="main-layout">
      <TopBar />
      <Layout>
        <Layout style={styles.mainContainer}>
          <Content>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    padding: "0 24px 24px",
    backgroundColor: "#FAFAFA",
  },
});
