import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const TopBarArrowSvg = () => (
  <svg
    width="37"
    height="73"
    viewBox="0 0 37 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="0.353553"
      y1="0.680626"
      x2="36.3536"
      y2="36.6806"
      stroke="#CDD4E2"
    />
    <line
      x1="36.3536"
      y1="36.1463"
      x2="0.353553"
      y2="72.1463"
      stroke="#CDD4E2"
    />
  </svg>
);

const TopBarArrowIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={TopBarArrowSvg} {...props} />
);

export default TopBarArrowIcon;
