import React, { useEffect, useState } from "react";
import style from "./styles/businessInfo.module.css";
import {
  Upload,
  Collapse,
  Select,
  Input,
  Progress,
  DatePicker,
  Avatar,
} from "antd";
import {
  LinkedinFilled,
  FacebookFilled,
  GoogleSquareFilled,
  GlobalOutlined,
  PlusCircleFilled,
  UploadOutlined,
  FilePdfFilled,
  CheckCircleFilled,
  FilePptFilled,
  CloseOutlined,
} from "@ant-design/icons";
import type { UploadProps } from "antd";
import { provinces } from "./provinces";
import { cities } from "./cities";
import ImageIcon from "../../../assets/icons/imageIcon";
import moment from "moment";
import { storage } from "../../../services/storage";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../services/notificationService";
import { useGetLogoUrl } from "../../../hooks/api/Suppliers";
import { useTranslation } from "../../../hooks/useTranslation";
// type Props = {}

const { Panel } = Collapse;
const { Dragger } = Upload;
const { TextArea } = Input;
const BusinessInfo = (componentProps: {
  setFunctions: {
    setOrganisationForm: React.Dispatch<React.SetStateAction<string>>;
    setSme: React.Dispatch<React.SetStateAction<string>>;
    setTurnover: React.Dispatch<React.SetStateAction<number>>;
    setDescription: React.Dispatch<React.SetStateAction<string>>;
    setWebsiteURL: React.Dispatch<React.SetStateAction<string>>;
    setFacebookURL: React.Dispatch<React.SetStateAction<string>>;
    setLinkedinURL: React.Dispatch<React.SetStateAction<string>>;
    setGoogleURL: React.Dispatch<React.SetStateAction<string>>;
    setStreetName: React.Dispatch<React.SetStateAction<string>>;
    setCity: React.Dispatch<React.SetStateAction<string>>;
    setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
    setCountry: React.Dispatch<React.SetStateAction<string>>;
    setMobile: React.Dispatch<React.SetStateAction<string>>;
    setPostalCode: React.Dispatch<React.SetStateAction<string>>;
    setProvince: React.Dispatch<React.SetStateAction<string>>;
    setRegistrationDate: React.Dispatch<React.SetStateAction<Date | null>>;
  };
  companySizes: {
    value: string;
    label: string;
  }[];

  companyTypes: {
    value: string;
    label: string;
  }[];

  businessStates: {
    organisationForm: string;
    SME: string;
    turnover: number;
    description: string;
    websiteURL: string;
    facebookURL: string;
    linkedinURL: string;
    googleURL: string;
    streetName: string;
    city: string;
    phoneNumber: string;
    country: string;
    mobile: string;
    postalCode: string;
    province: string;
    registrationDate: Date | null;
  };
}) => {
  const {
    setOrganisationForm,
    setSme,
    setTurnover,
    setDescription,
    setWebsiteURL,
    setFacebookURL,
    setLinkedinURL,
    setGoogleURL,
    setStreetName,
    setCity,
    setPhoneNumber,
    setCountry,
    setMobile,
    setPostalCode,
    setProvince,
    setRegistrationDate,
  } = componentProps.setFunctions;
  const { companySizes } = componentProps;
  const { companyTypes } = componentProps;
  const { businessStates } = componentProps;

  const [logoUrlKey, setLogoUrlKey] = useState<string>();
  const [logoUrl, setLogoUrl] = useState<string>();
  const [orgName, setOrgName] = useState<string>();
  const [orgRegisteredDate, setOrgRegisteredDate] = useState<string>();

  const { data: orgLogoUrl } = useGetLogoUrl(logoUrlKey ? logoUrlKey : "");
  const { t } = useTranslation("supplierForm");

  useEffect(() => {
    if (orgLogoUrl) {
      localStorage.setItem("orgLogoUrl", orgLogoUrl);
      setLogoUrl(orgLogoUrl);
    }
  }, [orgLogoUrl]);

  useEffect(() => {
    // getting orgLogoUrl key from local storage
    const logoUrlKey = localStorage.getItem("logoUrlKey");
    if (logoUrlKey) {
      const filteredLogoUrlKey = logoUrlKey?.replace("/", "%2F");
      setLogoUrlKey(filteredLogoUrlKey);
    }
    // getting org basic info
    const localStoredOrgInfo = localStorage.getItem("orgInfo");
    if (localStoredOrgInfo) {
      const orgData = JSON.parse(localStoredOrgInfo) as {
        name: string;
        averageRating: number;
        totalReviews: number;
        registeredDate: string;
      };
      setOrgName(orgData.name);
      setOrgRegisteredDate(orgData.registeredDate);
    }
  }, []);

  const organisationId = storage.getUser()?.organisationId;
  const props: UploadProps = {
    name: "file",
    multiple: false,
    action: `https://api-dev.sunt.euvic.cloud/api/supplier/upload-organisation-logo/${
      organisationId ? organisationId : 0
    }`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        // console.log(info.file.response.logoURL);
        const logoUrlKey = info.file.response.logoURL as string;
        localStorage.setItem("logoUrlKey", logoUrlKey);
        const filteredLogoUrlKey = logoUrlKey?.replace("/", "%2F");
        setLogoUrlKey(filteredLogoUrlKey);
        // message.success(`${info.file.name} file uploaded successfully.`);
        DisplaySuccessNotification("Success");
      } else if (status === "error") {
        DisplayErrorNotification("Error");
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <div>
      <Collapse defaultActiveKey={["1"]} className={style.collapse}>
        <Panel header="Företagsinformation" key="1" id="basicInfoPanel">
          <div className={style.basicInfoContainer}>
            <div className={style.picBox}>
              <Dragger {...props} multiple={false} showUploadList={false}>
                <div className={style.uploadOrgLogo}>
                  {/* company logo */}
                  <p className="ant-upload-drag-icon">
                    {logoUrl && (
                      <img className={style.uploadOrgImageIcon} src={logoUrl} />
                    )}
                    {!logoUrl && (
                      <ImageIcon className={style.uploadOrgImageIcon} />
                    )}
                  </p>
                  <p className="ant-upload-text">
                    Dra och släpp logga här eller välj genom att
                    <p>klicka här</p>
                  </p>
                </div>
              </Dragger>
              <p className={style.logoUploadPrecation}>
                *Format: PNG, JPG, JPEG Max storlek: 150 x 150px
              </p>
            </div>
            <div className={style.infoBox}>
              <div className={style.row}>
                <div className={style.textBox}>
                  <h2 className={style.sectionHeading}>{orgName}</h2>
                  <p className={style.subHeading}>{t("aboutOrganisation")}</p>
                </div>
              </div>
              <div className={style.descriptionInputBox}>
                <TextArea
                  className={style.descriptionInput}
                  style={{ height: 194, resize: "none" }}
                  placeholder="Skriv en kort presentationstext om företaget..."
                  value={businessStates.description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
                {/* description */}
              </div>
            </div>
          </div>
          <div className={style.sectionDivider}></div>
          <div className={style.orgInfoContainer}>
            <div className={style.row}>
              <div className={style.col}>
                <p className={style.inputLabel}>Registreringsår</p>
                <div
                  className={`${style.selectInput} ${style.registerationInputBox}`}
                >
                  {/* <Select
                    placeholder="1920"
                    className={style.select}
                    dropdownClassName={style.selectInputDropdown}
                    // onChange={setRegistrationDate}
                    // registration year
                  >
                  </Select> */}
                  <span className={style.registeredDateInput}>
                    {orgRegisteredDate?.slice(0, 10)}
                  </span>
                  {/* <input
                    // value={businessStates.registrationDate}
                    onChange={(e) => {
                      setRegistrationDate(
                        e.target.valueAsDate ? e.target.valueAsDate : new Date()
                      );
                    }}
                    type="Date"
                  ></input> */}
                </div>
              </div>
              {/* <div className={style.col}>
                <p className={style.inputLabel}>Organisationsnummer</p>
                <input
                  className={style.input}
                  type="text"
                  placeholder="1950929399494"
                  name="organisationNumber"
                  //   value={organisationNumber}
                  //   onChange={(e) => setOrganisationNumber(e.target.value)}
                  // organisationNumber
                />
              </div> */}
              <div className={style.col}>
                <p className={style.inputLabel}>Företagsstorlek</p>
                <div className={`${style.selectInput}`}>
                  <Select
                    placeholder="Välj antal..."
                    className={style.select}
                    value={businessStates.SME}
                    dropdownClassName={style.selectInputDropdown}
                    options={companySizes}
                    onChange={setSme}
                    //company size
                  >
                    {/* {value} */}
                  </Select>
                </div>
              </div>
              <div className={style.col}>
                <p className={style.inputLabel}>Omsättning (TKR)</p>
                <div>
                  <input
                    type="text"
                    placeholder="Omsättning i TKR..."
                    className={style.input}
                    value={businessStates.turnover}
                    onChange={(e) => {
                      setTurnover(parseInt(e.target.value));
                    }}
                    // turnover tkr
                  />
                </div>
              </div>
              <div className={style.col}>
                <p className={style.inputLabel}>{t("organisationType")}</p>
                <div className={`${style.selectInput}`}>
                  <Select
                    placeholder="Aktiebolag"
                    className={style.select}
                    dropdownClassName={style.selectInputDropdown}
                    options={companyTypes}
                    value={businessStates.organisationForm}
                    onChange={setOrganisationForm}
                    //company type
                  >
                    {/* {value} */}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </Panel>
        <Panel header="Kontakt Information, Webb och Sociala Kanaler" key="2">
          <div className={style.collapseContainer}>
            <div className={style.firstChild}>
              {/* <h2 className={style.sectionHeading}>Kontakt Information</h2> */}
              <div className={style.parentRow}>
                <div className={style.subCol}>
                  <p className={style.inputLabel}>Adress</p>
                  <input
                    className={style.input}
                    type="text"
                    placeholder="Gatuadress"
                    value={businessStates.streetName}
                    onChange={(e) => {
                      setStreetName(e.target.value);
                    }}
                    // Address
                  />
                </div>
              </div>
              <div className={style.parentRow}>
                <div className={style.row}>
                  <div className={style.subCol}>
                    <p className={style.inputLabel}>Postnummer</p>
                    <input
                      className={style.input}
                      type="text"
                      placeholder="14470"
                      value={businessStates.postalCode}
                      onChange={(e) => {
                        setPostalCode(e.target.value);
                      }}
                      //  zip code
                    />
                  </div>
                  <div className={style.subCol}>
                    <p className={style.inputLabel}>Postort</p>
                    <div className={`${style.selectInput}`}>
                      <Select
                        placeholder="Stockholm"
                        className={style.select}
                        dropdownClassName={style.selectInputDropdown}
                        options={cities?.map((city) => {
                          return {
                            value: city.label,
                            label: city.label,
                          };
                        })}
                        value={businessStates.city}
                        onChange={setCity}

                        // Postal Address
                      ></Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.parentRow}>
                <div className={style.row}>
                  <div className={style.subCol}>
                    <p className={style.inputLabel}>Län</p>
                    <div className={`${style.selectInput}`}>
                      <Select
                        placeholder="Stockholm Län"
                        className={style.select}
                        dropdownClassName={style.selectInputDropdown}
                        value={businessStates.province}
                        onChange={setProvince}
                        options={provinces?.map((province) => {
                          return {
                            value: province.label,
                            label: province.label,
                          };
                        })}
                      ></Select>
                    </div>
                  </div>
                  <div className={style.subCol}>
                    <p className={style.inputLabel}>Land</p>
                    <div className={`${style.selectInput}`}>
                      <Select
                        placeholder="Sverige"
                        className={style.select}
                        dropdownClassName={style.selectInputDropdown}
                        value={businessStates.country}
                        onChange={setCountry}
                        options={[{ value: "Sweden", label: "Sweden" }]}
                        // Country
                      >
                        {/* {value} */}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.parentRow}>
                <div className={style.row}>
                  <div className={style.subCol}>
                    <p className={style.inputLabel}>Telefonnummer</p>
                    <input
                      className={style.input}
                      type="text"
                      placeholder="012 3488 581"
                      value={businessStates.phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      //   Phone Number
                    />
                  </div>
                  <div className={style.subCol}>
                    <p className={style.inputLabel}>Mobil</p>
                    <input
                      className={style.input}
                      type="text"
                      placeholder="080 - 1818 124"
                      value={businessStates.mobile}
                      onChange={(e) => {
                        setMobile(e.target.value);
                      }}
                      //   Mobile
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={style.secondChild}>
              <div className={style.parentRow}>
                <div className={style.subCol}>
                  <p className={style.inputLabel}>Webplats</p>
                  <div className={style.socialInput}>
                    <GlobalOutlined className={style.globIcon} />
                    <input
                      name="website"
                      className={style.input}
                      type="text"
                      placeholder="www.foretåg.se"
                      value={businessStates.websiteURL}
                      onChange={(e) => {
                        setWebsiteURL(e.target.value);
                      }}
                      //   WebSite
                    />
                  </div>
                </div>
              </div>
              <div className={style.socialInputRow}>
                <div className={style.subCol}>
                  <p className={style.inputLabel}>{t("socialMedia")}</p>
                  <div className={style.socialInput}>
                    <LinkedinFilled
                      className={`${style.icon} ${style.linkedIn}`}
                    />
                    <input
                      className={style.input}
                      type="text"
                      placeholder="ISSFacilit/In"
                      name="linkedIn"
                      value={businessStates.linkedinURL}
                      onChange={(e) => {
                        setLinkedinURL(e.target.value);
                      }}
                      //linkedin
                    />
                  </div>
                </div>
              </div>
              <div className={style.socialInputRow}>
                <div className={style.subCol}>
                  <div className={style.socialInput}>
                    <FacebookFilled
                      className={`${style.icon} ${style.facebook}`}
                    />
                    <input
                      className={style.input}
                      type="text"
                      placeholder="ISSFacility"
                      name="facebook"
                      value={businessStates.facebookURL}
                      onChange={(e) => {
                        setFacebookURL(e.target.value);
                      }}
                      //facebook
                    />
                  </div>
                </div>
              </div>
              <div className={style.socialInputRow}>
                <div className={style.subCol}>
                  <div className={style.socialInput}>
                    <GoogleSquareFilled
                      className={`${style.icon} ${style.gmail}`}
                    />
                    <input
                      className={style.input}
                      type="text"
                      placeholder="ISSFacility"
                      name="google"
                      value={businessStates.googleURL}
                      onChange={(e) => {
                        setGoogleURL(e.target.value);
                      }}
                      //google
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Panel>
        <Panel header="Bilagor och Media" key="3">
          <div className={style.mediaContainer}>
            <div className={style.mediaContainerFirstChild}>
              <div className={style.mediaUpload}>
                <Dragger {...props} showUploadList={false}>
                  {/* company logo */}
                  <div className={style.mediaUploadInner}>
                    <p className="ant-upload-drag-icon">
                      <UploadOutlined className={style.uploadIcon} />
                    </p>
                    <p className="ant-upload-text">
                      Dra och släpp filer här för att ladda upp eller välj filer
                      genom att <span>klicka här</span>
                    </p>
                    <p className="ant-upload-hint">
                      *Format: PDF, PPT, PNG, JPG, JPEG Max storlek: 5MB
                    </p>
                  </div>
                </Dragger>
              </div>
            </div>
            <div className={style.mediaContainerFirstChild}>
              <h2 className={style.sectionHeading}>Uppladdade filer</h2>
              <div className={style.fileListContainer}>
                <div className={style.fileListBox}>
                  <FilePdfFilled className={style.fileIcon} />
                  <p>GreenView_Certificate_ISS_Facility_Services_2020(1).pdf</p>
                  <CheckCircleFilled className={style.fileCheckIcon} />
                </div>
                <div className={style.fileListBox}>
                  <FilePdfFilled className={style.fileIcon} />
                  <div className={style.textAndProgress}>
                    <p>
                      GreenView_Certificate_ISS_Facility_Services_2020(1).pdf
                    </p>
                    <div className={style.prgressBar}>
                      <Progress
                        percent={50}
                        showInfo={false}
                        strokeColor="#808795"
                      />
                    </div>
                  </div>
                  <CloseOutlined className={style.fileCloseIcon} />
                </div>
                <div className={style.fileListBox}>
                  <FilePptFilled className={style.fileIcon} />
                  <div className={style.textAndProgress}>
                    <p>ISS_Facility_Services_2022_Presentation.ppt</p>
                    <div className={style.prgressBar}>
                      <Progress
                        percent={30}
                        showInfo={false}
                        strokeColor="#808795"
                      />
                    </div>
                  </div>
                  <CloseOutlined className={style.fileCloseIcon} />
                </div>
                <div className={style.fileListBox}>
                  <FilePdfFilled className={style.fileIcon} />
                  <div className={style.textAndProgress}>
                    <p>
                      GreenView_Certificate_ISS_Facility_Services_2020(1).pdf
                    </p>
                    <div className={style.prgressBar}>
                      <Progress
                        percent={10}
                        showInfo={false}
                        strokeColor="#808795"
                      />
                    </div>
                  </div>
                  <CloseOutlined className={style.fileCloseIcon} />
                </div>
              </div>
            </div>
          </div>
        </Panel>
        {/* <Panel header="Om Företåg" key="0">
          <div className={style.collapseContainer}>
            <div className={style.firstChild}>
              <div className={style.parentRow}>
                <div className={style.subCol}>
                  <p className={style.inputLabel}>Företågnamn</p>
                  <input
                    className={style.input}
                    type="text"
                    placeholder="Skriv företagsnamn..."
                    name="name"
                    // value={name}
                    // onChange={(e) => setName(e.target.value)}
                    // name
                  />
                </div>
              </div>
              <div className={style.parentRow}>
                <div className={style.row}>
                  <div className={style.subCol}>
                    <p className={style.inputLabel}>Organisationsnummer</p>
                    <input
                      className={style.input}
                      type="text"
                      placeholder="1950929399494"
                      name="organisationNumber"
                      //   value={organisationNumber}
                      //   onChange={(e) => setOrganisationNumber(e.target.value)}
                      // organisationNumber
                    />
                  </div>
                  <div className={style.subCol}>
                    <p className={style.inputLabel}>Företagstyp</p>
                    <div className={`${style.selectInput}`}>
                      <Select
                        placeholder="Aktiebolag"
                        className={style.select}
                        dropdownClassName={style.selectInputDropdown}
                        // onChange={setCompanyType}
                        // options={companyTypes}
                        //company type
                      ></Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.parentRow}>
                <div className={style.row}>
                  <div className={style.subCol}>
                    <p className={style.inputLabel}>Registreringsår</p>
                    <div className={`${style.selectInput}`}>
                      <Select
                        placeholder="1920"
                        className={style.select}
                        dropdownClassName={style.selectInputDropdown}
                        // onChange={setRegistrationDate}
                        // registration year
                      ></Select>
                    </div>
                  </div>
                  <div className={style.subCol}>
                    <p className={style.inputLabel}>Företagsstorlek</p>
                    <div className={`${style.selectInput}`}>
                      <Select
                        placeholder="Mikroföretag"
                        className={style.select}
                        dropdownClassName={style.selectInputDropdown}
                        // onChange={setCompanySze}
                        // options={companySizes}
                        //company size
                      ></Select>
                    </div>
                  </div>
                  <div className={style.subCol}>
                    <p className={style.inputLabel}>Omsättning (TKR)</p>
                    <div>
                      <input
                        type="text"
                        placeholder="200M"
                        className={style.input}
                        // onChange={(e) => {
                        //   setTurnover(parseInt(e.target.value));
                        // }}
                        // turnover tkr
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.parentRow}>
                <div className={style.row}>
                  <div className={style.subCol}>
                    <p className={style.inputLabel}>Serviceområden</p>
                    <div className={`${style.selectInput}`}>
                      <Select
                        placeholder="Välj Serviceområden..."
                        className={style.select}
                        dropdownClassName={style.selectInputDropdown}
                        // onChange={setServiceAreas}
                        // options={serviceAreasData?.map((serviceArea) => {
                        //   return {
                        //     value: serviceArea.label,
                        //     label: serviceArea.label,
                        //   };
                        // })}
                        //service areas
                      ></Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.parentRow}>
                <div className={style.row}>
                  <div className={style.subCol}>
                    <p className={style.inputLabel}>Kategorier</p>
                    <div className={`${style.selectInput}`}>
                      <Select
                        placeholder="Välj kategori..."
                        className={style.select}
                        dropdownClassName={style.selectInputDropdown}
                        // onChange={setSuntCategoryId}
                        // options={categoriesData?.map((category) => {
                        //   return { value: category.id, label: category.name };
                        // })}
                        // categories
                      ></Select>
                    </div>
                  </div>
                </div>
              </div>

              <div className={style.parentRow}>
                <div className={style.row}>
                  <div className={style.subCol}>
                    <p className={style.inputLabel}>Sub-kategorier</p>
                    <div className={`${style.selectInput}`}>
                      <Select
                        placeholder="Välj kategori..."
                        className={style.select}
                        dropdownClassName={style.selectInputDropdown}
                        // onChange={setSubCategoryId}
                        // options={CategoryNames?.map((category) => {
                        //   return {
                        //     value: category.id,
                        //     label: category.suntCategoryDetailed,
                        //   };
                        // })}
                        // sub categories
                      ></Select>
                    </div>
                  </div>
                </div>
              </div>

              <div className={style.parentRow}>
                <div className={style.subCol}>
                  <p className={style.inputLabel}>SNI-kod</p>
                  <input
                    className={style.input}
                    type="text"
                    placeholder="Välj SNI-koder..."
                    // onChange={(e) => {
                    //   setSniCode(e.target.value);
                    // }}
                    //sni code
                  />
                </div>
              </div>
              <div className={style.parentRow}>
                <div className={style.subCol}>
                  <p className={style.inputLabel}>Företågslogotyp</p>
                  <Dragger {...props}>
                    <div className={style.uploadInput}>
                      <p className="ant-upload-drag-icon">
                        <PlusCircleFilled />
                      </p>
                      <p className="ant-upload-text">
                        Browse eller drag PNG, JPG 150px x 150px...
                      </p>
                    </div>
                  </Dragger>
                </div>
              </div>
            </div>
            <div className={style.secondChild}>
              <div className={style.parentRow}>
                <div className={style.subCol}>
                  <p className={style.inputLabel}>Webplats</p>
                  <input
                    name="website"
                    className={style.input}
                    type="text"
                    placeholder="www.foretåg.se"
                    // onChange={(e) => {
                    //   const { name, value } = e.target;
                    //   setWebsites((state) => {
                    //     return {
                    //       ...state,
                    //       [name]: value,
                    //     };
                    //   });
                    // }}
                  />
                </div>
              </div>
              <div className={style.socialInputRow}>
                <div className={style.subCol}>
                  <p className={style.inputLabel}>Socila Medior</p>
                  <div className={style.socialInput}>
                    <LinkedinFilled
                      className={`${style.icon} ${style.linkedIn}`}
                    />
                    <input
                      className={style.input}
                      type="text"
                      placeholder="ISSFacilit/In"
                      name="linkedIn"
                      //   onChange={(e) => {
                      //     const { name, value } = e.target;
                      //     setWebsites((state) => {
                      //       return {
                      //         ...state,
                      //         [name]: value,
                      //       };
                      //     });
                      //   }}
                      //linkedin
                    />
                  </div>
                </div>
              </div>
              <div className={style.socialInputRow}>
                <div className={style.subCol}>
                  <div className={style.socialInput}>
                    <FacebookFilled
                      className={`${style.icon} ${style.facebook}`}
                    />
                    <input
                      className={style.input}
                      type="text"
                      placeholder="ISSFacility"
                      name="facebook"
                      //   onChange={(e) => {
                      //     const { name, value } = e.target;
                      //     setWebsites((state) => {
                      //       return {
                      //         ...state,
                      //         [name]: value,
                      //       };
                      //     });
                      //   }}
                      //facebook
                    />
                  </div>
                </div>
              </div>
              <div className={style.socialInputRow}>
                <div className={style.subCol}>
                  <div className={style.socialInput}>
                    <GoogleSquareFilled
                      className={`${style.icon} ${style.gmail}`}
                    />
                    <input
                      className={style.input}
                      type="text"
                      placeholder="ISSFacility"
                      name="google"
                      //   onChange={(e) => {
                      //     const { name, value } = e.target;
                      //     setWebsites((state) => {
                      //       return {
                      //         ...state,
                      //         [name]: value,
                      //       };
                      //     });
                      //   }}
                      //google
                    />
                  </div>
                </div>
              </div>
              <div className={style.parentRow}>
                <div className={style.subCol}>
                  <p className={style.inputLabel}>Bilgor och Medior</p>
                  <Dragger {...props}>
                    <div className={style.uploadInput}>
                      <p className="ant-upload-drag-icon">
                        <PlusCircleFilled />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </div>
                  </Dragger>
                </div>
              </div>
            </div>
          </div>
          <div className={style.descriptionInputBox}>
            <div className={style.subCol}>
              <p className={style.inputLabel}>Beskrivning</p>
              <TextArea
                className={style.descriptionInput}
                style={{ height: 125, resize: "none" }}
                placeholder="Skriv en kort presentationstext om företaget..."
                // onChange={(e) => {
                //   setDescription(e.target.value);
                // }}
                // description
              />
            </div>
          </div>
        </Panel> */}
      </Collapse>
    </div>
  );
};

export default BusinessInfo;
