import React, { useEffect, useState } from "react";
import style from "./styles/style.module.css";
import { Collapse } from "antd";
import BusinessInfo from "./BusinessInfo";
import Category from "./Category";
import ServiceAreas from "./ServiceAreas";
import { SupplierData } from "../../../../hooks/api/Suppliers";
import { useTranslation } from "../../../../hooks/useTranslation";

const { Panel } = Collapse;

type Props = {
  navigation: (no: number) => void;
};

type catDataType = {
  parentId: number;
  subCats: { id: number; title: string }[];
};

type serviceAreaType = {
  id: number;
  cities: { code: number; title: string }[];
}[];

const RequestApproval = (props: Props) => {
  const { navigation } = props;
  const [categoriesData, setCategoriesData] = useState<catDataType>();
  const [data, setData] = useState<SupplierData>();
  const [serviceAreas, setServiceAreas] = useState<serviceAreaType>();
  const { t } = useTranslation("supplierForm");
  useEffect(() => {
    const stringifiedCatData = localStorage.getItem("sendingCatsData");
    const localStoredData = localStorage.getItem("supplierData");
    const localStoredServiceAreas = localStorage.getItem("sendingServiceAreas");
    if (localStoredData) {
      const localData = JSON.parse(localStoredData) as SupplierData;
      setData(localData);
    }
    if (stringifiedCatData) {
      const catData = JSON.parse(stringifiedCatData) as catDataType;
      setCategoriesData(catData);
    }
    if (localStoredServiceAreas) {
      const serviceAreas = JSON.parse(
        localStoredServiceAreas
      ) as serviceAreaType;
      setServiceAreas(serviceAreas);
    }
  }, []);

  // useEffect(() => {
  //   const serviceAreaStringified = data?.organisationData?.services;
  //   console.log(serviceAreaStringified, serviceAreaStringified?.length);
  //   if (serviceAreaStringified && serviceAreaStringified.length > 0) {
  //     const localServiceAreas = JSON.parse(
  //       serviceAreaStringified
  //     ) as serviceAreaType;
  //     setServiceAreas(localServiceAreas);
  //   }
  // }, [data]);

  return (
    <div className={style.mainContainer}>
      <h1 className={style.heading}>Sammanfattning</h1>
      <Collapse
        defaultActiveKey={["1"]}
        className={style.collapseBox}
        expandIconPosition="end"
      >
        <Panel
          header={
            <div className={style.headerContainer} onClick={() => {}}>
              <div className={style.headerTitleBox}>
                <h2 className={style.title}>{t("businessInfo")}</h2>
              </div>
              <button onClick={() => navigation(0)}>{t("adjust")}</button>
            </div>
          }
          key="2"
        >
          <BusinessInfo data={data} />
        </Panel>
      </Collapse>
      <Collapse className={style.collapseBox} expandIconPosition="end">
        <Panel
          header={
            <div className={style.headerContainer} onClick={() => {}}>
              <div className={style.headerTitleBox}>
                <h2 className={style.title}>{t("categoryDetail")}</h2>
              </div>
              <button onClick={() => navigation(1)}>{t("adjust")}</button>
            </div>
          }
          key="2"
        >
          <div className={style.capsuleBox}>
            {categoriesData?.subCats.map((item) => (
              <div key={item.id} className={style.capsuleBtn}>
                <span>{item.title}</span>
                <div className={style.closeIcon}></div>
              </div>
            ))}
          </div>
        </Panel>
      </Collapse>
      <Collapse className={style.collapseBox} expandIconPosition="end">
        <Panel
          header={
            <div className={style.headerContainer} onClick={() => {}}>
              <div className={style.headerTitleBox}>
                <h2 className={style.title}>{t("serviceAreas")}</h2>
              </div>
              <button onClick={() => navigation(2)}>{t("adjust")}</button>
            </div>
          }
          key="2"
        >
          {serviceAreas?.map((item) => (
            <ServiceAreas key={item.id} area={item} />
          ))}
        </Panel>
      </Collapse>
    </div>
  );
};

export default RequestApproval;
