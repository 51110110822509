import React from "react";
import { Dropdown } from "../../Buyer/components/Dropdown";
import { useTranslation } from "../../../hooks/useTranslation";
import cssStyle from "./styles/reviewFilter.module.css";
import { StyleSheet } from "../../../models/StyleSheet";

// type Props = {}

const ReviewFilter = () => {
  const { t } = useTranslation("reviewsFilter");
  return (
    <div className={`${cssStyle.container} ${cssStyle.reviewFilter}`}>
      <div className={cssStyle.heading}>
        <h2>{t("filter")}</h2>
        <p>Rensa alla</p>
      </div>

      <Dropdown label={t("reviewGrade")} style={styles.categoryContainer}>
        <>
          <div className={cssStyle.clearBtn}>
            <p>Clear</p>
          </div>
        </>
      </Dropdown>
      <Dropdown label={t("category")} style={styles.categoryContainer}>
        <>
          <div className={cssStyle.clearBtn}>
            <p>Clear</p>
          </div>
        </>
      </Dropdown>
      <Dropdown label={t("city")}>
        <>
          <div className={cssStyle.clearBtn}>
            <p>Clear</p>
          </div>
        </>
      </Dropdown>
      <Dropdown label={t("organization")}>
        <>
          <div className={cssStyle.clearBtn}>
            <p>Clear</p>
          </div>
        </>
      </Dropdown>
      <Dropdown label={t("tags")}>
        <>
          <div className={cssStyle.clearBtn}>
            <p>Clear</p>
          </div>
        </>
      </Dropdown>
      <Dropdown label={t("miscellaneous")}>
        <>
          <div className={cssStyle.clearBtn}>
            <p>Clear</p>
          </div>
        </>
      </Dropdown>
    </div>
  );
};

export default ReviewFilter;

const styles = StyleSheet.create({
  categoryContainer: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    // minWidth: "240px",
    width: "100%",
    // maxWidth: "240px",
    minHeight: "calc(100vh - 64px)",
    backgroundColor: "#FFFFFF",
    marginBottom: "-24px",
    paddingBottom: "10px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
  },
  header: {
    width: "100%",
    height: "48px",
    paddingLeft: "15px",
    display: "flex",
    alignItems: "center",
    fontFamily: "d-din",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "22px",
    color: "#000",
  },
  dateContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  dateInput: {
    outline: "none",
    backgroundColor: "#F2F2F5",
    height: "32px",
    border: "0",
    borderRadius: "4px",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    letterSpacing: "0.07em",
    color: "#000000",
    width: "150px",
    padding: "5px 8px",
    margin: "10px 0",
    display: "block",
  },
});
