import { AccountType } from "../constants/enums";
import { AuthUser } from "../hooks/api/Auth";

class Storage {
  public setAuth(token: string, refreshToken: string, role: AccountType): void {
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("role", role);
  }

  public getAuth() {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const role = localStorage.getItem("role");

    if (token && refreshToken && role) return { token, refreshToken, role };
    return { token: null, refreshToken: null, role: null };
  }

  public getUser(): AuthUser | null {
    const user = localStorage.getItem("user") ?? "";
    if (user) {
      const parsedUser = JSON.parse(user) as AuthUser;
      if (user) return parsedUser;
    }

    return null;
  }

  public setUser(user: AuthUser): void {
    localStorage.setItem("user", JSON.stringify(user));
  }

  public clearStorage() {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
  }
}

export const storage = new Storage();
