import React, { useState, useEffect, useMemo } from "react";
import { DimmedLink, StarRating } from "../../../components";
import {
  Radio,
  Checkbox,
  AutoComplete,
  Select,
  Avatar,
  Popover,
  Input,
  Rate,
} from "antd";
import {
  InfoCircleOutlined,
  StarOutlined,
  StarFilled,
} from "@ant-design/icons";
import { Formik, Form } from "formik";
import "../../../css/createReviewForm.css";
import ArrowDown from "../../../assets/icons/ArrowDown.svg";
import { colors } from "../../../constants/colors";
import {
  createReviewInitValues,
  createReviewValidationSchema,
  optionalDataToSendInterface,
  valuesInterface,
  styles,
} from "./createReviewForm.utils";
import {
  useGetCategoryOrganisations,
  useGetCategoryNames,
} from "../../../hooks/api/Category";
import { OrganisationsData } from "../../../hooks/api/Organisations";
import { useOptionalReview } from "../../../hooks/api/Reviews";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../services/notificationService";
import { useTranslation } from "../../../hooks/useTranslation";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { CustomRating } from "../components/CustomRating";
import cssStyle from "./styles/style.module.css";
import { StyleSheet } from "../../../models/StyleSheet";

export const CreateAssessmentForm = ({
  defaultOrganisation,
}: {
  defaultOrganisation?: OrganisationsData;
}) => {
  const TERMS_AND_CONDITIONS_LINK = "https://www.sunt.tech/privacy";

  const { t } = useTranslation("createEditReview");

  const { suntCategoryId, organisationId, organisationName } = useParams();

  const [organizationName, setOrganizationName] = useState("");
  const [organizationError, setOrganizationError] = useState("");

  const [qualityRate, setQualityRate] = useState<number | null>(0);
  const [customerServiceRate, setCustomerServiceRate] = useState<number | null>(
    0
  );
  const [deliveryCapRate, setDeliveryCapRate] = useState<number | null>(0);
  const [sustainRate, setSustainRate] = useState<number | null>(0);
  const [innovationRate, setInnovationRate] = useState<number | null>(0);
  const [costRate, setCostRate] = useState<number | null>(0);
  // const [conditionsRate, setConditionsRate] = useState<number | null>(0);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { mutate } = useOptionalReview();

  useEffect(() => {
    organisationName ? setOrganizationName(organisationName) : null;
  }, [organisationName]);

  const handleSubmit = (values: valuesInterface) => {
    const optionalDataToSend: optionalDataToSendInterface = {
      qualityRating: qualityRate,
      customerServiceRating: customerServiceRate,
      deliverCapacity: deliveryCapRate,
      sustainabilityRating: sustainRate,
      innovationRating: innovationRate,
      pricingRating: costRate,
      // conditionsRating: conditionsRate,
      recommendation: values.recommendation || 0,
    };

    mutate(
      {
        data: optionalDataToSend,
        reviewId: parseInt(searchParams.get("reviewId") || ""),
      },
      {
        onSuccess: () => {
          DisplaySuccessNotification(t("reviewAddSuccess"));
          navigate(
            `/supplier-reviews/categories/${
              suntCategoryId ? suntCategoryId : 1
            }/organisations/${organisationId ? organisationId : ""}`
          );
        },
        onError: () => {
          DisplayErrorNotification(t("reviewAddError"));
        },
      }
    );
  };

  const popoverContent = (
    <div className={cssStyle.popoverBox} id="popvoerSuplierOverview">
      <p>Att tänka på när du skriver omdömen:</p>
      <ul>
        <li>Var konstruktiv</li>
        <li>Beskriv utförligt</li>
        <li>Var detaljerad</li>
        <li>Utelämna personuppgifter</li>
        <li>Var artig</li>
        <li>Ha rätt uppgifter</li> <li>Korrekturläs</li>
        <li>Justera vid behov</li>
      </ul>
    </div>
  );

  const popoverChange = () => {
    // try to access the popover parent node through the grand child
    // For change the border radius of the parent node.
    const element = document.querySelectorAll("#popvoerSuplierOverview");
    if (element.length > 0) {
      const popoverContainer = element[0]?.parentElement?.parentElement;
      if (popoverContainer) popoverContainer.style.borderRadius = "10px";
    } else {
      setTimeout(() => {
        const element = document.querySelectorAll("#popvoerSuplierOverview");
        const popoverContainer = element[0]?.parentElement?.parentElement;
        if (popoverContainer) popoverContainer.style.borderRadius = "10px";
      }, 500);
    }
  };

  const { TextArea } = Input;

  return (
    <Formik
      initialValues={createReviewInitValues}
      validationSchema={createReviewValidationSchema}
      onSubmit={handleSubmit}
      onReset={() => {
        setQualityRate(0);
        setCustomerServiceRate(0);
        setDeliveryCapRate(0);
        setSustainRate(0);
        setInnovationRate(0);
        setCostRate(0);
        // setConditionsRate(0);
      }}
    >
      {({ handleChange, values, errors, touched }) => (
        <>
          <Form>
            <div className={cssStyle.container}>
              <div>
                <h2 className={cssStyle.heading}>Ge objektiv bedömning</h2>
                <div className={cssStyle.assessmentContainer}>
                  <div className={cssStyle.assessmentBox}>
                    <h2>Kvalitet</h2>
                    <div className={cssStyle.row}>
                      <span
                        onClick={() => {
                          qualityRate == 1
                            ? setQualityRate(0)
                            : setQualityRate(1);
                        }}
                        style={{
                          backgroundColor:
                            qualityRate == 1 ? "#4FBFC0" : "white",
                          color: qualityRate == 1 ? "white" : "black",
                        }}
                        className={cssStyle.box}
                      >
                        Uppfyller ej avtalade
                        <br /> krav
                      </span>
                      <span
                        onClick={() => {
                          qualityRate == 3
                            ? setQualityRate(0)
                            : setQualityRate(3);
                        }}
                        style={{
                          backgroundColor:
                            qualityRate == 3 ? "#4FBFC0" : "white",
                          color: qualityRate == 3 ? "white" : "black",
                        }}
                        className={cssStyle.box}
                      >
                        Uppfyller troligtvis <br /> avtalade krav
                      </span>
                      <span
                        onClick={() => {
                          qualityRate == 5
                            ? setQualityRate(0)
                            : setQualityRate(5);
                        }}
                        style={{
                          backgroundColor:
                            qualityRate == 5 ? "#4FBFC0" : "white",
                          color: qualityRate == 5 ? "white" : "black",
                        }}
                        className={cssStyle.box}
                      >
                        Uppfyller avtalade <br /> krav
                      </span>
                    </div>
                  </div>
                  <div className={cssStyle.assessmentBox}>
                    <h2>Kundservice</h2>
                    <div className={cssStyle.row}>
                      <span
                        onClick={() => {
                          customerServiceRate == 1
                            ? setCustomerServiceRate(0)
                            : setCustomerServiceRate(1);
                        }}
                        style={{
                          backgroundColor:
                            customerServiceRate == 1 ? "#4FBFC0" : "white",
                          color: customerServiceRate == 1 ? "white" : "black",
                        }}
                        className={cssStyle.box}
                      >
                        Uppfyller ej avtalade <br /> krav
                      </span>
                      <span
                        onClick={() => {
                          customerServiceRate == 3
                            ? setCustomerServiceRate(0)
                            : setCustomerServiceRate(3);
                        }}
                        style={{
                          backgroundColor:
                            customerServiceRate == 3 ? "#4FBFC0" : "white",
                          color: customerServiceRate == 3 ? "white" : "black",
                        }}
                        className={cssStyle.box}
                      >
                        Uppfyller troligtvis <br /> avtalade krav
                      </span>
                      <span
                        onClick={() => {
                          customerServiceRate == 5
                            ? setCustomerServiceRate(0)
                            : setCustomerServiceRate(5);
                        }}
                        style={{
                          backgroundColor:
                            customerServiceRate == 5 ? "#4FBFC0" : "white",
                          color: customerServiceRate == 5 ? "white" : "black",
                        }}
                        className={cssStyle.box}
                      >
                        Uppfyller avtalade <br /> krav
                      </span>
                    </div>
                  </div>
                  <div className={cssStyle.assessmentBox}>
                    <h2>Leveransförmåga</h2>
                    <div className={cssStyle.row}>
                      <span
                        onClick={() => {
                          deliveryCapRate == 1
                            ? setDeliveryCapRate(0)
                            : setDeliveryCapRate(1);
                        }}
                        style={{
                          backgroundColor:
                            deliveryCapRate == 1 ? "#4FBFC0" : "white",
                          color: deliveryCapRate == 1 ? "white" : "black",
                        }}
                        className={cssStyle.box}
                      >
                        Uppfyller ej avtalade <br /> krav
                      </span>
                      <span
                        onClick={() => {
                          deliveryCapRate == 3
                            ? setDeliveryCapRate(0)
                            : setDeliveryCapRate(3);
                        }}
                        style={{
                          backgroundColor:
                            deliveryCapRate == 3 ? "#4FBFC0" : "white",
                          color: deliveryCapRate == 3 ? "white" : "black",
                        }}
                        className={cssStyle.box}
                      >
                        Uppfyller troligtvis <br /> avtalade krav
                      </span>
                      <span
                        onClick={() => {
                          deliveryCapRate == 5
                            ? setDeliveryCapRate(0)
                            : setDeliveryCapRate(5);
                        }}
                        style={{
                          backgroundColor:
                            deliveryCapRate == 5 ? "#4FBFC0" : "white",
                          color: deliveryCapRate == 5 ? "white" : "black",
                        }}
                        className={cssStyle.box}
                      >
                        Uppfyller avtalade <br /> krav
                      </span>
                    </div>
                  </div>
                  <div className={cssStyle.assessmentBox}>
                    <h2>Hållbarhet</h2>
                    <div className={cssStyle.row}>
                      <span
                        onClick={() => {
                          sustainRate == 1
                            ? setSustainRate(0)
                            : setSustainRate(1);
                        }}
                        style={{
                          backgroundColor:
                            sustainRate == 1 ? "#4FBFC0" : "white",
                          color: sustainRate == 1 ? "white" : "black",
                        }}
                        className={cssStyle.box}
                      >
                        Uppfyller ej avtalade <br /> krav
                      </span>
                      <span
                        onClick={() => {
                          sustainRate == 3
                            ? setSustainRate(0)
                            : setSustainRate(3);
                        }}
                        style={{
                          backgroundColor:
                            sustainRate == 3 ? "#4FBFC0" : "white",
                          color: sustainRate == 3 ? "white" : "black",
                        }}
                        className={cssStyle.box}
                      >
                        Uppfyller troligtvis <br /> avtalade krav
                      </span>
                      <span
                        onClick={() => {
                          sustainRate == 5
                            ? setSustainRate(0)
                            : setSustainRate(5);
                        }}
                        style={{
                          backgroundColor:
                            sustainRate == 5 ? "#4FBFC0" : "white",
                          color: sustainRate == 5 ? "white" : "black",
                        }}
                        className={cssStyle.box}
                      >
                        Uppfyller avtalade <br /> krav
                      </span>
                    </div>
                  </div>
                  <div className={cssStyle.assessmentBox}>
                    <h2>Innovation</h2>
                    <div className={cssStyle.row}>
                      <span
                        onClick={() => {
                          innovationRate == 1
                            ? setInnovationRate(0)
                            : setInnovationRate(1);
                        }}
                        style={{
                          backgroundColor:
                            innovationRate == 1 ? "#4FBFC0" : "white",
                          color: innovationRate == 1 ? "white" : "black",
                        }}
                        className={cssStyle.box}
                      >
                        Uppfyller ej avtalade <br /> krav
                      </span>
                      <span
                        onClick={() => {
                          innovationRate == 3
                            ? setInnovationRate(0)
                            : setInnovationRate(3);
                        }}
                        style={{
                          backgroundColor:
                            innovationRate == 3 ? "#4FBFC0" : "white",
                          color: innovationRate == 3 ? "white" : "black",
                        }}
                        className={cssStyle.box}
                      >
                        Uppfyller troligtvis <br /> avtalade krav
                      </span>
                      <span
                        onClick={() => {
                          innovationRate == 5
                            ? setInnovationRate(0)
                            : setInnovationRate(5);
                        }}
                        style={{
                          backgroundColor:
                            innovationRate == 5 ? "#4FBFC0" : "white",
                          color: innovationRate == 5 ? "white" : "black",
                        }}
                        className={cssStyle.box}
                      >
                        Uppfyller avtalade <br /> krav
                      </span>
                    </div>
                  </div>
                  <div className={cssStyle.assessmentBox}>
                    <h2>Kostnad</h2>
                    <div className={cssStyle.row}>
                      <span
                        onClick={() => {
                          costRate == 1 ? setCostRate(0) : setCostRate(1);
                        }}
                        style={{
                          backgroundColor: costRate == 1 ? "#4FBFC0" : "white",
                          color: costRate == 1 ? "white" : "black",
                        }}
                        className={cssStyle.box}
                      >
                        Uppfyller ej avtalade <br /> krav
                      </span>
                      <span
                        onClick={() => {
                          costRate == 3 ? setCostRate(0) : setCostRate(3);
                        }}
                        style={{
                          backgroundColor: costRate == 3 ? "#4FBFC0" : "white",
                          color: costRate == 3 ? "white" : "black",
                        }}
                        className={cssStyle.box}
                      >
                        Uppfyller troligtvis <br /> avtalade krav
                      </span>
                      <span
                        onClick={() => {
                          costRate == 5 ? setCostRate(0) : setCostRate(5);
                        }}
                        style={{
                          backgroundColor: costRate == 5 ? "#4FBFC0" : "white",
                          color: costRate == 5 ? "white" : "black",
                        }}
                        className={cssStyle.box}
                      >
                        Uppfyller avtalade <br /> krav
                      </span>
                    </div>
                  </div>
                </div>

                <div>
                  <p style={styles.header}>{t("recommendationHeader")}</p>
                  {organizationName && (
                    <p style={styles.weakText}>
                      {t("recommendationDescription", { organizationName })}*
                    </p>
                  )}

                  <Radio.Group
                    value={values.recommendation}
                    onChange={handleChange}
                    className={cssStyle.radioContainer}
                    name="recommendation"
                    id="recommendation"
                  >
                    <Radio.Button value={1}>1</Radio.Button>
                    <Radio.Button value={2}>2</Radio.Button>
                    <Radio.Button value={3}>3</Radio.Button>
                    <Radio.Button value={4}>4</Radio.Button>
                    <Radio.Button value={5}>5</Radio.Button>
                    <Radio.Button value={6}>6</Radio.Button>
                    <Radio.Button value={7}>7</Radio.Button>
                    <Radio.Button value={8}>8</Radio.Button>
                    <Radio.Button value={9}>9</Radio.Button>
                    <Radio.Button value={10}>10</Radio.Button>
                  </Radio.Group>
                  <div style={styles.radioDescription}>
                    <span style={styles.radioDescriptionText}>
                      {t("notLikely")}
                    </span>
                    <span style={styles.radioDescriptionText}>
                      {t("mostLikely")}
                    </span>
                  </div>
                </div>
                {/* <div className={cssStyle.btn}>Skicka</div> */}
                <button
                  // className={cssStyle.btn}
                  // style={styles.submitBtn}
                  style={{ ...styles.submitBtn }}
                  type="submit"
                  onClick={() => {
                    handleSubmit(values);
                  }}
                >
                  Skicka
                </button>
              </div>
              <div className={cssStyle.explanationBox}>
                <h4 className={cssStyle.heading}>Förklaring</h4>
                <p>
                  <strong>Uppfyller ej avtalade krav =</strong> leverantören
                  uppfyller inte kraven. Detta har mätts och kan bevisas.
                </p>
                <p>
                  <strong>Uppfyller troligtvis avtalade krav =</strong>{" "}
                  Leverantören uppfyller troligtvis kraven, men detta har inte
                  mätts och kan inte bevisas
                </p>
                <p>
                  <strong>Uppfyller avtalade krav =</strong> Leverantören
                  uppfyller kraven. Detta har mätts och kan bevisas.
                </p>
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

const Iconstyle = StyleSheet.create({
  infoIcon: {
    fontSize: "20px",
    color: "#CDD4E2",
    padding: "1px 0px 0px 8px",
    cursor: "pointer",
  },
});
