import * as Yup from "yup";

export const initialValues = {
  level: null,
  categoryId: null,
  subject: "",
  link: "",
  message: "",
  suntPolicy: false,
};
export interface valuesInterface {
  level: null | number;
  categoryId: number | null;
  subject: string;
  link: string;
  message: string;
  suntPolicy: boolean;
}
export interface errorAndTouchInterface {
  level: string | undefined;
  categoryId: string | undefined;
  subject: string | undefined;
  link: string | undefined;
  message: string | undefined;
  suntPolicy: string | undefined;
}

export const validationSchema = Yup.object().shape({
  level: Yup.number().required(),
  subject: Yup.string().required(),
  suntPolicy: Yup.bool().oneOf([true]),
});
