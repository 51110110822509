import { Avatar } from "antd";
import React from "react";
import {
  BoldedText,
  DimmedText,
  DisplayRate,
  StarRating,
} from "../../../components";
import { StyleSheet } from "../../../models/StyleSheet";
import { Facebook, Google, Linkedin } from "../../../assets";
import Background from "../../../assets/icons/BackgroundProfile.svg";
import { colors } from "../../../constants/colors";
import { useTranslation } from "../../../hooks/useTranslation";
import {
  SupplierCompany,
  useGetSupplierCompanyReviews,
} from "../../../hooks/api/Suppliers";

export const ProfileInfo = ({ data }: { data?: SupplierCompany }) => {
  const { t } = useTranslation("supplierProfile");

  const { data: companyReviews } = useGetSupplierCompanyReviews();

  return (
    <div style={styles.container}>
      <div style={styles.section}>
        <img src={Background} style={styles.background} />
        <Avatar
          src={data?.organisation.logoURL}
          style={styles.profile}
          size={100}
        >
          {data?.organisation.name[0]}
        </Avatar>
      </div>
      <div style={styles.section}>
        <DimmedText style={styles.text}>{data?.organisation.name}</DimmedText>
        {data?.organisation.phoneNumber ? (
          <DimmedText style={styles.text}>
            {data?.organisation.phoneNumber}
          </DimmedText>
        ) : null}

        {data?.organisation.websiteURL ? (
          <DimmedText style={styles.text}>
            {data?.organisation.websiteURL}
          </DimmedText>
        ) : null}
        <div style={styles.iconContainer}>
          <a href="#">
            <Facebook style={styles.icon} />
          </a>
          <a href="#">
            <Linkedin style={styles.icon} />
          </a>
          <a href="#">
            <Google style={styles.icon} />
          </a>
        </div>
      </div>

      <div style={styles.section}>
        <BoldedText style={styles.ratingHeaderText}>
          {t("rating", {
            rate: companyReviews?.ratings._avg.overallRating
              ? Math.round(companyReviews?.ratings._avg.overallRating * 2) / 2
              : "0.0",
          })}
        </BoldedText>
        <div style={styles.ratingHeader}>
          <StarRating
            color={colors.primary}
            size={24}
            value={
              companyReviews?.ratings._avg.overallRating
                ? Math.round(companyReviews?.ratings._avg.overallRating * 2) / 2
                : undefined
            }
            disabled
          />
          <DimmedText style={styles.reviewText}>{`${
            companyReviews?.count || "0"
          } ${t("reviews")}`}</DimmedText>
        </div>
      </div>
      <div style={{ ...styles.section, ...styles.detailedRatingContainer }}>
        <div>
          <DisplayRate
            styleLabel={styles.detailedLabel}
            label={t("quality")}
            rate={companyReviews?.ratings._avg.qualityRating}
          />
          <DisplayRate
            styleLabel={styles.detailedLabel}
            label={t("sustainability")}
            rate={companyReviews?.ratings._avg.sustainabilityRating}
          />
          <DisplayRate
            styleLabel={styles.detailedLabel}
            label={t("deliveryCapacity")}
            rate={companyReviews?.ratings._avg.deliverCapacity}
          />
        </div>
        <div>
          <DisplayRate
            styleLabel={styles.detailedLabel}
            label={t("customerService")}
            rate={companyReviews?.ratings._avg.customerServiceRating}
          />
          <DisplayRate
            styleLabel={styles.detailedLabel}
            label={t("cost")}
            rate={companyReviews?.ratings._avg.pricingRating}
          />
          <DisplayRate
            styleLabel={styles.detailedLabel}
            label={t("innovation")}
            rate={companyReviews?.ratings._avg.innovationRating}
          />
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  ratingHeaderText: {
    marginTop: "104px",
    fontSize: "20px",
    lineHeight: "22px",
  },
  ratingHeader: {
    display: "flex",
    alignItems: "flex-end",
    height: "fit-content",
  },
  container: {
    width: "100%",
  },
  section: {
    width: "100%",
  },
  background: {
    width: "100%",
    borderRadius: "4px",
  },
  profile: {
    marginLeft: "5%",
    marginTop: "-50px",
    marginBottom: "12px",
    border: "2px solid #FFFFFF",
    backgroundColor: "#FFFFFF",
    color: "#4fbfc0",
    fontSize: "50px",
    lineHeight: "52px",
    fontFamily: "d-din",
  },
  text: {
    color: "#000000",
  },
  icon: {
    width: "16px",
    height: "16px",
    borderRadius: "4px",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "80px",
  },
  reviewText: {
    marginLeft: "20px",
    fontSize: "12px",
    lineHeight: "13px",
    fontStyle: "italic",
  },
  detailedRatingContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  detailedLabel: {
    fontWeight: "400",
    color: "#000000",
  },
});
