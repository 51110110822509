import { useNavigate } from "react-router-dom";

export function useNavigateAndScroll() {
  const navigate = useNavigate();

  const navigateTo = (to: string) => {
    navigate(to);
    window.scrollTo(0, 0);
  };

  return navigateTo;
}
