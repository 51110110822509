import React from "react";
import { Input } from "../components/Input";
import { Formik } from "formik";
import {
  signupBuyerSchema,
  signupInitValuesBuyer,
  signupInitValuesBuyerInterface,
} from "./singup.utils";
import { Bottom } from "./components/Bottom";
import { useSignUpBuyer } from "../../../hooks/api/SignUp";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../services/notificationService";
import { useNavigate } from "react-router";
import { useTranslation } from "../../../hooks/useTranslation";
import { DefaultButton } from "../../../components";
import { StyleSheet } from "../../../models/StyleSheet";
import { PasswordInput } from "../components/PasswordInput";

export const SignupFormBuyer = ({
  selected,
  setSelected,
}: {
  selected: null | "buyer" | "supplier";
  setSelected: React.Dispatch<
    React.SetStateAction<"buyer" | "supplier" | null>
  >;
}) => {
  const { mutate, isLoading } = useSignUpBuyer();
  const { t: tNotification } = useTranslation("notificationText");
  const { t } = useTranslation("signupPage");
  const navigate = useNavigate();

  const onSubmit = ({
    firstName,
    lastName,
    email,
    password,
    buyerOrganisation,
    region,
    city,
    position,
    phoneNumber,
  }: signupInitValuesBuyerInterface) => {
    const dataToSend = {
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      buyerOrganisation,
      county: region,
      community: city,
      position,
    };
    mutate(dataToSend, {
      onSuccess: () => {
        DisplaySuccessNotification(tNotification("successSignup"));
        navigate("/verified-page");
      },
      onError: (error: unknown) => {
        const parsedError = error as { message: string };
        if (parsedError.message.includes("409")) {
          DisplayErrorNotification(tNotification("errorConflict"));
        } else {
          DisplayErrorNotification(tNotification("errorSignup"));
        }
      },
    });
  };

  return (
    <Formik
      initialValues={signupInitValuesBuyer}
      onSubmit={(value) => {
        onSubmit(value);
      }}
      validationSchema={signupBuyerSchema}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => (
        <>
          <form onSubmit={handleSubmit}>
            <div style={styles.formContainer}>
              <div style={styles.formContent}>
                <div style={styles.inline}>
                  <Input
                    name="firstName"
                    label={`${t("firstName")}*`}
                    // placeholder={t("placeholderName")}
                    type="text"
                    value={values.firstName}
                    handleChange={handleChange}
                    error={errors.firstName}
                    touched={touched.firstName}
                  />
                  <Input
                    name="email"
                    label={`${t("email")}*`}
                    // placeholder={t("placeholderEmail")}
                    type="text"
                    value={values.email}
                    handleChange={handleChange}
                    error={errors.email}
                    touched={touched.email}
                  />
                  <PasswordInput
                    name="password"
                    label={`${t("password")}*`}
                    // placeholder={t("password")}
                    value={values.password}
                    handleChange={handleChange}
                    error={errors.password}
                    touched={touched.password}
                  />
                  <Input
                    name="phoneNumber"
                    label={t("phoneNumber")}
                    // placeholder={t("placeholderPhoneNumber")}
                    type="text"
                    value={values.phoneNumber}
                    handleChange={handleChange}
                  />
                  <Input
                    name="region"
                    label={t("region")}
                    // placeholder={t("placeholderRegion")}
                    type="text"
                    value={values.region}
                    handleChange={handleChange}
                  />
                </div>
              </div>
              <div style={styles.formContent}>
                <div style={styles.inline}>
                  <Input
                    name="lastName"
                    label={`${t("lastName")}*`}
                    // placeholder={t("placeholderSurname")}
                    type="text"
                    value={values.lastName}
                    handleChange={handleChange}
                    error={errors.lastName}
                    touched={touched.lastName}
                  />
                  <Input
                    name="position"
                    label={`${t("position")}*`}
                    // placeholder={t("placeholderPosition")}
                    type="text"
                    value={values.position}
                    handleChange={handleChange}
                    error={errors.position}
                    touched={touched.position}
                  />
                  <PasswordInput
                    name="confirmPassword"
                    label={`${t("confirmPassword")}*`}
                    // placeholder={t("password")}
                    value={values.confirmPassword}
                    handleChange={handleChange}
                    error={errors.confirmPassword}
                    touched={touched.confirmPassword}
                  />
                  <Input
                    name="buyerOrganisation"
                    label={t("buyerOrganisation")}
                    // placeholder={t("placeholderOrganisation")}
                    type="text"
                    value={values.buyerOrganisation}
                    handleChange={handleChange}
                  />
                  <Input
                    name="city"
                    label={t("city")}
                    // placeholder={t("placeholderCity")}
                    type="text"
                    value={values.city}
                    handleChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div>
              <div style={styles.termsAndConditionsContainer}>
                <div style={styles.termsAndConditions}>
                  <Bottom
                    valueTerms={values.termsAndConditions}
                    onChange={handleChange}
                    errorTerms={errors.termsAndConditions}
                    touchedTerms={touched.termsAndConditions}
                  />
                </div>
              </div>
              <div style={styles.btnContainer}>
                <DefaultButton
                  text={t("register")}
                  type="submit"
                  style={styles.submitBtn}
                  variant="primary"
                  loading={isLoading}
                />
              </div>
            </div>
          </form>
        </>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  btnContainer: {
    textAlign: "center",
  },
  submitBtn: {
    maxWidth: "656px",
    width: "100%",
    height: "63px",
    background: "#07285D",
    border: "0",
    borderRadius: "4px",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "19px",
    letterSpacing: "0.07em",
    color: "#FFFFFF",
    margin: "15px 0 30px 0",
    justifyContent: "center",
  },
  forgotPassword: {
    fontFamily: "d-din",
    fontStyle: "italic",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    color: "#4FBFC0",
    textDecoration: "underline",
    marginTop: "5px",
  },
  formContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: "10px",
  },
  formContent: {
    width: "328px",
    marginRight: "7.5px",
    marginLeft: "7.5px",
  },
  checkbox: {
    marginLeft: "8px",
    width: "315px",
  },
  checkBoxText: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "13px",
    color: "#808795",
  },
  inline: {
    display: "inline",
  },
  back: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  backButtton: {
    width: "656px",
    justifyContent: "right",
  },
  arrow: {
    marginBottom: "2.5px",
    display: "inline",
    marginLeft: "585px",
  },
  link: {
    fontFamily: "d-din",
    fontStyle: "italic",
    fontWeight: "400",
    fontSize: "16x",
    lineHeight: "17px",
    color: "#4FBFC0",
    textDecoration: "underline",
    display: "inline",
  },
  termsAndConditionsContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  termsAndConditions: {
    display: "flex",
    justifyContent: "left",
    width: "656px",
  },
});
