import React from "react";
import { BoldedText, DimmedLink } from "../../../components";
import { StyleSheet } from "../../../models/StyleSheet";
import { Stage } from "./components/Stage";
import { ChartPiece, List, Profile, Upload } from "../../../assets";
import { useTranslation } from "../../../hooks/useTranslation";
import { useNavigate } from "react-router";

export const ProfileStage = () => {
  const { t } = useTranslation("supplierProfile");

  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <div>
          <BoldedText style={styles.headerText}>
            {t("getStartedProfile")}
          </BoldedText>
          <BoldedText style={styles.headerTextDescription}>
            {t("getStartedProfileDesc")}
          </BoldedText>
        </div>
        <div>
          <DimmedLink>{t("seeInstructions")}</DimmedLink>
        </div>
      </div>
      <div style={styles.containerStage}>
        <Stage label={t("updateProfile")} stage={t("stage", { number: "1" })}>
          <Profile />
        </Stage>
        <Stage
          label={t("influencingPublicAffairs")}
          stage={t("stage", { number: "2" })}
          onClick={() => {
            navigate("/insights");
          }}
        >
          <List />
        </Stage>
        <Stage
          label={t("getMoreReviews")}
          stage={t("stage", { number: "3" })}
          onClick={() => {
            navigate("/invite-to-review");
          }}
        >
          <Upload />
        </Stage>
        <Stage
          label={t("inviteToDialogue")}
          stage={t("stage", { number: "4" })}
          onClick={() => {
            navigate("/invite-to-dialogue");
          }}
        >
          <ChartPiece />
        </Stage>
        <Stage label={t("analyzeResults")} stage={t("stage", { number: "5" })}>
          <ChartPiece />
        </Stage>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    padding: "32px",
    borderRadius: "4px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerText: {
    fontSize: "24px",
    lineHeight: "26px",
  },
  headerTextDescription: {
    fontSize: "14px",
    lineHeight: "15px",
  },
  containerStage: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "50px",
  },
});
