import { LeftOutlined } from "@ant-design/icons";
import { Col, Row, Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { OrganisationsData } from "../../../hooks/api/Organisations";
import style from "./styles/style.module.css";
import { useTranslation } from "../../../hooks/useTranslation";

type catType = {
  id: number;
  catTitle: string;
  icon: string;
  fullCheck: boolean;
  partialCheck: boolean;
  totalCatCheck: number;
  subCategory: { subCatId: number; title: string; isCheck: boolean }[];
}[];

const Categories = ({
  organisation,
}: {
  organisation: OrganisationsData | undefined;
}) => {
  const [categories, setCategories] = useState<string[]>();
  const { t } = useTranslation("supplierProfilePage");

  useEffect(() => {
    if (organisation) {
      const catData = organisation?.secondLevelCategories
        ?.split(",")
        .map((item) => item.trim());
      setCategories(catData);
      console.log(catData);
      const localStoredCatData = localStorage.getItem("categoryData");
      if (localStoredCatData) {
        const catStoredData = JSON.parse(localStoredCatData) as catType;
        console.log(catStoredData);

        // reset the local stored category data
        catStoredData.forEach((item) => {
          for (let i = 0; i < item.subCategory.length; i++) {
            item.subCategory[i].isCheck = false;
          }
          item.partialCheck = false;
          item.fullCheck = false;
        });
        // update the local store category data according to user
        catStoredData.forEach((item) => {
          let checkCounter = 0;
          for (let i = 0; i < item.subCategory.length; i++) {
            if (catData?.includes(item.subCategory[i].subCatId.toString())) {
              item.subCategory[i].isCheck = true;
              checkCounter++;
            }
          }
          item.totalCatCheck = checkCounter;
          if (checkCounter === item.subCategory.length && checkCounter !== 0) {
            item.fullCheck = true;
            item.partialCheck = false;
          }
          if (checkCounter > 0 && checkCounter < item.subCategory.length) {
            item.partialCheck = true;
            item.fullCheck = false;
          }
          if (checkCounter === 0 || checkCounter === item.subCategory.length) {
            item.partialCheck = false;
          }
        });
        localStorage.setItem("categoryData", JSON.stringify(catStoredData));
        // remove the selected sending category data
        localStorage.removeItem("sendingCatsData");
      }
    }
  }, [organisation]);

  return (
    <>
      <Collapse
        bordered={false}
        className={style.collapsePan}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <LeftOutlined
            rotate={isActive ? 270 : 180}
            className={style.loadMoreArrow}
          />
        )}
      >
        <Collapse.Panel
          key={1}
          header={
            <div className={style.mediaTopContainer}>
              <p className={style.categoryHeading}>{t("category")}</p>
            </div>
          }
        >
          <div className={style.capsuleBox}>
            {categories?.map((cat, index) => {
              if (index % 2 !== 0) {
                return (
                  <div key={cat} className={style.capsuleBtn}>
                    <span>{cat}</span>
                    <div className={style.closeIcon}></div>
                  </div>
                );
              }
            })}
            {/* {organisation?.organisationsCategories &&
              organisation?.organisationsCategories?.length > 0 && (
                <div className={style.capsuleBtn}>
                  <span>
                    {
                      organisation?.organisationsCategories?.[0].category
                        ?.suntCategory?.name
                    }
                  </span>
                  <div className={style.closeIcon}></div>
                </div>
              )} */}

            {/* {organisation?.organisationsCategories &&
              organisation?.organisationsCategories.length > 0 && (
                <div className={style.capsuleBtn}>
                  <span>
                    {
                      organisation?.organisationsCategories?.[0].category
                        ?.suntCategoryDetailed
                    }
                  </span>
                  <div className={style.closeIcon}></div>
                </div>
              )} */}
          </div>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default Categories;
