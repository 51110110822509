import React from "react";

import { SpinnerLoader } from "../../../components/SpinnerLoader";
import { Conditional, CompanyItemExtended } from "../../../components";
import { OrganisationsData } from "../../../hooks/api/Organisations";
import { TableHeader } from "./TableHeader";
import { StyleSheet } from "../../../models/StyleSheet";
import { useNavigate } from "react-router";

interface SuppliersTableProps {
  suppliers: Array<OrganisationsData>;
  isLoading: boolean;
  categoryId: number;
  sortType: {
    sortBy:
      | "name"
      | "employeesNumber"
      | "city"
      | "numberOfReviews"
      | "averageRating"
      | null;
    orderBy: "desc" | "asc";
  };
  setSortType: React.Dispatch<
    React.SetStateAction<{
      sortBy:
        | "name"
        | "employeesNumber"
        | "city"
        | "numberOfReviews"
        | "averageRating"
        | null;
      orderBy: "desc" | "asc";
    }>
  >;
}
// SuppliersTable parameters
export const SuppliersTable = ({
  suppliers,
  isLoading,
  categoryId,
  sortType,
  setSortType,
}: SuppliersTableProps) => {
  const navigate = useNavigate();
  // const dummyData = [
  //   {
  //     id: 32,
  //     name: "adfds",
  //     employeesNumber: 23,
  //     city: "fdasf",
  //     address: "dfasdf",
  //     reviews: [
  //       {
  //         overallRating: 3,
  //         pricingRating: 4,
  //         qualityRating: 3,
  //         innovationRating: 4,
  //         sustainabilityRating: 4,
  //         customerServiceRating: 3,
  //         deliverCapacity: 3,
  //       },
  //     ],
  //   },
  // ];
  return (
    <>
      <TableHeader sortType={sortType} setSortType={setSortType} />
      <div style={styles.shadow}>
        {/* <Conditional
          condition={isLoading}
          trueRender={<SpinnerLoader />}
          falseRender={
            <>
              {dummyData
                ? dummyData?.map((company) => (
                    <CompanyItemExtended
                      key={company.id}
                      company={company}
                      onClick={() => {
                        navigate(
                          `/supplier-reviews/categories/${categoryId}/organisations/${company.id}`
                        );
                      }}
                    />
                  ))
                : [null]}
            </>
          }
        /> */}
        <Conditional
          condition={isLoading}
          trueRender={<SpinnerLoader />}
          falseRender={
            <>
              {suppliers
                ? suppliers?.map((company: OrganisationsData) => (
                    <CompanyItemExtended
                      key={company.id}
                      company={company}
                      onClick={() => {
                        navigate(
                          `/supplier-reviews/categories/${categoryId}/organisations/${company.id}`
                        );
                      }}
                    />
                  ))
                : [null]}
            </>
          }
        />
      </div>
    </>
  );
};
const styles = StyleSheet.create({
  shadow: {
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
  },
});
