import React from "react";
import { StyleSheet } from "../../models/StyleSheet";

export const BoldedText = ({
  children,
  style,
}: {
  children: string | JSX.Element;
  style?: React.CSSProperties;
}) => {
  return <p style={{ ...styles.text, ...style }}>{children}</p>;
};

const styles = StyleSheet.create({
  text: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "35px",
    letterSpacing: "0.07em",
    color: "#000000",
    marginBottom: "10px",
  },
});
