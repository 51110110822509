import React from "react";
import "antd/dist/antd.css";
import "../../../css/mainContainer.css";
import { Layout, Button, Row, Menu, Typography } from "antd";
import { colors } from "../../../constants/colors";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "../../../hooks/useTranslation";
import { StyleSheet } from "../../../models/StyleSheet";
import { H3, Searchbar } from "../../../components";

const { Header } = Layout;

export function TopBar() {
  const { t } = useTranslation("topBar");
  const navigate = useNavigate();

  return (
    <Header className="header" style={styles.header}>
      <div className="top-bar">
        <H3 text={"Category Insights"} style={styles.topleftText} />
      </div>
      <div style={styles.searchBar}>
        <Searchbar placeholder={"Search"} />
      </div>
      <Row style={styles.topRightContainer}>
        <Link type="text" style={styles.topRightLink} to={""}>
          <H3 text={"Export"} style={styles.link} />
        </Link>
        <Link type="text" style={styles.topRightLink} to={""}>
          <H3 text={"Share"} style={styles.link} />
        </Link>
      </Row>
    </Header>
  );
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: "white",
  },
  link: {
    marginLeft: "32px",
    marginRight: "32px",
    lineHeight: "13.03px",
    color: "#000",
    textDecoration: "underline",
    marginBottom: "2px",
    fontSize: "20px",
  },
  topRightContainer: {
    width: "100%",
    justifyContent: "end",
    alignItems: "center",
  },
  topleftText: {
    marginLeft: "32px",
    marginRight: "32px",
    fontWeight: "700",
    lineHeight: "13.03px",
    color: "#000",
    marginBottom: "2px",
  },
  topRightLink: {
    marginLeft: 10,
    marginRight: 10,
  },
  icon: {
    width: "100%",
  },
  searchBar: {
    height: 32,
    marginBottom: 32,
  },
});
