import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../../hooks/useTranslation";
import { StarRating } from "../../../../components";
import { colors } from "../../../../constants/colors";
import { StyleSheet } from "../../../../models/StyleSheet";
import { Avatar, Button, Col, Row, Tooltip } from "antd";
import { CommentDots } from "../../../../assets";
import {
  reviewsInterface,
  useLikeOrUnlikeReviews,
} from "../../../../hooks/api/Reviews";
import {
  EnvironmentOutlined,
  LikeFilled,
  LikeOutlined,
  EditFilled,
} from "@ant-design/icons";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "../../../../index.css";
import { storage } from "../../../../services/storage";
import { QueryInterface } from "..";
import cssStyle from "./ReviewItem.module.css";
import { useNavigate } from "react-router";
dayjs.extend(relativeTime);

export const ReviewItem = ({ review }: { review: reviewsInterface }) => {
  const { t } = useTranslation("reviewPage");
  const navigate = useNavigate();
  const [like, setLike] = useState<boolean>(false);

  const { mutate, data: count } = useLikeOrUnlikeReviews();
  const buyer = storage.getUser();

  const dataToSendLike = {
    reviewId: review.id,
    buyerId: buyer?.id ? buyer?.id : 0,
    action: "like",
  };

  const dataToSendUnLike = {
    reviewId: review.id,
    buyerId: buyer?.id ? buyer?.id : 0,
    action: "unlike",
  };

  const handleLike = () => {
    mutate(dataToSendLike);
  };
  const handleUnLike = () => {
    mutate(dataToSendUnLike);
  };
  const already = review?.ReviewLikes?.some(
    (like) => like.buyerId === buyer?.id
  );

  const countLikes = count ? count - 1 : undefined;
  const reviewLikesCount =
    review?.ReviewLikes?.length && review?.ReviewLikes?.length !== 0
      ? review?.ReviewLikes?.length - 1
      : undefined;

  return (
    <div style={styles.container}>
      <div className="reviewRow" style={styles.review}>
        <Row style={styles.reviewHeader} align="middle">
          <Col span={8}>
            <Avatar size={40}>
              {review?.isAnonymous ? "A" : review.buyer?.user.firstName[0]}
            </Avatar>
            <span style={styles.name}>
              {review.isAnonymous === true
                ? t("anonymousUser")
                : `${review.buyer?.user.firstName || ""} ${
                    review.buyer?.user.lastName || ""
                  }`}
            </span>
          </Col>
          <Col style={styles.verticalCenter} span={4}>
            <StarRating
              style={styles.rate}
              color={colors.primary}
              disabled={true}
              size={15}
              value={review.overallRating}
            />
            <span style={styles.rateNumber}>{review.overallRating}</span>
          </Col>
          <Col style={styles.verticalCenter} span={4}>
            <span style={styles.createdAt}>
              ~ {dayjs(review.updatedAt).fromNow()}
            </span>
          </Col>
          <Col style={styles.center} span={5}>
            <EnvironmentOutlined
              style={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.85)" }}
            />
            <span style={styles.location}>
              {review.isAnonymous === true
                ? t("anonymousCommunity")
                : review.buyer?.community == ""
                ? t("informationMissing")
                : review.buyer?.community}
            </span>
          </Col>
          <Col span={3} style={{ textAlign: "right" }}>
            {buyer?.id === review.buyer?.user.id &&
              dayjs().diff(dayjs(review.createdAt), "day") < 14 && (
                <Tooltip title="Edit">
                  <Button
                    onClick={() => {
                      navigate(`/edit-review/${review.id}`);
                    }}
                    shape="circle"
                    icon={<EditFilled className={cssStyle.editIcon} />}
                    className={cssStyle.editBtn}
                  />
                </Tooltip>
              )}
          </Col>
        </Row>
      </div>

      <div style={styles.commentBox}>
        <div style={styles.comment}>{review.text}</div>

        {already == true && count == undefined && like != true ? (
          <>
            <LikeFilled
              onClick={() => {
                handleUnLike();
              }}
              style={styles.likeFilledIcon}
            />
            {reviewLikesCount !== 0 ? (
              <span style={styles.likeText}>
                You and {reviewLikesCount} others liked this
              </span>
            ) : (
              <span style={styles.likeText}>You liked this</span>
            )}
          </>
        ) : (
          <>
            {like == true ? (
              <>
                <LikeFilled
                  onClick={() => {
                    setLike(!like);
                    handleUnLike();
                  }}
                  style={styles.likeFilledIcon}
                />
                {countLikes !== 0 ? (
                  <span style={styles.likeText}>
                    You and {countLikes} others liked this
                  </span>
                ) : (
                  <span style={styles.likeText}>You liked this</span>
                )}
              </>
            ) : (
              <>
                <LikeOutlined
                  onClick={() => {
                    setLike(!like);
                    handleLike();
                  }}
                  style={styles.likeOutlinedIcon}
                />
                <span style={styles.likeText}>Användbart?</span>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
const styles = StyleSheet.create({
  container: {
    margin: "0px 0px 18px 0px",
  },
  center: {
    display: "flex",
    alignItems: "center",
  },
  comment: {
    marginBottom: "25px",
  },
  likeFilledIcon: {
    fontSize: "20px",
    color: "#07285D",
    marginRight: "10px",
  },
  likeOutlinedIcon: {
    fontSize: "20px",
    color: "#808795",
    marginRight: "10px",
  },
  likeText: {
    fontFamily: "d-din",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "15px",
    letterSpacing: "0.07em",
    color: "#808795",
    cursor: "pointer",
  },
  review: {
    width: "100%",
    height: "64px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    paddingLeft: "25px",
    paddingRight: "25px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
  },
  reviewHeader: {
    width: "100%",
  },
  verticalCenter: {
    paddingTop: "5px",
  },
  numberCol: {
    paddingTop: "3px",
  },
  name: {
    fontFamily: "d-din",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "15px",
    letterSpacing: "0.07em",
    marginLeft: "25px",
  },
  location: {
    fontFamily: "d-din",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "13px",
    letterSpacing: "0.07em",
    color: "rgba(0, 0, 0, 0.55)",
    marginLeft: "15px",
  },
  rate: { marginTop: "-10px" },

  rateNumber: {
    fontFamily: "d-din",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    height: "31.42px",
    color: colors.primary,
    marginLeft: "10px",
    marginBottom: "3px",
  },
  createdAt: {
    color: "#000000",
    marginLeft: "1ch",
    fontFamily: "d-din",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "15px",
    letterSpacing: "0.07em",
  },
  commentBox: {
    padding: "25px 25px 25px 30px",
    fontFamily: "d-din",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "118.1%",
    letterSpacing: "0.07em",
    wordBreak: "break-word",
    backgroundColor: "#FFFFFF",
    margin: "1px 0px 0px 0px",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
  },
});
