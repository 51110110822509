import React from "react";
import {
  AdminContainer,
  BuyerContainer,
  SupplierContainer,
} from "./containers/MainContainer";
import { useAuthState } from "../hooks/useAuthState";
import { PublicRoutes } from "./PublicRoutes";
import { BuyerRoutes } from "./BuyerRoutes";
import { AccountType } from "../constants/enums";
import { SupplierRoutes } from "./SupplierRoutes";
import { OnboardingChecker } from "../components/wrapers/OnboardingChecker";
import { AdminRoutes } from "./AdminRoutes";

export function Router() {
  const { accountType } = useAuthState();

  if (accountType === AccountType.Buyer) {
    return (
      // <OnboardingChecker>
      <BuyerContainer>
        <BuyerRoutes />
      </BuyerContainer>
      // </OnboardingChecker>
    );
  }

  if (accountType === AccountType.Supplier) {
    return (
      // <OnboardingChecker>
      <SupplierContainer>
        <SupplierRoutes />
      </SupplierContainer>
      // </OnboardingChecker>
    );
  }

  if (accountType === AccountType.Admin) {
    return (
      <AdminContainer>
        <AdminRoutes />
      </AdminContainer>
    );
  }

  return <PublicRoutes />;
}
