import React, { useState } from "react";
import { Progress } from "antd";
// import { useTranslation } from "../../../hooks/useTranslation";
import { useNavigate, useParams } from "react-router-dom";
import style from "./styles/topbar.module.css";
import { NavLink } from "react-router-dom";

const TopBar = () => {
  // const { t } = useTranslation("supplierProfile");
  const { suntCategoryId } = useParams();
  const [moveTo, setMoveTo] = useState<string>("");

  const move = (value: 0 | 1 | 2 | 3) => {
    setMoveTo(`bar-move-at-${value}`);
  };

  const isActiveLink = (navData: any) => {
    return navData.isActive ? style.linkActive : style.text;
  };

  return (
    <>
      <div className={style.header}>
        <div className={style.topbarCenter}>
          {/* <ul className={style.links}>
            <li>
              <NavLink
                onClick={() => move(0)}
                to={`/categories/${suntCategoryId || " "}`}
                className={isActiveLink}
              >
                Leverantörer
              </NavLink>
            </li>
            <li onClick={() => move(1)}>
              <NavLink
                to={`/insights/${suntCategoryId || " "}`}
                className={isActiveLink}
              >
                Insikter
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => move(2)}
                to={`/reviews/${suntCategoryId || " "}`}
                className={isActiveLink}
              >
                Mina Omdömen
              </NavLink>
            </li>
          </ul> */}
          <div className={style.bottomLine}>
            <div className={`${style.bar} ${style[moveTo]}`} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBar;
