import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

// type Props = {};

// calendar Icon
const calendarSvg = () => (
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.736 5.32788H0.262695V5.98626H15.736V5.32788Z" fill="white" />
    <path
      d="M7.11921 13.2969H5.42247C5.19152 13.2969 5.00427 13.1072 5.00427 12.8733V11.155C5.00427 10.9211 5.19152 10.7314 5.42247 10.7314H7.11921C7.35016 10.7314 7.53741 10.9211 7.53741 11.155V12.8733C7.53741 13.1072 7.35016 13.2969 7.11921 13.2969Z"
      fill="#CDD4E2"
    />
    <path
      d="M10.5737 13.2969H8.87694C8.64599 13.2969 8.45874 13.1072 8.45874 12.8733V11.155C8.45874 10.9211 8.64599 10.7314 8.87694 10.7314H10.5737C10.8046 10.7314 10.9919 10.9211 10.9919 11.155V12.8733C10.9919 13.1072 10.8046 13.2969 10.5737 13.2969Z"
      fill="#CDD4E2"
    />
    <path
      d="M14.0281 13.2969H12.3314C12.1005 13.2969 11.9132 13.1072 11.9132 12.8733V11.155C11.9132 10.9211 12.1005 10.7314 12.3314 10.7314H14.0281C14.2591 10.7314 14.4463 10.9211 14.4463 11.155V12.8733C14.4463 13.1072 14.2591 13.2969 14.0281 13.2969Z"
      fill="#CDD4E2"
    />
    <path
      d="M3.66463 13.2969H1.968C1.73705 13.2969 1.5498 13.1072 1.5498 12.8733V11.155C1.5498 10.9211 1.73705 10.7314 1.968 10.7314H3.66474C3.89569 10.7314 4.08294 10.9211 4.08294 11.155V12.8733C4.08283 13.1072 3.89559 13.2969 3.66463 13.2969Z"
      fill="#CDD4E2"
    />
    <path
      d="M3.64703 7.19873H1.9872C1.72781 7.19873 1.51672 7.41251 1.51672 7.6752V9.35618C1.51672 9.61888 1.72781 9.83265 1.9872 9.83265H3.64703C3.90641 9.83265 4.1175 9.61888 4.1175 9.35618V7.6752C4.1175 7.41251 3.90641 7.19873 3.64703 7.19873ZM3.59475 9.30324H2.03947V7.72814H3.59475V9.30324Z"
      fill="#808795"
    />
    <path
      d="M7.10139 7.19873H5.44166C5.18228 7.19873 4.97119 7.41251 4.97119 7.6752V9.35618C4.97119 9.61888 5.18228 9.83265 5.44166 9.83265H7.1015C7.36088 9.83265 7.57197 9.61888 7.57197 9.35618V7.6752C7.57186 7.41251 7.36088 7.19873 7.10139 7.19873ZM7.04912 9.30324H5.49394V7.72814H7.04922V9.30324H7.04912Z"
      fill="#808795"
    />
    <path
      d="M8.89442 9.83265H10.5543C10.8136 9.83265 11.0247 9.61888 11.0247 9.35618V7.6752C11.0247 7.41251 10.8136 7.19873 10.5543 7.19873H8.89442C8.63504 7.19873 8.42395 7.41251 8.42395 7.6752V9.35618C8.42395 9.61898 8.63504 9.83265 8.89442 9.83265ZM8.9467 7.72814H10.502V9.30324H8.9467V7.72814Z"
      fill="#808795"
    />
    <path
      d="M14.0104 7.19873H12.3507C12.0913 7.19873 11.8802 7.41251 11.8802 7.6752V9.35618C11.8802 9.61888 12.0913 9.83265 12.3507 9.83265H14.0104C14.2698 9.83265 14.4809 9.61888 14.4809 9.35618V7.6752C14.4809 7.41251 14.2698 7.19873 14.0104 7.19873ZM13.9582 9.30324H12.403V7.72814H13.9582V9.30324Z"
      fill="#808795"
    />
    <path
      d="M3.64703 14.1958H1.9872C1.72781 14.1958 1.51672 14.4096 1.51672 14.6723V16.3533C1.51672 16.6159 1.72781 16.8297 1.9872 16.8297H3.64703C3.90641 16.8297 4.1175 16.6159 4.1175 16.3533V14.6723C4.1175 14.4096 3.90641 14.1958 3.64703 14.1958ZM3.59475 16.3003H2.03947V14.7252H3.59475V16.3003Z"
      fill="#808795"
    />
    <path
      d="M7.10139 14.1958H5.44166C5.18228 14.1958 4.97119 14.4096 4.97119 14.6723V16.3533C4.97119 16.6159 5.18228 16.8297 5.44166 16.8297H7.1015C7.36088 16.8297 7.57197 16.6159 7.57197 16.3533V14.6723C7.57186 14.4096 7.36088 14.1958 7.10139 14.1958ZM7.04912 16.3003H5.49394V14.7252H7.04922V16.3003H7.04912Z"
      fill="#808795"
    />
    <path
      d="M10.5543 14.1958H8.89442C8.63504 14.1958 8.42395 14.4096 8.42395 14.6723V16.3533C8.42395 16.6159 8.63504 16.8297 8.89442 16.8297H10.5543C10.8136 16.8297 11.0247 16.6159 11.0247 16.3533V14.6723C11.0247 14.4096 10.8137 14.1958 10.5543 14.1958ZM10.502 16.3003H8.9467V14.7252H10.502V16.3003Z"
      fill="#808795"
    />
    <path
      d="M14.0104 14.1958H12.3507C12.0913 14.1958 11.8802 14.4096 11.8802 14.6723V16.3533C11.8802 16.6159 12.0913 16.8297 12.3507 16.8297H14.0104C14.2698 16.8297 14.4809 16.6159 14.4809 16.3533V14.6723C14.4809 14.4096 14.2698 14.1958 14.0104 14.1958ZM13.9582 16.3003H12.403V14.7252H13.9582V16.3003Z"
      fill="#808795"
    />
    <path
      d="M6.30239 2.57262H9.66972H9.69147C9.83585 2.57262 9.95284 2.45414 9.95284 2.30792C9.95284 2.1617 9.83585 2.04321 9.69147 2.04321H9.66972H6.30239C6.15801 2.04321 6.04102 2.1617 6.04102 2.30792C6.04102 2.45414 6.15801 2.57262 6.30239 2.57262Z"
      fill="#808795"
    />
    <path
      d="M13.1869 2.57262H13.2653H13.8603C14.0047 2.57262 14.1217 2.45414 14.1217 2.30792C14.1217 2.1617 14.0047 2.04321 13.8603 2.04321H13.2653H13.1869C13.0425 2.04321 12.9255 2.1617 12.9255 2.30792C12.9255 2.45414 13.0425 2.57262 13.1869 2.57262Z"
      fill="#808795"
    />
    <path
      d="M15.7347 5.20591C15.5903 5.20591 15.4733 5.3244 15.4733 5.47062V5.72135H0.522748V3.09134C0.522748 2.80535 0.752548 2.57262 1.03504 2.57262H2.72958C2.87397 2.57262 2.99096 2.45414 2.99096 2.30792C2.99096 2.1617 2.87397 2.04321 2.72958 2.04321H1.03504C0.464305 2.04321 0 2.51333 0 3.09134V5.98605V17.1948C0 17.7729 0.464305 18.2431 1.03504 18.2431H14.9611C15.5318 18.2431 15.9961 17.7729 15.9961 17.1948V5.98605V5.47062C15.9961 5.3244 15.8791 5.20591 15.7347 5.20591ZM14.9611 17.7138H1.03504C0.752548 17.7138 0.522748 17.481 0.522748 17.195V6.25076H15.4733V17.1948C15.4733 17.481 15.2435 17.7138 14.9611 17.7138Z"
      fill="#808795"
    />
    <path
      d="M4.51651 4.53409C5.06947 4.53409 5.51935 4.07848 5.51935 3.51846V1.01562C5.51935 0.455611 5.06947 0 4.51651 0C3.96355 0 3.51367 0.455611 3.51367 1.01562V3.51846C3.51367 4.07858 3.96355 4.53409 4.51651 4.53409ZM4.03642 1.01562C4.03642 0.747528 4.25179 0.529411 4.51651 0.529411C4.78123 0.529411 4.9966 0.747528 4.9966 1.01562V3.51846C4.9966 3.78656 4.78123 4.00467 4.51651 4.00467C4.25179 4.00467 4.03642 3.78656 4.03642 3.51846V1.01562Z"
      fill="#808795"
    />
    <path
      d="M11.4793 4.45257C12.0322 4.45257 12.4821 4.01072 12.4821 3.46755V1.06656C12.4821 0.523389 12.0322 0.081543 11.4793 0.081543C10.9263 0.081543 10.4764 0.523389 10.4764 1.06656V3.46755C10.4764 4.01072 10.9263 4.45257 11.4793 4.45257ZM10.9992 1.06656C10.9992 0.815412 11.2146 0.610954 11.4793 0.610954C11.744 0.610954 11.9594 0.815412 11.9594 1.06656V3.46755C11.9594 3.7187 11.744 3.92316 11.4793 3.92316C11.2146 3.92316 10.9992 3.7187 10.9992 3.46755V1.06656Z"
      fill="#808795"
    />
  </svg>
);

const CalendarIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={calendarSvg} {...props} />
);

// const CalendarIcon = () => {
//   return <calendarIcon />;
// };

export default CalendarIcon;
