import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../hooks/useTranslation";
import { StarRating } from "../../../components";
import { colors } from "../../../constants/colors";
import { StyleSheet } from "../../../models/StyleSheet";
import { Avatar, Col, Row, Input } from "antd";
import { CommentDots } from "../../../assets";
import {
  reviewsInterface,
  useGetSupplierReviews,
  useLikeOrUnlikeReviews,
  usePostSupplierReviewReply,
} from "../../../hooks/api/Reviews";
import {
  EllipsisOutlined,
  EnvironmentOutlined,
  LikeFilled,
  LikeOutlined,
  SendOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "../../../index.css";
import { storage } from "../../../services/storage";
import style from "./styles/reviewItem.module.css";
import SendTextIcon from "../../../assets/icons/sendTextIcon";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../services/notificationService";
import { reviewType } from "./type";
// import { QueryInterface } from "..";
dayjs.extend(relativeTime);

const { TextArea } = Input;

type ReviewItemProp = {
  supplierId: number;
  reviewId: number;
  supplierName?: string;
  data?: reviewType;
  buyerName?: string;
};

export const ReviewItem = (props: ReviewItemProp) => {
  const { reviewId, supplierId, supplierName, buyerName, data } = props;
  const { t } = useTranslation("reviewPage");
  const {
    mutate: replySend,
    isSuccess: repliedSuccess,
    isError,
    error,
    data: repliedResponse,
  } = usePostSupplierReviewReply();
  const [reply, setReply] = useState<string>();
  const [isHideReplyBox, setIsHideReplyBox] = useState<boolean>(true);

  useEffect(() => {
    setIsHideReplyBox(!repliedSuccess);
  }, [repliedSuccess]);

  const sendReply = () => {
    const replyData = {
      supplierId: supplierId,
      reviewId: reviewId,
      text: reply,
    };
    replySend(replyData, {
      onSuccess: () => {
        DisplaySuccessNotification("Successfully sent");
      },
      onError: () => {
        DisplayErrorNotification("Reply not sent");
      },
    });
  };

  return (
    <div className={style.container}>
      <div className="reviewRow" style={styles.review}>
        <Row style={styles.reviewHeader} align="middle">
          <Col span={6}>
            <Avatar size={40}>
              {/* {data?.isAnonymous ? "A" : data?.buyer?.user.firstName[0]} */}
              {data?.buyer?.user.firstName[0]}
            </Avatar>
            <span style={styles.name}>
              {data?.buyer?.user.firstName} {data?.buyer?.user.lastName}
            </span>
          </Col>
          <Col style={styles.center} span={6}>
            {data?.suntCategory.name}
          </Col>
          <Col style={styles.center} span={5}>
            {/* {"Bostadsstiftelsen Signalisten i Solna".slice(0, 20)} ... */}
            {data?.organisation.name}
          </Col>
          <Col style={styles.verticalCenter} span={3}>
            <span style={styles.createdAt}>
              {/* ~ {dayjs(review.updatedAt).fromNow()} */}
              {dayjs(data?.updatedAt).format("DD/MM/YYYY")}
            </span>
          </Col>
          <Col style={styles.verticalCenter} span={4}>
            <StarRating
              style={styles.rate}
              color={colors.primary}
              disabled={true}
              size={15}
              // value={review.overallRating}
              value={data?.overallRating}
            />
            <span style={styles.rateNumber}>
              {/* {review.overallRating} */}
              {data?.overallRating}
            </span>
          </Col>
        </Row>
      </div>

      <div className={style.commentBox}>
        <div className={style.comment}>{data?.text}</div>
      </div>
      {data?.ReviewAnswer.length === 0 && isHideReplyBox && (
        <div className={style.replyContainer}>
          <Avatar size={40}>
            {supplierName && supplierName[0].toUpperCase()}
          </Avatar>
          <div className={style.replyBoxBtn}>
            {/* <span>Svara här...</span> */}
            <TextArea
              placeholder="Svara här..."
              className={style.textarea}
              value={reply}
              onChange={(e) => {
                setReply(e.target.value);
              }}
              autoSize
            />
            <div onClick={sendReply} className={style.btn}>
              <SendTextIcon className={style.icon} />
            </div>
          </div>
        </div>
      )}
      {repliedSuccess && (
        <div className={style.repliedContainer}>
          <div className={style.head}>
            <div>
              <Avatar size={40}>
                {supplierName && supplierName[0].toUpperCase()}
              </Avatar>
              <span className={style.supplierName}>{supplierName} </span>
              {/* <span className={style.time}>svarade den --/--/---- </span> */}
            </div>
            {/* dotted btn */}
            {/* <div className={style.dottedBtn}>
            <EllipsisOutlined className={style.icon} />
          </div> */}
          </div>
          <div className={style.body}>
            <div className={style.repliedText}>
              {repliedResponse && repliedResponse["text"]}
            </div>
          </div>
        </div>
      )}
      {data?.ReviewAnswer &&
        !repliedSuccess &&
        data.ReviewAnswer.map((reply) => (
          <div key={reply.id} className={style.repliedContainer}>
            <div className={style.head}>
              <div>
                <Avatar size={40}>
                  {supplierName && supplierName[0].toUpperCase()}
                </Avatar>
                <span className={style.supplierName}>{supplierName} </span>
                {/* <span className={style.time}>svarade den --/--/---- </span> */}
              </div>
              {/* dotted btn */}
              {/* <div className={style.dottedBtn}>
            <EllipsisOutlined className={style.icon} />
          </div> */}
            </div>
            <div className={style.body}>
              <div className={style.repliedText}>{reply.text}</div>
            </div>
          </div>
        ))}
    </div>
  );
};
const styles = StyleSheet.create({
  center: {
    display: "flex",
    alignItems: "center",
  },
  likeFilledIcon: {
    fontSize: "20px",
    color: "#07285D",
    marginRight: "10px",
  },
  likeOutlinedIcon: {
    fontSize: "20px",
    color: "#808795",
    marginRight: "10px",
  },
  likeText: {
    fontFamily: "d-din",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "15px",
    letterSpacing: "0.07em",
    color: "#808795",
    cursor: "pointer",
  },
  review: {
    width: "100%",
    height: "64px",
    display: "flex",
    alignItems: "center",
    // backgroundColor: "#FFFFFF",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    paddingLeft: "25px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
  },
  reviewHeader: {
    width: "100%",
  },
  verticalCenter: {
    paddingTop: "5px",
  },
  numberCol: {
    paddingTop: "3px",
  },
  name: {
    fontFamily: "d-din",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "15px",
    letterSpacing: "0.07em",
    marginLeft: "25px",
  },
  location: {
    fontFamily: "d-din",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "13px",
    letterSpacing: "0.07em",
    color: "rgba(0, 0, 0, 0.55)",
    marginLeft: "15px",
  },
  rate: { marginTop: "-10px" },

  rateNumber: {
    fontFamily: "d-din",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    height: "31.42px",
    color: colors.primary,
    marginLeft: "10px",
    marginBottom: "3px",
  },
  createdAt: {
    color: "#000000",
    // marginLeft: "1ch",
    fontFamily: "d-din",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "15px",
    letterSpacing: "0.07em",
  },
});
