import React from "react";
import style from "./styles/style.module.css";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { SupplierData, useSaveData } from "../../../../hooks/api/Suppliers";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../../services/notificationService";
import { useTranslation } from "../../../../hooks/useTranslation";
type Props = {
  sectionNo: number;
  next: () => void;
  back: () => void;
};

const FloatingButtons = (props: Props) => {
  const { mutate: saveDataMutate } = useSaveData();
  const { sectionNo, next, back } = props;
  const { t } = useTranslation("floatingButtons");

  const sendDataToDB = () => {
    const rawData = localStorage.getItem("supplierData");
    if (rawData) {
      const data = JSON.parse(rawData) as SupplierData;
      saveDataMutate(data, {
        onSuccess: () => {
          DisplaySuccessNotification("Success");
        },
        onError: () => {
          DisplayErrorNotification("Error");
        },
      });
    }
  };

  return (
    <div className={style.btnBox}>
      {sectionNo !== 0 && (
        <button className={style.btn} onClick={() => back()}>
          <LeftOutlined className={style.backIcon} />
          <span>{t("back")}</span>
        </button>
      )}
      <button className={style.btn} onClick={() => history.back()}>
        {t("cancel")}
      </button>
      {sectionNo === 3 && (
        <button className={style.btn} onClick={sendDataToDB}>
          {t("requestChange")}
        </button>
      )}
      {sectionNo >= 0 && sectionNo <= 2 && (
        <button className={style.btn} onClick={() => next()}>
          <span>{t("next")}</span>
          <RightOutlined className={style.nextIcon} />
        </button>
      )}
    </div>
  );
};

export default FloatingButtons;
