import React, { useState } from "react";
import { useTranslation } from "../../../hooks/useTranslation";
import { useNavigate } from "react-router";
import { useVerifyAccount } from "../../../hooks/api/Admin";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../services/notificationService";
import style from "./styles/style.module.css";

export const UserVerification = () => {
  const [email, setEmail] = useState("");
  const [pin, setPin] = useState("");

  const { t } = useTranslation("verifyAccount");
  const navigate = useNavigate();
  const { mutate } = useVerifyAccount();

  const handleSubmit = () => {
    const dataToSend = {
      email,
      pin,
    };
    console.log("1", dataToSend);

    mutate(dataToSend, {
      onSuccess: () => {
        DisplaySuccessNotification(t("accountVerifiedSuccess"));
        navigate("/dashboard");
      },
      onError: () => {
        DisplayErrorNotification(t("accountVerifiedError"));
      },
    });
  };

  return (
    <>
      <p className={style.inputLabel}>Enter email</p>
      <input
        className={style.input}
        type="text"
        placeholder="Enter email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <p className={style.inputLabel}>Enter Pin</p>
      <input
        className={style.input}
        placeholder="Enter pin"
        value={pin}
        onChange={(e) => {
          setPin(e.target.value);
        }}
      />
      <button
        className={style.btn}
        type="submit"
        onClick={() => {
          handleSubmit();
        }}
      >
        Submit
      </button>
    </>
  );
};
