import React, { useRef, useState } from "react";
import { Divider } from "antd";
import { Formik, FormikProps } from "formik";
import { StyleSheet } from "../../../models/StyleSheet";
import { Dropdown } from "../../../containers/Buyer/components/Dropdown";

import {
  OrganisationFilterInitialValues,
  OrganisationFilterInterface,
} from "../../../containers/Buyer/OrganisationFilter/OrganisationFilterForm.utils";
import { Link } from "react-router-dom";
import { H4 } from "../../text/H4";

export const InsightMenu = () => {
  const [, setChanges] = useState(false);

  const form = useRef<FormikProps<OrganisationFilterInterface>>(null);

  const onChange = () => {
    setChanges((prevStage) => !prevStage);
  };

  return (
    <Formik
      innerRef={form}
      initialValues={OrganisationFilterInitialValues}
      onSubmit={onChange}
      style={styles.container}
    >
      {({ handleSubmit }) => (
        <form onChange={handleSubmit}>
          <Dropdown
            label={"Analysis of reviews"}
            style={styles.categoryContainer}
          >
            <>
              <Link type="text" style={styles.MenuItem} to={""}>
                <H4 text={"option 1"} style={styles.link} />
              </Link>
              <Link type="text" style={styles.MenuItem} to={""}>
                <H4 text={"option 2"} style={styles.link} />
              </Link>
              <Link type="text" style={styles.MenuItem} to={""}>
                <H4 text={"option 3"} style={styles.link} />
              </Link>
            </>
          </Dropdown>
          <Divider />
          <Dropdown
            label={"Administrative requirements"}
            style={styles.categoryContainer}
          >
            <Link type="text" style={styles.MenuItem} to={""}>
              <H4 text={"Analysis of reviews"} style={styles.link} />
            </Link>
          </Dropdown>
          <Divider />
          <Dropdown
            label={"Follow-up methods"}
            style={styles.categoryContainer}
          >
            <Link type="text" style={styles.MenuItem} to={""}>
              <H4 text={"option"} style={styles.link} />
            </Link>
          </Dropdown>
          <Divider />
        </form>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  MenuItem: {
    outline: "none",
    // backgroundColor: "#F2F2F5",
    height: "32px",
    border: "0",
    borderRadius: "4px",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    letterSpacing: "0.07em",
    color: "#000000",
    width: "150px",
    padding: "5px 8px",
    margin: "10px 0",
    display: "block",
  },
  link: {
    width: "60px",
    fontSize: "14px",
  },
  categoryContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "1px auto",
  },
  container: {
    width: "100%",
    minWidth: "244px",
    minHeight: "calc(100vh - 64px)",
    // backgroundColor: "#FFFFFF",
    marginBottom: "-24px",
    paddingBottom: "10px",
  },
});
