import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button } from "antd";
import Icon, { ArrowDownOutlined } from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import Style from "./style.module.css";
import Category from "./Category";
import { useSuntGetCategories } from "../../../hooks/api/Category";
import categoryIcons from "../../../assets/icons/categoryIcons";
import {
  EffectCoverflow,
  Navigation,
  Pagination,
  Autoplay,
  Keyboard,
  Mousewheel,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-coverflow";
import SupplierSearch from "../../../components/notGeneral/SupplierSearch";

const Categories = () => {
  const navigate = useNavigate();
  const { data: categoriesData } = useSuntGetCategories();
  const [swiperCards, setSwiperCards] = useState(true);
  const [divComponents, setDivComponents] = useState(false);
  const [categoriesSlideData, setCategorySlideData] = useState([
    {
      name: "",
      id: 0,
      icon: "",
      isActive: false,
    },
  ]);

  const [next, setNext] = useState(5);
  const handleMoreCategories = () => {
    setNext(categoriesSlideData.length);
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }, 100);
  };
  const handleLessCategories = () => {
    window.scrollTo({ top: 0 });
    setTimeout(() => {
      setNext(5);
    }, 400);
  };

  const [rotateLoadBtnStyle, setRotateLoadBtnStyle] = useState("");
  const [isRotateLoadBtn, setIsRotateLoadBtn] = useState<boolean>(false);

  useEffect(() => {
    setCategorySlideData([
      {
        name: "",
        id: 0,
        icon: "",
        isActive: false,
      },
    ]);
    categoriesData &&
      categoriesData.filter((category) => {
        setCategorySlideData((categoriesSlideData) => {
          return [
            ...categoriesSlideData,
            {
              id: category.id,
              name: category.name,
              icon: categoryIcons[
                category.logoURL as keyof typeof categoryIcons
              ],
              isActive: category.isActive,
            },
          ];
        });
      });
  }, [categoriesData]);

  useEffect(() => {
    if (next === 5) {
      setIsRotateLoadBtn(false);
      setRotateLoadBtnStyle("");
    } else if (next === categoriesSlideData.length) {
      setIsRotateLoadBtn(true);
      setRotateLoadBtnStyle(`${Style.rotateLoadBtn}`);
    }
  }, [next, categoriesSlideData.length]);

  // Function for changing category design
  const onSwiper = () => {
    setDivComponents(false);
    setSwiperCards(true);
  };
  const onIcon = () => {
    setDivComponents(true);
    setSwiperCards(false);
  };

  // custom icons for toggle button
  const sliderSvg = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="7.22217"
        width="11.5556"
        height="26"
        rx="1"
        fill={swiperCards ? "#fff" : "#000"}
      />
      <rect
        x="20.2222"
        y="4.48267"
        width="5.77778"
        height="17.931"
        rx="1"
        fill={swiperCards ? "#fff" : "#000"}
      />
      <rect
        y="4.48267"
        width="5.77778"
        height="17.931"
        rx="1"
        fill={swiperCards ? "#fff" : "#000"}
      />
    </svg>
  );
  const SliderIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={sliderSvg} {...props} />
  );
  const componentSvg = () => (
    <svg
      width="16"
      height="20"
      viewBox="0 0 19 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="2.5"
        cy="2.5"
        r="2.5"
        fill={divComponents ? "#fff" : "#000"}
      />
      <circle
        cx="2.5"
        cy="11.5"
        r="2.5"
        fill={divComponents ? "#fff" : "#000"}
      />
      <circle
        cx="2.5"
        cy="20.5"
        r="2.5"
        fill={divComponents ? "#fff" : "#000"}
      />
      <circle
        cx="9.5"
        cy="2.5"
        r="2.5"
        fill={divComponents ? "#fff" : "#000"}
      />
      <circle
        cx="9.5"
        cy="11.5"
        r="2.5"
        fill={divComponents ? "#fff" : "#000"}
      />
      <circle
        cx="9.5"
        cy="20.5"
        r="2.5"
        fill={divComponents ? "#fff" : "#000"}
      />
      <circle
        cx="16.5"
        cy="2.5"
        r="2.5"
        fill={divComponents ? "#fff" : "#000"}
      />
      <circle
        cx="16.5"
        cy="11.5"
        r="2.5"
        fill={divComponents ? "#fff" : "#000"}
      />
      <circle
        cx="16.5"
        cy="20.5"
        r="2.5"
        fill={divComponents ? "#fff" : "#000"}
      />
    </svg>
  );
  const ComponentIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={componentSvg} {...props} />
  );

  return (
    <>
      <div className={Style.outerContainer}>
        <div className={Style.innerContainer}>
          <>
            <div className={Style.contentContainer}>
              <p className={Style.topContent}>
                Hitta och interagera med leverantörer du kan lita på.
              </p>
              <p className={Style.mainHeading}>
                Platsen för dialog och erfarenhetsutbyte inom den offentliga
                sektorn
              </p>
              <SupplierSearch
                onSelect={(selected: string) => {
                  const arr = selected.split("//");
                  navigate(
                    `/supplier-reviews/categories/${arr[1]}/organisations/${arr[2]}`
                  );
                }}
              />
              <div className={Style.toggleButton}>
                <Button
                  className={Style.swiperIcon}
                  onClick={onSwiper}
                  style={{
                    backgroundColor: swiperCards ? "#4fbfc0" : "transparent",
                  }}
                >
                  <SliderIcon />
                </Button>
                <Button
                  className={Style.componentIcon}
                  onClick={onIcon}
                  style={{
                    backgroundColor: divComponents ? "#4fbfc0" : "transparent",
                  }}
                >
                  <ComponentIcon />
                </Button>
              </div>
              {swiperCards && (
                <Swiper
                  style={{ paddingRight: "116px", paddingLeft: "75px" }}
                  effect={"coverflow"}
                  grabCursor={true}
                  initialSlide={0}
                  centeredSlides={true}
                  centeredSlidesBounds={true}
                  keyboard={{ enabled: true }}
                  mousewheel={true}
                  slidesPerView={3}
                  freeMode={false}
                  key={Date.now()}
                  coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 2,
                    slideShadows: false,
                  }}
                  loop={true}
                  modules={[
                    EffectCoverflow,
                    Pagination,
                    Navigation,
                    Autoplay,
                    Keyboard,
                    Mousewheel,
                  ]}
                  className={Style.swiper}
                >
                  {categoriesSlideData &&
                    categoriesSlideData.slice(1).map((category) => (
                      <Col key={category.id} xs={24} sm={24} md={20} lg={20}>
                        <SwiperSlide
                          key={`swiper-slide-${category.id}`}
                          className={Style.swiperSlide}
                        >
                          <Category
                            id={category.id}
                            title={category.name ?? ""}
                            isActive={category.isActive}
                            swiperDesign={true}
                            catImage={category.icon}
                          />
                        </SwiperSlide>
                      </Col>
                    ))}
                </Swiper>
              )}
              {/* 2nd design */}
              {divComponents && (
                <>
                  <Row>
                    {categoriesSlideData &&
                      categoriesSlideData.slice(1, next)?.map((category) => (
                        <Col xs={24} sm={12} md={6} lg={6} key={category.id}>
                          <Category
                            id={category.id}
                            title={category.name ?? ""}
                            isActive={category.isActive}
                            swiperDesign={false}
                            catImage={category.icon}
                          />
                        </Col>
                      ))}
                  </Row>
                  <div className={Style.loadMoreBox}>
                    <div
                      className={`${Style.loadBtn} ${rotateLoadBtnStyle}`}
                      onClick={() => {
                        if (isRotateLoadBtn) {
                          handleLessCategories();
                        } else {
                          handleMoreCategories();
                        }
                      }}
                    >
                      <ArrowDownOutlined className={Style.icon} />
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        </div>
      </div>
    </>
  );
};
export default Categories;
