import React from "react";
import { Typography } from "antd";
import { CSSProperties } from "react";

const { Text } = Typography;

interface H1Props {
  text: string;
  style?: CSSProperties;
}

export function H1({ text, style }: H1Props) {
  return (
    <Text style={{ fontSize: 56, fontFamily: "D-DIN", ...style }}>{text}</Text>
  );
}
