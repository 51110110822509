import Trash from "./Trash.png";
import Home from "./Home.png";
import BluePrint from "./Blue Print.png";
import Delivery from "./Delivery.png";
import Affiliate from "./Affiliate.png";
import HangerFashion from "./Hanger Fashion.png";
import StudyLamp from "./Study lamp.png";
import UserSecurity from "./User Security.png";
import Medicine from "./Medicine.png";
import Tools from "./Tools.png";
import Trees from "./Trees.png";
import GasStation from "./GasStation.png";
import HockeyStickGrowth from "./Hockey stick growth.png";
import Technology from "./Technology.png";
import Cutlery from "./Cutlery.png";
import Website from "./Website.png";
import BoyGraduation from "./Boy Graduation.png";
import NoCategory from "./No category.png";

// Toggle icons
import SwiperIcon from "../../../assets/images/SwiperIcon.svg";
import ComponentIcon from "../../../assets/images/ComponentIcon.svg";

export { SwiperIcon, ComponentIcon };

const iconObject = {
  Trash,
  Home,
  "Blue Print": BluePrint,
  Delivery,
  Affiliate,
  "Hanger Fashion": HangerFashion,
  "Study lamp": StudyLamp,
  "User Security": UserSecurity,
  Medicine,
  Tools,
  Trees,
  GasStation,
  "Hockey stick growth": HockeyStickGrowth,
  Technology,
  Cutlery,
  Website,
  "Boy Graduation": BoyGraduation,
  "No category": NoCategory,
};

export default iconObject;
