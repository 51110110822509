import React, { useState, useEffect, Children } from "react";
import { Divider, Input, Select, Space, Button, Radio, Avatar } from "antd";
import "../../../css/public.css";
import MyIcons from "./Icon";
import { useNavigate, useParams } from "react-router-dom";
import { StyleSheet } from "../../../models/StyleSheet";
import { Breadcrumb, Layout, Row, Col } from "antd";
import { RightOutlined, CloseOutlined } from "@ant-design/icons";
import { SuppliersTable } from "./SuppliersTable";
import { Conditional, DefaultButton, Pagination } from "../../../components";
import {
  useGetCategoryOrganisations,
  useGetSuntCategory,
} from "../../../hooks/api/Category";
import { CategoryRadio } from "../../../components";
// import {image} from '../../../assets/icons/categoryIcons'
import { useSuntGetCategories } from "../../../hooks/api/Category";
import { useTranslation } from "../../../hooks/useTranslation";
import arrow from "../../../assets/icons/Arrow.svg";
import { InviteSupplier } from "../components/InviteSupplier";
import { OrganisationFilter } from "../OrganisationFilter";
import {
  OrganisationFilterInterface,
  OrganisationFilterInitialValues,
} from "../OrganisationFilter/OrganisationFilterForm.utils";
import { Insights } from "../components/Insights";
import { FilterProvider } from "../../../context/FilterProvider";
import cssStyle from "./styles/style.module.css";
import { createContext, useContext } from "react";
import { OrganisationFilterForm } from "../OrganisationFilter/OrganisationFilterForm";

// export type GlobalContent = {
//   catName: string[];
//   cpvCode: string[];
//   city: string[];
//   reviewGrade: string;
//   reviewsMin: string;
//   dateFrom: string;
//   dateTo: string;
//   employeesMin: string;
//   employeesMax: string;
//   turnoverMin: number | null;
//   turnoverMax: number | null;
//   turnoverMaxHardCode?: number;
//   turnoverMaxValueOfSlider: number;
//   experience: string[];
//   role: string[];
//   tags: string[];
//   companyType: string[];
//   other: string[];
//   supplier_Ids: number[];

//   setCatName: React.Dispatch<React.SetStateAction<string[]>>;
//   setCpvCode: React.Dispatch<React.SetStateAction<string[]>>;
//   setCity: React.Dispatch<React.SetStateAction<string[]>>;
//   setReviewGrade: (c: string) => void;
//   setReviewsMin: (c: string) => void;
//   setDateFrom: (c: string) => void;
//   setDateTo: (c: string) => void;
//   setEmployeesMin: (c: string) => void;
//   setEmployeesMax: (c: string) => void;
//   setTurnoverMin: (c: number | null) => void;
//   setTurnoverMax: (c: number | null) => void;
//   setTurnoverMaxHardCode: (c: number) => void;
//   setTurnoverMaxValueOfSlider: (c: number) => void;
//   setExperience: React.Dispatch<React.SetStateAction<string[]>>;
//   setRole: (c: string[]) => void;
//   setTags: (c: string[]) => void;
//   setCompanyType: React.Dispatch<React.SetStateAction<string[]>>;
//   setOther: React.Dispatch<React.SetStateAction<string[]>>;
//   setSupplier_Ids: (c: number[]) => void;
// };

// export const MyGlobalContext = createContext<GlobalContent>({
//   catName: [],
//   cpvCode: [],
//   city: [], //^
//   reviewGrade: "",
//   reviewsMin: "",
//   dateFrom: "", //
//   dateTo: "", //
//   employeesMin: "",
//   employeesMax: "",
//   turnoverMin: null,
//   turnoverMax: null,
//   turnoverMaxHardCode: 100,
//   turnoverMaxValueOfSlider: 100,
//   experience: [], //^
//   role: [], //^
//   tags: [],
//   companyType: [],
//   other: [],
//   supplier_Ids: [],

//   setCatName: () => {},
//   setCpvCode: () => {},
//   setCity: () => {},
//   setReviewGrade: () => {},
//   setReviewsMin: () => {},
//   setDateFrom: () => {},
//   setDateTo: () => {},
//   setEmployeesMin: () => {},
//   setEmployeesMax: () => {},
//   setTurnoverMin: () => {},
//   setTurnoverMax: () => {},
//   setTurnoverMaxHardCode: () => {},
//   setTurnoverMaxValueOfSlider: () => {},
//   setExperience: () => {},
//   setRole: () => {},
//   setTags: () => {},
//   setCompanyType: () => {},
//   setOther: () => {},
//   setSupplier_Ids: () => {},
// });
// export const useGlobalContext = () => useContext(MyGlobalContext);

export function CategoryDetails() {
  const [suntCategory, setSuntCategory] = useState<string | undefined>(
    undefined
  );

  const { suntCategoryId } = useParams();
  const { t } = useTranslation("categories");
  const { data: categoriesData } = useSuntGetCategories(); // 1st level sunt categories
  const { data: categoryData } = useGetSuntCategory(
    parseInt(suntCategoryId || "")
  );

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState<
    [OrganisationFilterInterface | undefined, boolean | undefined]
  >([undefined, undefined]);
  const [sortType, setSortType] = useState<{
    sortBy:
      | "name"
      | "employeesNumber"
      | "city"
      | "numberOfReviews"
      | "averageRating"
      | null;
    orderBy: "desc" | "asc";
  }>({ sortBy: null, orderBy: "desc" });
  const [activatedSuppliers, setActivatedSuppliers] = useState<number[]>([]);

  const catName = categoriesData?.filter((val) => {
    if (val.id.toString() == suntCategoryId) {
      return val;
    }
  });

  const categoryName: string | undefined =
    catName && catName[0].name?.toString();

  useEffect(() => {
    setSuntCategory(categoryName);
  }, [categoryName]);

  const navigate = useNavigate();
  const { data, isLoading } = useGetCategoryOrganisations(
    parseInt(suntCategoryId ?? ""),

    {
      suntCategoryId: parseInt(suntCategoryId || ""),
      page: page - 1,
      size: 20,
      name: "",
      supplier_filter: false,
      s: query[0]?.supplier_Ids,
      cpv_filter: query[0]?.cpv ? query[0].cpv : [],
      cat_name_filter: query[0]?.name ? query[0].name : [],
      review_filter: parseFloat(query[0]?.reviewGrade || "0"),
      review_count_filter: query[0]?.reviewsMin || 0,
      turnover_filter_min:
        query[0]?.turnoverMin !== 0 ? query[0]?.turnoverMin : null,
      city_filter: query[0]?.city ? query[0].city : [],
      tags_filter: query[0]?.tags ? query[0].tags : [],
      public_sector_filter: query[0]?.experience ? query[0].experience : [],
      company_type_filter: query[0]?.companyType ? query[0].companyType : [],
      role_filter: query[0]?.role ? query[0].role : [],
      turnover_filter_max:
        query[0]?.turnoverMaxHardCode === 100
          ? null
          : query[0]?.turnoverMax
          ? query[0].turnoverMax
          : null,
      employee_count_filter_min: query[0]?.employeesMin
        ? parseInt(query[0].employeesMin)
        : null,
      employee_count_filter_max: query[0]?.employeesMax
        ? parseInt(query[0].employeesMax)
        : null,
      founded_date_filter_start: query[0]?.dateFrom ? query[0].dateFrom : null,
      founded_date_filter_end: query[0]?.dateTo ? query[0].dateTo : null,
      sme_filter: query[0]?.other ? query[0].other : [],
      sort_by: sortType.sortBy
        ? sortType.sortBy
        : "numberOfPublicSectorTenders",
      order_by: sortType.sortBy ? sortType.orderBy : "desc",
    }
  );

  const filtered = categoriesData?.filter((val) => {
    if (val.id.toString() !== suntCategoryId) {
      return val;
    }
  });

  const Categories = filtered?.map((data, index) => {
    return (
      <Select.Option key={data.id.toString()} disabled={!data.isActive}>
        <Avatar
          style={{
            // maxWidth: "100px",
            // maxHeight: "100px",
            padding: "7px",
            margin: "4px",
            backgroundColor: "#f2f2f5",
            border: "2px black",
          }}
          src={MyIcons[index + 1]}
          shape="circle"
          size={"large"}
        ></Avatar>
        <span style={styles.DropDown}>{data.name}</span>
      </Select.Option>
    );
  });

  const [catHeadingArrowRotate, setCatHeadingArrowRotate] = useState("");
  return (
    // <MyGlobalContext.Provider
    //   value={{
    //     catName,
    //     setCatName,
    //     cpvCode,
    //     setCpvCode,
    //     city,
    //     setCity,
    //     reviewGrade,
    //     setReviewGrade,
    //     reviewsMin,
    //     setReviewsMin,
    //     employeesMin,
    //     setEmployeesMin,
    //     employeesMax,
    //     setEmployeesMax,
    //     dateFrom,
    //     setDateFrom,
    //     dateTo,
    //     setDateTo,
    //     turnoverMin,
    //     setTurnoverMin,
    //     turnoverMax,
    //     setTurnoverMax,
    //     turnoverMaxHardCode,
    //     setTurnoverMaxHardCode,
    //     turnoverMaxValueOfSlider,
    //     setTurnoverMaxValueOfSlider,
    //     experience,
    //     setExperience,
    //     role,
    //     setRole,
    //     tags,
    //     setTags,
    //     companyType,
    //     setCompanyType,
    //     other,
    //     setOther,
    //     supplier_Ids,
    //     setSupplier_Ids,
    //   }}
    // >
    <FilterProvider
      value={{ query, setQuery, activatedSuppliers, setActivatedSuppliers }}
    >
      <div style={styles.outsideContainer}>
        <div style={styles.filterBox}>
          {/* <OrganisationFilter /> */}
          <OrganisationFilterForm />
        </div>
        <div style={styles.insideContainer}>
          <div style={styles.header}>
            <Row align="middle">
              <Col sm={24} md={24} lg={16}>
                <div>
                  <Breadcrumb style={styles.breadcrumbBox}>
                    <Breadcrumb.Item>
                      <a href="/categories">{t("categories")}</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("organisations")}</Breadcrumb.Item>
                  </Breadcrumb>
                  <div style={styles.box} className={cssStyle.box}>
                    <div
                      className={`${cssStyle.categoryNameBox} ${catHeadingArrowRotate}`}
                    >
                      <Avatar
                        style={{
                          padding: "7px",
                          height: "50px",
                          width: "50px",
                        }}
                        src={MyIcons[0]}
                      ></Avatar>
                      <Select
                        onClick={() => {
                          let cssClass = catHeadingArrowRotate;
                          cssClass =
                            cssClass.length === 0 ? cssStyle.arrowRotate : "";
                          setCatHeadingArrowRotate(cssClass);
                        }}
                        onBlur={() => {
                          setCatHeadingArrowRotate("");
                        }}
                        size={"large"}
                        style={styles.categoryName}
                        onChange={(value) => {
                          setSuntCategory(value);
                        }}
                        value={suntCategory}
                        dropdownClassName={cssStyle.headingDropdown}
                      >
                        {Categories}
                      </Select>
                      {/* <RightOutlined className={cssStyle.polygonIcon} /> */}
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={24} md={24} lg={8}>
                <div className={cssStyle.containerBtn}>
                  <DefaultButton
                    text={t("leaveFeedback")}
                    style={styles.btnFeedback}
                    variant={"primary"}
                    onClick={() => navigate("/create-review")}
                  />
                  <InviteSupplier />
                </div>
              </Col>
            </Row>
          </div>
          {(query[0]?.name.length !== 0 ||
            query[0]?.cpv.length !== 0 ||
            query[0]?.city.length !== 0 ||
            query[0]?.reviewGrade !== "" ||
            query[0]?.reviewsMin !== 0 ||
            // query[0]?.employeesMin !== "" ||
            // query[0]?.employeesMax !== "" ||
            // query[0]?.tags.length !== 0 ||
            query[0]?.dateFrom !== "" ||
            query[0]?.dateTo !== "" ||
            query[0]?.turnoverMin !== 0 ||
            query[0]?.turnoverMax !== 0 ||
            query[0]?.companyType.length !== 0 ||
            query[0]?.other.length !== 0 ||
            query[0]?.experience.length !== 0) && (
            <div className={cssStyle.capsuleBox}>
              {query[0]?.name.map((name) => {
                return (
                  <div key={name} className={cssStyle.capsuleBtn}>
                    <span>{name}</span>
                    <div className={cssStyle.closeIcon}></div>
                  </div>
                );
              })}

              {query[0]?.cpv.map((cpv) => {
                return (
                  <div key={cpv} className={cssStyle.capsuleBtn}>
                    <span>{cpv}</span>
                    <div className={cssStyle.closeIcon}></div>
                  </div>
                );
              })}

              {query[0]?.city.map((city) => {
                return (
                  <div key={city} className={cssStyle.capsuleBtn}>
                    <span>{city}</span>
                    <div className={cssStyle.closeIcon}>
                      <CloseOutlined />
                    </div>
                  </div>
                );
              })}

              {query[0]?.reviewGrade !== "" && (
                <div className={cssStyle.capsuleBtn}>
                  <span>Reviews: {query[0]?.reviewGrade}</span>
                  <div className={cssStyle.closeIcon}></div>
                </div>
              )}

              {query[0]?.reviewsMin !== 0 && (
                <div className={cssStyle.capsuleBtn}>
                  <span>No of Reviews: {query[0]?.reviewsMin}</span>
                  <div className={cssStyle.closeIcon}></div>
                </div>
              )}

              {query[0]?.dateFrom !== "" && (
                <div className={cssStyle.capsuleBtn}>
                  <span>Date from: {query[0]?.dateFrom}</span>
                  <div className={cssStyle.closeIcon}></div>
                </div>
              )}

              {query[0]?.dateTo !== "" && (
                <div className={cssStyle.capsuleBtn}>
                  <span>Date to: {query[0]?.dateTo}</span>
                  <div className={cssStyle.closeIcon}></div>
                </div>
              )}

              {query[0]?.turnoverMin !== 0 && (
                <div className={cssStyle.capsuleBtn}>
                  <span>Turn over min: {query[0]?.turnoverMin}</span>
                  <div className={cssStyle.closeIcon}></div>
                </div>
              )}

              {query[0]?.turnoverMax !== 0 && (
                <div className={cssStyle.capsuleBtn}>
                  <span>Turn over max: {query[0]?.turnoverMax}</span>
                  <div className={cssStyle.closeIcon}></div>
                </div>
              )}

              {query[0]?.companyType.map((companyType) => {
                return (
                  <div key={companyType} className={cssStyle.capsuleBtn}>
                    <span>{companyType}</span>
                    <div className={cssStyle.closeIcon}></div>
                  </div>
                );
              })}

              {query[0]?.experience.map((experience) => {
                return (
                  <div key={experience} className={cssStyle.capsuleBtn}>
                    <span>{experience}</span>
                    <div className={cssStyle.closeIcon}></div>
                  </div>
                );
              })}

              {query[0]?.other.map((companySize) => {
                return (
                  <div key={companySize} className={cssStyle.capsuleBtn}>
                    <span>{companySize}</span>
                    <div className={cssStyle.closeIcon}></div>
                  </div>
                );
              })}

              {/* {query[0]?.employeesMin !== "" && (
                <div className={cssStyle.capsuleBtn}>
                  <span> Min Employees: {query[0]?.employeesMin}</span>
                  <div className={cssStyle.closeIcon}>
                    <CloseOutlined />
                  </div>
                </div>
              )}

              {query[0]?.employeesMax !== "" && (
                <div className={cssStyle.capsuleBtn}>
                  <span> Max Employees: {query[0]?.employeesMax}</span>
                  <div className={cssStyle.closeIcon}>
                    <CloseOutlined />
                  </div>
                </div>
              )}

              {query[0]?.tags.map((tag) => {
                return (
                  <div key={tag} className={cssStyle.capsuleBtn}>
                    <span>{tag}</span>
                    <div className={cssStyle.closeIcon}>
                      <CloseOutlined />
                    </div>
                  </div>
                );
              })} */}
            </div>
          )}

          <Layout style={styles.container}>
            <Conditional
              condition={!!suntCategoryId}
              trueRender={
                <SuppliersTable
                  suppliers={data?.data ?? []}
                  categoryId={parseInt(suntCategoryId || "")}
                  isLoading={isLoading}
                  sortType={sortType}
                  setSortType={setSortType}
                />
              }
            />
          </Layout>
          <Conditional
            condition={!isLoading}
            trueRender={
              <Pagination page={page} setPage={setPage} total={data?.count} />
            }
          />
        </div>
        {/* <Insights suntCategoryId={suntCategoryId} /> */}
      </div>
    </FilterProvider>
    // </MyGlobalContext.Provider>
  );
  // return (
  //   <FilterProvider
  //     value={{ query, setQuery, activatedSuppliers, setActivatedSuppliers }}
  //   >
  //     <div style={styles.outsideContainer}>
  //       <OrganisationFilter />
  //       <div style={styles.insideContainer}>
  //         <div style={styles.header}>
  //           <h1 style={styles.categoryName}>
  //             {categoryData?.suntCategory.name}
  //           </h1>

  //           <div style={styles.containerBtn}>
  //             <a href="https://community.sunt.tech/auth/login">
  //               <button className="pointer" style={styles.btn}>
  //                 <span style={styles.btnText}>{t("Insights")}</span>
  //                 <span style={styles.btnIcon}>
  //                   <img src={arrow} />
  //                 </span>
  //               </button>
  //             </a>
  //             <DefaultButton
  //               text={t("leaveFeedback")}
  //               style={styles.btnFeedback}
  //               variant={"primary"}
  //               onClick={() =>
  //                 navigate(
  //                   `/create-review/categories/${parseInt(
  //                     suntCategoryId ?? ""
  //                   )}?organisationId`
  //                 )
  //               }
  //             />
  //             <InviteSupplier />
  //           </div>
  //         </div>
  //         <Layout style={styles.container}>
  //           <Breadcrumb>
  //             <Breadcrumb.Item>
  //               <a href="/categories">{t("categories")}</a>
  //             </Breadcrumb.Item>
  //             <Breadcrumb.Item>{t("organisations")}</Breadcrumb.Item>
  //           </Breadcrumb>
  //           <Conditional
  //             condition={!!suntCategoryId}
  //             trueRender={
  //               <SuppliersTable
  //                 suppliers={data?.data ?? []}
  //                 categoryId={parseInt(suntCategoryId || "")}
  //                 isLoading={isLoading}
  //                 sortType={sortType}
  //                 setSortType={setSortType}
  //               />
  //             }
  //           />
  //         </Layout>
  //         <Conditional
  //           condition={!isLoading}
  //           trueRender={
  //             <Pagination page={page} setPage={setPage} total={data?.count} />
  //           }
  //         />
  //       </div>
  //       <Insights suntCategoryId={suntCategoryId} />
  //     </div>
  //   </FilterProvider>
  // );
}

const styles = StyleSheet.create({
  outsideContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0px -24px",
  },
  insideContainer: {
    // paddingRight: "25px",
    // paddingLeft: "25px",
    padding: "25px",
    flex: "1",
  },
  box: {
    display: "inline-block",
  },
  filterBox: {
    padding: "25px 0px 25px 25px",
  },
  header: {
    background: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    padding: "20px 20px 0px 20px",
  },
  normal: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400px",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
  },
  radio: {
    margin: "5px 0",
    display: "block",
  },
  container: {
    marginTop: "10px",
    backgroundColor: "#FAFAFA",
    width: "100%",
    // minWidth: "798px",
    // width: "calc(100vw - 520px)",
  },
  breadcrumbBox: {
    marginBottom: "14px",
  },
  catBoxBorder: {
    border: "1px solid #F2F2F5",
  },
  categoryNameBox: {
    border: "1px solid #F2F2F5",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    paddingRight: "13px",
    marginBottom: "16px",
  },
  categoryName: {
    margin: "15px 10px 15px 10px",
    // width: "650px",
  },
  DropDown: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "35px",
    margin: "15px 10px 15px 10px",
    // color: "rgba(7, 40, 93, 1)" ! important,
    width: "500px",
  },
  containerBtn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    justifyContent: "space-between",
    // marginLeft: "auto",
    // paddingRight: "20px",
    marginBottom: "16px",
    height: "",
    border: "1px solid red",
  },
  btn: {
    fontFamily: "D-Din",
    fontStyle: "normal",
    backgroundColor: "#07285D",
    borderRadius: "4px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "space-between",
    width: "192px",
    border: "0",
    height: "32px",
    marginRight: "20px",
  },
  btnText: {
    margin: "7.5px 0px 7.5px 26.5px",
  },
  btnIcon: {
    margin: "7.5px 6.5px 7.5px 0px",
  },
  btnFeedback: {
    fontFamily: "D-Din",
    fontStyle: "normal",
    backgroundColor: "#07285D",
    borderRadius: "25px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#FFFFFF",
    width: "220px",
    border: "0",
    height: "32px",
    marginBottom: "20px",
  },
});
