import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Radio } from "antd";
import React, { useState } from "react";
import { SpinnerLoader } from "../../../components";
import { Conditional } from "../../../components/Conditional";
import { useGetAllUsers } from "../../../hooks/api/Admin";
import { Header } from "./components/Header";
import { UserRow } from "./components/UserRow";
import { User } from "./showUsers.utils";
import style from "./style/style.module.css";

export const ShowUsers = () => {
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState("name");

  const { data, isLoading } = useGetAllUsers(searchValue, searchBy);
  // console.log(data);
  console.log(searchBy);

  return (
    <>
      <div className={style.column}>
        <div className={style.searchBar}>
          <div className={style.inputContainer}>
            <AutoComplete
              style={{ width: 370 }}
              placeholder="Search"
              onChange={setSearchValue}
              filterOption={true}
              bordered={false}
              value={searchValue}
              dropdownClassName={style.searchBarDropdown}
            />
          </div>
          <SearchOutlined className={style.icon} />
        </div>

        <div className={style.row}>
          <Radio.Group
            className={style.radio}
            name="searchBy"
            value={searchBy}
            // onChange={(e) => {
            //   // setSearchBy(e.target.value);
            // }}
          >
            <Radio
              value={"name"}
              onClick={() => {
                if (searchBy !== "name") {
                  setSearchBy("name");
                } else {
                  setSearchBy("");
                }
              }}
            >
              <p className={style.text}>By Name</p>
            </Radio>
            <Radio
              value={"email"}
              onClick={() => {
                if (searchBy !== "email") {
                  setSearchBy("email");
                } else {
                  setSearchBy("");
                }
              }}
            >
              <p className={style.text}>By Email</p>
            </Radio>
            <Radio
              value={"phoneNumber"}
              onClick={() => {
                if (searchBy !== "phoneNumber") {
                  setSearchBy("phoneNumber");
                } else {
                  setSearchBy("");
                }
              }}
            >
              <p className={style.text}>By Phone Number</p>
            </Radio>
          </Radio.Group>
        </div>
      </div>
      <Conditional
        condition={isLoading}
        trueRender={<SpinnerLoader />}
        falseRender={
          <>
            <Header />
            {data?.buyers
              ? data?.buyers?.map((buyer: User, index) => (
                  <UserRow key={index} User={buyer} />
                ))
              : [null]}
            {data?.suppliers
              ? data?.suppliers?.map((supplier: User, index) => (
                  <UserRow key={index} User={supplier} />
                ))
              : [null]}
            {data?.buyers.length == 0 && data?.suppliers.length == 0 && (
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "30px",
                  marginTop: "30px",
                }}
              >
                No Data Found
              </h3>
            )}
          </>
        }
      />
    </>
  );
};
