import React, { useEffect } from "react";
import { StyleSheet } from "../models/StyleSheet";
import { Col, Progress, Row, Tooltip } from "antd";
import { red, green } from "@ant-design/colors";
import { InfoCircleOutlined } from "@ant-design/icons";
import cssStyles from "./styles/progressBar.module.css";

export const ProgressBar = ({
  label,
  rate,
  styleLabel,
}: {
  label: string;
  rate: number | null | undefined | string;
  styleLabel?: React.CSSProperties;
}) => {
  const rateNum = rate ? +rate : 0;
  const showInfo = () => {};
  return (
    <div
    // className={cssStyles.progressBar}
    >
      <p style={{ ...styles.label, ...styleLabel }}>{label}</p>

      <Row style={styles.container}>
        <Col className={cssStyles.progressWidth}>
          {/* <Progress
            percent={rate ? parseInt(rate.toString()) * 20 : 0}
            steps={5}
            strokeColor={[
              "#092d60",
              "#1b5278",
              "#286e8b",
              "#368b9e",
              "#4bb7ba",
            ]} //with steps
            //   strokeColor={{ from: "#4FBFC0", to: "#87d068" }} //without steps
            strokeWidth={5}
            trailColor={"#CDD4E2"}
            showInfo={false}
          /> */}
          <Progress
            percent={rate ? parseInt(rate.toString()) * 20 : 0}
            strokeColor={{ "0%": "#092d60", "100%": "#4bb7ba" }}
            showInfo={false}
          />
        </Col>
        <Col>
          {/* <span style={styles.ratingNumber}>{rate == 0.0 ? "NA" : rate}</span> */}
          <span style={styles.ratingNumber}>
            {/* {rate && rate !== 0 ? parseInt(rate.toString()) : "NA"} */}
            {rate && rate !== 0 ? rateNum.toFixed(2) : "NA"}
          </span>
        </Col>
        {/* <Col>
          <Tooltip
            placement="bottom"
            overlayStyle={{ color: "black" }}
            color="white"
            overlayInnerStyle={{
              boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
              border: "1px solid #CDD4E2",
              color: "#CDD4E2",
              padding: "20px",
              borderRadius: "4px",
              maxWidth: "200px",
              whiteSpace: "pre-line",
            }}
            title="1 poäng = Leverantören uppfyller inte kraven. Detta har mätts och kan bevisas.

            3 poäng = Leverantören uppfyller troligtvis kraven, men detta har inte mätts och kan inte bevisas.

            5 poäng = Leverantören uppfyller kraven. Detta har mätts och kan bevisas."
          >
            <InfoCircleOutlined onClick={showInfo} style={styles.infoIcon} />
          </Tooltip>
        </Col> */}
      </Row>
    </div>
  );
};

const styles = StyleSheet.create({
  label: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#000000",
    // margin: "35px 0 0px 0px",
  },
  infoIcon: {
    fontSize: "20px",
    color: "#CDD4E2",
    padding: "1px 0px 0px 8px",
  },
  container: {
    width: "100%",
    paddingTop: "0px",
  },
  ratingNumber: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "17px",
    color: "#4FBFC0",
    letterSpacing: "0.07em",
    marginLeft: "8px",
  },
});
