import * as Yup from "yup";
import { StyleSheet } from "../../../models/StyleSheet";
import { colors } from "../../../constants/colors";
import { i18n } from "../../../i18n";

const strongPasswordRegExp =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!-/:-@[-`{-~])/;

export const signupInitValuesBuyer: signupInitValuesBuyerInterface = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  phoneNumber: "",
  buyerOrganisation: "",
  region: "",
  city: "",
  position: "",
  termsAndConditions: false,
};
export interface signupInitValuesBuyerInterface {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  buyerOrganisation: string;
  region: string;
  city: string;
  position: string;
  termsAndConditions: boolean;
}
export const signupBuyerSchema = Yup.object().shape({
  firstName: Yup.string().required(
    i18n.t("validators.required", { ns: "translation" })
  ),
  lastName: Yup.string().required(
    i18n.t("validators.required", { ns: "translation" })
  ),
  email: Yup.string()
    .email(i18n.t("validators.email", { ns: "translation" }))
    .required(i18n.t("validators.required", { ns: "translation" })),
  password: Yup.string()
    .min(8, i18n.t("validators.tooShort", { ns: "translation" }))
    .matches(
      strongPasswordRegExp,
      i18n.t("validators.passwordTooWeak", { ns: "translation" })
    )
    .required(i18n.t("validators.required", { ns: "translation" })),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      i18n.t("validators.diffrentPasswords", { ns: "translation" })
    )
    .required(i18n.t("validators.diffrentPasswords", { ns: "translation" })),
  phoneNumber: Yup.string(),
  termsAndConditions: Yup.bool().oneOf(
    [true],
    i18n.t("validators.acceptTerms", { ns: "translation" })
  ),
  position: Yup.string().required(
    i18n.t("validators.required", { ns: "translation" })
  ),
});

export const signupInitValuesSupplier: signupInitValuesSupplierInterface = {
  firstName: "",
  lastName: "",
  role: "",
  email: "",
  organisationName: "",
  organisationNumber: "",
  password: "",
  confirmPassword: "",
  termsAndConditions: false,
};
export interface signupInitValuesSupplierInterface {
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  organisationName: string;
  organisationNumber: string;
  password: string;
  confirmPassword: string;
  termsAndConditions: boolean;
}
export const signupSupplierSchema = Yup.object().shape({
  firstName: Yup.string().required(
    i18n.t("validators.required", { ns: "translation" })
  ),
  lastName: Yup.string().required(
    i18n.t("validators.required", { ns: "translation" })
  ),
  role: Yup.string().required(
    i18n.t("validators.required", { ns: "translation" })
  ),
  email: Yup.string()
    .email(i18n.t("validators.email", { ns: "translation" }))
    .required(i18n.t("validators.required", { ns: "translation" })),
  organisationName: Yup.string().required(
    i18n.t("validators.required", { ns: "translation" })
  ),
  organisationNumber: Yup.string().required(
    i18n.t("validators.required", { ns: "translation" })
  ),
  password: Yup.string()
    .min(8, i18n.t("validators.tooShort", { ns: "translation" }))
    .matches(
      strongPasswordRegExp,
      i18n.t("validators.passwordTooWeak", { ns: "translation" })
    )
    .required(i18n.t("validators.required", { ns: "translation" })),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      i18n.t("validators.diffrentPasswords", { ns: "translation" })
    )
    .required(i18n.t("validators.diffrentPasswords", { ns: "translation" })),
  termsAndConditions: Yup.bool().oneOf(
    [true],
    i18n.t("validators.acceptTerms", { ns: "translation" })
  ),
});

export const styles = StyleSheet.create({
  logo: {
    position: "static",
    textAlign: "center",
    paddingTop: "20px",
    paddingBottom: "22px",
  },
  bottom: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formContainer: {
    marginTop: "32px",
    width: "256px",
  },
  middleLine: {
    maxWidth: "2px",
    border: "1px solid #CDD4E2",
    backgroundColor: "#CDD4E2",
    height: "400px",
    margin: "64px 80px 0 80px",
  },
  checkBoxText: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    letterSpacing: "0.07em",
  },
  link: {
    fontFamily: "d-din",
    fontStyle: "italic",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    color: "#4FBFC0",
    textDecoration: "underline",
    marginTop: "5px",
  },
  submitBtn: {
    width: "160px",
    height: "32px",
    background: "#07285D",
    border: "0",
    borderRadius: "4px",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.07em",
    color: "#FFFFFF",
    margin: "30px 0",
  },
  rightSide: {
    padding: "8px 0 8px 0",
    minWidth: "383px",
    textAlign: "center",
  },
  rightSideHeader: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#CDD4E2",
    textAlign: "center",
    width: "256px",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    margin: "0px 320px 0px 320px",
    paddingBottom: "70px",
  },
  leftSide: {
    padding: "8px 0px 8px 0px",
    minWidth: "383px",
    textAlign: "center",
  },
  leftSideHeader: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "22px",
    letterSpacing: "0.07em",
    color: "#3F3F3F",
    textAlign: "center",
    width: "128px",
    margin: "0 auto 28px auto",
    paddingBottom: "2px",
    borderBottom: `2px solid ${colors.secondary}`,
  },
  leftSideQuestion: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "22px",
    letterSpacing: "0.07em",
    textAlign: "center",
    marginTop: "15px",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  btn: {
    width: "256px",
    height: "48px",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "22px",
    letterSpacing: "0.07em",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    margin: "8px",
    paddingLeft: "32px",
    border: "2px solid transparent",
  },
  icon: {
    filter: "brightness(0%)",
  },
  iconSelected: {
    filter: "brightness(100%)",
  },
  btnUnSelected: {
    backgroundColor: "#FFFFFF",
    color: "#000000",
  },
  btnSelected: {
    backgroundColor: colors.primary,
    color: "#FFFFFF",
  },
  btnHover: {
    border: `1px solid ${colors.primary}`,
  },
  btnText: {
    marginLeft: "32px",
  },
  infoContainer: {
    width: "256px",
    margin: "48px auto 0 auto",
  },
  infoHeader: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    textAlign: "left",
  },
  infoDescription: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    textAlign: "justify",
  },
  error: {
    textAlign: "left",
    marginLeft: "8px",
    color: "red",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
  },
  checkBoxContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});
