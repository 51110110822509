import React from "react";
import { BoldedText } from "../../../components";
import { useTranslation } from "../../../hooks/useTranslation";
import { StyleSheet } from "../../../models/StyleSheet";

export const SelectedForYou = () => {
  const { t } = useTranslation("supplierProfile");

  return (
    <div style={styles.container}>
      <BoldedText style={styles.headerText}>{t("selectedForYou")}</BoldedText>
      <div style={styles.selectedContainer}>
        <div style={styles.selected}></div>
        <div style={styles.selected}></div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    padding: "32px",
    backgroundColor: "#FFFFFF",
    marginTop: "23px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
  },
  headerText: {
    fontSize: "24px",
    lineHeight: "26px",
  },
  selectedContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "32px",
  },
  selected: {
    width: "49%",
    height: "257px",
    backgroundColor: "#F2F2F5",
    borderRadius: "10px",
  },
});
