import React from "react";
import { Radio, RadioChangeEvent } from "antd";
import { StyleSheet } from "../../models/StyleSheet";

export const ExperienceRadio = ({
  options,
  name,
  setExperience,
}: {
  options: { value: string; boldLabel?: string; label?: string }[];
  name?: string;
  setExperience: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  return (
    <div>
      <Radio.Group name={name}>
        {options.map(({ value, label }) => (
          <Radio
            value={value}
            key={value}
            style={styles.radio}
            onChange={() => {
              if (value == "All") {
                setExperience([]);
              } else {
                setExperience([value]);
              }
            }}
          >
            <>
              <span style={styles.normal}>{label}</span>
            </>
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
};

const styles = StyleSheet.create({
  bold: {
    fontFamily: "D-DIN",
    fontWeight: "700px",
    color: "#000000",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
  },
  normal: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400px",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
  },
  radio: {
    margin: "5px 0",
    display: "block",
  },
  errorText: {
    textAlign: "left",
    marginLeft: "8px",
    color: "red",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
  },
});
