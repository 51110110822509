import React, { useEffect, useState } from "react";
import { ReviewItem } from "./ReviewItem";
import { Row, Col } from "antd";
import style from "./styles/style.module.css";
import {
  useGetOrganisationReviews,
  useGetSupplierReviews,
} from "../../../hooks/api/Reviews";
import { useParams } from "react-router";
import { reviewType } from "./type";

type userType = {
  adminVerified: boolean;
  createdAt: string;
  expiration: string;
  firstName: string;
  id: number;
  imageUrl: string;
  isOnboarded: boolean;
  lastLoggedIn: string;
  lastName: string;
  organisationId: number;
  role: string;
  updatedAt: string;
  userVerified: boolean;
  uuid: string;
  verifiedDate: string;
};

const ReviewContainer = () => {
  const { organisationId } = useParams();
  const [reviewData, setReviewData] = useState<reviewType[]>();
  const [supplierId, setSupplierId] = useState<number>(0);
  const [supplierName, setSupplierName] = useState<string>();
  const { data: orgReviewsData } = useGetOrganisationReviews(
    organisationId || ""
  );
  useEffect(() => {
    const localStoredData = localStorage.getItem("user");
    if (localStoredData) {
      const userData = JSON.parse(localStoredData) as userType;
      setSupplierId(userData.id);
      // setOrganisationId(userData.organisationId);
      setSupplierName(`${userData.firstName} ${userData.lastName}`);
    }
  }, []);

  useEffect(() => {
    setReviewData(orgReviewsData);
  }, [orgReviewsData]);

  return (
    <div>
      {/* comments header */}
      <div className={style.commentsHeader}>
        <Row align="middle">
          <Col span={6}>Namn</Col>
          <Col span={6}>Kategori</Col>
          <Col span={5}>Organisation</Col>
          <Col span={3}>Datum</Col>
          <Col span={4}>Omdöme</Col>
        </Row>
      </div>
      <div>
        {reviewData?.map((item) => {
          return (
            <ReviewItem
              key={item.id}
              reviewId={item?.id || 0}
              supplierId={supplierId}
              supplierName={supplierName}
              data={item}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ReviewContainer;
