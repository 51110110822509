import React, { useEffect, useMemo, useState } from "react";
import { LinkedinIcon, FacebookIcon, GoogleIcon, ArrowLeft } from "./icons";
import Icon, {
  ArrowLeftOutlined,
  RightOutlined,
  HomeOutlined,
  PhoneOutlined,
  GlobalOutlined,
  EnvironmentOutlined,
  LinkOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Col, Row, Space } from "antd";
import style from "./styles/style.module.css";
import { StarRating } from "../../../components";
import { colors } from "../../../constants/colors";
import { useLocation, useNavigate } from "react-router";
import { OrganisationsData } from "../../../hooks/api/Organisations";
import { InviteSupplier } from "../../Buyer/components/InviteSupplier";
import { useTranslation } from "../../../hooks/useTranslation";
import { useGetLogoUrl } from "../../../hooks/api/Suppliers";

// import { isObject } from "formik";

const TopContainer = ({
  organisation,
}: {
  organisation: OrganisationsData | undefined;
}) => {
  const organisationId = organisation?.id;
  const [viewNumber, setViewNumber] = useState(false);
  const [viewWebsite, setViewWebsite] = useState(false);
  const [orgLogoUrl, setOrgLogoUrl] = useState<string>();
  const { t } = useTranslation("supplierProfilePage");
  const founded = organisation?.registrationDate?.substring(0, 4);
  const navigate = useNavigate();
  // getting orgLogoUrl key from local storage
  const logoUrlKey = localStorage.getItem("logoUrlKey");
  const filteredLogoUrlKey = logoUrlKey?.replace("/", "%2F");
  const { data: logoUrl } = useGetLogoUrl(
    filteredLogoUrlKey ? filteredLogoUrlKey : ""
  );

  const reviewsNumber = useMemo(
    () => organisation?.reviews.length || 0,
    [organisation]
  );

  const overallRate = useMemo(() => {
    if (organisation?.reviews) {
      return (
        Math.round(
          (organisation?.reviews
            .map((review) => review.overallRating)
            .reduce((a, b) => a + b, 0) /
            organisation?.reviews.length) *
            2
        ) / 2
      );
    }
    return 0;
  }, [organisation]);

  const numberHandler = () => {
    if (viewNumber === true) {
      setViewNumber(false);
    } else {
      setViewNumber(true);
    }
  };
  const webisteHandler = () => {
    if (viewWebsite === true) {
      setViewWebsite(false);
    } else {
      setViewWebsite(true);
    }
  };

  useEffect(() => {
    if (logoUrl) {
      setOrgLogoUrl(logoUrl);
    }
  }, [logoUrl]);

  return (
    <>
      <div className={style.topContainerColumn}>
        <Row>
          <Col md={19} lg={19}>
            <div>
              <div className={style.topLeftContainer}>
                <div>
                  {/* <Space wrap size={16}>
                    <Avatar size={64} src={orgLogoUrl} />
                    <Avatar size="large" icon={<UserOutlined />} />
                    <Avatar icon={<UserOutlined />} />
                    <Avatar size="small" icon={<UserOutlined />} />
                  </Space> */}
                  <Space wrap size={16}>
                    <Avatar
                      className={style.orgLogo}
                      src={orgLogoUrl}
                      style={{
                        background: orgLogoUrl ? "white" : "#D9D9D9",
                        // width: "150px",
                        // height: "50%",
                        margin: "6px",
                        display: "block",
                        // backgroundSize: "contain",
                      }}
                      shape="circle"
                      size={100}
                    >
                      <span className={style.iconText}>
                        {organisation?.name[0]}
                      </span>
                    </Avatar>
                  </Space>
                </div>
                <div>
                  <p className={style.supplierName}>{organisation?.name}</p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "14px",
                    }}
                  >
                    <EnvironmentOutlined className={style.detailsIcon} />
                    <p className={style.text}>
                      {organisation?.streetName}
                      {", "}
                      {organisation?.postalCode}
                      {"  "} {organisation?.city}
                    </p>
                  </div>
                  <div className={style.contactDetails}>
                    <PhoneOutlined className={style.detailsIcon} />
                    {!viewNumber && (
                      <>
                        <p className={style.number} onClick={numberHandler}>
                          {t("viewPhoneNumber")}
                        </p>
                      </>
                    )}
                    {viewNumber && (
                      <>
                        <p className={style.text} onClick={numberHandler}>
                          {organisation?.phoneNumber}
                        </p>
                      </>
                    )}
                    <LinkOutlined
                      style={{ color: "#808795" }}
                      className={style.detailsIcon}
                    />
                    {!viewWebsite && (
                      <>
                        <p
                          className={style.websiteText}
                          onClick={webisteHandler}
                        >
                          {t("website")}
                        </p>
                      </>
                    )}
                    {viewWebsite && (
                      <>
                        {organisation?.websiteURL !== "Information saknas" ? (
                          <a
                            className={style.websiteText}
                            onClick={() => {
                              if (organisation?.websiteURL) {
                                parent.open(
                                  `https://${organisation?.websiteURL}/`
                                );
                              }
                              webisteHandler();
                            }}
                          >
                            {organisation?.websiteURL}
                          </a>
                        ) : (
                          <p className={style.text} onClick={webisteHandler}>
                            Information saknas!
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={5} lg={5} className={style.rightContainer}>
            <div className={style.columnContainer}>
              <div className={style.ratingContainer}>
                <StarRating
                  color={colors.primary}
                  disabled={true}
                  size={24}
                  value={isNaN(overallRate) ? 0.0 : overallRate}
                />
                <p className={style.ratingText}>
                  {isNaN(overallRate) ? "0" : overallRate}
                </p>
                <span className={style.ratingText}>
                  {"("}
                  {reviewsNumber <= 1000
                    ? reviewsNumber
                    : `${Math.round(reviewsNumber / 1000)}k`}
                  {")"}
                </span>
              </div>
              {/* <InviteSupplier /> */}
              {/* <button
                onClick={() => {
                  navigate(
                    `/edit-profile/organisation/${
                      organisationId ? organisationId : ""
                    }`
                  );
                }}
                className={style.btn}
              >
                Redigera
              </button> */}
            </div>
          </Col>
        </Row>
      </div>
      {/* middleContainer */}

      <div className={style.topMiddleContainer}>
        {/* <Row> */}
        {/* <Col xs={24} sm={15} md={17} lg={17}> */}
        <div className={style.topMiddleLeftContainer}>
          <p className={style.heading}> {t("description")}</p>
          <p className={style.content}>{organisation?.description}</p>
        </div>
        {/* </Col> */}
        {/* <Col xs={24} sm={9} md={7} lg={7} className={style.rightContainer}> */}
        <div className={style.columnContainer}>
          <p className={style.socialHeading}> {t("socials")}</p>
          <div className={style.topMiddleRightContainer}>
            <Icon
              component={() => (
                <img src={LinkedinIcon} className={style.socialIcons} />
              )}
            />
            <Icon
              component={() => (
                <img src={FacebookIcon} className={style.socialIcons} />
              )}
            />
            <Icon
              component={() => (
                <img src={GoogleIcon} className={style.socialIcons} />
              )}
            />
          </div>
        </div>
        {/* </Col> */}
        {/* </Row> */}
      </div>
      {/* Last Container */}
      <div className={style.topLastContainer}>
        <div className={style.topLastContainerContent}>
          <p className={style.bottomHeading}>{t("founded")}</p>
          <p className={style.bottomText}>{founded}</p>
        </div>
        <div className={style.topLastContainerContent}>
          <p className={style.bottomHeading}>{t("organisationNumber")}</p>
          <p className={style.bottomText}>{organisation?.organisationNumber}</p>
        </div>
        <div className={style.topLastContainerContent}>
          <p className={style.bottomHeading}>{t("companySize")}</p>
          <p className={style.bottomText}>{organisation?.employeesNumber}</p>
        </div>
        <div className={style.topLastContainerContent}>
          <p className={style.bottomHeading}>{t("annualRevenue")}</p>
          <p className={style.bottomText}>{organisation?.turnover}</p>
        </div>
        <div className={style.topLastContainerContent}>
          <p className={style.bottomHeading}>{t("companyType")}</p>
          <p className={style.bottomText}>{organisation?.organisationForm}</p>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default TopContainer;
