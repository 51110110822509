import React, { useEffect, useState } from "react";
import { Collapse, Checkbox, Spin } from "antd";

import cStyle from "./styles/style.module.css";
import style from "./styles/serviceArea.module.css";
import ServiceCollapse from "./component/ServiceCollapse";
import { useGetCounties } from "../../../hooks/api/Suppliers";
import { logDOM } from "@testing-library/react";
import { useTranslation } from "../../../hooks/useTranslation";

type PropType = {
  onServiceAreas: (data: string) => void;
};

type serviceAreaType = {
  id: number;
  service: string;
  fullCheck: boolean;
  partialCheck: boolean;
  totalCatCheck: number;
  cities: { id: number; index: number; title: string; isCheck: boolean }[];
}[];

type countyDataType = {
  id: number;
  name: string;
  code: number;
  city: { id: number; name: string; code: number; countyId: number }[];
}[];

type newCountyType = {
  id: number;
  service: string;
  fullCheck: boolean;
  partialCheck: boolean;
  totalCatCheck: number;
  cities: { id: number; index: number; title: string; isCheck: boolean }[];
};

const ServiceArea = (props: PropType) => {
  const { onServiceAreas } = props;

  const { data: countyData, isLoading } = useGetCounties();
  const [counties, setCounties] = useState<countyDataType>();
  const { t } = useTranslation("supplierForm");

  const [data, setData] = useState([
    {
      id: 0,
      service: "Uppsala län",
      fullCheck: false,
      partialCheck: false,
      totalCatCheck: 0,
      cities: [
        { id: 305, index: 0, title: "Håbo", isCheck: false },
        { id: 319, index: 1, title: "Älvkarleby", isCheck: false },
        { id: 330, index: 2, title: "Knivsta", isCheck: false },
        { id: 331, index: 3, title: "Heby", isCheck: false },
        { id: 360, index: 4, title: "Tierp", isCheck: false },
        { id: 380, index: 5, title: "Uppsala", isCheck: false },
        { id: 381, index: 6, title: "Enköping", isCheck: false },
        { id: 382, index: 7, title: "Östhammar", isCheck: false },
      ],
    },
  ]);

  useEffect(() => {
    setCounties(countyData);
  }, [countyData]);
  useEffect(() => {
    const newData = [] as serviceAreaType;
    if (counties) {
      counties.forEach((county) => {
        const newCounty: newCountyType = {
          id: county.id,
          service: county.name,
          fullCheck: false,
          partialCheck: false,
          totalCatCheck: 0,
          cities: [],
        };
        county.city.forEach((city, i) => {
          const newCity = {
            id: city.id,
            index: i,
            title: city.name,
            isCheck: false,
          };
          newCounty.cities.push(newCity);
        });
        newData.push(newCounty);
      });
      setData(newData);
      const localData = localStorage.getItem("selectedServiceAreas");
      if (localData) {
        const jsonData = JSON.parse(localData) as serviceAreaType;
        setData(jsonData);
      }
    }
  }, [counties]);

  const [isAllCheck, setIsAllCheck] = useState<boolean>(false);
  const [wholePartialCheck, setWholePartialCheck] = useState<boolean>(false);

  // useEffect(() => {
  //   // get local stored serviceArea data from local storage
  //   const localData = localStorage.getItem("selectedServiceAreas");
  //   if (localData) {
  //     const jsonData = JSON.parse(localData) as serviceAreaType;
  //     setData(jsonData);
  //   }
  // }, []);

  const saveSelectedServiceAreas = () => {
    // convert data into string
    const stringifiedData = JSON.stringify(data);
    // store to local storage
    localStorage.setItem("selectedServiceAreas", stringifiedData);

    // prepare object, for sending data to backend
    const sendingData: {
      id: number;
      cities: { code: number; title: string }[];
    }[] = [];
    data.forEach((item) => {
      if (item.fullCheck || item.partialCheck) {
        const obj: { id: number; cities: { code: number; title: string }[] } = {
          id: item.id,
          cities: [],
        };
        item.cities.forEach((serviceArea) => {
          if (serviceArea.isCheck) {
            obj.cities.push({ code: serviceArea.id, title: serviceArea.title });
          }
        });
        sendingData.push(obj);
      }
    });

    // convert sendingData object to string
    const stringConvertedData = JSON.stringify(sendingData);
    localStorage.setItem("sendingServiceAreas", stringConvertedData);
    // pass to parent state
    onServiceAreas(stringConvertedData);
  };

  const wholePartialCheckHandler = () => {
    // checking if all false
    if (data.every((item) => item.fullCheck === false)) {
      return false;
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i].fullCheck === false) {
        return true;
      }
    }
    return false;
  };

  const catCheckHandler = (
    id: number,
    index: number,
    title: string,
    isCheck: boolean
  ) => {
    setData(
      data.map((item) => {
        if (item.id === id) {
          item.cities[index].isCheck = isCheck;
          let checkFalseCounter = 0;
          for (let i = 0; i < item.cities.length; i++) {
            if (item.cities[i].isCheck == false) {
              checkFalseCounter++;
            }
          }
          item.totalCatCheck = item.cities.length - checkFalseCounter;
          // checking partialChecker
          if (checkFalseCounter > 0 && checkFalseCounter < item.cities.length) {
            item.partialCheck = true;
            item.fullCheck = false;
          }
          if (
            checkFalseCounter === 0 ||
            checkFalseCounter === item.cities.length
          ) {
            item.partialCheck = false;
          }
          if (checkFalseCounter === 0) {
            item.fullCheck = true;
          }
        }
        return item;
      })
    );
    setIsAllCheck(data.every((item) => item.fullCheck === true));
    setWholePartialCheck(wholePartialCheckHandler());
    saveSelectedServiceAreas();
    // console.log(data);
  };

  const serviceCheckHandler = (id: number, isCheck: boolean) => {
    setData(
      data.map((item) => {
        if (item.id === id) {
          for (let i = 0; i < item.cities.length; i++) {
            item.cities[i].isCheck = isCheck;
          }
          item.fullCheck = isCheck;
          item.partialCheck = false;
          if (isCheck) {
            item.totalCatCheck = item.cities.length;
          } else {
            item.totalCatCheck = 0;
          }
        }
        return item;
      })
    );
    setIsAllCheck(data.every((item) => item.fullCheck === true));

    setWholePartialCheck(wholePartialCheckHandler());
    saveSelectedServiceAreas();
  };

  const allCheck = (isCheck: boolean) => {
    setData(
      data.map((item) => {
        for (let i = 0; i < item.cities.length; i++) {
          item.cities[i].isCheck = isCheck;
        }
        item.fullCheck = isCheck;
        item.partialCheck = false;
        if (isCheck) {
          item.totalCatCheck = item.cities.length;
        } else {
          item.totalCatCheck = 0;
        }
        return item;
      })
    );
    setIsAllCheck(isCheck);
    setWholePartialCheck(wholePartialCheckHandler());
    saveSelectedServiceAreas();
  };

  return (
    <div className={cStyle.container}>
      <>
        <h1 className={cStyle.heading}>Välj Serviceområden</h1>
        <div className={cStyle.subHeadingRow}>
          <h2 className={cStyle.subHeading}>{t("addServiceArea")}</h2>
        </div>
        {/*  */}
        <div className={style.clearAllBox}>
          <div>
            <Checkbox
              checked={isAllCheck}
              onChange={({ target: { checked } }) => {
                allCheck(checked);
              }}
            >
              <div
                className={`${style.partialCheckLayer} ${
                  wholePartialCheck ? style.opacity1 : ""
                }`}
              ></div>
            </Checkbox>
            <span>Hela Verige</span>
          </div>
          <button
            className={style.clearBtn}
            onClick={() => {
              allCheck(false);
            }}
          >
            {t("clear")}
          </button>
        </div>
        {isLoading && (
          <div className={style.spinLoadingBox}>
            <Spin size="large" />
          </div>
        )}
        {!isLoading &&
          data.map((item) => (
            <ServiceCollapse
              key={item.id}
              id={item.id}
              title={item.service}
              totalCatCheck={item.totalCatCheck}
              partialCheck={item.partialCheck}
              isFullCheck={item.fullCheck}
              cities={item.cities}
              onCheck={catCheckHandler}
              onCheckService={serviceCheckHandler}
            />
          ))}
      </>
    </div>
  );
};

export default ServiceArea;
