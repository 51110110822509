// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React from "react";
import { Rate, RateProps } from "antd";
import { RateStarEmpty, RateStarFilled, RateStarHalf } from "../assets";

interface RatingProps extends RateProps {
  color: string;
  size: number;
  setValue?: (value: number) => void;
}

export function StarRating({
  value,
  disabled,
  color,
  size,
  style,
  setValue,
}: RatingProps) {
  return (
    <Rate
      style={style}
      disabled={disabled}
      allowHalf
      {...(disabled ? { value } : { defaultValue: value })}
      onChange={(e) => setValue(e)}
      character={({ value, index }: { value: number; index: number }) => {
        if (index < value && index + 1 > value && !Number.isInteger(value)) {
          return <RateStarHalf color={color} width={size} height={size} />;
        } else if (index >= value) {
          return <RateStarEmpty color={color} width={size} height={size} />;
        } else if (index < value) {
          return <RateStarFilled color={color} width={size} height={size} />;
        }
      }}
    />
  );
}
