import React, { useState, useEffect } from "react";
import style from "./styles/style.module.css";
import { Collapse, Checkbox } from "antd";
import { RightOutlined } from "@ant-design/icons";
import TreeHeader from "./TreeHeader";
import { useGetCategoryNames } from "../../../../../hooks/api/Category";

const { Panel } = Collapse;
type Props = {
  id: number;
  title: string;
  icon?: string;
  fullCheck: boolean;
  partialCheck: boolean;
  subCategory?: { subCatId: number; title: string; isCheck: boolean }[];
  onCatCheck: (id: number, isCheck: boolean) => void;
  onSubCatCheck: (id: number, subCatId: number, isCheck: boolean) => void;
};

const TreeBox = (props: Props) => {
  const {
    title,
    icon,
    id,
    subCategory,
    fullCheck,
    partialCheck,
    onCatCheck,
    onSubCatCheck,
  } = props;

  const { data: oldCategoryNames } = useGetCategoryNames(
    parseInt(id.toString() || "")
  );
  const [categoryNames, setCategoryNames] = useState(oldCategoryNames);

  useEffect(() => {
    setCategoryNames(oldCategoryNames);
  }, [oldCategoryNames]);

  // console.log(oldCategoryNames);

  return (
    <>
      {/* this will show only sni */}
      <Collapse className={style.collapseBox}>
        <Panel
          header={
            <TreeHeader
              id={id}
              icon={icon}
              title={title}
              fullCheck={fullCheck}
              partialCheck={partialCheck}
              onCatCheck={onCatCheck}
            />
          }
          key="1"
          className={style.panelBox}
        >
          <div className={style.panelBody}>
            {/* {categoryNames?.map(({ id, suntCategoryDetailed: title }) => ( */}
            {subCategory?.map((data) => (
              <div key={data.subCatId} className={style.panelRow}>
                {/* checkbox */}
                <Checkbox
                  className={style.checkBox}
                  checked={data.isCheck}
                  onChange={({ target: { checked } }) => {
                    onSubCatCheck(id, data.subCatId, checked);
                  }}
                ></Checkbox>
                <div className={style.iconTitleBox}>
                  <span className={style.text}>{data.title}</span>
                </div>
              </div>
            ))}
            {categoryNames?.length === 0 && (
              <p style={{ margin: "10px", color: "grey" }}>
                SubCategories are not avaliable...
              </p>
            )}
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

export default TreeBox;
