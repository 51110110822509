import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  OrganisationsData,
  useGetOrganisationsIncludeReviews,
} from "../../../hooks/api/Organisations";
import { StyleSheet } from "../../../models/StyleSheet";
// import { PlatformsRating } from "./PlatformsRating";
import { RatingContainer } from "./RatingContainer";
import { ReviewsList } from "./ReviewsList";
import { TopBar } from "./TopBar";
// import { Breadcrumb, Layout, Row, Col } from "antd";
import { useTranslation } from "../../../hooks/useTranslation";
import cssStyle from "./styles/style.module.css";
// import { Conditional, DefaultButton, Pagination } from "../../../components";
// import { RightOutlined } from "@ant-design/icons";
// import { InviteSupplier } from "../components/InviteSupplier";
import { useGetSuntCategory } from "../../../hooks/api/Category";

export interface QueryInterface {
  categoryIds?: number[];
  reviewsGrades?: number[];
  position?: string[];
  founded_date_filter_start?: string | null;
  founded_date_filter_end?: string | null;
}

export const SupplierProfile = () => {
  const { t } = useTranslation("categories");

  const { organisationId, suntCategoryId } = useParams();
  const navigate = useNavigate();

  const { data, isError } = useGetOrganisationsIncludeReviews({
    id: parseInt(organisationId ?? ""),
    suntCategoryId: parseInt(suntCategoryId ?? ""),
  });
  if (isError) {
    navigate("/");
  }
  return (
    <div style={styles.container}>
      <div style={styles.insideContainer}>
        {/* Top Bar */}
        <TopBar
          suntCategoryId={suntCategoryId}
          organisationId={organisationId}
          organisation={data}
        />

        {/* Rating bar and review box*/}
        {organisationId && suntCategoryId && (
          <RatingContainer
            suntCategoryId={suntCategoryId}
            organisation={data}
          />
        )}

        {organisationId && suntCategoryId && (
          <ReviewsList
            organisationId={organisationId}
            suntCategoryId={suntCategoryId}
          />
        )}
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    margin: "0 -24px 0 -24px",
    justifyContent: "space-between",
  },
  filterBox: {
    padding: "25px 0px 25px 25px",
  },
  box: {
    display: "inline-block",
  },

  insideContainer: {
    padding: "25px",
    width: "100%",
  },
  rightInsideBar: {
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
    // minWidth: "468px",
    marginTop: "15px",
  },
});
