import React from "react";
import { useNavigate } from "react-router";
import style from "./styles/style.module.css";

export const Dashboard = () => {
  const navigate = useNavigate();
  return (
    <>
      <button
        className={style.btn}
        onClick={() => {
          navigate("/show-pending-requests");
        }}
      >
        Show Pending Requests
      </button>

      <button
        className={style.btn}
        onClick={() => {
          navigate("/on-call-user-verification");
        }}
      >
        On call user-verification
      </button>

      <button
        className={style.btn}
        onClick={() => {
          navigate("/admin-verification");
        }}
      >
        Admin Verification
      </button>

      <button
        className={style.btn}
        onClick={() => {
          navigate("/add-category");
        }}
      >
        Add Category
      </button>

      <button
        className={style.btn}
        onClick={() => {
          navigate("/add-sni");
        }}
      >
        Add Sni
      </button>

      <button
        className={style.btn}
        onClick={() => {
          navigate("/show-users");
        }}
      >
        Show Users
      </button>
    </>
  );
};
