import React from "react";
import { Slider } from "antd";
import cssStyle from "./styles/inputSlider.module.css";

function numberStringFormatter(multiplier: number, maximumValue: number) {
  const value = maximumValue * multiplier;
  const tick =
    value >= 1000 && value < 1000000
      ? (value / 1000).toFixed(1) + "K"
      : value >= 1000000
      ? (value / 1000000).toFixed(1) + "M"
      : value;
  return tick;
}

export const MinMaxSlider = ({
  reset,
  style,
  disabled,
  onChange,
  maximumValue,
}: {
  reset?: number;
  style?: object;
  disabled?: boolean;
  onChange?: ((value: [number, number]) => void) | undefined;
  maximumValue?: string;
}) => {
  if (disabled) {
    maximumValue = undefined;
  }
  return (
    <Slider
      key={reset}
      style={style}
      className={cssStyle.trackStyle}
      marks={{
        0: { label: <small>0</small> },
        25: {
          label: (
            <small>
              {numberStringFormatter(0.25, parseInt(maximumValue ?? "100"))}
            </small>
          ),
        },
        50: {
          label: (
            <small>
              {numberStringFormatter(0.5, parseInt(maximumValue ?? "100"))}
            </small>
          ),
        },
        75: {
          label: (
            <small>
              {numberStringFormatter(0.75, parseInt(maximumValue ?? "100"))}
            </small>
          ),
        },
        100: {
          label: (
            <small>
              {numberStringFormatter(1, parseInt(maximumValue ?? "100"))}
            </small>
          ),
        },
      }}
      range
      defaultValue={[0, 100]}
      tipFormatter={null}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
