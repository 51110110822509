import React, { useState } from "react";
import { loginInitValues, loginValidationSchema } from "./login.utils";
import { Formik, Form } from "formik";
import "../../../css/signup.css";
import { LoginDto, useLogin } from "../../../hooks/api/Auth";
import { storage } from "../../../services/storage";
import {
  DisplaySuccessNotification,
  DisplayErrorNotification,
} from "../../../services/notificationService";
import { useNavigate } from "react-router";
import { useTranslation } from "../../../hooks/useTranslation";
import { StyleSheet } from "../../../models/StyleSheet";
import { colors } from "../../../constants/colors";
import { Input } from "../components/Input";
import { DefaultButton } from "../../../components";
import { PasswordInput } from "../components/PasswordInput";

export function LoginForm() {
  const { t: tNotification } = useTranslation("notificationText");
  const { t } = useTranslation("loginPage");
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const { mutate, isLoading } = useLogin();

  const login = (values: LoginDto) => {
    mutate(values, {
      onSuccess: (data) => {
        DisplaySuccessNotification(`${tNotification("loggedIn")}`);
        storage.setAuth(data.token, data.refreshToken, data.user.role);
        storage.setUser({ ...data.user, isOnboarded: data.isOnboarded });
        navigate(0);
      },
      onError: (error: unknown) => {
        const parsedError = error as {
          response: { data: { message: string } };
        };
        if (parsedError.response.data.message === "Email not yet verified!")
          DisplayErrorNotification(tNotification("emailNotVerifed"));
        else if (
          parsedError.response.data.message ===
          "Account not yet verified by the admin!"
        ) {
          DisplayErrorNotification(tNotification("accountNotVerifed"));
        } else DisplayErrorNotification(tNotification("wrongEmailOrPassword"));
      },
    });
  };

  return (
    <Formik
      initialValues={loginInitValues}
      validationSchema={loginValidationSchema}
      onSubmit={(values) => {
        login(values);
      }}
    >
      {({ handleChange, values, errors, touched }) => (
        <Form>
          <div style={styles.centredContainer}>
            <div style={styles.leftSide}>
              <p
                style={styles.leftSideHeader}
                onClick={() => {
                  setClicked(true);
                  setTimeout(() => {
                    navigate("/register");
                  }, 500);
                }}
                className="pointer"
              >
                {t("register")}
              </p>
            </div>
            <div style={styles.rightSide}>
              <p style={styles.rightSideHeader}>{t("loginTitle")}</p>
            </div>
          </div>
          <div style={styles.centredContainer}>
            <div style={styles.animationContainer}>
              <div
                style={clicked ? styles.onClickAnimation : styles.noAnimation}
              >
                {" "}
              </div>
            </div>
          </div>
          <div style={styles.formContainer}>
            <div style={styles.formContent}>
              <Input
                name="email"
                label={`${t("email")}*`}
                // placeholder={t("emailPlaceholder")}
                type="text"
                value={values.email}
                handleChange={handleChange}
                error={errors.email}
                touched={touched.email}
              />
              <PasswordInput
                name="password"
                label={`${t("password")}*`}
                // placeholder={t("password")}
                value={values.password}
                handleChange={handleChange}
                error={errors.password}
                touched={touched.password}
              />
              <div style={styles.checkbox}>
                <span
                  className="pointer"
                  style={styles.forgotPassword}
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                >
                  {t("forgotPassword")}
                </span>
              </div>
            </div>
          </div>
          <div style={styles.btnContainer}>
            <DefaultButton
              text={t("loginTitle")}
              style={styles.submitBtn}
              type="submit"
              variant="primary"
              loading={isLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

const styles = StyleSheet.create({
  centredContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  animationContainer: {
    marginTop: "-10px",
    width: "656px",
    height: "3px",
    backgroundColor: "rgba(79, 191, 192, 0.3)",
  },
  noAnimation: {
    width: "328px",
    height: "5px",
    marginLeft: "50%",
    marginTop: "-1px",
    backgroundColor: `${colors.secondary}`,
    transition: "all .5s ease 0s",
  },
  onClickAnimation: {
    width: "328px",
    height: "5px",
    marginTop: "-1px",
    marginLeft: "0%",
    backgroundColor: `${colors.secondary}`,
    transition: "all .5s ease 0s",
  },
  btnContainer: {
    textAlign: "center",
  },
  submitBtn: {
    width: "300px",
    height: "63px",
    background: "#07285D",
    border: "0",
    borderRadius: "4px",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "19px",
    letterSpacing: "0.07em",
    color: "#FFFFFF",
    margin: "15px 0 30px 0",
    justifyContent: "center",
  },
  forgotPassword: {
    fontFamily: "d-din",
    fontStyle: "italic",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    color: "#4FBFC0",
    textDecoration: "underline",
    marginTop: "5px",
  },
  formContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "27px",
  },
  formContent: {
    width: "315px",
  },
  checkbox: {
    display: "flex",
    justifyContent: "right",
    marginRight: "8px",
  },
  checkBoxText: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "13px",
    color: "#808795",
  },
  leftSide: {
    marginTop: "17px",
    textAlign: "center",
    display: "inline",
    width: "328px",
    position: "relative",
  },
  rightSide: {
    marginTop: "17px",
    textAlign: "center",
    display: "inline",
    width: "328px",
  },
  leftSideHeader: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "26.06px",
    letterSpacing: "0.07em",
    color: "#3F3F3F",
    textAlign: "center",
    width: "100%",
  },
  rightSideHeader: {
    fontFamily: "d-din",
    fontStyle: "bold",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "26.06px",
    letterSpacing: "0.07em",
    color: "#3F3F3F",
    textAlign: "center",
    width: "100%",
  },
});
