import React, { useEffect, useMemo, useState } from "react";
import { LinkedinIcon, FacebookIcon, GoogleIcon, ArrowLeft } from "./icons";
import Icon, {
  ArrowLeftOutlined,
  RightOutlined,
  HomeOutlined,
  PhoneOutlined,
  GlobalOutlined,
  EnvironmentOutlined,
  LinkOutlined,
  CheckCircleFilled,
  CloseOutlined,
  FilePdfFilled,
  FilePptFilled,
} from "@ant-design/icons";
import { Avatar, Button, Col, Row, Collapse, Progress } from "antd";
import style from "./styles/style.module.css";
import { StarRating } from "../../../../components";
import { colors } from "../../../../constants/colors";
import { useLocation, useNavigate } from "react-router";
import { OrganisationsData } from "../../../../hooks/api/Organisations";
import { InviteSupplier } from "../../../Buyer/components/InviteSupplier";
import { useTranslation } from "../../../../hooks/useTranslation";
import { SupplierData, useGetLogoUrl } from "../../../../hooks/api/Suppliers";
const { Panel } = Collapse;

// import { isObject } from "formik";
type Props = {
  data: SupplierData | undefined;
};

type orgBasicInfoType = {
  name: string;
  averageRating: number;
  totalReviews: number;
};

const BusinessInfo = (props: Props) => {
  const { data: organisation } = props;
  // const BusinessInfo = ({
  //   organisation,
  // }: {
  //   organisation: OrganisationsData | undefined;
  // }) => {
  const [viewNumber, setViewNumber] = useState(false);
  const [viewWebsite, setViewWebsite] = useState(false);
  const [orgBasicInfo, setOrgBasicInfo] = useState<orgBasicInfoType>();
  const { t } = useTranslation("supplierProfilePage");
  const logoUrlKey = localStorage.getItem("logoUrlKey");
  const filteredLogoUrlKey = logoUrlKey?.replace("/", "%2F");
  const { data: logoUrl } = useGetLogoUrl(
    filteredLogoUrlKey ? filteredLogoUrlKey : ""
  );
  //   const founded = organisation?.registrationDate?.substring(0, 4);
  const navigate = useNavigate();

  //   const reviewsNumber = useMemo(
  //     () => organisation?.reviews.length || 0,
  //     [organisation]
  //   );

  //   const overallRate = useMemo(() => {
  //     if (organisation?.reviews) {
  //       return (
  //         Math.round(
  //           (organisation?.reviews
  //             .map((review) => review.overallRating)
  //             .reduce((a, b) => a + b, 0) /
  //             organisation?.reviews.length) *
  //             2
  //         ) / 2
  //       );
  //     }
  //     return 0;
  //   }, [organisation]);

  // useEffect(() => {
  //   console.log(organisation);
  // }, []);

  useEffect(() => {
    const localStoredOrgInfo = localStorage.getItem("orgInfo");
    if (localStoredOrgInfo) {
      const orgData = JSON.parse(localStoredOrgInfo) as orgBasicInfoType;
      setOrgBasicInfo(orgData);
    }
  }, []);

  const numberHandler = () => {
    if (viewNumber === true) {
      setViewNumber(false);
    } else {
      setViewNumber(true);
    }
  };
  const webisteHandler = () => {
    if (viewWebsite === true) {
      setViewWebsite(false);
    } else {
      setViewWebsite(true);
    }
  };
  return (
    <>
      <div className={style.topContainerColumn}>
        <Row>
          <Col md={19} lg={19}>
            <div>
              <div className={style.topLeftContainer}>
                <div>
                  <Avatar
                    src={logoUrl}
                    className={style.orgLogo}
                    shape="circle"
                    size={100}
                  >
                    <span className={style.iconText}>
                      {/* {organisation?.name[0]} */}
                    </span>
                  </Avatar>
                </div>
                <div>
                  <p className={style.supplierName}>{orgBasicInfo?.name}</p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "14px",
                    }}
                  >
                    <EnvironmentOutlined className={style.detailsIcon} />
                    <p className={style.text}>
                      {organisation?.organisationData?.streetName}
                      {", "}
                      {organisation?.organisationData?.postalCode}{" "}
                      {organisation?.organisationData?.city}
                    </p>
                  </div>
                  <div className={style.contactDetails}>
                    <PhoneOutlined className={style.detailsIcon} />
                    {!viewNumber && (
                      <>
                        <p className={style.number} onClick={numberHandler}>
                          {t("viewPhoneNumber")}
                        </p>
                      </>
                    )}
                    {viewNumber && (
                      <>
                        <p className={style.text} onClick={numberHandler}>
                          {organisation?.organisationData?.phoneNumber}
                        </p>
                      </>
                    )}
                    <LinkOutlined
                      style={{ color: "#808795" }}
                      className={style.detailsIcon}
                    />
                    {!viewWebsite && (
                      <>
                        <p
                          className={style.websiteText}
                          onClick={webisteHandler}
                        >
                          {t("website")}
                        </p>
                      </>
                    )}
                    {viewWebsite && (
                      <>
                        {organisation?.organisationData?.websiteURL !==
                        "Information saknas" ? (
                          <a
                            className={style.websiteText}
                            onClick={() => {
                              if (organisation?.organisationData?.websiteURL) {
                                parent.open(
                                  `https://${organisation?.organisationData?.websiteURL}/`
                                );
                              }
                              webisteHandler();
                            }}
                          >
                            {organisation?.organisationData?.websiteURL}
                          </a>
                        ) : (
                          <p className={style.text} onClick={webisteHandler}>
                            Information saknas!
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={5} lg={5} className={style.rightContainer}>
            <div className={style.columnContainer}>
              <div className={style.ratingContainer}>
                <StarRating
                  color={colors.primary}
                  disabled={true}
                  size={24}
                  //   value={isNaN(overallRate) ? 0.0 : overallRate}
                  value={orgBasicInfo?.averageRating}
                />
                <p className={style.ratingText}>
                  {/* {isNaN(overallRate) ? "0" : overallRate} */}
                  {orgBasicInfo?.averageRating.toString().slice(0, 3)}
                </p>
                <span className={style.ratingText}>
                  {/* {"("}
                  {reviewsNumber <= 1000
                    ? reviewsNumber
                    : `${Math.round(reviewsNumber / 1000)}k`}
                  {")"} */}
                  {/* {orgBasicInfo?.totalReviews} */}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* middleContainer */}

      <div className={style.topMiddleContainer}>
        {/* <Row> */}
        {/* <Col xs={24} sm={15} md={17} lg={17}> */}
        <div className={style.topMiddleLeftContainer}>
          <p className={style.heading}> {t("description")}</p>
          <p className={style.content}>
            {organisation?.organisationData?.description}
          </p>
        </div>
        {/* </Col> */}
        {/* <Col xs={24} sm={9} md={7} lg={7} className={style.rightContainer}> */}
        <div className={style.columnContainer}>
          <p className={style.socialHeading}> {t("socials")}</p>
          <div className={style.topMiddleRightContainer}>
            <div>
              <Icon
                component={() => (
                  <img src={LinkedinIcon} className={style.socialIcons} />
                )}
              />
              <a
                target="_blank"
                rel="noreferrer"
                href={organisation?.organisationData?.linkedinURL}
              >
                {organisation?.organisationData?.linkedinURL}
              </a>
            </div>
            <div>
              <Icon
                component={() => (
                  <img src={FacebookIcon} className={style.socialIcons} />
                )}
              />
              <a
                target="_blank"
                rel="noreferrer"
                href={organisation?.organisationData?.facebookURL}
              >
                {organisation?.organisationData?.facebookURL}
              </a>
            </div>
            <div>
              <Icon
                component={() => (
                  <img src={GoogleIcon} className={style.socialIcons} />
                )}
              />
              <a
                target="_blank"
                rel="noreferrer"
                href={organisation?.organisationData?.googleURL}
              >
                {organisation?.organisationData?.googleURL}
              </a>
            </div>
          </div>
        </div>
        {/* </Col> */}
        {/* </Row> */}
      </div>
      {/* Last Container */}
      <div className={style.topLastContainer}>
        <div className={style.topLastContainerContent}>
          <p className={style.bottomHeading}>{t("founded")}</p>
          {/* <p className={style.bottomText}>{founded}</p> */}
          <p className={style.bottomText}>
            {organisation?.organisationData?.registrationDate
              ?.toString()
              .substring(0, 10)}
          </p>
        </div>
        {/* <div className={style.topLastContainerContent}>
          <p className={style.bottomHeading}>{t("organisationNumber")}</p>
          <p className={style.bottomText}>{organisation?.organisationNumber}</p>
          <p className={style.bottomText}>1950929399494</p>
        </div> */}
        <div className={style.topLastContainerContent}>
          <p className={style.bottomHeading}>{t("companySize")}</p>
          {/* <p className={style.bottomText}>{organisation?.employeesNumber}</p> */}
          <p className={style.bottomText}>
            {organisation?.organisationData?.SME}
          </p>
        </div>
        <div className={style.topLastContainerContent}>
          <p className={style.bottomHeading}>{t("annualRevenue")}</p>
          {/* <p className={style.bottomText}>{organisation?.turnover}</p> */}
          <p className={style.bottomText}>
            {organisation?.organisationData?.turnover}
          </p>
        </div>
        <div className={style.topLastContainerContent}>
          <p className={style.bottomHeading}>{t("companyType")}</p>
          {/* <p className={style.bottomText}>{organisation?.organisationForm}</p> */}
          <p className={style.bottomText}>
            {organisation?.organisationData?.organisationForm}
          </p>
        </div>
      </div>
      <div className={style.mediaContainer}>
        <div className={style.fileListContainer}>
          <div className={style.fileListBox}>
            <div className={style.titleBox}>
              <FilePdfFilled className={style.fileIcon} />
              <p>GreenView_Certificate_ISS_Facility_Services_2020(1).pdf</p>
            </div>
            <CloseOutlined className={style.fileCloseIcon} />
          </div>
          <div className={style.fileListBox}>
            <div className={style.titleBox}>
              <FilePdfFilled className={style.fileIcon} />
              <p>Certificate_ISS_Facility_Services_2020(1).pdf</p>
            </div>
            <CloseOutlined className={style.fileCloseIcon} />
          </div>
          <div className={style.fileListBox}>
            <div className={style.titleBox}>
              <FilePdfFilled className={style.fileIcon} />
              <p>Facility_Services_2020(1).pdf</p>
            </div>
            <CloseOutlined className={style.fileCloseIcon} />
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default BusinessInfo;
