import React from "react";
import { Col, Row, Image } from "antd";
import footerStyle from "./styles/footer.module.css";
import logo from "../../../assets/images/Footerlogo.png";
import linkedinLogo from "../../../assets/images/linkedinLogo.png";

const FooterBar = () => {
  return (
    <>
      <div className={footerStyle.footerSpacer} />
      <Row className={footerStyle.container}>
        <Col xs={5} md={3}>
          <div className={footerStyle.nameLogoContainer}>
            <Image src={logo} className={footerStyle.logo} />
            <p className={footerStyle.brandName}>SUNT</p>
          </div>
        </Col>
        <Col xs={6} md={4}>
          <div className={footerStyle.linkedinContainer}>
            <p className={footerStyle.content}>Följ oss på</p>
            <img
              src={linkedinLogo}
              className={footerStyle.linkedinLogo}
              alt="LinkedinLogo"
            />
          </div>
        </Col>
        <Col xs={7} md={10}>
          <div className={footerStyle.middleContainer}>
            <p className={footerStyle.middleContent}>
              Integritetspolicy | Användarvillkor | Kundtjänst
            </p>
          </div>
        </Col>
        <Col xs={6} md={7}>
          <div className={footerStyle.copyrightContainer}>
            <p className={footerStyle.copyrightContent}>
              © 2022 SUNT AB. Alla rättigheter reserverade.
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default FooterBar;
