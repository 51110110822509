import React from "react";
import { Department } from "./components/Department";
import { SniLevel1 } from "./components/SniLevel1";
import { SniLevel2 } from "./components/SniLevel2";
import { SniLevel3 } from "./components/SniLevel3";
import { SniLevel4 } from "./components/SniLevel4";

export const AddSni = () => {
  return (
    <>
      <Department />
      <SniLevel1 />
      <SniLevel2 />
      <SniLevel3 />
      <SniLevel4 />
    </>
  );
};
