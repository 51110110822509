import React, { useMemo } from "react";
import { StyleSheet } from "../../../models/StyleSheet";
import { OrganisationsData } from "../../../hooks/api/Organisations";
import { useTranslation } from "../../../hooks/useTranslation";
import { ProgressBar } from "../../../components";
import { Col, Progress, Row, Tooltip, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { red, green } from "@ant-design/colors";
import cssStyles from "./styles/style.module.css";

export const RatingContainer = ({
  suntCategoryId,
  organisation,
}: {
  suntCategoryId: string;
  organisation: OrganisationsData | undefined;
}) => {
  const { t } = useTranslation("supplierReviews");

  const roundRate = (
    name:
      | "pricingRating"
      | "qualityRating"
      | "innovationRating"
      | "sustainabilityRating"
      | "customerServiceRating"
      | "deliverCapacity"
  ) => {
    if (organisation?.reviews.length) {
      const reviews = organisation?.reviews
        .map((review) => review[name])
        .filter((review) => review !== 0)
        .reduce((a, b) => Number(a) + Number(b), 0);
      const len = organisation?.reviews
        .map((review) => review[name])
        .filter((review) => review !== 0).length;
      return reviews !== null && len !== 0 ? (reviews / len).toFixed(1) : null;
    }
    return 0;
  };

  const qualityRate = roundRate("qualityRating");
  const customerServiceRate = roundRate("customerServiceRating");
  const costRate = roundRate("pricingRating");
  const innovationRate = roundRate("innovationRating");
  const sustainRate = roundRate("sustainabilityRating");
  const deliveryCapRate = roundRate("deliverCapacity");

  const popoverContent = (
    <div className={cssStyles.popoverBox} id="popvoerSuplierOverview">
      <p>
        <strong>1 poäng</strong> = Leverantören <strong>uppfyller inte</strong>
        &nbsp;kraven. Detta har mätts och kan bevisas.
      </p>
      <p>
        <strong>3 poäng</strong> = Leverantören{" "}
        <strong>uppfyller troligtvis</strong> kraven, men detta har inte mätts
        och kan inte bevisas.
      </p>
      <p>
        <strong>5 poäng</strong> = Leverantören <strong>uppfyller</strong>{" "}
        kraven. Detta har mätts och kan bevisas.
      </p>
    </div>
  );

  const popoverChange = () => {
    // try to access the popover parent node through the grand child
    // For change the border radius of the parent node.
    const element = document.querySelectorAll("#popvoerSuplierOverview");
    if (element.length > 0) {
      const popoverContainer = element[0]?.parentElement?.parentElement;
      if (popoverContainer) popoverContainer.style.borderRadius = "10px";
    } else {
      setTimeout(() => {
        const element = document.querySelectorAll("#popvoerSuplierOverview");
        const popoverContainer = element[0]?.parentElement?.parentElement;
        if (popoverContainer) popoverContainer.style.borderRadius = "10px";
      }, 500);
    }
  };

  return (
    <>
      <div style={styles.ratingContainer} className={cssStyles.ratingBox}>
        <div className={cssStyles.headingRow}>
          <h2 className={cssStyles.heading}>Omdöme</h2>
          <Popover content={popoverContent} placement="bottom" trigger="hover">
            <InfoCircleOutlined
              style={styles.infoIcon}
              onMouseEnter={popoverChange}
            />
          </Popover>
        </div>
        <Row align="middle" style={styles.RowStyle}>
          <Col md={12} lg={8}>
            <div style={styles.subContainer}>
              <ProgressBar label={t("quality")} rate={qualityRate} />
              <ProgressBar label={t("sustainability")} rate={sustainRate} />
            </div>
          </Col>
          <Col md={12} lg={8}>
            <div style={styles.subContainer}>
              <ProgressBar
                label={t("deliveryCapacity")}
                rate={deliveryCapRate}
              />
              <ProgressBar
                label={t("customerService")}
                rate={customerServiceRate}
              />
            </div>
          </Col>
          <Col md={12} lg={8}>
            <div style={styles.subContainer}>
              <ProgressBar label={t("cost")} rate={costRate} />
              <ProgressBar label={t("innovation")} rate={innovationRate} />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  ratingContainer: {
    width: "100%",
    // padding: "0px 0px 16px 0px",
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    marginTop: "15px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
  },
  RowStyle: {
    padding: "0px 10px 10px 25px",
  },
  btn: {
    width: "100%",
    marginTop: "28px",
    padding: "8px 0",
  },
  subContainer: {
    margin: "0px 0px 10px 0px",
  },
  infoIcon: {
    fontSize: "20px",
    color: "#CDD4E2",
    padding: "1px 0px 0px 8px",
    cursor: "pointer",
  },
});
