import { useQuery } from "react-query";
import { API } from "../../services/API";
import { SuntCategoriesData } from "./Category";

export interface OrganisationReview {
  overallRating: number;
  pricingRating: number | null;
  qualityRating: number | null;
  innovationRating: number | null;
  sustainabilityRating: number | null;
  customerServiceRating: number | null;
  deliverCapacity: number | null;
}

export interface OrganisationsData {
  id: number;
  organisationId?: number;
  name: string;
  employeesNumber: number;
  address: string;
  streetName?: string;
  city: string;
  province?: string;
  country?: string;
  postalCode?: string;
  phoneNumber?: string;
  numberOfReviews?: number;
  averageRating?: number;
  mobile?: string;
  organisationNumber?: string;
  organisationForm?: string;
  turnover?: number;
  registrationDate?: string;
  SME?: string;
  websiteURL?: string;
  facebookURL?: string;
  linkedinURL?: string;
  googleURL?: string;
  services?: string;
  suntCategories?: string;
  secondLevelCategories?: string;
  branschSNI?: string;
  secondLevelSNI?: string;
  contactInfo?: string;
  profileInfo: JSON;
  description?: string;
  createdAt: Date;
  updatedAt: Date;
  location: string;
  reviews: Array<OrganisationReview>;
  logoURL?: string;
  organisationsCategories?: [
    {
      category?: {
        suntCategoryId?: number;
        suntCategoryDetailed?: string;
        swedishName?: string;
        suntCategory?: {
          name?: string;
        };
      };
    }
  ];
  attachments?: [
    {
      id: number;
      organisationId: number;
      title: string;
      pdfUrl: string;
    }
  ];
}

interface QueryParams {
  id: number;
  suntCategoryId: number;
}

interface Params {
  id: number;
}

export interface ListResponseData {
  data: OrganisationsData[];
  count: number;
}

export interface OrganisationWithSuntCategory {
  id: number;
  organisationsCategories: {
    category: {
      suntCategory: {
        id: number;
      };
    };
  }[];
}

const getOrganisation = async (params: Params) => {
  const url = `/supplier/organisations/${params?.id}`;
  const { data }: { data: OrganisationsData | undefined } =
    await API.get<OrganisationsData>(url);

  return data;
};

export function useGetOrganisation(params: Params) {
  return useQuery(["getOrganisation", params], () => getOrganisation(params));
}

const getOrganisations = async (params: QueryParams) => {
  const url = `/categories/${params?.suntCategoryId}/organisations/${params?.id}`;
  const { data }: { data: OrganisationsData | undefined } =
    await API.get<OrganisationsData>(url);
  return data;
};

export function useGetOrganisations(params: QueryParams) {
  return useQuery(
    ["getOrganisations", params],
    () => getOrganisations(params),
    { enabled: !!params.id && !!params.suntCategoryId }
  );
}
const getOrganisationsIncludeReviews = async (params: QueryParams) => {
  const url = `/categories/${params?.suntCategoryId}/organisations/${params?.id}/include-reviews`;
  const { data }: { data: OrganisationsData | undefined } =
    await API.get<OrganisationsData>(url);

  return data;
};

export function useGetOrganisationsIncludeReviews(params: QueryParams) {
  return useQuery(["getOrganisationsIncludeReviews", params], () =>
    getOrganisationsIncludeReviews(params)
  );
}

const getOrganisationWithReviews = async (params: Params) => {
  const url = `/categories/organisations/${params?.id}/with-reviews`;
  const { data }: { data: OrganisationsData | undefined } =
    await API.get<OrganisationsData>(url);

  return data;
};

export function useGetOrganisationWithReviews(params: Params) {
  return useQuery(["getOrganisationWithReviews", params], () =>
    getOrganisationWithReviews(params)
  );
}

const getOrganisationsByName = async ({
  name,
  ids,
}: {
  name: string;
  ids: number[];
}) => {
  const url = "/organisations/organisations-withCategories-ByName";
  const { data }: { data: { name: string; id: number }[] } = await API.get(
    url,
    { params: { name, ids } }
  );
  return data;
};
export const useGetOrganisationsByName = ({
  name,
  ids,
}: {
  name: string;
  ids: number[];
}) => {
  return useQuery(["getOrganisationsByName", name, ids], () =>
    getOrganisationsByName({ name, ids })
  );
};

const getCities = async (suntCategoryId: string) => {
  const url = `/organisations/${suntCategoryId}/get-cities`;
  const { data } = await API.get<unknown, { city: string }[]>(url);
  return data;
};
export const useGetCities = (suntCategoryId: string) => {
  return useQuery(["getCities", suntCategoryId], () =>
    getCities(suntCategoryId)
  );
};

const getTags = async (suntCategoryId: string) => {
  const url = `/organisations/${suntCategoryId}/get-tags`;
  const { data } = await API.get<unknown, { tag: string }[]>(url);
  return data;
};
export const useGetTags = (suntCategoryId: string) => {
  return useQuery(["getTags", suntCategoryId], () => getTags(suntCategoryId));
};

const getPosition = async (suntCategoryId: string) => {
  const url = `/organisations/${suntCategoryId}/get-position`;
  const { data } = await API.get<unknown, { position: string }[]>(url);
  return data;
};
export const useGetPosition = (suntCategoryId: string) => {
  return useQuery(["getPosition", suntCategoryId], () =>
    getPosition(suntCategoryId)
  );
};

const getCompanyType = async (suntCategoryId: string) => {
  const url = `/organisations/${suntCategoryId}/get-Type`;
  const { data } = await API.get<unknown, { organisationForm: string }[]>(url);
  return data;
};
export const useGetCompanyType = (suntCategoryId: string) => {
  return useQuery(["getCompanyType", suntCategoryId], () =>
    getCompanyType(suntCategoryId)
  );
};
const getOther = async (suntCategoryId: string) => {
  const url = `/organisations/${suntCategoryId}/get-Other`;
  const { data } = await API.get<unknown, { SME: string }[]>(url);
  return data;
};
export const useGetOther = (suntCategoryId: string) => {
  return useQuery(["getOther", suntCategoryId], () => getOther(suntCategoryId));
};

const getMaximumTurnover = async (suntCategoryId: string) => {
  const url = `/organisations/${suntCategoryId}/get-turnover-max`;
  const { data } = await API.get<unknown, { maximumTurnover: number }>(url);
  return data;
};
export const useGetMaximumTurnover = (suntCategoryId: string) => {
  return useQuery(["getMaximumTurnover", suntCategoryId], () =>
    getMaximumTurnover(suntCategoryId)
  );
};
