import React from "react";
import {
  Input as AntInput,
  Typography,
  Row,
  InputProps as AntInputProps,
} from "antd";
import { StyleSheet } from "../../models/StyleSheet";

interface InputProps extends AntInputProps {
  title: string;
  error?: string | undefined;
}

export function Input({ title, onChange, value, error }: InputProps) {
  const { Text } = Typography;
  return (
    <Row className="component-row">
      <AntInput
        style={styles.input}
        placeholder={title}
        onChange={onChange}
        value={value}
      />
      {error && <Text type="danger">{error}</Text>}
    </Row>
  );
}

const styles = StyleSheet.create({
  input: {
    paddingLeft: 0,
    fontFamily: "D-DIN",
  },
});
