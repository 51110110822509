import React from "react";
import { HeaderBar } from "../../../../components/HeaderBar";
import { StyleSheet } from "../../../../models/StyleSheet";
import { DefaultButton } from "../../../../components";
import { Form, Formik } from "formik";
import { initialValues, validationSchema } from "./InteractNotRegistered.utils";
import { usePostInteract } from "../../../../hooks/api/Interact";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../../services/notificationService";
import { useNavigate } from "react-router";
import { useTranslation } from "../../../../hooks/useTranslation";

export const InteractNotRegistered = () => {
  const { mutate } = usePostInteract();

  const navigate = useNavigate();

  const { t } = useTranslation("interactPage");

  const onSubmit = (value: {
    email: string;
    subject: string;
    message: string;
  }) => {
    mutate(
      {
        receivers: [value.email],
        subject: value.subject,
        suppliersIds: [],
        text: value.message,
      },
      {
        onSuccess: () => {
          DisplaySuccessNotification(t("success"));
          navigate("/");
        },
        onError: () => {
          DisplayErrorNotification(t("error"));
        },
      }
    );
  };

  return (
    <>
      <HeaderBar header={t("inviteToSunt")} text={t("inviteToSuntDesc")} />
      <div style={styles.container}>
        <Formik
          onSubmit={(values) => {
            onSubmit(values);
          }}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, handleChange, errors, touched }) => (
            <Form>
              <div style={styles.smallerContainer}>
                <p style={styles.header}>{t("supplier")}</p>
                <p style={styles.text}>{t("supplierDescription")}</p>
                <input
                  placeholder={t("email")}
                  style={
                    errors.email && touched.email
                      ? { ...styles.input, ...styles.errorInput }
                      : styles.input
                  }
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                />
                {errors.email && touched.email ? (
                  <div style={styles.error}>{errors.email}</div>
                ) : null}
              </div>
              <div style={styles.smallerContainer}>
                <p style={styles.header}>{t("subject")}</p>
                <p style={styles.text}>{t("addTopic")}</p>
                <input
                  placeholder={t("write")}
                  style={
                    errors.subject && touched.subject
                      ? { ...styles.input, ...styles.errorInput }
                      : styles.input
                  }
                  name="subject"
                  onChange={handleChange}
                  value={values.subject}
                />
                {errors.subject && touched.subject ? (
                  <div style={styles.error}>{errors.subject}</div>
                ) : null}
              </div>
              <div style={styles.smallerContainer}>
                <p style={styles.header}>{t("text")}</p>
                <p style={styles.text}>{t("textDescription")}</p>
                <textarea
                  placeholder={t("write")}
                  style={styles.textArea}
                  name="message"
                  onChange={handleChange}
                  value={values.message}
                ></textarea>
              </div>
              <div style={styles.btnContainer}>
                <DefaultButton
                  text={t("cancel")}
                  style={styles.btn}
                  type="button"
                  onClick={() => navigate("/")}
                  variant="secondary"
                />
                <DefaultButton
                  text={t("submit")}
                  style={styles.btn}
                  type="submit"
                  variant="primary"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
    minWidth: "800px",
    width: "80vw",
    margin: "32px auto 0",
    padding: "32px",
  },
  header: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "22px",
    letterSpacing: "0.07em",
    margin: "0 0 10px",
  },
  text: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#808795",
    marginBottom: "32px",
  },
  input: {
    width: "352px",
    height: "32px",
    backgroundColor: " #F2F2F5",
    borderRadius: "4px",
    outline: "none",
    border: "none",
    padding: "6px 18px",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#808795",
  },
  smallerContainer: {
    marginBottom: "64px",
  },
  textArea: {
    resize: "none",
    width: "100%",
    height: "96px",
    backgroundColor: " #F2F2F5",
    borderRadius: "4px",
    outline: "none",
    border: "none",
    padding: "6px 18px",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#808795",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  btn: {
    width: "160px",
  },
  errorInput: {
    boxShadow: "0px 1px 10px rgba(256, 0, 0, 0.5)",
  },
  error: {
    textAlign: "left",
    marginLeft: "8px",
    color: "red",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    marginTop: "10px",
  },
});
