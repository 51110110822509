import { useMutation } from "react-query";
import { API } from "../../services/API";

interface interactInterface {
  receivers?: string[];
  suppliersIds?: number[];
  subject?: string;
  link?: string;
  text?: string;
}
interface interactDialogueInterface {
  suppliersIds: number[];
  level: number;
  categoryId: number;
  subject: string;
  link: string;
  message: string;
  suntPolicy: boolean;
}
interface interactDialogueInterfaceSupplier {
  buyersIds: number[];
  level: number;
  categoryId: number;
  subject: string;
  link: string;
  message: string;
  suntPolicy: boolean;
}

interface interactReviewInterfaceSupplier {
  emails: string[];
  subject: string;
  message: string;
}

const postInteract = async (data: interactInterface) => {
  const url = "/interact/invite";
  const { data: response } = await API.post(url, data);
  return response;
};

export const usePostInteract = () => {
  return useMutation(postInteract);
};

const postInteractDialogue = async (params: interactDialogueInterface) => {
  const url = "/interact/dialogue";
  const { data: response } = await API.post(url, params);
  return response;
};

export const usePostInteractDialogue = () => {
  return useMutation(postInteractDialogue);
};

const postInteractDialogueFromSupplier = async (
  params: interactDialogueInterfaceSupplier
) => {
  const url = "/interact/dialogue-supplier";
  const { data: response } = await API.post(url, params);
  return response;
};
export const usePostInteractDialogueFromSupplier = () => {
  return useMutation(postInteractDialogueFromSupplier);
};

const postInteractReviewFromSupplier = async (
  params: interactReviewInterfaceSupplier
) => {
  const url = "/interact/review-supplier";
  const { data: response } = await API.post(url, params);
  return response;
};
export const usePostInteractReviewFromSupplier = () => {
  return useMutation(postInteractReviewFromSupplier);
};
