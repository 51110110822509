import React, { useState, useEffect } from "react";
import { useTranslation } from "../../../hooks/useTranslation";
import style from "./styles/editTopBar.module.css";
import TopBarArrowIcon from "../../../assets/icons/topBarArrow";

type Prop = {
  sectionNo: number;
  navigation: (number: number) => void;
};

const EditTopBar = (prop: Prop) => {
  const { t } = useTranslation("supplierFormTopBar");
  const [moveTo, setMoveTo] = useState<string>("");
  const { sectionNo, navigation } = prop;

  useEffect(() => {
    move(sectionNo);
  }, [sectionNo]);

  const move = (value: number) => {
    setMoveTo(`bar-move-at-${value}`);
  };

  return (
    <>
      <div className={style.header}>
        <div className={style.topbarCenter}>
          <ul className={style.links}>
            <li
              onClick={() => navigation(0)}
              className={`${sectionNo === 0 ? style.linkActive : ""}`}
            >
              {t("editProfile")}
            </li>
            <TopBarArrowIcon />
            <li
              onClick={() => navigation(1)}
              className={`${sectionNo === 1 ? style.linkActive : ""}`}
            >
              {t("selectCategory")}
            </li>
            <TopBarArrowIcon />
            <li
              onClick={() => navigation(2)}
              className={`${sectionNo === 2 ? style.linkActive : ""}`}
            >
              {t("addServiceArea")}
            </li>
            <TopBarArrowIcon />
            <li
              onClick={() => navigation(3)}
              className={`${sectionNo === 3 ? style.linkActive : ""}`}
            >
              {t("requestChange")}
            </li>
            <TopBarArrowIcon />
          </ul>
          <div className={style.bottomLine}>
            <div className={`${style.bar} ${style[moveTo]}`} />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTopBar;
