import React from "react";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import "../../../css/public.css";
import { MinimalistLogo } from "../components/MinimalistLogo";
import { StyleSheet } from "../../../models/StyleSheet";

export function ForgotPassword() {
  return (
    <div>
      <MinimalistLogo style={styles.logo} />
      <ForgotPasswordForm />
    </div>
  );
}

const styles = StyleSheet.create({
  logo: {
    position: "static",
    textAlign: "center",
    paddingTop: "56px",
    paddingBottom: "56px",
  },
});
