import React from "react";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { styles } from "../singup.utils";
import { useTranslation } from "../../../../hooks/useTranslation";

const TERMS_AND_CONDITIONS_LINK = "https://www.sunt.tech/privacy";

export const Bottom = ({
  valueTerms,
  onChange,
  errorTerms,
  touchedTerms,
}: {
  valueTerms: boolean;
  onChange: (e: CheckboxChangeEvent) => void;
  errorTerms: string | undefined;
  touchedTerms: boolean | undefined;
}) => {
  const { t } = useTranslation("signupPage");

  return (
    <div style={styles.bottom}>
      <div style={styles.checkBoxContainer}>
        <Checkbox
          name="termsAndConditions"
          checked={valueTerms}
          onChange={onChange}
        >
          <span style={styles.checkBoxText}>
            {t("acceptTerms")}{" "}
            <a
              href={TERMS_AND_CONDITIONS_LINK}
              target="_blank"
              rel="noopener noreferrer"
              style={styles.link}
            >
              {t("termsAndConditions")}
            </a>
            *
          </span>
        </Checkbox>
        {errorTerms && touchedTerms ? (
          <div style={styles.error}>{errorTerms}</div>
        ) : null}
      </div>
    </div>
  );
};
