import { Menu } from "antd";
import React from "react";
import { StyleSheet } from "../../../models/StyleSheet";

export interface ProfileMenuProps {
  menuItems: { subitem: string; url: string }[];
  setDocument: React.Dispatch<React.SetStateAction<string>>;
  setTouched: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProfileMenu = ({
  menuItems,
  setDocument,
  setTouched,
}: ProfileMenuProps) => {
  function setUrl(url: string) {
    setDocument(url);
  }

  return (
    <div>
      <Menu
        mode="inline"
        items={menuItems.map((item) => {
          return {
            label: (
              <a
                style={styles.link}
                onClick={() => {
                  setUrl(item.url);
                  setTouched(true);
                }}
              >
                {item.subitem}
              </a>
            ),
            key: item.url,
          };
        })}
      ></Menu>
    </div>
  );
};

const styles = StyleSheet.create({
  MenuItem: {
    outline: "none",
    height: "32px",
    border: "0",
    borderRadius: "4px",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    letterSpacing: "0.07em",
    color: "#000000",
    width: "150px",
    padding: "5px 8px",
    margin: "10px 0",
    display: "block",
  },
  link: {
    width: "60px",
    fontSize: "14px",
    margin: "10px",
    hover: {
      backgroundColor: "red",
    },
  },

  linkClicked: {
    width: "60px",
    fontSize: "14px",
    margin: "10px",
    color: "blue",
  },

  categoryContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "1px auto",
    fontSize: "33px",
  },
  container: {
    width: "100%",
    minWidth: "244px",
    minHeight: "calc(100vh - 64px)",
    height: "100%",
    marginBottom: "-24px",
    paddingBottom: "10px",
  },
});
