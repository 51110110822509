/*eslint-disable*/
import React, { useEffect, useState } from "react";
import Attachments from "./Attachments";

import Categories from "./Categories";
import Media from "./Media";
import Services from "./Services";
import style from "./styles/style.module.css";
import TopContainer from "./TopContainer";
import {
  OrganisationsData,
  useGetOrganisation,
  useGetOrganisationsIncludeReviews,
  useGetOrganisationWithReviews,
} from "../../../hooks/api/Organisations";
import { useNavigate, useParams } from "react-router";

const ProfileDetail = () => {
  const navigate = useNavigate();
  const { organisationId } = useParams();

  const { data, isError } = useGetOrganisationWithReviews({
    id: parseInt(organisationId ?? ""),
  });
  if (isError) {
    navigate("/");
  }

  useEffect(() => {
    if (data) {
      const orgData = data as OrganisationsData;
      const orgInfo: {
        name: string;
        averageRating: number | undefined;
        totalReviews: number | undefined;
        registeredDate: string | undefined;
      } = {
        name: orgData.name,
        averageRating: orgData.averageRating,
        totalReviews: orgData.numberOfReviews,
        registeredDate: orgData.registrationDate,
      };
      localStorage.setItem("orgInfo", JSON.stringify(orgInfo));
    }
  }, [data]);

  return (
    <>
      <div className={style.container}>
        <div className={style.topContainer}>
          <TopContainer organisation={data} />
        </div>
        <div className={style.categoryContainer}>
          <Categories organisation={data} />
        </div>
        <div className={style.servicesContainer}>
          <Services organisation={data} />
        </div>
        <div className={style.pdfAttachments}>
          <Media />
        </div>
        {/* <div className={style.mediaAttachments}>
          <Media />
        </div> */}
      </div>
    </>
  );
};

export default ProfileDetail;
