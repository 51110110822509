import React, { useCallback, useMemo } from "react";
import { Button, Col, Row } from "antd";
import { StyleSheet } from "../../../../models/StyleSheet";

export function Header() {
  return (
    <>
      <Button style={styles.button} type="text" block={true}>
        <Row style={styles.container}>
          <Col span={4}>
            <p style={styles.heading}> Full Name</p>
          </Col>
          <Col span={2}>
            <p style={styles.heading}> Role</p>
          </Col>
          <Col span={4}>
            <p style={styles.heading}> Email</p>
          </Col>
          <Col span={4}>
            <p style={styles.heading}> Phone Number</p>
          </Col>
          <Col span={3}>
            <p style={styles.heading}> User Verified</p>
          </Col>
          <Col span={3}>
            <p style={styles.heading}>Admin Verified</p>
          </Col>
          <Col span={2}>
            <p style={styles.heading}>Review Count</p>
          </Col>
          <Col span={2}>
            <p style={styles.heading}> Verify</p>
          </Col>
        </Row>
      </Button>
    </>
  );
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: "white",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.2)",
    color: "black",
    height: "58px",
    paddingLeft: "0",
    margin: "10px 5px",
    // paddingRight: 20,
  },
  container: {
    alignItems: "center",
  },
  heading: {
    fontSize: "18px",
    paddingTop: "7px",
    color: "black",
    letterSpacing: "0.07em",
  },
  info: {
    fontSize: "16px",
    color: "black",
    letterSpacing: "0.07em",
  },
});
