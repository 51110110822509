import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { PendingRequests } from "../containers/Admin/PendingRequests";
import { Dashboard } from "../containers/Admin/Dashboard";
import { UserVerification } from "../containers/Admin/UserVerification";
import { AddSubCategory } from "../containers/Admin/AddCategory";
import { AddSni } from "../containers/Admin/AddSni";
import { ShowUsers } from "../containers/Admin/ShowUsers";
import { AdminVerification } from "../containers/Admin/AdminVerification";

export function AdminRoutes() {
  return (
    <>
      <Routes>
        <Route path="/*" element={<Navigate to="/dashboard" />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/show-pending-requests" element={<PendingRequests />} />
        <Route
          path="/on-call-user-verification"
          element={<UserVerification />}
        />
        <Route path="/admin-verification" element={<AdminVerification />} />
        <Route path="/add-category" element={<AddSubCategory />} />
        <Route path="/add-sni" element={<AddSni />} />
        <Route path="/show-users" element={<ShowUsers />} />
      </Routes>
    </>
  );
}
