import React, { createContext } from "react";
import { OrganisationFilterInterface } from "../containers/Buyer/OrganisationFilter/OrganisationFilterForm.utils";

interface ContextProps {
  query: [OrganisationFilterInterface | undefined, boolean | undefined];
  setQuery: React.Dispatch<
    React.SetStateAction<
      [OrganisationFilterInterface | undefined, boolean | undefined]
    >
  >;
  activatedSuppliers: number[];
  setActivatedSuppliers: React.Dispatch<React.SetStateAction<number[]>>;
}
export const FilterContext = createContext<ContextProps | undefined>(undefined);

export const FilterProvider = ({
  children,
  value,
}: {
  children: JSX.Element;
  value: ContextProps;
}) => {
  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};
