import React, { useContext, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { useTranslation } from "../../../hooks/useTranslation";
import { StyleSheet } from "../../../models/StyleSheet";
import { useNavigate } from "react-router";
import { FilterContext } from "../../../context/FilterProvider";
import cssStyle from "./styles/inviteSupplier.module.css";

export const InviteSupplier = ({
  style,
}: {
  style?: React.CSSProperties | undefined;
}) => {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const { t } = useTranslation("inviteButton");
  const filterContextState = useContext(FilterContext);

  const items = (
    <Menu
      style={style}
      items={[
        {
          key: "1",
          label: t("dialogue"),
          onClick: () => {
            navigate("/invite-to-dialogue", {
              state: {
                activatedSupplier: filterContextState?.activatedSuppliers
                  ? filterContextState.activatedSuppliers
                  : [],
              },
            });
          },
        },
        {
          key: "2",
          label: t("tender"),
          onClick: () => {
            navigate("/invite-to-tender", {
              state: {
                activatedSupplier: filterContextState?.activatedSuppliers
                  ? filterContextState.activatedSuppliers
                  : [],
              },
            });
          },
        },
        {
          key: "3",
          label: t("sunt"),
          onClick: () => {
            navigate("/invite-to-sunt");
          },
        },
      ]}
    />
  );

  return (
    <Dropdown
      overlayClassName={`${cssStyle.dropdownBox}`}
      overlay={items}
      onVisibleChange={() => {
        setHover(!hover);
      }}
    >
      <Button style={styles.topButton} onClick={() => {}}>
        {t("inviteSupplier")}
        <RightOutlined
          style={hover ? { ...styles.icon, ...styles.iconHover } : styles.icon}
        />
      </Button>
    </Dropdown>
  );
};

const styles = StyleSheet.create({
  topButton: {
    fontFamily: "D-Din",
    fontStyle: "normal",
    backgroundColor: "#07285D",
    borderRadius: "25px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#FFFFFF",
    width: "220px",
    border: "0",
    height: "32px",
  },
  icon: {
    position: "absolute",
    right: "15px",
    top: "8px",
    transition: "0.2s",
  },
  iconHover: {
    transform: "rotate(90deg)",
  },
});
