import React from "react";
import { Col, Row, Progress } from "antd";
import { StyleSheet } from "../../../models/StyleSheet";
import { ProfileInfo } from "./ProfileInfo";
import { ProfileStage } from "./ProfileStage";
import { SelectedForYou } from "./SelectedForYou";
import { useTranslation } from "../../../hooks/useTranslation";
import { useGetSupplierCompany } from "../../../hooks/api/Suppliers";

export const SupplierProfile = () => {
  const { t } = useTranslation("supplierProfile");
  const { data } = useGetSupplierCompany();
  return (
    <>
      <Row justify="space-between">
        <Col span={6} style={styles.profileInfo}>
          <ProfileInfo data={data} />
        </Col>
        <Col span={17}>
          <ProfileStage />
          <SelectedForYou />
        </Col>
      </Row>
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
    height: "72px",
    alignItems: "center",
    margin: "0px -24px 32px",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 30px",
    alignContent: "end",
  },
  headerText: {
    margin: "0 24px ",
  },
  profileInfo: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    padding: "24px",
  },
});
