import { LeftOutlined } from "@ant-design/icons";
import { Col, Row, Collapse } from "antd";
import React from "react";
import { OrganisationsData } from "../../../hooks/api/Organisations";
import SDStyle from "./style.module.css";
import { useTranslation } from "../../../hooks/useTranslation";
const Categories = ({
  organisation,
}: {
  organisation: OrganisationsData | undefined;
}) => {
  const { t } = useTranslation("supplierProfilePage");

  return (
    <>
      <Collapse
        bordered={false}
        className={SDStyle.collapsePan}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <LeftOutlined
            rotate={isActive ? -90 : 0}
            className={SDStyle.loadMoreArrow}
          />
        )}
      >
        <Collapse.Panel
          key={1}
          header={
            <div className={SDStyle.mediaTopContainer}>
              <p className={SDStyle.categoryHeading}>{t("category")}</p>
            </div>
          }
        >
          <div className={SDStyle.capsuleBox}>
            <div className={SDStyle.capsuleBtn}>
              <span>
                {
                  organisation?.organisationsCategories?.[0].category
                    ?.suntCategory?.name
                }
              </span>
              <div className={SDStyle.closeIcon}></div>
            </div>

            <div className={SDStyle.capsuleBtn}>
              <span>
                {
                  organisation?.organisationsCategories?.[0].category
                    ?.suntCategoryDetailed
                }
              </span>
              <div className={SDStyle.closeIcon}></div>
            </div>
          </div>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default Categories;
