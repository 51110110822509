import React from "react";
import "antd/dist/antd.css";
import { Layout } from "antd";
import { TopBar } from "../../router/containers/MainContainer/components/TopBar";
import { StyleSheet } from "../../models/StyleSheet";
import { useAuthState } from "../../hooks/useAuthState";
import { Onboarding } from "../../containers/Common/Onboarding";

const { Content } = Layout;

export const OnboardingChecker = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const { user } = useAuthState();
  if (!user?.isOnboarded) {
    return (
      <Layout className="main-layout">
        <TopBar />
        <Layout>
          <Layout style={styles.mainContainer}>
            <Content>
              <Onboarding />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
  return <>{children}</>;
};

const styles = StyleSheet.create({
  mainContainer: {
    padding: "0 24px 24px",
    backgroundColor: "#FAFAFA",
  },
});
