import React from "react";
import { Radio, RadioChangeEvent } from "antd";
import { StyleSheet } from "../../models/StyleSheet";
import { SuntCategoriesData } from "../../hooks/api/Category";

export const CategoryRadio = ({
  options,
  name,
  setSuntCategory,
}: {
  options: SuntCategoriesData[];
  name?: string;
  setSuntCategory: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  return (
    <div>
      <Radio.Group name={name} style={styles.group}>
        {options.map((value) => (
          <Radio
            value={value?.name}
            key={value?.id}
            style={styles.radio}
            disabled={!value?.isActive}
            onChange={() => {
              setSuntCategory(value?.name);
            }}
          >
            <>
              <span style={styles.normal}>{value?.name}</span>
            </>
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
};

const styles = StyleSheet.create({
  bold: {
    fontFamily: "D-DIN",
    fontWeight: "700px",
    color: "#000000",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
  },
  group: {
    overflow: "auto",
  },
  normal: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400px",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
  },
  radio: {
    margin: "5px 0",
    display: "block",
  },
  errorText: {
    textAlign: "left",
    marginLeft: "8px",
    color: "red",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
  },
});
