import React, { useState, useEffect } from "react";
import { Progress } from "antd";
import { useTranslation } from "../../../hooks/useTranslation";
import style from "./styles/topbar.module.css";
import { Avatar } from "antd";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { storage } from "../../../services/storage";
import {
  OrganisationsData,
  useGetOrganisation,
} from "../../../hooks/api/Organisations";
import { StyleSheet } from "../../../models/StyleSheet";
import { DefaultButton } from "../../../components/buttons/DefaultButton";

const TopBar = () => {
  const navigate = useNavigate();
  const organisationId = storage.getUser()?.organisationId;

  const { t } = useTranslation("supplierProfile");
  const [moveTo, setMoveTo] = useState<string>("");
  const [showReviewIngredient, setShowReivewIngredient] =
    useState<boolean>(false);

  const routePath: string = window.location.pathname;

  useEffect(() => {
    if (routePath === "/reviews") {
      setShowReivewIngredient(true);
    } else {
      setShowReivewIngredient(false);
    }
  }, [routePath]);

  const move = (value: 0 | 1 | 2 | 3) => {
    setMoveTo(`bar-move-at-${value}`);
  };

  const isActiveLink = (navData: any) => {
    return navData.isActive ? style.linkActive : style.text;
  };

  return (
    <>
      <div className={style.header}>
        {showReviewIngredient && (
          <div className={style.companyLogo}>
            <div className={style.row}>
              <Avatar
                src="https://avatars.githubusercontent.com/in/15368?s=40&v=4"
                size={32}
              />
              <h3 className={style.companyName}>COOR</h3>
            </div>
          </div>
        )}
        <div className={style.topbarCenter}>
          <ul className={style.links}>
            <li>
              <NavLink
                onClick={() => move(0)}
                to={`/profile/organisation/${
                  organisationId ? organisationId : ""
                }`}
                className={isActiveLink}
              >
                Profil
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => move(1)}
                to={`/reviews/${organisationId ? organisationId : ""}`}
                className={isActiveLink}
              >
                Omdömen
              </NavLink>
            </li>
            <li>
              {/* <NavLink
                onClick={() => move(2)}
                to="/invite-to-dialogue"
                className={isActiveLink}
              >
                Dialog
              </NavLink> */}
            </li>
            <li>
              {/* <NavLink
                onClick={() => move(3)}
                to="/insights"
                className={isActiveLink}
              >
                Insikter
              </NavLink> */}
            </li>
          </ul>
          <div className={style.bottomLine}>
            <div className={`${style.bar} ${style[moveTo]}`} />
          </div>
        </div>
        <DefaultButton
          text={t("getMoreReviews")}
          style={styles.btn}
          variant={"primary"}
          onClick={() => navigate("/invite-to-review")}
        />

        {showReviewIngredient && (
          <button className={style.btn}>Få fler omdömenj</button>
        )}
      </div>
    </>
  );
};

export default TopBar;

const styles = StyleSheet.create({
  btn: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    // lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#FFFFFF",
    backgroundColor: "#07285D",
    borderRadius: "20px",
    padding: "7px 16px 8px 16px",
    border: "0",
    position: "absolute",
    top: "20px",
    right: "25px",
  },
});
