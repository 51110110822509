import { useQuery } from "react-query";
import { API } from "../../services/API";

interface AttachmentInterface {
  id: number;
  title: string;
  pdfUrl: string;
  organisationId: number;
}

const getAttachmentPdf = async (id: string) => {
  const url = `organisations/${id}/attachments`;
  const { data } = await API.get<unknown, { data: AttachmentInterface[] }>(url);
  return data;
};

export const useGetAttachmentPdf = (id: string) => {
  return useQuery(["getAttachmentPdf", id], () => getAttachmentPdf(id));
};
