import { Form, Formik } from "formik";
import React, { useState } from "react";
import {
  BoldedText,
  DefaultButton,
  DefaultInput,
  DefaultTextArea,
  DimmedText,
  HeaderBar,
  MultipleSelectInteractStringData,
} from "../../../../components";
import { usePostInteractReviewFromSupplier } from "../../../../hooks/api/Interact";
import { useTranslation } from "../../../../hooks/useTranslation";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../../services/notificationService";
// import { styles } from "../../../Common/InteractDialogue/TemplateDialogue";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import cssStyles from "./styles/style.module.css";
import { RightOutlined } from "@ant-design/icons";
import { StyleSheet } from "../../../../models/StyleSheet";

export const InviteToReview = () => {
  const initialValues = {
    subject: "",
    link: "",
    message: "",
  };
  interface reviewValuesInterface {
    subject: string;
    link: string;
    message: string;
  }
  const validationSchema = Yup.object().shape({
    subject: Yup.string(),
  });

  const [emails, setEmails] = useState<string[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation("interactReview");
  const [emailsError, setEmailsError] = useState("");
  const [name, setName] = useState("");
  const { t: tNotification } = useTranslation("notificationService");
  const emailsArr = name.split(",").map((item) => {
    return { value: item, label: item };
  });
  const checkCorrectEmails = () => {
    if (emailsArr.length !== 0) {
      setEmailsError("");
    } else {
      setEmailsError(t("incorrectEmail"));
    }
  };

  const { mutate } = usePostInteractReviewFromSupplier();

  const onSubmit = (val: reviewValuesInterface) => {
    if (!emailsError) {
      mutate(
        {
          emails: emails,
          // subject: val.subject,
          subject: "Skriv recension",
          message: val.message,
        },
        {
          onSuccess: () => {
            DisplaySuccessNotification(tNotification("success"));
            navigate("/categories");
          },
          onError: () => {
            DisplayErrorNotification(tNotification("error"));
          },
        }
      );
    }
  };

  return (
    <>
      <HeaderBar header={t("header")} text={t("headerDescription")} />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, handleChange }) => (
          <Form>
            <div style={styles.container}>
              <div style={styles.section}>
                <div
                // style={styles.smallerContainer}
                >
                  <BoldedText style={styles.sectionTextHeader}>
                    {t("receiver")}
                  </BoldedText>
                  {/* <DimmedText>{t("selectReceiver")}</DimmedText> */}
                  <p className={cssStyles.emailText}>{t("email")}</p>
                  {/* <div className={cssStyles.emailInputBox}> */}
                  <div style={styles.emailmessageContainer}>
                    <MultipleSelectInteractStringData
                      setTyped={setName}
                      style={{
                        marginRight: "96px",
                        height: "35px",
                        width: "352px",
                      }}
                      setValue={setEmails}
                      value={emails}
                      placeholder={t("buyer")}
                      onBlur={checkCorrectEmails}
                      options={emailsArr}
                    />
                  </div>
                </div>
                {/* <div style={styles.smallerContainer}> */}
                {/* <div style={styles.subjectContainer}> */}
                {/* <BoldedText style={styles.sectionTextHeader}>
                    {t("subject")}
                  </BoldedText> */}
                {/* <DimmedText style={styles.subjectLabel}>
                    {t("subjectDescription")}
                  </DimmedText>
                  <div style={{ display: "flex" }}>
                    <DefaultInput
                      type="text"
                      style={{ marginRight: "96px", height: "35px" }}
                      placeholder={t("write")}
                      name="subject"
                      value={values.subject}
                      onChange={handleChange}
                      error={errors.subject}
                      touched={touched.subject}
                    />
                  </div>
                </div> */}
                {/* <div style={styles.msgContainer}> */}
                <div style={styles.msgContainer}>
                  {/* <BoldedText style={styles.sectionTextHeader}>
                    {t("message")}
                  </BoldedText>
                  <DimmedText>{t("writeMessage")}</DimmedText> */}
                  <div style={styles.MessageLabel}>Meddelande</div>
                  <DefaultTextArea
                    placeholder={t("write")}
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                  />
                </div>
                <div style={styles.flex}>
                  {/* <DefaultButton
                    style={styles.btn}
                    text={t("cancel")}
                    variant="secondary"
                    onClick={() => {
                      navigate("/");
                    }}
                  /> */}
                  <div>
                    <DefaultButton
                      style={styles.btn}
                      text={t("submit")}
                      variant="primary"
                      type="submit"
                      onClick={() => {
                        checkCorrectEmails();
                      }}
                    />
                    {/* <button
                      type="submit"
                      style={styles.btn}
                      onClick={() => {
                        checkCorrectEmails();
                      }}
                    >
                      <span>{t("submit")}</span>
                      <RightOutlined style={styles.backIcon} />
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: "32px 50px",
  },
  textContainer: {
    display: "flex",
    alignItems: "flex-end",
    marginTop: "70px",
  },
  mainText: {
    marginRight: "20px",
  },
  marginRight: {
    marginRight: "32px",
  },
  section: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    padding: "44px 96px",
    margin: "32px 0",
  },
  sectionTextHeader: {
    // fontSize: "32px",
    // lineHeight: "22px",
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "35px",
    letterSpacing: "0.07em",
    color: "#808795",
    marginBottom: "26px",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
  },
  input: {
    width: "480px",
  },
  link: {
    fontFamily: "D-DIN",
    fontStyle: "italic",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    color: "#4FBFC0",
    textDecoration: "underline",
  },
  btn: {
    width: "220px",
    backgroundColor: "#07285D",
    borderRadius: "22px",
    fontFamily: "d-din",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#fff",
    border: "0px",
    height: "36px",
  },
  backIcon: {
    transform: "translateX(56px)",
  },
  errorText: {
    textAlign: "left",
    marginLeft: "8px",
    color: "red",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
  },
  errorInput: {
    boxShadow: "0px 1px 10px rgba(256, 0, 0, 0.5)",
  },
  error: {
    textAlign: "left",
    marginLeft: "8px",
    color: "red",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    marginTop: "10px",
  },
  smallerContainer: {
    marginBottom: "64px",
  },
  emailContainer: {
    marginBottom: "28px",
  },
  msgContainer: {
    marginBottom: "42px",
  },
  MessageLabel: {
    fontFamily: "D-DIN",
    marginBottom: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "15px",
    letterSpacing: "0.07em",
    color: "#808795",
  },
  subjectLabel: {
    fontFamily: "D-DIN",
    marginBottom: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "15px",
    letterSpacing: "0.07em",
    color: "#808795",
  },
  subjectContainer: {
    marginBottom: "36px",
  },
  emailmessageContainer: {
    marginBottom: "36px",
  },
});

// import "./styles/style.module.css";
// import { RightOutlined, LeftOutlined } from "@ant-design/icons";
// import { Form, Formik } from "formik";
// import React, { useState, useEffect } from "react";
// import {
//   BoldedText,
//   DefaultButton,
//   DefaultInput,
//   DefaultTextArea,
//   DimmedText,
//   HeaderBar,
//   MultipleSelectInteractStringData,
// } from "../../../../components";
// import { usePostInteractReviewFromSupplier } from "../../../../hooks/api/Interact";
// import { useTranslation } from "../../../../hooks/useTranslation";
// import { MailOutlined } from "@ant-design/icons";
// import {
//   DisplayErrorNotification,
//   DisplaySuccessNotification,
// } from "../../../../services/notificationService";
// import { styles } from "../../../Common/InteractDialogue/TemplateDialogue";
// import { useNavigate } from "react-router";
// import * as Yup from "yup";
// import { Input, Typography } from "antd";
// import cssStyles from "./styles/style.module.css";

// export const InviteToReview = () => {
//   const initialValues = {
//     subject: "",
//     link: "",
//     message: "",
//   };
//   interface reviewValuesInterface {
//     subject: string;
//     link: string;
//     message: string;
//   }
//   const validationSchema = Yup.object().shape({
//     subject: Yup.string().required(),
//   });

//   const [emails, setEmails] = useState<string[]>([]);
//   const navigate = useNavigate();
//   const { t } = useTranslation("interactReview");
//   const [emailsError, setEmailsError] = useState("");
//   const [name, setName] = useState("");
//   const { t: tNotification } = useTranslation("notificationService");
//   const emailsArr = name.split(",").map((item) => {
//     return { value: item, label: item };
//   });
//   const checkCorrectEmails = () => {
//     if (emailsArr.length !== 0) {
//       setEmailsError("");
//     } else {
//       setEmailsError(t("incorrectEmail"));
//     }
//   };

//   const { mutate } = usePostInteractReviewFromSupplier();

//   const onSubmit = (val: reviewValuesInterface) => {
//     if (!emailsError) {
//       mutate(
//         {
//           emails: emails,
//           subject: val.subject,
//           message: val.message,
//         },
//         {
//           onSuccess: () => {
//             DisplaySuccessNotification(tNotification("success"));
//             navigate("/categories");
//           },
//           onError: () => {
//             DisplayErrorNotification(tNotification("error"));
//           },
//         }
//       );
//     }
//   };

//   const { Text } = Typography;

//   const [messageValue, setValue] = useState<string>("");

//   const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
//     const newValue = e.target.value;
//     setValue(newValue);
//   };

//   const wordCount =
//     messageValue.trim().split(/\s+/).length +
//     (messageValue.trim() !== "" ? 1 : 0);
//   return (
//     <>
//       <HeaderBar header={t("header")} text={t("headerDescription")} />
//       <Formik
//         initialValues={initialValues}
//         onSubmit={onSubmit}
//         validationSchema={validationSchema}
//       >
//         {({ values, errors, touched, handleChange }) => (
//           <Form>
//             <div style={styles.container}>
//               <div style={styles.section}>
//                 <div style={styles.emailContainer}>
//                   <BoldedText style={styles.sectionTextHeader}>
//                     {t("receiver")}
//                   </BoldedText>
//                   {/* <DimmedText>{t("selectReceiver")}</DimmedText> */}
//                   {/* <DimmedText
//                     style={{
//                       marginTop: "26px",
//                     }}
//                   >
//                     {t("subjectDescription")}
//                   </DimmedText> */}
//                   <p className={cssStyles.emailText}>
//                     {t("subjectDescription")}
//                   </p>
//                   <div className={cssStyles.emailInputBox}>
//                     <MultipleSelectInteractStringData
//                       setTyped={setName}
//                       style={{
//                         marginRight: "96px",
//                         height: "35px",
//                         width: "352px",
//                       }}
//                       setValue={setEmails}
//                       value={emails}
//                       placeholder={t("buyer")}
//                       onBlur={checkCorrectEmails}
//                       options={emailsArr}
//                     />
//                   </div>
//                 </div>
//                 {/* <div style={styles.smallerContainer}> */}
//                 {/* <BoldedText style={styles.sectionTextHeader}>
//                     {t("subject")}
//                   </BoldedText> */}
//                 {/* <DimmedText>E-postadress*</DimmedText> */}
//                 {/* <DimmedText>{t("subjectDescription")}</DimmedText> */}
//                 {/* <div style={{ display: "flex" }}>
//                     <DefaultInput
//                       type="text"
//                       style={{ marginRight: "96px", height: "35px" }}
//                       placeholder={t("write")}
//                       name="subject"
//                       value={values.subject}
//                       onChange={handleChange}
//                       error={errors.subject}
//                       touched={touched.subject}
//                     />
//                   </div> */}
//                 {/* </div> */}
//                 <div style={styles.msgContainer}>
//                   {/* <BoldedText style={styles.sectionTextHeader}>
//                     {t("message")}
//                   </BoldedText> */}
//                   {/* <DimmedText>{t("writeMessage")}</DimmedText> */}
//                   <div style={styles.MessageLabel}>
//                     {/* Meddelande.({`${wordCount} ord kvar`}) */}
//                     Meddelande
//                   </div>
//                   <DefaultTextArea
//                     placeholder={t("write")}
//                     name="message"
//                     value={values.message}
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div style={styles.flex}>
//                   {/* <DefaultButton
//                     style={styles.btn}
//                     text={t("cancel")}
//                     variant="secondary"
//                     onClick={() => {
//                       navigate("/");
//                     }}
//                   /> */}
//                   <div>
//                     {/* <DefaultButton
//                       style={styles.btn}
//                       text={t("submit")}
//                       variant="primary"
//                       type="submit"
//                       // icon={RightOutlined}
//                       onClick={() => {
//                         checkCorrectEmails();
//                       }}
//                     /> */}
//                     <button
//                       type="submit"
//                       style={styles.btn}
//                       onClick={() => {
//                         checkCorrectEmails();
//                       }}
//                     >
//                       <span>{t("submit")}</span>
//                       <RightOutlined style={styles.backIcon} />
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </Form>
//         )}
//       </Formik>
//     </>
//   );
// };
