import React, { useEffect } from "react";
import { Checkbox } from "antd";
import { StyleSheet } from "../../models/StyleSheet";
import { ShowStars } from "../../containers/Buyer/components/ShowStars";
import { useRef } from "react";
import { FormItemInputContext } from "antd/lib/form/context";

export const MultipleSelectCheckbox = ({
  options,
  setValues,
  showGrades,
  setCatNames,
}: {
  options: { value: string; label?: string }[];
  setValues: React.Dispatch<React.SetStateAction<string[]>>;
  showGrades?: boolean;
  setCatNames?: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const checkBoxRef = useRef<HTMLInputElement[] | null>(null);

  const onChange = (checked: boolean, option: string, label?: string) => {
    if (checked === true) {
      setValues((prev) =>
        prev.concat(prev.find((opt) => opt === option) ? [] : [option])
      );
      if (setCatNames && label) {
        setCatNames((prev) =>
          prev.concat(prev.find((lab) => lab === label) ? [] : [label])
        );
      }
    } else {
      setValues((prev) => prev.filter((opt) => opt !== option));
      if (setCatNames && label) {
        setCatNames((prev) => prev.filter((lab) => lab !== label));
      }
    }
  };

  return (
    <div style={styles.container}>
      {options.map((option) => (
        <Checkbox
          key={option.value}
          style={styles.checkbox}
          ref={(e) => {
            if (e) {
              checkBoxRef.current?.push(e);
            }
          }}
          onChange={(value) => {
            onChange(value.target.checked, option.value, option.label);
          }}
        >
          {showGrades ? (
            <ShowStars number={parseFloat(option.value)} />
          ) : option?.label ? (
            option.label
          ) : (
            option.value
          )}
        </Checkbox>
      ))}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "3px 0",
  },
  checkbox: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    letterSpacing: "0.07em",
  },
  gradeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
