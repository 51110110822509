import React from "react";
import { Button } from "antd";
import { useState } from "react";
import { colors } from "../../constants/colors";
import { H3 } from "../text/H3";
import { StyleSheet } from "../../models/StyleSheet";
import { useNavigate } from "react-router";

export interface CategoryItemProps {
  id: number;
  title: string;
  isActive: boolean;
}

export function CategoryItem({ title, id, isActive }: CategoryItemProps) {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const onCategoryClick = () => {
    navigate(`/categories/${id}`);
  };

  return (
    <Button
      style={styles.container}
      disabled={!isActive}
      type="text"
      onClick={onCategoryClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <H3
        text={title.substring(0, 40)}
        style={hover ? styles.boldedText : styles.text}
        isActive={isActive}
      />
    </Button>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
    color: "black",
    width: "100%",
    height: "85px",
    borderColor: colors.primary,
    border: "none",
  },
  text: {
    position: "absolute",
    left: 85,
    top: 35,
    fontSize: 20,
  },
  boldedText: {
    position: "absolute",
    left: 85,
    top: 35,
    fontSize: 20,
    fontWeight: "bold",
  },
});
