import React, { CSSProperties, ReactNode } from "react";
import "antd/dist/antd.css";
import { StyleSheet } from "../../models/StyleSheet";
import { H5 } from "../text/H5";
import { colors } from "../../constants/colors";
import { useTranslation } from "../../hooks/useTranslation";

interface ComingSoonProps {
  children: ReactNode;
  fontSize: number;
  style?: CSSProperties;
}

export const ComingSoon = ({ children, fontSize, style }: ComingSoonProps) => {
  const { t } = useTranslation("comingSoon");

  return (
    <div style={style}>
      <div style={styles.container}>
        <div style={styles.opacityContainer}>{children}</div>
        <div
          style={{
            backgroundColor: "#F5F5F5",
            width: fontSize * 8,
            height: fontSize * 2,
            opacity: 0.8,
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <H5
          text={t("comingSoon").toUpperCase()}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            color: colors.primary,
            fontWeight: "bold",
            fontSize: fontSize,
          }}
        />
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: "#F5F5F5",
  },
  opacityContainer: {
    opacity: 0.3,
  },
});
