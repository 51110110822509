import React, { useState, useEffect, useMemo } from "react";
import { DimmedLink, StarRating } from "../../../components";
import { Checkbox, Select, Popover, Input, DatePicker } from "antd";
import {
  InfoCircleOutlined,
  StarOutlined,
  StarFilled,
} from "@ant-design/icons";
import { useFormik } from "formik";
import "../../../css/createReviewForm.css";
import { colors } from "../../../constants/colors";
import {
  createReviewInitValues,
  createReviewValidationSchema,
  dataToSendInterface,
  valuesInterface,
  styles,
} from "./createReviewForm.utils";
import { useGetCategoryNames } from "../../../hooks/api/Category";
import { OrganisationsData } from "../../../hooks/api/Organisations";
import { usePostReview } from "../../../hooks/api/Reviews";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../services/notificationService";
import { useTranslation } from "../../../hooks/useTranslation";
import { useParams, useNavigate } from "react-router-dom";
import cssStyle from "./styles/style.module.css";
import SupplierSearch from "../../../components/notGeneral/SupplierSearch";

const { RangePicker } = DatePicker;

export const CreateReviewForm = ({
  defaultOrganisation,
}: {
  defaultOrganisation?: OrganisationsData;
}) => {
  const TERMS_AND_CONDITIONS_LINK = "https://www.sunt.tech/privacy";

  const { t } = useTranslation("createEditReview");
  const { suntCategoryId: sCId } = useParams();
  const [suntCategoryId, setSuntCategoryId] = useState<number | null>(
    parseInt(sCId || "")
  );

  const handleSubmit = (values: valuesInterface) => {
    const dataToSend: dataToSendInterface = {
      organisationId: values.organizationId,
      suntCategoryId: suntCategoryId || 0,
      overallRating: values.overallRating,
      isAnonymous: values.isAnonymous,
      isHiddenOnProfile: values.isHiddenOnProfile,
      text: values.text,
      categoryId: values.categoryId,
      ...(!values.hideAgreementInputs
        ? {
            agreement: {
              identifier: values.agreement,
              estimatedValue: values.estimatedValue,
              startDate: values.startDate,
              endDate: values.endDate,
            },
          }
        : {}),
    };

    mutate(dataToSend, {
      onSuccess: (res) => {
        const data = res as unknown as { id: number };
        DisplaySuccessNotification(t("reviewAddSuccess"));
        navigate(
          `/create-assessment/categories/${suntCategoryId || 0}/organisation/${
            values.organizationId || 0
          }/assessment?reviewId=${data.id}`
        );
      },
      onError: (error) => {
        const parsedError = error as {
          response: { data: { message: string } };
        };
        const errorMessage = parsedError.response.data.message;
        console.log(errorMessage);
        DisplayErrorNotification(
          errorMessage ===
            "You can only submit one review every 14 days per supplier"
            ? t("reviewFrequencyError")
            : t("reviewAddError")
        );
      },
    });
  };

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: createReviewInitValues,
    validationSchema: createReviewValidationSchema,
    onSubmit: handleSubmit,
  });

  const { mutate } = usePostReview();

  const { data: categoryNamesData } = useGetCategoryNames(suntCategoryId || 0);

  const renderedCategoryOptions = useMemo<
    { value: number | null; label: string }[]
  >(
    () => [
      {
        value: null,
        label: "Ingen kategori",
      },
      ...(categoryNamesData
        ?.filter((category) => !!category.suntCategoryDetailed)
        .map((category) => ({
          value: category.id,
          label: category.suntCategoryDetailed,
        })) || []),
    ],
    [categoryNamesData]
  );

  const popoverContent = (
    <div className={cssStyle.popoverBox} id="popvoerSuplierOverview">
      <p>Att tänka på när du skriver omdömen:</p>
      <ul>
        <li>Var konstruktiv</li>
        <li>Beskriv utförligt</li>
        <li>Var detaljerad</li>
        <li>Utelämna personuppgifter</li>
        <li>Var artig</li>
        <li>Ha rätt uppgifter</li> <li>Korrekturläs</li>
        <li>Justera vid behov</li>
      </ul>
    </div>
  );

  useEffect(() => {
    void formik.setFieldValue("organizationId", defaultOrganisation?.id);
  }, [defaultOrganisation]);

  const popoverChange = () => {
    // try to access the popover parent node through the grand child
    // For change the border radius of the parent node.
    const element = document.querySelectorAll("#popvoerSuplierOverview");
    if (element.length > 0) {
      const popoverContainer = element[0]?.parentElement?.parentElement;
      if (popoverContainer) popoverContainer.style.borderRadius = "10px";
    } else {
      setTimeout(() => {
        const element = document.querySelectorAll("#popvoerSuplierOverview");
        const popoverContainer = element[0]?.parentElement?.parentElement;
        if (popoverContainer) popoverContainer.style.borderRadius = "10px";
      }, 500);
    }
  };

  const { TextArea } = Input;

  return (
    <form onSubmit={formik.handleSubmit}>
      <>
        <div className={cssStyle.container}>
          <div>
            <h2 className={cssStyle.heading}>Lämna ett omdöme</h2>
            <div className={`${cssStyle.inputBox}`}>
              <h3 className={cssStyle.heading}>
                {defaultOrganisation
                  ? "Leverantör att bedöma"
                  : "Sök leverantör att bedöma"}
              </h3>
              <SupplierSearch
                hideSearchIcon={true}
                disabled={defaultOrganisation !== undefined}
                defaultValue={defaultOrganisation?.name}
                width={510}
                style={cssStyle}
                onSelect={(selected: string) => {
                  const arr = selected.split("//");
                  setSuntCategoryId(parseInt(arr[1]));
                  void formik.setFieldValue("organizationId", Number(arr[2]));
                  void formik.setFieldValue("categoryId", null);
                }}
              />
              <div className={cssStyle.inputBottomBox}>
                <p
                  className={`${cssStyle.validationErrorMsg} ${
                    formik.touched.organizationId &&
                    formik.errors.organizationId
                      ? cssStyle.show
                      : cssStyle.hide
                  }`}
                >
                  {t("selectOrganisationFirst")}
                </p>
              </div>
            </div>

            <div className={cssStyle.inputBox}>
              <h3 className={cssStyle.heading}>Kategori</h3>

              <div
                style={{ paddingLeft: "0px" }}
                className={`${cssStyle.agreementSelect}`}
              >
                <Select
                  placeholder="Välj kategori..."
                  className={cssStyle.select}
                  size={"large"}
                  onChange={(value) => {
                    void formik.setFieldValue("categoryId", value);
                  }}
                  value={formik.values.categoryId}
                  options={renderedCategoryOptions}
                  dropdownClassName={cssStyle.categoryDropdown}
                ></Select>
              </div>
              <div className={cssStyle.inputBottomBox}>
                <p
                  className={`${cssStyle.validationErrorMsg} ${cssStyle.hide}`}
                >
                  &lsquo
                </p>
              </div>
            </div>
            <div className={cssStyle.inputBox}>
              <h3 className={cssStyle.heading}>Välj avtal </h3>

              <Checkbox
                checked={formik.values.hideAgreementInputs}
                onChange={(e) =>
                  void formik.setFieldValue(
                    "hideAgreementInputs",
                    e.target.checked
                  )
                }
              >
                <span style={styles.checkboxText}>
                  Saknar information om avtal
                </span>
              </Checkbox>
              <div className={cssStyle.inputBottomBox}>
                <p
                  className={`${cssStyle.validationErrorMsg} ${cssStyle.hide}`}
                >
                  &lsquo;
                </p>
              </div>
            </div>
            {!formik.values.hideAgreementInputs && (
              <>
                <div className={cssStyle.inputBox}>
                  <h3 className={cssStyle.heading}>
                    Avtalsnamn / avtalsnummer
                  </h3>
                  <input
                    className={`${cssStyle.agreementSelect}`}
                    type="text"
                    placeholder="Avtalsnamn / avtalsnummer"
                    name="agreement"
                    id="agreement"
                    value={formik.values.agreement}
                    onChange={formik.handleChange}
                  />
                  <div className={cssStyle.inputBottomBox}>
                    <p
                      className={`${cssStyle.validationErrorMsg} ${
                        formik.touched.agreement && formik.errors.agreement
                          ? cssStyle.show
                          : cssStyle.hide
                      }`}
                    >
                      Avtalsnamn / avtalsnummer är obligatorisk
                    </p>
                  </div>
                </div>

                <div className={cssStyle.inputBox}>
                  <h3 className={cssStyle.heading}>Avtalsperiod</h3>
                  <RangePicker
                    name="agreementPeriod"
                    id="agreementPeriod"
                    onChange={(dates) => {
                      void formik.setFieldValue(
                        "startDate",
                        dates?.[0]?.toISOString()
                      );
                      setTimeout(() => {
                        void formik.setFieldValue(
                          "endDate",
                          dates?.[1]?.toISOString()
                        );
                      }, 0);
                    }}
                    placeholder={["Startdatum", "Slutdatum"]}
                    className={`${cssStyle.agreementSelect}`}
                    separator=""
                  />
                  <div className={cssStyle.inputBottomBox}>
                    <p
                      className={`${cssStyle.validationErrorMsg} ${
                        (formik.touched.startDate && formik.errors.startDate) ||
                        (formik.touched.endDate && formik.errors.endDate)
                          ? cssStyle.show
                          : cssStyle.hide
                      }`}
                    >
                      Avtalsperiod är obligatorisk
                    </p>
                  </div>
                </div>

                <div className={cssStyle.inputBox}>
                  <h3 className={cssStyle.heading}>
                    Avtalets uppskattade totala värde i SEK
                  </h3>
                  <input
                    className={`${cssStyle.agreementSelect}`}
                    type="number"
                    step="0.01"
                    placeholder="Estimerat värde för avtal"
                    name="estimatedValue"
                    id="estimatedValue"
                    value={formik.values.estimatedValue}
                    onChange={formik.handleChange}
                  />
                  <div className={cssStyle.inputBottomBox}>
                    <p
                      className={`${cssStyle.validationErrorMsg} ${
                        formik.touched.estimatedValue &&
                        formik.errors.estimatedValue
                          ? cssStyle.show
                          : cssStyle.hide
                      }`}
                    >
                      Estimerat värde för avtal är obligatorisk
                    </p>
                  </div>
                </div>
              </>
            )}

            <div className={cssStyle.inputBox}>
              <h3 className={cssStyle.heading}>
                Ge betyg baserat på dina erfarenheter
              </h3>
              <div className={cssStyle.ratingBox}>
                <StarRating
                  disabled={false}
                  value={formik.values.overallRating}
                  style={styles.rate}
                  color={colors.primary}
                  size={24}
                  setValue={(value) =>
                    void formik.setFieldValue("overallRating", value)
                  }
                />
                <span style={styles.rateValue}>
                  {formik.values.overallRating}
                </span>
              </div>
            </div>
            <div className={`${cssStyle.experienceBox} ${cssStyle.inputBox}`}>
              <div className={cssStyle.row}>
                <h3 className={cssStyle.heading}>
                  Berätta om dina erfarenheter
                </h3>
                <Popover
                  content={popoverContent}
                  placement="bottom"
                  trigger="hover"
                >
                  <InfoCircleOutlined
                    className={cssStyle.infoIcon}
                    onMouseEnter={popoverChange}
                  />
                </Popover>
              </div>
              <TextArea
                className={`${cssStyle.textarea} ${
                  formik.errors.text ? cssStyle.error : ""
                } `}
                name="text"
                id="text"
                value={formik.values.text}
                onChange={formik.handleChange}
                placeholder="Hur var din upplevelse med denna leverantör?
Vad gör den här leverantören bra?
Kom ihåg att vara ärlig, hjälpsam och konstruktiv."
                autoSize={{ minRows: 4 }}
              />
              <div className={cssStyle.inputBottomBox}>
                <p
                  className={`${cssStyle.validationErrorMsg} ${
                    formik.errors.text ? cssStyle.show : cssStyle.hide
                  }`}
                >
                  {t("maxdescriptionlength")}
                </p>
                <p className={cssStyle.textCounter}>
                  {formik.values.text.trim().length}/1000
                </p>
              </div>
            </div>
            <div className={cssStyle.checkBoxContainer}>
              <div>
                <Checkbox
                  checked={formik.values.isAnonymous}
                  onChange={formik.handleChange}
                  name="isAnonymous"
                  id="isAnonymous"
                >
                  <span style={styles.checkboxText}>
                    {t("dontShowMyProfile")}
                  </span>
                </Checkbox>
                <br />
                <Checkbox
                  checked={formik.values.isHiddenOnProfile}
                  onChange={formik.handleChange}
                  name="isHiddenOnProfile"
                  id="isHiddenOnProfile"
                  style={{ marginTop: "12px" }}
                >
                  <span style={styles.checkboxText}>
                    {t("dontShowMyOpinion")}
                  </span>
                </Checkbox>
              </div>
              <div className={cssStyle.link}>
                <DimmedLink
                  openInNewPage={true}
                  link={TERMS_AND_CONDITIONS_LINK}
                >
                  {t("integrityDescription")}
                </DimmedLink>
              </div>
            </div>

            <button
              className={`${cssStyle.submitButton} ${
                formik.errors.text ? cssStyle.disabled : ""
              }`}
              type="submit"
            >
              Skicka
            </button>
          </div>
          <div className={cssStyle.starBox}>
            <h4 className={cssStyle.heading}>Förklaring av betygsättningen</h4>
            <div className={cssStyle.row}>
              <StarFilled className={cssStyle.star} />
              <StarOutlined className={cssStyle.emptyStar} />
              <StarOutlined className={cssStyle.emptyStar} />
              <StarOutlined className={cssStyle.emptyStar} />
              <StarOutlined className={cssStyle.emptyStar} />
              <p className={cssStyle.ratingNumber}>1.0</p>
              <p className={cssStyle.text}>Oacceptabel</p>
            </div>
            <div className={cssStyle.row}>
              <StarFilled className={cssStyle.star} />
              <StarFilled className={cssStyle.star} />
              <StarOutlined className={cssStyle.emptyStar} />
              <StarOutlined className={cssStyle.emptyStar} />
              <StarOutlined className={cssStyle.emptyStar} />
              <p className={cssStyle.ratingNumber}>2.0</p>
              <p className={cssStyle.text}>Behöver utveckling</p>
            </div>
            <div className={cssStyle.row}>
              <StarFilled className={cssStyle.star} />
              <StarFilled className={cssStyle.star} />
              <StarFilled className={cssStyle.star} />
              <StarOutlined className={cssStyle.emptyStar} />
              <StarOutlined className={cssStyle.emptyStar} />
              <p className={cssStyle.ratingNumber}>3.0</p>
              <p className={cssStyle.text}>Möter förväntningarna</p>
            </div>
            <div className={cssStyle.row}>
              <StarFilled className={cssStyle.star} />
              <StarFilled className={cssStyle.star} />
              <StarFilled className={cssStyle.star} />
              <StarFilled className={cssStyle.star} />
              <StarOutlined className={cssStyle.emptyStar} />
              <p className={cssStyle.ratingNumber}>4.0</p>
              <p className={cssStyle.text}>Överträffar förväntningarna</p>
            </div>
            <div className={cssStyle.row}>
              <StarFilled className={cssStyle.star} />
              <StarFilled className={cssStyle.star} />
              <StarFilled className={cssStyle.star} />
              <StarFilled className={cssStyle.star} />
              <StarFilled className={cssStyle.star} />
              <p className={cssStyle.ratingNumber}>5.0</p>
              <p className={cssStyle.text}>
                Sätter en ny standard för prestanda
              </p>
            </div>
            <hr />
            <h4 className={cssStyle.heading}>
              Att tänka på när du skriver omdömen
            </h4>
            <div className={cssStyle.row}>
              <ul>
                <li>Var konstruktiv</li>
                <li>Beskriv utförligt</li>
                <li>Var detaljerad</li>
                <li>Utelämna personuppgifter</li>
                <li>Var artig</li>
                <li>Ha rätt uppgifter</li> <li>Korrekturläs</li>
                <li>Justera vid behov</li>
              </ul>
            </div>
          </div>
        </div>
      </>
    </form>
  );
};
