import React, { useEffect, useState } from "react";
import { useGetPendingOrganisations } from "../../../hooks/api/Admin";
import CollapsePanel from "./Components/CollapsePanel";

export const PendingRequests = () => {
  const [state, setstate] = useState(true);
  useEffect(() => {
    console.log("refresh");
  }, [state]);
  const { data: organisations } = useGetPendingOrganisations(state);
  const [pendingOrganisation, setPendingOrganisation] = useState(organisations);

  useEffect(() => {
    setPendingOrganisation(organisations);
  }, [organisations]);
  return (
    <>
      {pendingOrganisation?.map((organisation, index) => (
        <CollapsePanel
          key={index}
          organisation={organisation}
          state={state}
          setState={setstate}
        />
      ))}
      {organisations?.length == 0 && (
        <h3
          style={{
            textAlign: "center",
            fontSize: "30px",
            marginTop: "30px",
          }}
        >
          No Data Found
        </h3>
      )}
    </>
  );
};
