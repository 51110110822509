import React from "react";
import { useState } from "react";
import { useTranslation } from "../../../../hooks/useTranslation";
import { useNavigate } from "react-router";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../../services/notificationService";
import style from "../styles/style.module.css";
import { useAddDepartment, useGetCheckDep } from "../../../../hooks/api/Admin";

export const Department = () => {
  const [department, setDepartment] = useState("");
  const [name, setName] = useState("");
  const [swedishName, setSwedishName] = useState("");

  const [error, setError] = useState("");

  const { t } = useTranslation("addSni");
  const navigate = useNavigate();

  const { mutate } = useAddDepartment();

  const { data: depResult } = useGetCheckDep(department);
  let chkError = "";
  if (depResult === true) {
    chkError = "Department already Exists";
  }

  function validate(department: string) {
    let errors = "";
    if (department && department.length > 1) {
      errors = "Department must be exactly 1 character long";
    }
    return errors;
  }

  const handleDepartmentSubmit = () => {
    if (department == "" || name == "" || swedishName == "") {
      DisplayErrorNotification(t("ProvideAllData"));
    } else {
      const newErrors = validate(department);
      setError(newErrors);

      if (newErrors === "" && chkError == "") {
        const dataToSend = {
          data: {
            department,
            name,
            swedishName,
          },
        };

        mutate(dataToSend, {
          onSuccess: () => {
            DisplaySuccessNotification(t("addDepSuccess"));
            navigate("/dashboard");
          },
          onError: () => {
            DisplayErrorNotification(t("addDepError"));
          },
        });
      } else {
        if (chkError !== "") {
          DisplayErrorNotification(t("DepAlreadyExists"));
        } else {
          DisplayErrorNotification(t("WrongDepError"));
        }
      }
    }
  };

  return (
    <div className={style.container}>
      <p className={style.heading}>Add Department</p>
      <div className={style.row}>
        <div className={style.col}>
          <p className={style.inputLabel}>Enter Department</p>
          <input
            className={style.input}
            type="text"
            placeholder="Department"
            value={department}
            onChange={(e) => {
              setDepartment(e.target.value);
              const newErrors = validate(e.target.value);
              setError(newErrors);
            }}
          />
          {error && <div className={style.error}>{error}</div>}
          {depResult == true && <div className={style.error}>{chkError}</div>}
        </div>
        <div className={style.col}>
          <p className={style.inputLabel}>Enter name</p>
          <input
            className={style.input}
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>

        <div className={style.col}>
          <p className={style.inputLabel}>Enter swedish name</p>
          <input
            className={style.input}
            type="text"
            placeholder="Swedish Name"
            value={swedishName}
            onChange={(e) => {
              setSwedishName(e.target.value);
            }}
          />
        </div>

        <div className={style.btnContainer}>
          <button
            className={style.btn}
            type="submit"
            onClick={() => {
              handleDepartmentSubmit();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
