import React from "react";
import { Col, Modal, Row } from "antd";
import { useState } from "react";
import { StyleSheet } from "../../../models/StyleSheet";
import { TopBar } from "../insightModal/TopBar";
import { InsightMenu } from "../insightModal/InsightMenu";

export interface InsightsModalProps {
  text: string;
  typeColor?: "primary" | "secondary";
}

export function InsightsModal({ text }: InsightsModalProps) {
  const [visible, setVisible] = useState(false);
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <a style={styles.link} type="text" onClick={() => setVisible(true)}>
        {text}
      </a>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        width={1400}
      >
        <TopBar />

        <Row>
          <Col span={18} push={6} style={{ backgroundColor: "#E5E5E5" }}></Col>
          <Col span={6} pull={18}>
            <InsightMenu />
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
const styles = StyleSheet.create({
  link: {
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "13.03px",
    color: "#000",
    textDecoration: "underline",
    marginLeft: "3px",
    marginBottom: "2px",
  },
});
