export const provinces = [
  {
    label: "Stockholms län",
    value: 0,
  },
  {
    label: "Uppsala län",
    value: 3,
  },
  {
    label: "Södermanlands län",
    value: 4,
  },
  {
    label: "Östergötlands län",
    value: 5,
  },
  {
    label: "Jönköpings län",
    value: 6,
  },
  {
    label: "Kronobergs län",
    value: 7,
  },
  {
    label: "Kalmar län",
    value: 8,
  },
  {
    label: "Gotlands län",
    value: 9,
  },
  {
    label: "Blekinge län",
    value: 10,
  },
  {
    label: "Skåne län",
    value: 12,
  },
  {
    label: "Hallands län",
    value: 13,
  },
  {
    label: "Västra Götalands län",
    value: 14,
  },
  {
    label: "Värmlands län",
    value: 17,
  },
  {
    label: "Örebro län",
    value: 18,
  },
  {
    label: "Västmanlands län",
    value: 19,
  },
  {
    label: "Dalarnas län",
    value: 20,
  },
  {
    label: "Gävleborgs län",
    value: 21,
  },
  {
    label: "Västernorrlands län",
    value: 22,
  },
  {
    label: "Jämtlands län",
    value: 23,
  },
  {
    label: "Västerbottens län",
    value: 24,
  },
  {
    label: "Norrbottens län",
    value: 25,
  },
];
