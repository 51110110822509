import React from "react";
import { Row, Col } from "antd";
import { StyleSheet } from "../../../models/StyleSheet";
import { TableHeaderSorter } from "./TableHeaderSorter";

interface TableHeaderProps {
  sortType: {
    sortBy:
      | "name"
      | "employeesNumber"
      | "city"
      | "numberOfReviews"
      | "averageRating"
      | null;
    orderBy: "desc" | "asc";
  };
  setSortType: React.Dispatch<
    React.SetStateAction<{
      sortBy:
        | "name"
        | "employeesNumber"
        | "city"
        | "numberOfReviews"
        | "averageRating"
        | null;
      orderBy: "desc" | "asc";
    }>
  >;
}

export const TableHeader = ({ sortType, setSortType }: TableHeaderProps) => {
  return (
    <Row style={styles.rowHeader}>
      <Col span={2} />
      <TableHeaderSorter
        alignLeft={true}
        sortType={sortType}
        setSortType={setSortType}
        sorterName={"name"}
        span={5}
      />
      <TableHeaderSorter
        sortType={sortType}
        setSortType={setSortType}
        sorterName={"employeesNumber"}
        span={4}
      />
      <TableHeaderSorter
        sortType={sortType}
        setSortType={setSortType}
        sorterName={"city"}
        span={4}
      />
      <TableHeaderSorter
        sortType={sortType}
        setSortType={setSortType}
        sorterName={"numberOfReviews"}
        span={4}
      />
      <TableHeaderSorter
        sortType={sortType}
        setSortType={setSortType}
        sorterName={"averageRating"}
        span={5}
      />
    </Row>
  );
};

const styles = StyleSheet.create({
  rowHeader: {
    textAlign: "center",
    background: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
    // borderRadius: "0px 0px 4px 4px",
    borderRadius: "4px",
    padding: "16px 16px 16px 0",
    marginBottom: "16px",
  },
  name: {
    textAlign: "left",
  },
  activeText: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#4FBFC0",
  },
  otherText: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#000000",
  },
  hover: {
    cursor: "pointer",
  },
});
