import React from "react";
import { StyleSheet } from "../../../models/StyleSheet";
import logo from "../../../assets/icons/Logo.svg";

export function MinimalistLogo({
  style,
}: {
  style?: { [key: string]: string };
}) {
  return (
    <div style={style ? style : styles.container}>
      <img src={logo} style={styles.img} />
      <p style={styles.text}>SUNT</p>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: "0px",
    textAlign: "center",
    marginTop: "47px",
  },
  img: {
    display: "inline",
    margin: "0px 15px",
    marginBottom: "14px",
  },
  text: {
    fontFamily: "D-DIN",
    fontStyle: "bolder",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "35px",
    textAlign: "center",
    display: "inline",
    color: "#07285D",
  },
});
