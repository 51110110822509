import { Select } from "antd";
import React from "react";
import { useGetAllDetailedCategories } from "../../hooks/api/Category";
import { useTranslation } from "../../hooks/useTranslation";
import { StyleSheet } from "../../models/StyleSheet";

export const SelectDetailedCategories = ({
  categoryId,
  setCategoryId,
  correctlyCompleted,
  onBlur,
  placeholder,
}: {
  categoryId: number | null;
  setCategoryId: React.Dispatch<React.SetStateAction<number | null>>;
  correctlyCompleted: boolean | null;
  onBlur?: React.FocusEventHandler<HTMLElement>;
  placeholder?: string;
}) => {
  const { t } = useTranslation("interactDialogue");
  const { data: categoriesData } = useGetAllDetailedCategories();
  return (
    <Select
      style={
        correctlyCompleted !== false
          ? styles.select
          : { ...styles.select, ...styles.selectError }
      }
      placeholder={placeholder}
      value={categoryId}
      onChange={setCategoryId}
      options={categoriesData?.map((category) => {
        return {
          value: category.id,
          label: category.suntCategoryDetailed,
        };
      })}
      allowClear={true}
      onBlur={onBlur}
    />
  );
};

const styles = StyleSheet.create({
  select: {
    width: "300px",
    borderRadius: "4px",
  },
  selectError: {
    boxShadow: "0px 1px 10px rgba(256, 0, 0, 0.5)",
  },
});
