import React from "react";
import { StyleSheet } from "../models/StyleSheet";

export const DisplayRate = ({
  label,
  rate,
  styleLabel,
}: {
  label: string;
  rate: number | null | undefined;
  styleLabel?: React.CSSProperties;
}) => {
  const styleRendered = (
    rateIn: number | null | undefined,
    staticRate: number
  ) => {
    if (rateIn !== null && rateIn !== undefined) {
      const rate = Math.round(rateIn);
      if (staticRate === 0) {
        if (rate === staticRate) {
          return { ...styles.activeOption, ...styles.optionFirst };
        } else {
          return { ...styles.option, ...styles.optionFirst };
        }
      }
      if (staticRate === 10) {
        if (rate === staticRate) {
          return { ...styles.activeOption, ...styles.optionLast };
        } else {
          return { ...styles.option, ...styles.optionLast };
        }
      }
      if (rate === staticRate) {
        return styles.activeOption;
      } else {
        return { ...styles.option, ...styles.hiddenOption };
      }
    }
    if (staticRate === 0) {
      return { ...styles.option, ...styles.optionFirst };
    }
    if (staticRate === 10) {
      return { ...styles.option, ...styles.optionLast };
    }
  };

  const renderedStyleLabel = (
    rateIn: number | null | undefined,
    staticRate: number
  ) => {
    if (rateIn) {
      const rate = Math.round(rateIn);
      return rate !== staticRate
        ? { ...styles.rateLabel, ...styles.hiddenOption }
        : styles.rateLabel;
    }
    return { ...styles.rateLabel, ...styles.hiddenOption };
  };

  return (
    <div>
      <p style={{ ...styles.label, ...styleLabel }}>{label}</p>

      <div style={styles.container}>
        <div style={styles.containerSlider}>
          <div style={styleRendered(rate, 0)}></div>
          <div style={styleRendered(rate, 1)}></div>
          <div style={styleRendered(rate, 2)}></div>
          <div style={styleRendered(rate, 3)}></div>
          <div style={styleRendered(rate, 4)}></div>
          <div style={styleRendered(rate, 5)}></div>
          <div style={styleRendered(rate, 6)}></div>
          <div style={styleRendered(rate, 7)}></div>
          <div style={styleRendered(rate, 8)}></div>
          <div style={styleRendered(rate, 9)}></div>
          <div style={styleRendered(rate, 10)}></div>
        </div>
        <div style={styles.labelContainer}>
          <div style={{ ...styles.rateLabel, ...styles.optionFirst }}>0</div>
          <div style={renderedStyleLabel(rate, 1)}>1</div>
          <div style={renderedStyleLabel(rate, 2)}>2</div>
          <div style={renderedStyleLabel(rate, 3)}>3</div>
          <div style={renderedStyleLabel(rate, 4)}>4</div>
          <div style={renderedStyleLabel(rate, 5)}>5</div>
          <div style={renderedStyleLabel(rate, 6)}>6</div>
          <div style={renderedStyleLabel(rate, 7)}>7</div>
          <div style={renderedStyleLabel(rate, 8)}>8</div>
          <div style={renderedStyleLabel(rate, 9)}>9</div>
          <div style={{ ...styles.rateLabel, ...styles.optionLast }}>10</div>
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  label: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#000000",
    margin: "30px 0 15px 0px",
  },
  container: {
    width: "100%",
  },
  containerSlider: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "5px",
    backgroundColor: "#CDD4E2",
  },
  option: {
    width: "12px",
    height: "12px",
    background: "#FFFFFF",
    border: "2px solid #CDD4E2",
    borderRadius: "100%",
  },
  activeOption: {
    width: "12px",
    height: "12px",
    background: "#FFFFFF",
    border: "2px solid #07285D",
    borderRadius: "100%",
  },
  optionFirst: {
    marginLeft: "-2px",
  },
  optionLast: {
    marginRight: "-2px",
  },
  hiddenOption: {
    visibility: "hidden",
  },
  labelContainer: {
    width: "100%",
    marginTop: "5px",
    display: "flex",
    justifyContent: "space-between",
  },
  rateLabel: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#4FBFC0",
    width: "12px",
    textAlign: "center",
  },
});
