import React, { useState } from "react";
import { SignupFormBuyer } from "./SignupFormBuyer";
import "../../../css/public.css";
import "../../../css/signup.css";
import { MinimalistLogo } from "../components/MinimalistLogo";
import { Conditional } from "../../../components";
import { StyleSheet } from "../../../models/StyleSheet";
import { SignupFormSupplier } from "./SignupFormSupplier";
import { DefaultPage } from "./DefaultPage";
import { colors } from "../../../constants/colors";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../../hooks/useTranslation";
import arrow from "../../../assets/icons/ArrowLeft.svg";

export function SignUp() {
  const [selected, setSelected] = useState<null | "buyer" | "supplier">(null);
  const { t } = useTranslation("signupPage");
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);

  return (
    <div>
      <MinimalistLogo style={styles.logo} />
      <Conditional
        condition={selected === "buyer"}
        trueRender={
          <>
            <div style={styles.centredContainer}>
              <div style={styles.leftSide}>
                <p style={styles.leftSideHeader}>{t("register")}</p>
              </div>
              <div style={styles.rightSide}>
                <p
                  style={styles.rightSideHeader}
                  onClick={() => {
                    setClicked(true);
                    setTimeout(() => {
                      navigate("/login");
                    }, 500);
                  }}
                  className="pointer"
                >
                  {t("logIn")}
                </p>
              </div>
            </div>
            <div style={styles.centredContainer}>
              <div style={styles.animationContainer}>
                <div
                  style={clicked ? styles.onClickAnimation : styles.noAnimation}
                >
                  {" "}
                </div>
              </div>
            </div>
            <div style={styles.centredContainer}>
              <div style={styles.backButtton}>
                <img src={arrow} style={styles.arrow} />
                <p
                  style={styles.link}
                  onClick={() => {
                    setSelected(null);
                  }}
                  className="pointer"
                >
                  {t("backBtn")}
                </p>
              </div>
            </div>
            <Conditional
              condition={selected === null}
              trueRender={
                <DefaultPage selected={selected} setSelected={setSelected} />
              }
            />
            <Conditional
              condition={selected === "buyer"}
              trueRender={
                <SignupFormBuyer
                  selected={selected}
                  setSelected={setSelected}
                />
              }
            />
          </>
        }
      />
      <Conditional
        condition={selected === "supplier"}
        trueRender={
          <>
            <div style={styles.centredContainer}>
              <div style={styles.leftSide}>
                <p style={styles.leftSideHeader}>{t("register")}</p>
              </div>
              <div style={styles.rightSide}>
                <p
                  style={styles.rightSideHeader}
                  onClick={() => {
                    setClicked(true);
                    setTimeout(() => {
                      navigate("/login");
                    }, 500);
                  }}
                  className="pointer"
                >
                  {t("logIn")}
                </p>
              </div>
            </div>
            <div style={styles.centredContainer}>
              <div style={styles.animationContainer}>
                <div
                  style={clicked ? styles.onClickAnimation : styles.noAnimation}
                >
                  {" "}
                </div>
              </div>
            </div>
            <div style={styles.centredContainer}>
              <div style={styles.backButtton}>
                <img src={arrow} style={styles.arrow} />
                <p
                  style={styles.link}
                  onClick={() => {
                    setSelected(null);
                  }}
                  className="pointer"
                >
                  {t("backBtn")}
                </p>
              </div>
            </div>
            <Conditional
              condition={selected === null}
              trueRender={
                <DefaultPage selected={selected} setSelected={setSelected} />
              }
            />
            <SignupFormSupplier selected={selected} setSelected={setSelected} />
          </>
        }
      />
      <Conditional
        condition={selected === null}
        trueRender={
          <DefaultPage selected={selected} setSelected={setSelected} />
        }
      />
    </div>
  );
}

const styles = StyleSheet.create({
  centredContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  animationContainer: {
    marginTop: "-10px",
    width: "656px",
    height: "3px",
    backgroundColor: "rgba(79, 191, 192, 0.3)",
  },
  noAnimation: {
    width: "328px",
    height: "5px",
    marginTop: "-1px",
    backgroundColor: `${colors.secondary}`,
    transition: "all .5s ease 0s",
  },
  onClickAnimation: {
    width: "328px",
    height: "5px",
    marginTop: "-1px",
    marginLeft: "50%",
    backgroundColor: `${colors.secondary}`,
    transition: "all .5s ease 0s",
  },
  logo: {
    position: "static",
    textAlign: "center",
    paddingTop: "56px",
    paddingBottom: "22px",
    marginBottom: "25px",
  },
  leftSide: {
    marginTop: "17px",
    textAlign: "center",
    display: "inline",
    width: "328px",
  },
  rightSide: {
    marginTop: "17px",
    textAlign: "center",
    display: "inline",
    width: "328px",
  },
  leftSideHeader: {
    fontFamily: "d-din",
    fontStyle: "bold",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "26.06px",
    letterSpacing: "0.07em",
    color: "#3F3F3F",
    textAlign: "center",
    width: "100%",
  },
  rightSideHeader: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "26.06px",
    letterSpacing: "0.07em",
    color: "#3F3F3F",
    textAlign: "center",
    width: "100%",
    transition: "all .5s ease 0s",
  },
  backButtton: {
    marginTop: "10px",
    width: "656px",
    justifyContent: "right",
  },
  arrow: {
    marginBottom: "2.5px",
    display: "inline",
    marginLeft: "585px",
  },
  link: {
    fontFamily: "d-din",
    fontStyle: "italic",
    fontWeight: "400",
    fontSize: "16x",
    lineHeight: "17px",
    color: "#4FBFC0",
    textDecoration: "underline",
    display: "inline",
  },
});
