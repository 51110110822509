import React, { useEffect, useState } from "react";
import { Collapse, Checkbox } from "antd";

const { Panel } = Collapse;

import style from "./styles/style.module.css";

type Props = {
  id: number;
  title: string;
  isFullCheck: boolean;
  partialCheck: boolean;
  totalCatCheck: number;
  cities: { index: number; title: string; isCheck: boolean }[];
  onCheck: (id: number, index: number, title: string, isCheck: boolean) => void;
  onCheckService: (id: number, isCheck: boolean) => void;
};
const ServiceCollapse = (props: Props) => {
  const {
    id,
    title,
    cities,
    totalCatCheck,
    isFullCheck,
    partialCheck,
    onCheck,
    onCheckService,
  } = props;
  const [isPartial, setIsPartial] = useState(partialCheck);
  useEffect(() => {
    setIsPartial(partialCheck);
  }, [partialCheck]);
  return (
    <Collapse defaultActiveKey={["1"]} className={style.container}>
      <Panel
        header={
          <div className={style.headerContainer} onClick={() => {}}>
            <div className={style.headerTitleBox}>
              <Checkbox
                className={style.checkbox}
                checked={isFullCheck}
                onChange={({ target: { checked } }) => {
                  onCheckService(id, checked);
                }}
              >
                <div
                  className={`${style.partialCheckLayer} ${
                    !isPartial ? style.opacity0 : ""
                  }`}
                ></div>
                <div className={style.chickBoxUpperLayer} />
              </Checkbox>
              <h2 className={style.title}>{title}</h2>
            </div>
            <p className={style.status}>
              Selected {totalCatCheck} of {cities?.length} kommuns
            </p>
          </div>
        }
        key="2"
      >
        <div className={style.checkBoxGroup}>
          {cities?.map((item) => (
            <Checkbox
              key={item.title}
              className={style.checkBox}
              onChange={({ target: { checked } }) => {
                onCheck(id, item.index, item.title, checked);
              }}
              checked={item.isCheck}
            >
              {item.title}
            </Checkbox>
          ))}
        </div>
      </Panel>
    </Collapse>
  );
};

export default ServiceCollapse;
