import React from "react";

import { CreateReviewForm } from "./CreateReviewForm";
import { useTranslation } from "../../../hooks/useTranslation";
import { useGetOrganisations } from "../../../hooks/api/Organisations";
import { useLocation, useParams } from "react-router";
import { Conditional } from "../../../components";
import { HeaderBar } from "../../../components/HeaderBar";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const CreateReview = () => {
  const { t } = useTranslation("createEditReview");
  const query = useQuery();
  const organisationId = query.get("organisationId");
  const { suntCategoryId } = useParams();

  const { data: defaultOrganisation } = useGetOrganisations({
    id: parseInt(organisationId || ""),
    suntCategoryId: parseInt(suntCategoryId || ""),
  });

  return (
    <>
      {/* <HeaderBar header={t("reviews")} text={t("shareYourExperiences")} /> */}
      <Conditional
        condition={defaultOrganisation}
        trueRender={
          <CreateReviewForm defaultOrganisation={defaultOrganisation} />
        }
        falseRender={<CreateReviewForm />}
      />
    </>
  );
};
