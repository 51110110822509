import { Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSignUpSupplier } from "../../../hooks/api/SignUp";
import { useTranslation } from "../../../hooks/useTranslation";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../services/notificationService";
import { Bottom } from "./components/Bottom";
import { Input } from "../components/Input";
import { PasswordInput } from "../components/PasswordInput";
import {
  signupInitValuesSupplier,
  signupInitValuesSupplierInterface,
  signupSupplierSchema,
} from "./singup.utils";
import { DefaultButton } from "../../../components";
import { StyleSheet } from "../../../models/StyleSheet";

export const SignupFormSupplier = ({
  selected,
  setSelected,
}: {
  selected: null | "buyer" | "supplier";
  setSelected: React.Dispatch<
    React.SetStateAction<"buyer" | "supplier" | null>
  >;
}) => {
  const { mutate, isLoading } = useSignUpSupplier();
  const { t: tNotification } = useTranslation("notificationText");
  const { t } = useTranslation("signupPage");
  const navigate = useNavigate();

  const onSubmit = ({
    firstName,
    lastName,
    email,
    role,
    organisationName,
    organisationNumber,
    password,
  }: signupInitValuesSupplierInterface) => {
    const dataToSend = {
      firstName,
      lastName,
      email,
      role,
      organisationNumber,
      organisationName,
      password,
    };
    mutate(dataToSend, {
      onSuccess: () => {
        DisplaySuccessNotification(tNotification("successSignup"));
        navigate("/verified-page");
      },
      onError: (error: unknown) => {
        const parsedError = error as { message: string };
        if (parsedError.message.includes("409")) {
          DisplayErrorNotification(tNotification("errorConflict"));
        } else {
          DisplayErrorNotification(tNotification("errorSignup"));
        }
      },
    });
  };

  return (
    <Formik
      initialValues={signupInitValuesSupplier}
      onSubmit={(value) => {
        onSubmit(value);
      }}
      validationSchema={signupSupplierSchema}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => (
        <>
          <form onSubmit={handleSubmit}>
            <div style={styles.formContainer}>
              <div style={styles.formContent}>
                <div style={styles.inline}>
                  <Input
                    name="firstName"
                    label={`${t("firstName")}*`}
                    // placeholder={t("placeholderName")}
                    type="text"
                    value={values.firstName}
                    handleChange={handleChange}
                    error={errors.firstName}
                    touched={touched.firstName}
                  />
                  <Input
                    name="email"
                    label={`${t("email")}*`}
                    // placeholder={t("placeholderEmail")}
                    type="text"
                    value={values.email}
                    handleChange={handleChange}
                    error={errors.email}
                    touched={touched.email}
                  />
                  <PasswordInput
                    name="password"
                    label={`${t("password")}*`}
                    // placeholder={t("password")}
                    value={values.password}
                    handleChange={handleChange}
                    error={errors.password}
                    touched={touched.password}
                  />
                  <Input
                    name="organisationName"
                    label={`${t("organisationName")}*`}
                    // placeholder={t("placeholderOrganisationName")}
                    type="text"
                    value={values.organisationName}
                    handleChange={handleChange}
                    error={errors.organisationName}
                    touched={touched.organisationName}
                  />
                </div>
              </div>
              <div style={styles.formContent}>
                <div style={styles.inline}>
                  <Input
                    name="lastName"
                    label={`${t("lastName")}*`}
                    // placeholder={t("placeholderSurname")}
                    type="text"
                    value={values.lastName}
                    handleChange={handleChange}
                    error={errors.lastName}
                    touched={touched.lastName}
                  />
                  <Input
                    name="role"
                    label={`${t("role")}*`}
                    // placeholder={t("placeholderRole")}
                    type="text"
                    value={values.role}
                    handleChange={handleChange}
                    error={errors.role}
                    touched={touched.role}
                  />
                  <PasswordInput
                    name="confirmPassword"
                    label={`${t("confirmPassword")}*`}
                    // placeholder={t("password")}
                    value={values.confirmPassword}
                    handleChange={handleChange}
                    error={errors.confirmPassword}
                    touched={touched.confirmPassword}
                  />
                  <Input
                    name="organisationNumber"
                    label={`${t("organisationNumber")}*`}
                    // placeholder={t("placeholderOrganisationNumber")}
                    type="text"
                    value={values.organisationNumber}
                    handleChange={handleChange}
                    error={errors.organisationNumber}
                    touched={touched.organisationNumber}
                  />
                </div>
              </div>
            </div>
            <div style={styles.termsAndConditionsContainer}>
              <div style={styles.termsAndConditions}>
                <Bottom
                  valueTerms={values.termsAndConditions}
                  onChange={handleChange}
                  errorTerms={errors.termsAndConditions}
                  touchedTerms={touched.termsAndConditions}
                />
              </div>
            </div>
            <div style={styles.btnContainer}>
              <DefaultButton
                text={t("register")}
                type="submit"
                style={styles.submitBtn}
                variant="primary"
                loading={isLoading}
              />
            </div>
          </form>
        </>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  btnContainer: {
    textAlign: "center",
  },
  submitBtn: {
    maxWidth: "656px",
    width: "100%",
    height: "63px",
    background: "#07285D",
    border: "0",
    borderRadius: "4px",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "19px",
    letterSpacing: "0.07em",
    color: "#FFFFFF",
    margin: "15px 0 30px 0",
    justifyContent: "center",
  },
  forgotPassword: {
    fontFamily: "d-din",
    fontStyle: "italic",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    color: "#4FBFC0",
    textDecoration: "underline",
    marginTop: "5px",
  },
  formContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: "10px",
  },
  formContent: {
    width: "328px",
    marginRight: "7.5px",
    marginLeft: "7.5px",
  },
  checkbox: {
    marginLeft: "8px",
    width: "315px",
  },
  checkBoxText: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "13px",
    color: "#808795",
  },
  inline: {
    display: "inline",
  },
  termsAndConditionsContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  termsAndConditions: {
    display: "flex",
    justifyContent: "left",
    width: "656px",
  },
});
