import React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "../../../../hooks/useTranslation";
import { useNavigate } from "react-router";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../../services/notificationService";
import style from "../styles/style.module.css";
import {
  useAddSniLevel3,
  useGetAllSniLevel2,
  useGetCheckSni3,
} from "../../../../hooks/api/Admin";
import { Select } from "antd";

export const SniLevel3 = () => {
  const [sniLevel2Name, setSniLevel2Name] = useState("");
  const [code, setCode] = useState("");
  const [swedishName, setSwedishName] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const { t } = useTranslation("addSni");

  const navigate = useNavigate();

  const { mutate } = useAddSniLevel3();

  const { data: SniLevel2NamesData } = useGetAllSniLevel2();
  const { data: sniResult } = useGetCheckSni3(code);
  let chkError = "";
  if (sniResult === true) {
    chkError = "Sni already Exists";
  }

  function validate(code: string) {
    let errors = "";
    if (code && code.length !== 4) {
      errors = "Sni code must be exactly 4 character long";
    }
    return errors;
  }

  const renderedSniLevel2Options = useMemo(
    () =>
      SniLevel2NamesData?.filter((sni) => !!sni.name).map((sni) => {
        return {
          value: `${sni.code ? sni.code : ""} - ${sni.name ? sni.name : ""}`,
          id: sni.id,
        };
      }),
    [SniLevel2NamesData]
  );
  const sniLevel2Code = sniLevel2Name.split(" ")[0];
  const sniLevel2_Id = useMemo(
    () =>
      SniLevel2NamesData?.find((option) => option.code === sniLevel2Code)?.id,
    [sniLevel2Name, sniLevel2Code, SniLevel2NamesData]
  );

  const handleSniLevel3Submit = () => {
    if (name == "" || swedishName == "" || code == "" || !sniLevel2_Id) {
      DisplayErrorNotification(t("ProvideAllData"));
    } else {
      const newErrors = validate(code);
      setError(newErrors);

      if (newErrors === "" && chkError == "") {
        const dataToSend = {
          data: {
            sniLevel2_Id: sniLevel2_Id,
            code: code,
            name: name,
            swedishName: swedishName,
          },
        };

        mutate(dataToSend, {
          onSuccess: () => {
            DisplaySuccessNotification(t("addSniSuccess"));
            navigate("/dashboard");
          },
          onError: () => {
            DisplayErrorNotification(t("addSniError"));
          },
        });
      } else {
        if (chkError !== "") {
          DisplayErrorNotification(t("SniAlreadyExists"));
        } else {
          DisplayErrorNotification(t("WrongSniError"));
        }
      }
    }
  };
  return (
    <div className={style.container}>
      <p className={style.heading}>Add Sni Level 3</p>
      <div className={style.row}>
        <div className={style.col}>
          <p className={style.inputLabel}>Enter code</p>
          <input
            className={style.input}
            type="text"
            placeholder="Code"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
              const newErrors = validate(e.target.value);
              setError(newErrors);
            }}
          />
          {error && code.length !== 4 && (
            <div className={style.error}>{error}</div>
          )}
          {sniResult == true && <div className={style.error}>{chkError}</div>}
        </div>
        <div className={style.col}>
          <p className={style.inputLabel}>Enter name</p>
          <input
            className={style.input}
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div className={style.col}>
          <p className={style.inputLabel}>Enter swedish name</p>
          <input
            className={style.input}
            type="text"
            placeholder="Swedish Name"
            value={swedishName}
            onChange={(e) => {
              setSwedishName(e.target.value);
            }}
          />
        </div>
        <div className={style.col}>
          <p className={style.inputLabel}>Select SNI Level 2</p>
          <div className={style.supplierBox}>
            <div className={style.box}>
              <div className={`${style.agreementSelect}`}>
                <Select
                  className={style.select}
                  size={"large"}
                  onChange={(value) => setSniLevel2Name(value)}
                  value={sniLevel2Name}
                  options={
                    renderedSniLevel2Options ? renderedSniLevel2Options : []
                  }
                  dropdownClassName={style.Dropdown}
                ></Select>
              </div>
            </div>
          </div>
        </div>
        <div className={style.btnContainer}>
          <button
            className={style.btn}
            type="submit"
            onClick={() => {
              handleSniLevel3Submit();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
