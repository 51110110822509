import { Link } from "react-router-dom";
import React from "react";
import { StyleSheet } from "../models/StyleSheet";
import {
  RightOutlined,
  LeftOutlined,
  FileExcelFilled,
} from "@ant-design/icons";
import { DefaultButton } from "../components";
import { useTranslation } from "../hooks/useTranslation";
import cssStyles from "./styles/headerBar.module.css";
import { Button } from "antd";
export const HeaderBar = ({
  header,
  text,
}: {
  header: string;
  text: string;
}) => {
  return (
    <div style={styles.topBar}>
      <div style={styles.topBarContainer}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            maxWidth: "436px",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {/* <img src="https://avatars.githubusercontent.com/" /> */}
          <h1 style={styles.topBarHeader} className={cssStyles.topBarHeader}>
            {header}
          </h1>
          {/* <p style={styles.bottomLine}></p> */}
        </div>
        <div>
          {/* <DefaultButton
            style={styles.btn}
            text={"Tillbaka"}
            variant="primary"
          /> */}
          <Link to="/">
            <Button style={styles.btn}>
              <span>Tillbaka</span>
              <RightOutlined style={styles.backIcon} />
            </Button>
          </Link>
        </div>
        {/* <p style={styles.topBarText}>{text}</p> */}
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  topBar: {
    margin: "0px -24px",
    background: "#FFFF",
  },
  topBarContainer: {
    padding: "0 80px",
    height: "72px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  btn: {
    width: "220px",
    backgroundColor: "#07285D",
    borderRadius: "22px",
    fontFamily: "d-din",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#fff",
    border: "0px",
    height: "36px",
  },
  backIcon: {
    transform: "translateX(56px)",
  },
  bottomLine: {
    transition: "transform 0.4s ease-in-out",
    position: "absolute",
    left: "186px",
    height: "3px",
    width: "25%",
    transform: "translateY(44px)",
    borderRadius: "4px",
    backgroundColor: "#4FBFC0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "2px dashed red",
  },
  topBarHeader: {
    color: "#000000",
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "35px",
    letterSpacing: "0.07em",
    marginBottom: "0px",
    height: "72px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "253px",
  },
  topBarText: {
    color: "white",
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
  },
});
