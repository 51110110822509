import React from "react";
import { Col, Modal, Row } from "antd";
import { useState } from "react";
import { StyleSheet } from "../../../models/StyleSheet";
import { TopBar } from "../SupplierProfileModal/TopBar";
import { ProfileMenu } from "../SupplierProfileModal/ProfileMenu";
import { DefaultButton } from "../../buttons/DefaultButton";
import { Conditional } from "../../Conditional";
import { BoldedText } from "../../text/BoldedText";
import { useTranslation } from "../../../hooks/useTranslation";

export interface ProfileModalProps {
  text: string;
  typeColor?: "primary" | "secondary";
  menuItems: { subitem: string; url: string }[];
}

export function ProfileModal({ text, menuItems }: ProfileModalProps) {
  const [visible, setVisible] = useState(false);
  const [document, setDocument] = useState("");
  const [touched, setTouched] = useState(false);

  const { t } = useTranslation("supplierReviews");

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <DefaultButton
        style={styles.btn}
        text={text}
        onClick={() => setVisible(true)}
        variant="primary"
      />

      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        width={1700}
      >
        <TopBar />

        <Row style={{ minHeight: "300px" }}>
          <Col span={18} push={6}>
            <Conditional
              condition={!!document}
              trueRender={<iframe src={document} style={styles.pdf}></iframe>}
              falseRender={
                <Conditional
                  condition={menuItems.length === 0}
                  trueRender={
                    <BoldedText style={styles.text}>
                      {t("noCompany")}
                    </BoldedText>
                  }
                  falseRender={
                    <BoldedText style={styles.text}>
                      {touched ? t("noAttachment") : t("selectAttachment")}
                    </BoldedText>
                  }
                />
              }
            />
          </Col>
          <Col span={6} pull={18}>
            <ProfileMenu
              menuItems={menuItems}
              setDocument={setDocument}
              setTouched={setTouched}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
const styles = StyleSheet.create({
  link: {
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "13.03px",
    color: "#000",
    textDecoration: "underline",
    marginLeft: "3px",
    marginBottom: "2px",
  },
  btn: {
    padding: "7.5px 26.5px",
    width: "192px",
    height: "32px",
  },
  pdf: { width: "100%", height: "700px" },
  text: {
    textAlign: "center",
    marginTop: "100px",
  },
});
