import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import swe from "./locales/swe.json";

const language = process.env.REACT_APP_LANGUAGE ?? "";
i18n
  .use(initReactI18next)
  .init({
    compatibilityJSON: "v3",
    resources: {
      en: {
        translation: en,
      },
      swe: {
        translation: swe,
      },
    },
    lng: language,
    fallbackLng: "swe",
    defaultNS: "common",
    interpolation: {
      escapeValue: false,
    },
  })
  .catch(() => {});

export { i18n };
