import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Categories from "../containers/Buyer/Categories";
import { CreateReview } from "../containers/Buyer/CreateReview";
import { CreateAssessmentForm } from "../containers/Buyer/CreateReview/CreateAssessmentForm";
import { CategoryDetails } from "../containers/Buyer/CategoriesDetails";
import { SupplierProfile } from "../containers/Buyer/SupplierReviews";
import TopBar from "../containers/Buyer/components/TopBar";
import Insights from "../containers/Buyer/Insights";

import {
  InteractDialogue,
  InteractNotRegistered,
  InteractRegistered,
} from "../containers/Buyer/Interact";
import FooterBar from "../containers/Buyer/components/FooterBar";
import SupplierProfileDetails from "../containers/Buyer/SupplierProfileDetails";
import { EditReview } from "../containers/Buyer/EditReview";

export function BuyerRoutes() {
  return (
    <>
      <Routes>
        <Route path="/*" element={<Navigate to="/categories" />} />
        <Route path="/categories" element={<Categories />} />
        <Route
          path="/categories/:suntCategoryId"
          element={
            <>
              <TopBar />
              <CategoryDetails />
            </>
          }
        />
        <Route path="/create-review" element={<CreateReview />} />
        <Route
          path="/create-review/categories/:suntCategoryId"
          element={<CreateReview />}
        />
        <Route path="/edit-review/:reviewId" element={<EditReview />} />
        <Route
          path="/create-assessment/categories/:suntCategoryId/organisation/:organisationId/assessment"
          element={<CreateAssessmentForm />}
        />
        <Route
          path="/insights/:suntCategoryId"
          element={
            <>
              <TopBar />
              <Insights />
            </>
          }
        />
        <Route
          path="/supplier-reviews/categories/:suntCategoryId/organisations/:organisationId"
          element={
            <>
              {/* <TopBar /> */}
              <SupplierProfile />
            </>
          }
        />
        <Route
          path="/supplier-reviews/categories/:suntCategoryId/organisations/:organisationId/supplier-details"
          element={<SupplierProfileDetails />}
        />
        <Route path="/invite-to-sunt" element={<InteractNotRegistered />} />
        <Route path="/invite-to-tender" element={<InteractRegistered />} />
        <Route path="/invite-to-dialogue" element={<InteractDialogue />} />
      </Routes>
      <FooterBar />
    </>
  );
}
