import React from "react";
import "../../../css/public.css";
import { StyleSheet } from "../../../models/StyleSheet";
import { MultiSelection } from "./MultiSelection";
import { useTranslation } from "../../../hooks/useTranslation";
import "../../../css/MultiSelection.css";

export const Onboarding = () => {
  const { t } = useTranslation("onboardingPage");
  return (
    <div className="background">
      <div style={styles.container}>
        <h1 style={styles.header}>{t("welcome")}</h1>
        <h3 style={styles.info}>{t("information")}</h3>
        <MultiSelection />
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: "auto",
    fontFamily: "D-Din",
    fontStyle: "normal",
    marginTop: "30px",
    textAlign: "center",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
    width: "800px",
    minHeight: "600px",
    backgroundColor: "#FFFFFF",
    marginBottom: "15px",
  },
  header: {
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "35px",
    letterSpacing: "0.07em",
    paddingTop: "32px",
    paddingBottom: "16px",
  },
  info: {
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "22px",
    paddingBottom: "16px",
  },
});
