import React, { useState } from "react";
import { HeaderBar } from "../../../../components/HeaderBar";
import { BoldedText, DimmedText } from "../../../../components";
import { Formik, Form } from "formik";
import {
  initialValues,
  validationSchema,
  valuesInterface,
} from "../../../Common/InteractDialogue/TemplateDialogue.utils";
import { useTranslation } from "../../../../hooks/useTranslation";
import { useNavigate } from "react-router";
import { usePostInteractDialogue } from "../../../../hooks/api/Interact";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../../services/notificationService";
import { MultipleSelectInteract } from "../../../../components";
import { useLocation } from "react-router";
import { useGetOrganisationsByName } from "../../../../hooks/api/Organisations";
import {
  styles,
  TemplateDialogue,
} from "../../../Common/InteractDialogue/TemplateDialogue";

interface CustomizedState {
  activatedSupplier: number[];
}

export const InteractDialogue = () => {
  const { activatedSupplier } = useLocation().state as CustomizedState;
  const [name, setName] = useState("");
  const { t } = useTranslation("interactDialogue");
  const { t: tNotification } = useTranslation("notificationService");
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [correctlyCompleted, setCorrectlyCompleted] = useState<boolean | null>(
    null
  );
  const [organizationError, setOrganizationError] = useState("");

  const [organisations, setOrganisations] = useState<number[]>(
    activatedSupplier ? activatedSupplier : []
  );

  const { mutate } = usePostInteractDialogue();

  const { data: organizationData } = useGetOrganisationsByName({
    name,
    ids: organisations,
  });

  const navigate = useNavigate();

  const onSubmit = (val: valuesInterface) => {
    if (correctlyCompleted && !organizationError) {
      val.categoryId = categoryId;
      mutate(
        {
          suppliersIds: organisations,
          level: val.level ? val.level : 0,
          categoryId: val.categoryId ? val.categoryId : 0,
          subject: val.subject,
          link: val.link,
          message: val.message,
          suntPolicy: val.suntPolicy,
        },
        {
          onSuccess: () => {
            DisplaySuccessNotification(tNotification("success"));
            navigate("/categories");
          },
          onError: () => {
            DisplayErrorNotification(tNotification("error"));
          },
        }
      );
    }
  };

  const checkCorrectName = () => {
    if (organisations.length !== 0) {
      setOrganizationError("");
    } else {
      setOrganizationError(t("incorrectOrganization"));
    }
  };

  return (
    <>
      <HeaderBar header={t("header")} text={t("headerDescription")} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, errors, touched }) => (
          <>
            <Form>
              <div style={styles.container}>
                <div style={styles.textContainer}>
                  <BoldedText style={styles.mainText}>
                    {t("contactInformation")}
                  </BoldedText>
                  <DimmedText>{t("contactDescription")}</DimmedText>
                </div>
                <div style={styles.section}>
                  <BoldedText style={styles.sectionTextHeader}>
                    {t("receiver")}
                  </BoldedText>
                  <DimmedText>{t("selectReceiver")}</DimmedText>
                  <MultipleSelectInteract
                    setValue={setOrganisations}
                    value={organisations}
                    placeholder={t("supplier")}
                    onBlur={checkCorrectName}
                    error={organizationError}
                    setTyped={setName}
                    options={organizationData?.map((organisation) => {
                      return {
                        value: organisation.id,
                        label: organisation.name,
                      };
                    })}
                  />
                </div>
                {/* <div style={styles.section}>
                  <BoldedText style={styles.sectionTextHeader}>
                    {t("sectionContactInformation")}
                  </BoldedText>
                  <DimmedText>{t("sectionContactText")}</DimmedText>
                  <div style={styles.flex}>
                    <DefaultInput
                      style={styles.input}
                      type="text"
                      placeholder={t("emailPlaceholder")}
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      error={errors.email}
                      touched={touched.email}
                    />
                    <DefaultInput
                      style={styles.input}
                      type="text"
                      placeholder={t("phonePlaceholder")}
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div> */}
                <TemplateDialogue
                  categoryId={categoryId}
                  setCategoryId={setCategoryId}
                  correctlyCompleted={correctlyCompleted}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  customOnSubmit={() => {
                    checkCorrectName();
                  }}
                  customOnClear={() => {
                    setCorrectlyCompleted(null);
                    setOrganizationError("");
                    setOrganisations([]);
                  }}
                  setCorrectlyCompleted={setCorrectlyCompleted}
                />
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
