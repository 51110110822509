import React from "react";
import { BuyerIcon, SupplierIcon } from "../../../../assets";
import { styles } from "../singup.utils";
import { useTranslation } from "../../../../hooks/useTranslation";
import "../../../../css/signup.css";

export const LeftSide = ({
  selected,
  setSelected,
}: {
  selected: null | "buyer" | "supplier";
  setSelected: React.Dispatch<
    React.SetStateAction<"buyer" | "supplier" | null>
  >;
}) => {
  const { t } = useTranslation("signupPage");
  return (
    <div style={styles.leftSide}>
      <p style={styles.leftSideQuestion}>{t("registerQuestion")}</p>
      <div style={styles.btnContainer}>
        <button
          style={
            selected === "supplier"
              ? { ...styles.btn, ...styles.btnSelected }
              : { ...styles.btn, ...styles.btnUnSelected }
          }
          className="selectBtn"
          onClick={() => setSelected("supplier")}
          type="button"
        >
          <SupplierIcon
            style={selected === "supplier" ? styles.iconSelected : styles.icon}
          />
          <span style={styles.btnText}>{t("supplierStatement")}</span>
        </button>
        <button
          style={
            selected === "buyer"
              ? { ...styles.btn, ...styles.btnSelected }
              : { ...styles.btn, ...styles.btnUnSelected }
          }
          className="selectBtn"
          onClick={() => setSelected("buyer")}
          type="button"
        >
          <BuyerIcon
            style={selected === "buyer" ? styles.iconSelected : styles.icon}
          />
          <span style={styles.btnText}>{t("buyerStatement")}</span>
        </button>
      </div>
    </div>
  );
};
