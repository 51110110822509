import { API } from "../../services/API";
import { useMutation, useQuery } from "react-query";

export interface SupplierCompany {
  organisation: {
    name: string;
    websiteURL?: string;
    logoURL?: string;
    phoneNumber?: string;
  };
}

export interface SupplierData {
  organisationId?: number;
  organisationData?: {
    organisationForm?: string;
    SME?: string;
    registrationDate?: Date | null;
    turnover?: number;
    description?: string;
    services?: string;
    branschSNI?: string;
    websiteURL?: string;
    facebookURL?: string;
    linkedinURL?: string;
    googleURL?: string;
    secondLevelCategories?: string;
    streetName?: string;
    postalCode?: string;
    city?: string;
    phoneNumber?: string;
    province?: string;
    country?: string;
    mobile?: string;
  };
}

const getSupplierCompany = async () => {
  const url = "/supplier/profile-info";
  const { data } = await API.get<unknown, SupplierCompany>(url);
  return data;
};

export const useGetSupplierCompany = () => {
  return useQuery(["getSupplierCompany"], () => getSupplierCompany());
};

export interface SupplierCompanyReviews {
  count: number;
  ratings: {
    _avg: {
      overallRating: null | number;
      pricingRating: null | number;
      qualityRating: null | number;
      innovationRating: null | number;
      sustainabilityRating: null | number;
      customerServiceRating: null | number;
      deliverCapacity: null | number;
    };
  };
}
const getSupplierCompanyReviews = async () => {
  const url = "/supplier/profile-reviews";
  const { data } = await API.get<unknown, SupplierCompanyReviews>(url);
  return data;
};
export const useGetSupplierCompanyReviews = () => {
  return useQuery(["getSupplierCompanyReviews"], () =>
    getSupplierCompanyReviews()
  );
};

const saveSupplierData = async (data: SupplierData) => {
  const url = "/supplier/save-rganisation-info";
  const { data: response } = await API.post(url, data);
  return response;
};
export const useSaveData = () => {
  return useMutation(saveSupplierData);
};

const getCountiesAndCities = async () => {
  const url = "/county";
  const { data } = await API.get(url);
  return data;
};
export const useGetCounties = () => {
  return useQuery(["getCountiesAndCities"], () => getCountiesAndCities());
};

const getLogoUrl = async (logoUrlKey: string) => {
  const url = `/supplier/get-file-url/${logoUrlKey}`;
  const { data } = await API.get(url);
  return data;
};
export const useGetLogoUrl = (logoUrlKey: string) => {
  return useQuery(["getLogoUrl", logoUrlKey], () => getLogoUrl(logoUrlKey));
};

// const postSupplierContactInfo = async (data: SupplierData) => {
//   const url = "/supplier/organisation-contact-info";
//   const { data: response } = await API.post(url, data);
//   return response;
// };
// export const usePostSupplierContactInfo = () => {
//   return useMutation(postSupplierContactInfo);
// };
