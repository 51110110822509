import { Space } from "antd";
import { Formik, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";

import { useParams } from "react-router";
import CalendarIcon from "../../../assets/icons/calendarIcon";
import { MultipleSelect, MultipleSelectCheckbox } from "../../../components";
import { useGetCategoryNames } from "../../../hooks/api/Category";
import { useGetPositionToOrganisation } from "../../../hooks/api/Reviews";
import { useTranslation } from "../../../hooks/useTranslation";
import { StyleSheet } from "../../../models/StyleSheet";
import { Dropdown } from "../components/Dropdown";
import { QueryInterface } from "../SupplierReviews";
import cssStyle from "./styles/style.module.css";

export const ReviewsFilter = ({
  setQuery,
  setCatNames,
}: {
  setQuery: React.Dispatch<
    React.SetStateAction<[QueryInterface, boolean | undefined]>
  >;
  setCatNames: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const { t } = useTranslation("reviewsFilter");
  const { organisationId, suntCategoryId } = useParams();

  const [detailedCategoryId, setDetailedCategoryId] = useState<string[]>([]);
  const [grade, setGrade] = useState<string[]>([]);
  const [position, setPosition] = useState<string[]>([]);
  // date input states
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [inputFromState, setInputFromState] = useState("text");
  const [inputToState, setInputToState] = useState("text");

  const { data: categoryDetailedData } = useGetCategoryNames(
    parseInt(suntCategoryId || "")
  );
  const [categoryNames, setCategoryNames] = useState(categoryDetailedData);

  useEffect(() => {
    setCategoryNames(categoryDetailedData);
  }, [categoryDetailedData]);

  const grade_Options = [
    { value: "5" },
    { value: "4.5" },
    { value: "4" },
    { value: "3.5" },
    { value: "3" },
    { value: "2.5" },
    { value: "2" },
    { value: "1.5" },
    { value: "1" },
    { value: "0.5" },
    { value: "0" },
  ];

  const [gradeOptions, setGradeOptions] = useState(grade_Options);

  const { data: positionData } = useGetPositionToOrganisation(
    suntCategoryId || "",
    organisationId || ""
  );

  useEffect(() => {
    setCategoryNames(categoryDetailedData);
    setGradeOptions(grade_Options);

    const gradeNumbers = grade.map(Number);
    const detailedCategoryIdNumbers = detailedCategoryId.map(Number);
    setQuery((prev) => [
      {
        categoryIds: detailedCategoryIdNumbers,
        reviewsGrades: gradeNumbers,
        position,
        founded_date_filter_start: dateFrom,
        founded_date_filter_end: dateTo,
      },
      !prev[1],
    ]);
  }, [grade, detailedCategoryId, position, dateFrom, dateTo, setQuery]);

  const clearAll = () => {
    clearSubCategories();
    clearReviewGrade();
    clearRoles();
    clearRegistrationDates();
  };

  const clearSubCategories = () => {
    setDetailedCategoryId([]);
    setCategoryNames([]);
    setCatNames([]);
  };

  const clearReviewGrade = () => {
    setGrade([]);
    setGradeOptions([]);
  };

  const clearRoles = () => {
    setPosition([]);
  };

  const clearRegistrationDates = () => {
    setDateFrom("");
    setDateTo("");
    setInputFromState("text");
    setInputToState("text");
  };

  return (
    <div style={styles.container} className={cssStyle.reviewFilter}>
      <div className={cssStyle.heading}>
        <h2>{t("filter")}</h2>
        <p onClick={clearAll}>Rensa alla</p>
      </div>

      <Dropdown label={t("subcategory")} style={styles.categoryContainer}>
        <>
          <div
            onClick={() => {
              clearSubCategories();
            }}
            className={cssStyle.clearBtn}
          >
            <p>Clear</p>
          </div>

          <MultipleSelectCheckbox
            options={
              categoryNames
                ? categoryNames.map((category) => {
                    return {
                      value: category.id.toString(),
                      label: category.suntCategoryDetailed,
                    };
                  })
                : []
            }
            setValues={setDetailedCategoryId}
            setCatNames={setCatNames}
          />
        </>
      </Dropdown>
      <Dropdown label={t("reviewGrade")} style={styles.categoryContainer}>
        <>
          <div
            onClick={() => {
              clearReviewGrade();
            }}
            className={cssStyle.clearBtn}
          >
            <p>Clear</p>
          </div>
          <MultipleSelectCheckbox
            options={gradeOptions}
            setValues={setGrade}
            showGrades={true}
          />
        </>
      </Dropdown>
      <Dropdown label={t("role")}>
        <>
          <div
            onClick={() => {
              clearRoles();
            }}
            className={cssStyle.clearBtn}
          >
            <p>Clear</p>
          </div>
          <MultipleSelect
            placeholder={t("role")}
            value={position}
            setValue={setPosition}
            options={
              positionData
                ? positionData.map((position) => {
                    return { value: position.position };
                  })
                : []
            }
          />
        </>
      </Dropdown>
      <Dropdown label={t("userCreationDate")}>
        <>
          <div
            onClick={() => {
              clearRegistrationDates();
            }}
            className={cssStyle.clearBtn}
          >
            <p>Clear</p>
          </div>
          <Space
            style={styles.dateContainer}
            className={cssStyle.dateContainer}
          >
            <div className={cssStyle.dateInput}>
              <input
                type={inputFromState}
                placeholder="Från"
                value={dateFrom}
                onMouseEnter={() => {
                  setInputFromState("date");
                }}
                // onMouseLeave={() => {
                //   setInputFromState(
                //     dateFrom.length > 0 ? "date" : "text"
                //   );
                // }}
                onBlur={() => {
                  setInputFromState(dateFrom.length > 0 ? "date" : "text");
                }}
                onChange={(e) => {
                  setDateFrom(e.target.value);
                }}
              />
              <div className={cssStyle.dateInputIcon}>
                <CalendarIcon />
              </div>
            </div>
            <div className={cssStyle.dateInput}>
              <input
                type={inputToState}
                placeholder="Till"
                value={dateTo}
                onMouseEnter={() => {
                  setInputToState("date");
                }}
                // onMouseLeave={() => {
                //   setInputToState(dateTo.length > 0 ? "date" : "text");
                // }}
                onBlur={() => {
                  setInputToState(dateTo.length > 0 ? "date" : "text");
                }}
                onChange={(e) => {
                  setDateTo(e.target.value);
                }}
              />
              <div className={cssStyle.dateInputIcon}>
                <CalendarIcon />
              </div>
            </div>
            {/* <DatePicker
              style={styles.dateInput}
              placeholder={t("from")}
              onChange={(v, vString) => {
                setDateFrom(vString);
              }}
            />
            <DatePicker
              style={styles.dateInput}
              placeholder={t("to")}
              onChange={(v, vString) => {
                setDateTo(vString);
              }}
            /> */}
          </Space>
        </>
      </Dropdown>
    </div>
  );
};

const styles = StyleSheet.create({
  categoryContainer: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    // minWidth: "240px",
    width: "100%",
    // maxWidth: "240px",
    minHeight: "calc(100vh - 64px)",
    backgroundColor: "#FFFFFF",
    marginBottom: "-24px",
    paddingBottom: "10px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
  },
  header: {
    width: "100%",
    height: "48px",
    paddingLeft: "15px",
    display: "flex",
    alignItems: "center",
    fontFamily: "d-din",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "22px",
    color: "#000",
  },
  dateContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  dateInput: {
    outline: "none",
    backgroundColor: "#F2F2F5",
    height: "32px",
    border: "0",
    borderRadius: "4px",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    letterSpacing: "0.07em",
    color: "#000000",
    width: "150px",
    padding: "5px 8px",
    margin: "10px 0",
    display: "block",
  },
});
