import React from "react";
import { Typography } from "antd";
import { CSSProperties } from "react";

const { Text } = Typography;

interface H3Props {
  text: string;
  style?: CSSProperties;
  isActive?: boolean;
}

export function H3({ text, style, isActive }: H3Props) {
  return (
    <Text
      style={{ fontSize: 24, fontFamily: "D-DIN", ...style }}
      disabled={isActive === false ? true : false}
    >
      {text}
    </Text>
  );
}
