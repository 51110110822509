import React, { useEffect, useMemo } from "react";
import { ProgressBar } from "../../../components";
import { useTranslation } from "../../../hooks/useTranslation";
import { StarRating } from "../../../components/index";
import { colors } from "../../../constants/colors";
import { StyleSheet } from "../../../models/StyleSheet";
import style from "./styles/ratingContainer.module.css";
import { OrganisationsData } from "../../../hooks/api/Organisations";

type Props = {
  organisation: OrganisationsData | undefined;
};

const RatingContainer = (props: Props) => {
  const { organisation } = props;
  const { t } = useTranslation("supplierReviews");

  const roundRate = (
    name:
      | "pricingRating"
      | "qualityRating"
      | "innovationRating"
      | "sustainabilityRating"
      | "customerServiceRating"
      | "deliverCapacity"
  ) => {
    if (organisation?.reviews.length) {
      const reviews = organisation?.reviews
        .map((review) => review[name])
        .filter((review) => review !== 0)
        .reduce((a, b) => Number(a) + Number(b), 0);
      const len = organisation?.reviews
        .map((review) => review[name])
        .filter((review) => review !== 0).length;
      return reviews !== null && len !== 0 ? (reviews / len).toFixed(2) : null;
    }
    return 0;
  };

  const qualityRate = roundRate("qualityRating");
  const customerServiceRate = roundRate("customerServiceRating");
  const costRate = roundRate("pricingRating");
  const innovationRate = roundRate("innovationRating");
  const sustainRate = roundRate("sustainabilityRating");
  const deliveryCapRate = roundRate("deliverCapacity");

  useEffect(() => {
    console.log(
      qualityRate,
      customerServiceRate,
      costRate,
      innovationRate,
      sustainRate,
      deliveryCapRate
    );
  }, [deliveryCapRate]);

  const reviewsNumber = useMemo(
    () => organisation?.reviews.length || 0,
    [organisation]
  );

  const overallRate = useMemo(() => {
    if (organisation?.reviews) {
      return (
        Math.round(
          (organisation?.reviews
            .map((review) => review.overallRating)
            .reduce((a, b) => a + b, 0) /
            organisation?.reviews.length) *
            2
        ) / 2
      );
    }
    return 0;
  }, [organisation]);

  return (
    <>
      <div className={style.ratingBox}>
        <div className={style.headingBox}>
          <div>
            <h2 className={style.heading}>
              Omdöme: {isNaN(overallRate) ? "0" : overallRate}
            </h2>
            <StarRating
              disabled={true}
              value={isNaN(overallRate) ? 0.0 : overallRate}
              color={colors.primary}
              size={24}
            />
          </div>
          <p>
            Antal omdömen:
            {reviewsNumber <= 1000
              ? reviewsNumber
              : `${Math.round(reviewsNumber / 1000)}k`}
          </p>
        </div>
        <div className={style.progressRow}>
          <div className={style.subContainer}>
            <ProgressBar label={t("quality")} rate={qualityRate} />
            <ProgressBar label={t("sustainability")} rate={sustainRate} />
          </div>
          <div className={style.subContainer}>
            <ProgressBar label={t("deliveryCapacity")} rate={deliveryCapRate} />
            <ProgressBar
              label={t("customerService")}
              rate={customerServiceRate}
            />
          </div>
          <div className={style.subContainer}>
            <ProgressBar label={t("cost")} rate={costRate} />
            <ProgressBar label={t("innovation")} rate={innovationRate} />
          </div>
        </div>
      </div>
    </>
  );
};

export default RatingContainer;

const styles = StyleSheet.create({
  ratingContainer: {
    width: "100%",
    // padding: "0px 0px 16px 0px",
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    marginTop: "15px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
  },
  RowStyle: {
    padding: "0px 10px 10px 25px",
  },
  btn: {
    width: "100%",
    marginTop: "28px",
    padding: "8px 0",
  },
  subContainer: {
    margin: "0px 0px 10px 0px",
  },
  infoIcon: {
    fontSize: "20px",
    color: "#CDD4E2",
    padding: "1px 0px 0px 8px",
    cursor: "pointer",
  },
});
