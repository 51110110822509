import React, { useEffect, useState } from "react";
import { AutoComplete } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import DefaultStyle from "./supplierSearch.module.css";
import {
  useSuntGetCategories,
  useGetAllSuppliers,
} from "../../hooks/api/Category";

function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const SupplierSearch = ({
  style,
  width,
  onSelect,
  defaultValue,
  disabled,
  hideSearchIcon,
}: {
  style?: typeof DefaultStyle;
  width?: number;
  onSelect: (selected: string) => void;
  defaultValue?: string;
  disabled?: boolean;
  hideSearchIcon?: boolean;
}) => {
  const styleToUse = style ?? DefaultStyle;
  const { data: categoriesData } = useSuntGetCategories();
  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const debouncedSearch = useDebounce(searchValue, 500);
  const [options, setOptions] = useState<
    { label: string; value: string; logo: string }[]
  >([]);

  const { data: organisationData } = useGetAllSuppliers(debouncedSearch);

  useEffect(() => {
    const orgs = organisationData?.data.map((org) => ({
      value: `${org.name}//${org.organisationsCategories[0].category.suntCategoryId}//${org.id}`,
      label: `${org.name} | ${org.organisationNumber}`,
      logo: org.logoURL,
    }));

    if (orgs) {
      setOptions(orgs);
    }

    orgs?.length ? setIsOpen(true) : setIsOpen(false);
  }, [organisationData, categoriesData]);

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  const renderItem = (title: string, value: string, logo: string) => ({
    value: value,
    label: (
      <div
        style={{
          display: "flex",
          marginTop: "5px",
        }}
      >
        <span>
          <img
            style={{ height: "23px", width: "23px", marginRight: "10px" }}
            src={logo}
            alt=""
          />
        </span>
        {title}
      </div>
    ),
  });

  const newOptions = [
    {
      options: options.map((option) => {
        return renderItem(option.label, option.value, option.logo);
      }),
    },
  ];

  return (
    <>
      <div className={styleToUse.searchBar}>
        <div className={styleToUse.inputContainer}>
          <AutoComplete
            disabled={disabled}
            open={isOpen}
            style={{ width: width ?? 500 }}
            placeholder="Sök leverantör..."
            onChange={(v) => {
              if (v.split("//").length > 1) {
                setSelectedValue("");
              } else {
                setSelectedValue("");
                setSearchValue(v);
              }
            }}
            options={newOptions}
            bordered={false}
            value={selectedValue || searchValue}
            dropdownClassName={styleToUse.searchBarDropdown}
            onSelect={(selected: string) => {
              const arr = selected.split("//");
              setSelectedValue(arr[0]);

              onSelect(selected);
              setIsOpen(false);
            }}
          />
        </div>
        {!hideSearchIcon && <SearchOutlined className={styleToUse.icon} />}
      </div>
    </>
  );
};
export default SupplierSearch;
