import { LeftOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import React from "react";
import SDStyle from "./style.module.css";
import { useTranslation } from "../../../hooks/useTranslation";
const Services = () => {
  const { t } = useTranslation("supplierProfilePage");
  return (
    <>
      <Collapse
        bordered={false}
        className={SDStyle.collapsePan}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <LeftOutlined
            rotate={isActive ? -90 : 0}
            className={SDStyle.loadMoreArrow}
          />
        )}
      >
        <Collapse.Panel
          key={1}
          header={
            <div className={SDStyle.mediaTopContainer}>
              <p className={SDStyle.categoryHeading}>{t("services")}</p>
            </div>
          }
        ></Collapse.Panel>
      </Collapse>
    </>
  );
};

export default Services;
