import React from "react";
import { Radio, RadioChangeEvent } from "antd";
import { StyleSheet } from "../../models/StyleSheet";

export const DefaultRadio = ({
  options,
  name,
  onChange,
  value,
  error,
  touched,
  errorText,
}: {
  options: { value: string | number; boldLabel?: string; label?: string }[];
  name?: string;
  onChange?: (e: RadioChangeEvent) => void;
  value?: string | number | null;
  error?: string;
  touched?: boolean;
  errorText?: string;
}) => {
  return (
    <div>
      <Radio.Group onChange={onChange} value={value} name={name}>
        {options.map(({ value, boldLabel, label }) => (
          <Radio value={value} key={value} style={styles.radio}>
            <>
              <span style={styles.bold}>{boldLabel}</span>{" "}
              <span style={styles.normal}>{label}</span>
            </>
          </Radio>
        ))}
      </Radio.Group>
      {error && touched ? (
        <div style={styles.errorText}>{errorText}</div>
      ) : null}
    </div>
  );
};

const styles = StyleSheet.create({
  bold: {
    fontFamily: "D-DIN",
    fontWeight: "700px",
    color: "#000000",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
  },
  normal: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400px",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
  },
  radio: {
    margin: "5px 0",
    display: "block",
  },
  errorText: {
    textAlign: "left",
    marginLeft: "8px",
    color: "red",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
  },
});
