import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../constants/colors";
import { useTranslation } from "../../../hooks/useTranslation";
import { LeftSide } from "./components/LeftSide";
import { StyleSheet } from "../../../models/StyleSheet";

export const DefaultPage = ({
  selected,
  setSelected,
}: {
  selected: null | "buyer" | "supplier";
  setSelected: React.Dispatch<
    React.SetStateAction<"buyer" | "supplier" | null>
  >;
}) => {
  const { t } = useTranslation("signupPage");
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  return (
    <>
      <div style={styles.centredContainer}>
        <div style={styles.leftSide}>
          <p style={styles.leftSideHeader}>{t("register")}</p>
        </div>
        <div style={styles.rightSide}>
          <p
            style={styles.rightSideHeader}
            onClick={() => {
              setClicked(true);
              setTimeout(() => {
                navigate("/login");
              }, 500);
            }}
            className="pointer"
          >
            {t("logIn")}
          </p>
        </div>
      </div>
      <div style={styles.centredContainer}>
        <div style={styles.animationContainer}>
          <div style={clicked ? styles.onClickAnimation : styles.noAnimation}>
            {" "}
          </div>
        </div>
      </div>
      <div>
        <LeftSide selected={selected} setSelected={setSelected} />
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  centredContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  animationContainer: {
    marginTop: "-10px",
    width: "656px",
    height: "3px",
    backgroundColor: "rgba(79, 191, 192, 0.3)",
    marginBottom: "10px",
  },
  noAnimation: {
    width: "328px",
    height: "5px",
    marginTop: "-1px",
    backgroundColor: `${colors.secondary}`,
    transition: "all .5s ease 0s",
  },
  onClickAnimation: {
    width: "328px",
    height: "5px",
    marginTop: "-1px",
    marginLeft: "50%",
    backgroundColor: `${colors.secondary}`,
    transition: "all .5s ease 0s",
  },
  btnContainer: {
    textAlign: "center",
  },
  submitBtn: {
    width: "300px",
    height: "63px",
    background: "#07285D",
    border: "0",
    borderRadius: "4px",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "19px",
    letterSpacing: "0.07em",
    color: "#FFFFFF",
    margin: "15px 0 30px 0",
    justifyContent: "center",
  },
  forgotPassword: {
    fontFamily: "d-din",
    fontStyle: "italic",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    color: "#4FBFC0",
    textDecoration: "underline",
    marginTop: "5px",
  },
  formContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "17px",
  },
  formContent: {
    width: "315px",
  },
  checkbox: {
    marginLeft: "8px",
    width: "315px",
  },
  checkBoxText: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "13px",
    color: "#808795",
  },
  leftSide: {
    marginTop: "17px",
    textAlign: "center",
    display: "inline",
    width: "328px",
  },
  rightSide: {
    marginTop: "17px",
    textAlign: "center",
    display: "inline",
    width: "328px",
  },
  leftSideHeader: {
    fontFamily: "d-din",
    fontStyle: "bold",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "26.06px",
    letterSpacing: "0.07em",
    color: "#3F3F3F",
    textAlign: "center",
    width: "100%",
  },
  rightSideHeader: {
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "26.06px",
    letterSpacing: "0.07em",
    color: "#3F3F3F",
    textAlign: "center",
    width: "100%",
  },
});
