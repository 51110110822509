import React from "react";
import style from "./styles/style.module.css";
import mediaStyle from "./styles/media.module.css";
import { MediaPlaceholder } from "./icons";
import Icon, {
  DownOutlined,
  LeftOutlined,
  FilePdfFilled,
} from "@ant-design/icons";
import { Button, Col, Collapse } from "antd";
import { useTranslation } from "../../../hooks/useTranslation";
import PdfIcon from "../../../assets/icons/pdfIcon";
import ImageIcon from "../../../assets/icons/imageIcon";
import VideoIcon from "../../../assets/icons/videoIcon";

const Media = () => {
  const { t } = useTranslation("supplierProfilePage");
  return (
    <>
      <Collapse
        bordered={false}
        className={style.collapsePan}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <LeftOutlined
            rotate={isActive ? 270 : 180}
            className={style.loadMoreArrow}
          />
        )}
      >
        {/* <Collapse.Panel
          key={1}
          header={
            <div className={style.mediaTopContainer}>
              <p className={style.categoryHeading}>{t("attachmentAndMedia")}</p>
            </div>
          }
        >
          <div className={mediaStyle.container}>
            <div className={mediaStyle.box}>
              <div>
                <PdfIcon className={mediaStyle.icon} />
              </div>
              <p>GreenView_Certificate_2020...(1).pdf</p>
            </div>
            <div className={mediaStyle.box}>
              <div>
                <PdfIcon className={mediaStyle.icon} />
              </div>
              <p>GreenView_Certificate_2020...(1).pdf</p>
            </div>
            <div className={mediaStyle.box}>
              <div>
                <ImageIcon className={mediaStyle.icon} />
              </div>
              <p>Bild_Företag_045</p>
            </div>
            <div className={mediaStyle.box}>
              <div>
                <ImageIcon className={mediaStyle.icon} />
              </div>
              <p>Bild_Företag_045</p>
            </div>
            <div className={mediaStyle.box}>
              <div>
                <VideoIcon className={mediaStyle.icon} />
              </div>
              <p>Presentation_045</p>
            </div>
          </div>
        </Collapse.Panel> */}
      </Collapse>
    </>
  );
};

export default Media;
