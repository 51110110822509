/* eslint-disable */
import React, {Fragment, useEffect, useState} from "react";
import {Col, Collapse} from "antd";
import style from "../styles/style.module.css";
import {PdfPlaceholder, MediaPlaceholder} from "../icons";

import Icon, {
    CheckCircleTwoTone,
    CloseCircleTwoTone
} from "@ant-design/icons";
import {OrganisationsData} from "../../../../hooks/api/Organisations";
import {
    DisplayErrorNotification,
    DisplaySuccessNotification
} from "../../../../services/notificationService";
import {dataToSendInterface} from "../pendingRequests.utils";
import {
    useApproveRequest,
    useRejectRequest
} from "../../../../hooks/api/Admin";
import {useNavigate} from "react-router-dom";


const {Panel} = Collapse;

type CAT_INTERFACE = {
    parentId: number,
    subCats: {
        id: string,
        name: string
    }[]
}
const CollapsePanel = ({
                           organisation,
                           state,
                           setState
                       }: {
    organisation: OrganisationsData;
    state: boolean;
    setState: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const navigate = useNavigate();

    const [companyType, setCompanyType] = useState<string>("");
    const [companySize, setCompanySize] = useState<string>("");
    const [registrationDate, setRegistrationDate] = useState<string>("");
    const [turnover, setTurnover] = useState<string>("");
    const [website, setWebsite] = useState<string>("");
    const [streetName, setAddress] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [postalCode, setPostalCode] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [province, setProvince] = useState<string>("");
    const [country, setCountry] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [mobile, setMobile] = useState("");

    //split 5
    // const serviceAreas_array: string[] = organisation.services
    //   ? organisation.services?.split(", ")
    //   : [];

    const serviceAreas_array = organisation.services?.split(", ");
    // const secondLevelCategories_array = [
    //   {
    //     "id": 1134,
    //     "title": "Avfallstjänster & produkter"
    //   },
    //   {
    //     "id": 1197,
    //     "title": "Miljötjänster"
    //   },
    //   {
    //     "id": 1298,
    //     "title": "Snöröjning & halkbekämpning"
    //   },
    //   {
    //     "id": 1304,
    //     "title": "Städmaterial"
    //   }
    // ];
    const secondLevelCategories_array =
        organisation.secondLevelCategories?.split(", ");
    // console.log("Da", JSON.parse(organisation.secondLevelCategories as string));

    const branschSNI_array = organisation.branschSNI?.split(", ");

    // sepreate ids and names
    const getIdsAndNames = (array: string[] | undefined) => {
        // console.log("array: ", array);
        const ids = [];
        const names = [];
        if (array) {
            for (let i = 0; i < array.length; i++) {
                if (i % 2 == 0) {
                    ids.push(array[i]);
                } else {
                    names.push(array[i]);
                }
            }
        }
        // console.log("ids: ", ids, "names: ", names);
        const obj = [];
        for (let i = 0; i < ids.length; i++) {
            obj.push({id: ids[i], name: names[i]});
        }
        // console.log(obj);
        return obj;
    };
    const getIdsAndTitles = (array: any[] | undefined) => {
        // console.log("array: ", array);
        const ids = [];
        const names = [];
        if (array) {
            // @ts-ignore
            for (let i = 0; i < array.length; i++) {

                if (i % 2 == 0) {
                    // @ts-ignore
                    ids.push(array[i].id);
                } else {
                    // @ts-ignore
                    names.push(array[i].title);
                }
            }
        }
        // console.log("ids: ", ids, "names: ", names);
        const obj = [];
        for (let i = 0; i < ids.length; i++) {
            obj.push({id: ids[i], name: names[i]});
        }
        // console.log(obj);
        return obj;
    };

    //objects
    const [serviceAreas_object, setServiceAreas_object] = useState([
        {id: "", name: ""}
    ]);
    // const [secondLevelCategories_object, setSecondLevelCategories_object] =
    //   useState([{ id: "", name: "" }]);
    const [data, setData] = useState<CAT_INTERFACE>({parentId: 0, subCats: [{id: "", name: ""}]});
    const [services, setServices] = useState<{ id: string, cities: { code: number, title: string }[] }[]>([{
        id: "",
        cities: [{code: 0, title: ""}]
    }]);
    const [branschSNI_object, setBranschSNI_object] = useState([
        {id: "", name: ""}
    ]);

    // setting objects

    useEffect(() => {
        // console.log(organisation,"organisation.secondLevelCategories");
        try {
            setServices(JSON.parse(organisation.services as string));
        } catch (e) {
            console.log(e, "e");
        }
        try {
            const parsedCategories = JSON.parse(organisation.secondLevelCategories as string);
            const mutatedCategories = {
                ...parsedCategories,
                subCats: parsedCategories?.subCats?.map((el: { id: string, title: string }) => ({
                    ...el,
                    name: el?.title
                }))
            };
            setData(mutatedCategories);
        } catch (e) {
            console.log(e, "e");
        }
    }, [organisation]);
    console.log(data, "data");
    useEffect(() => {
        // @ts-ignore

        // console.log(JSON.parse(organisation.secondLevelCategories as string),"(JSON.parse(organisation.secondLevelCategories)");
        // setData(JSON.parse(organisation.secondLevelCategories as string))
        // setSecondLevelCategories_object(
        //   getIdsAndTitles(secondLevelCategories_array)
        // );
        setBranschSNI_object(getIdsAndNames(branschSNI_array));
    }, []);

    //approve/reject 5
    const [approvedServiceAreas, setApprovedServiceAreas] = useState<{ code: number, title: string }[]>( //string[]
        []
    );
    const [rejectedServiceAreas, setRejectedServiceAreas] = useState<{ code: number, title: string }[]>( //string[]
        []
    );

    const [approvedSecondLevelCategories, setApprovedSecondLevelCategories] =
        useState<{ id: string; name: string }[]>([]);
    const [rejectedSecondLevelCategories, setRejectedSecondLevelCategories] =
        useState<{ id: string; name: string }[]>([]);
    const [approvedBranschSNI, setApprovedBranschSNI] = useState<{ id: string; name: string }[]>([]);
    const [rejectedBranschSNI, setRejectedBranschSNI] = useState<{ id: string; name: string }[]>([]);

    const check = (array: number[], value: number) => { // string[] , string
        const result = array.includes(value);
        return result;
    };

    const checkObj = (
        array: { id: string; name: string }[],
        value: { id: string; name: string }
    ) => {
        const result = array.includes(value);
        return result;
    };

    const {mutate: approveMutate} = useApproveRequest();
    const {mutate: rejectMutate} = useRejectRequest();

    const getJoined = (ObjArray: { id: string; name: string }[] | undefined) => {
        if (ObjArray) {
            const linear = [];
            for (let i = 0; i < ObjArray.length; i++) {
                linear.push(ObjArray[i].id);
                linear.push(ObjArray[i].name);
            }

            const joined = linear.join(", ");
            return joined;
        }
        return "";
    };

    const getJoinedServices = (ObjArray: { code: number; title: string }[] | undefined) => {
        if (ObjArray) {
            const linear = [];
            for (let i = 0; i < ObjArray.length; i++) {
                linear.push(ObjArray[i].code);
                linear.push(ObjArray[i].title);
            }

            const joined = linear.join(", ");
            return joined;
        }
        return "";
    };

    const handleApprove = () => {
        //join 5
        // const services = approvedServiceAreas.join(", ");
        const services = getJoinedServices(approvedServiceAreas);
        const secondLevelCategories = getJoined(approvedSecondLevelCategories);
        const branschSNI = getJoined(approvedBranschSNI);

        const dataToSend: dataToSendInterface = {
            organisationId: organisation.organisationId,
            organisationData: {
                streetname:
                    organisation.streetName && streetName == "true"
                        ? organisation.streetName
                        : "",
                city: organisation.city && city == "true" ? organisation.city : "",
                province:
                    organisation.province && province == "true"
                        ? organisation.province
                        : "",
                country:
                    organisation.country && country == "true" ? organisation.country : "",
                postalCode:
                    organisation.postalCode && postalCode == "true"
                        ? organisation.postalCode
                        : "",
                phoneNumber:
                    organisation.phoneNumber && phoneNumber == "true"
                        ? organisation.phoneNumber
                        : "",
                mobile:
                    organisation.mobile && mobile == "true" ? organisation.mobile : "",
                organisationForm:
                    organisation.organisationForm && companyType == "true"
                        ? organisation.organisationForm
                        : "",
                turnover:
                    organisation.turnover && turnover == "true"
                        ? organisation.turnover
                        : 0,
                registrationDate:
                    organisation.registrationDate && registrationDate == "true"
                        ? organisation.registrationDate
                        : null,
                SME: organisation.SME && companySize == "true" ? organisation.SME : "",
                websiteURL:
                    organisation.websiteURL && website == "true"
                        ? organisation.websiteURL
                        : "",
                facebookURL: organisation.facebookURL ? organisation?.facebookURL : "",
                linkedinURL: organisation.linkedinURL ? organisation?.linkedinURL : "",
                googleURL: organisation.googleURL ? organisation?.googleURL : "",
                description:
                    organisation.description && description == "true"
                        ? organisation.description
                        : "",
                services: approvedServiceAreas?.length > 0 ? services : "",
                secondLevelCategories:
                    approvedSecondLevelCategories?.length > 0
                        ? secondLevelCategories
                        : "",
                branschSNI: approvedBranschSNI?.length > 0 ? branschSNI : ""
            }
        };

        console.log("1", dataToSend);

        approveMutate(dataToSend, {
            onSuccess: () => {
                DisplaySuccessNotification("Approved Sucessfully");
                setState(!state);
            },
            onError: () => {
                DisplayErrorNotification("Error in Approval");
            }
        });
    };

    const handleReject = () => {
        const dataToSend: dataToSendInterface = {
            organisationId: organisation.organisationId
        };

        console.log("1", dataToSend);

        rejectMutate(dataToSend, {
            onSuccess: () => {
                DisplaySuccessNotification("Rejected Sucessfully");
                setState(!state);
            },
            onError: () => {
                DisplayErrorNotification("Error in Rejection");
            }
        });
    };

    const handleApproveAll = () => {
        setCompanyType("true");
        setCompanySize("true");
        setRegistrationDate("true");
        setTurnover("true");
        const cityArray: { code: number, title: string }[] = services.map(el => Object.assign({}, ...el?.cities));
        setApprovedServiceAreas(cityArray ? cityArray : []);
        setRejectedServiceAreas([]);
        setApprovedSecondLevelCategories(data?.subCats);  //secondLevelCategories_object
        setRejectedSecondLevelCategories([]);
        setApprovedBranschSNI(branschSNI_object);
        setRejectedBranschSNI([]);
        setWebsite("true");
        setAddress("true");
        setPostalCode("true");
        setCity("true");
        setProvince("true");
        setCountry("true");
        setPhoneNumber("true");
        setMobile("true");
        setDescription("true");
    };

    return (
        <div>
            <Collapse defaultActiveKey={["0"]} className={style.collapse}>
                <Panel header={organisation.name} key="1">
                    <div className={style.collapseContainer}>
                        <div className={style.firstChild}>
                            <div className={style.parentRow}>
                                <div className={style.row}>
                                    <div className={style.subCol}>
                                        <div className={style.headingRow}>
                                            <div className={style.firstCol}>
                                                <p className={style.inputLabel}>Företagstyp</p>
                                            </div>
                                            <div className={style.secondCol}>
                                                <div>
                                                    <CheckCircleTwoTone
                                                        onClick={() => {
                                                            setCompanyType("true");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            companyType == "true" ? "#52c41a" : "#cccccc"
                                                        }
                                                    />
                                                    <CloseCircleTwoTone
                                                        onClick={() => {
                                                            setCompanyType("false");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            companyType == "false" ? "red" : "#cccccc"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${style.selectInput}`}>
                                            <div className={style.select}>
                                                {organisation.organisationForm}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.subCol}>
                                        <div className={style.headingRow}>
                                            <div className={style.firstCol}>
                                                <p className={style.inputLabel}>Företagsstorlek</p>
                                            </div>
                                            <div className={style.secondCol}>
                                                <div>
                                                    <CheckCircleTwoTone
                                                        onClick={() => {
                                                            setCompanySize("true");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            companySize == "true" ? "#52c41a" : "#cccccc"
                                                        }
                                                    />
                                                    <CloseCircleTwoTone
                                                        onClick={() => {
                                                            setCompanySize("false");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            companySize == "false" ? "red" : "#cccccc"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${style.selectInput}`}>
                                            <div className={style.select}>{organisation.SME}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style.parentRow}>
                                <div className={style.row}>
                                    <div className={style.subCol}>
                                        <div className={style.headingRow}>
                                            <div className={style.firstCol}>
                                                <p className={style.inputLabel}>Registreringsår</p>
                                            </div>
                                            <div className={style.secondCol}>
                                                <div>
                                                    <CheckCircleTwoTone
                                                        onClick={() => {
                                                            setRegistrationDate("true");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            registrationDate == "true" ? "#52c41a" : "#cccccc"
                                                        }
                                                    />
                                                    <CloseCircleTwoTone
                                                        onClick={() => {
                                                            setRegistrationDate("false");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            registrationDate == "false" ? "red" : "#cccccc"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${style.selectInput}`}>
                                            <div className={style.select}>
                                                {organisation.registrationDate?.substring(0, 10)}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={style.subCol}>
                                        <div className={style.headingRow}>
                                            <div className={style.firstCol}>
                                                <p className={style.inputLabel}>Omsättning (TKR)</p>
                                            </div>
                                            <div className={style.secondCol}>
                                                <div>
                                                    <CheckCircleTwoTone
                                                        onClick={() => {
                                                            setTurnover("true");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            turnover == "true" ? "#52c41a" : "#cccccc"
                                                        }
                                                    />
                                                    <CloseCircleTwoTone
                                                        onClick={() => {
                                                            setTurnover("false");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            turnover == "false" ? "red" : "#cccccc"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${style.selectInput}`}>
                                            <div className={style.select}>
                                                {organisation.turnover}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style.parentRow}>
                                <div className={style.row}>
                                    <div className={style.subCol}>
                                        <div className={style.headingRow}>
                                            <div className={style.leftCol}>
                                                <p className={style.inputLabel}>Serviceområden</p>
                                            </div>
                                        </div>

                                        <div className={`${style.StrechBox}`}>
                                            <div className={style.capsuleBox}>
                                                {services?.map(el => el?.cities?.map((service, index) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <div className={style.headingRow}>
                                                                <div className={style.leftCol}>
                                                                    <div key={index} className={style.capsuleBtn}>
                                                                        <span>{service?.title}</span>
                                                                    </div>
                                                                </div>
                                                                <div className={style.rightCol}>
                                                                    <CheckCircleTwoTone
                                                                        onClick={() => {
                                                                            const arr = rejectedServiceAreas.filter(
                                                                                (ele) => {
                                                                                    return ele != service;
                                                                                }
                                                                            );
                                                                            setRejectedServiceAreas(arr);

                                                                            if (
                                                                                !approvedServiceAreas.includes(service)
                                                                            ) {
                                                                                setApprovedServiceAreas((oldState) => [
                                                                                    ...oldState,
                                                                                    service
                                                                                ]);
                                                                            }
                                                                        }}
                                                                        className={style.icon}
                                                                        twoToneColor={
                                                                            check(approvedServiceAreas?.map(el => el?.code), service?.code)
                                                                                ? "#52c41a"
                                                                                : "#cccccc"
                                                                        }
                                                                    />
                                                                    <CloseCircleTwoTone
                                                                        onClick={() => {
                                                                            const arr = approvedServiceAreas.filter(
                                                                                (ele) => {
                                                                                    return ele != service;
                                                                                }
                                                                            );
                                                                            setApprovedServiceAreas(arr);

                                                                            if (
                                                                                !rejectedServiceAreas.includes(service)
                                                                            ) {
                                                                                setRejectedServiceAreas((oldState) => [
                                                                                    ...oldState,
                                                                                    service
                                                                                ]);
                                                                            }
                                                                        }}
                                                                        className={style.icon}
                                                                        twoToneColor={
                                                                            check(rejectedServiceAreas?.map(el => el?.code), service?.code)
                                                                                ? "red"
                                                                                : "#cccccc"
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    );
                                                }))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className={style.parentRow}>
                <div className={style.row}>
                  <div className={style.subCol}>
                    <div className={style.headingRow}>
                      <div className={style.leftCol}>
                        <p className={style.inputLabel}>Sunt Kategorier</p>
                      </div>
                    </div>

                    <div className={`${style.StrechBox}`}>
                      <div className={style.capsuleBox}>
                        {suntCategories_object?.map((category, index) => {
                          return (
                            <>
                              <div className={style.headingRow}>
                                <div className={style.leftCol}>
                                  <div key={index} className={style.capsuleBtn}>
                                    <span>{category.name}</span>
                                  </div>
                                </div>
                                <div className={style.rightCol}>
                                  <CheckCircleTwoTone
                                    onClick={() => {
                                      const arr = rejectedSuntCategories.filter(
                                        (ele) => {
                                          return ele != category;
                                        }
                                      );
                                      setRejectedSuntCategories(arr);

                                      if (
                                        !approvedSuntCategories.includes(
                                          category
                                        )
                                      ) {
                                        setApprovedSuntCategories(
                                          (oldState) => [...oldState, category]
                                        );
                                      }
                                    }}
                                    className={style.icon}
                                    twoToneColor={
                                      checkObj(
                                        approvedSuntCategories,
                                        category
                                      ) == true
                                        ? "#52c41a"
                                        : "#cccccc"
                                    }
                                  />
                                  <CloseCircleTwoTone
                                    onClick={() => {
                                      const arr = approvedSuntCategories.filter(
                                        (ele) => {
                                          return ele != category;
                                        }
                                      );
                                      setApprovedSuntCategories(arr);

                                      if (
                                        !rejectedSuntCategories.includes(
                                          category
                                        )
                                      ) {
                                        setRejectedSuntCategories(
                                          (oldState) => [...oldState, category]
                                        );
                                      }
                                    }}
                                    className={style.icon}
                                    twoToneColor={
                                      checkObj(
                                        rejectedSuntCategories,
                                        category
                                      ) == true
                                        ? "red"
                                        : "#cccccc"
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

                            <div className={style.parentRow}>
                                <div className={style.row}>
                                    <div className={style.subCol}>
                                        <div className={style.headingRow}>
                                            <div className={style.leftCol}>
                                                <p className={style.inputLabel}>
                                                    Second level Kategorier
                                                </p>
                                            </div>
                                        </div>

                                        <div className={`${style.StrechBox}`}>
                                            <div className={style.capsuleBox}>
                                                {data && data?.subCats?.map(
                                                    (category, index) => {
                                                        return (
                                                            <>
                                                                <div className={style.headingRow}>
                                                                    <div className={style.leftCol}>
                                                                        <div
                                                                            key={index}
                                                                            className={style.capsuleBtn}
                                                                        >
                                                                            <span>{category.name}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className={style.rightCol}>
                                                                        <CheckCircleTwoTone
                                                                            onClick={() => {
                                                                                const arr =
                                                                                    rejectedSecondLevelCategories.filter(
                                                                                        (ele) => {
                                                                                            return ele != category;
                                                                                        }
                                                                                    );
                                                                                setRejectedSecondLevelCategories(arr);

                                                                                if (
                                                                                    !approvedSecondLevelCategories.includes(
                                                                                        category
                                                                                    )
                                                                                ) {
                                                                                    setApprovedSecondLevelCategories(
                                                                                        (oldState) => [
                                                                                            ...oldState,
                                                                                            category
                                                                                        ]
                                                                                    );
                                                                                }
                                                                            }}
                                                                            className={style.icon}
                                                                            twoToneColor={
                                                                                checkObj(
                                                                                    approvedSecondLevelCategories,
                                                                                    category
                                                                                ) == true
                                                                                    ? "#52c41a"
                                                                                    : "#cccccc"
                                                                            }
                                                                        />
                                                                        <CloseCircleTwoTone
                                                                            onClick={() => {
                                                                                const arr =
                                                                                    approvedSecondLevelCategories.filter(
                                                                                        (ele) => {
                                                                                            return ele != category;
                                                                                        }
                                                                                    );
                                                                                setApprovedSecondLevelCategories(arr);

                                                                                if (
                                                                                    !rejectedSecondLevelCategories.includes(
                                                                                        category
                                                                                    )
                                                                                ) {
                                                                                    setRejectedSecondLevelCategories(
                                                                                        (oldState) => [
                                                                                            ...oldState,
                                                                                            category
                                                                                        ]
                                                                                    );
                                                                                }
                                                                            }}
                                                                            className={style.icon}
                                                                            twoToneColor={
                                                                                checkObj(
                                                                                    rejectedSecondLevelCategories,
                                                                                    category
                                                                                ) == true
                                                                                    ? "red"
                                                                                    : "#cccccc"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={style.parentRow}>
                                <div className={style.row}>
                                    <div className={style.subCol}>
                                        <div className={style.headingRow}>
                                            <div className={style.leftCol}>
                                                <p className={style.inputLabel}>SNI-kod</p>
                                            </div>
                                        </div>
                                        <div className={`${style.StrechBox}`}>
                                            <div className={style.capsuleBox}>
                                                {branschSNI_object?.map((sni, index) => {
                                                    return (
                                                        <>
                                                            <div className={style.headingRow}>
                                                                <div className={style.leftCol}>
                                                                    <div key={index} className={style.capsuleBtn}>
                                                                        <span>{sni.name}</span>
                                                                    </div>
                                                                </div>
                                                                <div className={style.rightCol}>
                                                                    <CheckCircleTwoTone
                                                                        onClick={() => {
                                                                            const arr = rejectedBranschSNI.filter(
                                                                                (ele) => {
                                                                                    return ele != sni;
                                                                                }
                                                                            );
                                                                            setRejectedBranschSNI(arr);

                                                                            if (!approvedBranschSNI.includes(sni)) {
                                                                                setApprovedBranschSNI((oldState) => [
                                                                                    ...oldState,
                                                                                    sni
                                                                                ]);
                                                                            }
                                                                        }}
                                                                        className={style.icon}
                                                                        twoToneColor={
                                                                            checkObj(approvedBranschSNI, sni) == true
                                                                                ? "#52c41a"
                                                                                : "#cccccc"
                                                                        }
                                                                    />
                                                                    <CloseCircleTwoTone
                                                                        onClick={() => {
                                                                            const arr = approvedBranschSNI.filter(
                                                                                (ele) => {
                                                                                    return ele != sni;
                                                                                }
                                                                            );
                                                                            setApprovedBranschSNI(arr);

                                                                            if (!rejectedBranschSNI.includes(sni)) {
                                                                                setRejectedBranschSNI((oldState) => [
                                                                                    ...oldState,
                                                                                    sni
                                                                                ]);
                                                                            }
                                                                        }}
                                                                        className={style.icon}
                                                                        twoToneColor={
                                                                            checkObj(rejectedBranschSNI, sni) == true
                                                                                ? "red"
                                                                                : "#cccccc"
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className={style.parentRow}>
                <div className={style.row}>
                  <div className={style.subCol}>
                    <div className={style.headingRow}>
                      <div className={style.leftCol}>
                        <p className={style.inputLabel}>Second level SNI-kod</p>
                      </div>
                    </div>
                    <div className={`${style.StrechBox}`}>
                      <div className={style.capsuleBox}>
                        {secondLevelSNI_object?.map((sni, index) => {
                          return (
                            <>
                              <div className={style.headingRow}>
                                <div className={style.leftCol}>
                                  <div key={index} className={style.capsuleBtn}>
                                    <span>{sni.name}</span>
                                  </div>
                                </div>
                                <div className={style.rightCol}>
                                  <CheckCircleTwoTone
                                    onClick={() => {
                                      const arr = rejectedSecondLevelSNI.filter(
                                        (ele) => {
                                          return ele != sni;
                                        }
                                      );
                                      setRejectedSecondLevelSNI(arr);

                                      if (
                                        !approvedSecondLevelSNI.includes(sni)
                                      ) {
                                        setApprovedSecondLevelSNI(
                                          (oldState) => [...oldState, sni]
                                        );
                                      }
                                    }}
                                    className={style.icon}
                                    twoToneColor={
                                      checkObj(approvedSecondLevelSNI, sni) ==
                                      true
                                        ? "#52c41a"
                                        : "#cccccc"
                                    }
                                  />
                                  <CloseCircleTwoTone
                                    onClick={() => {
                                      const arr = approvedSecondLevelSNI.filter(
                                        (ele) => {
                                          return ele != sni;
                                        }
                                      );
                                      setApprovedSecondLevelSNI(arr);

                                      if (
                                        !rejectedSecondLevelSNI.includes(sni)
                                      ) {
                                        setRejectedSecondLevelSNI(
                                          (oldState) => [...oldState, sni]
                                        );
                                      }
                                    }}
                                    className={style.icon}
                                    twoToneColor={
                                      checkObj(rejectedSecondLevelSNI, sni) ==
                                      true
                                        ? "red"
                                        : "#cccccc"
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                        </div>
                        <div className={style.secondChild}>
                            <div className={style.parentRow}>
                                <div className={style.row}>
                                    <div className={style.subCol}>
                                        <div className={style.headingRow}>
                                            <div className={style.leftCol}>
                                                <p className={style.inputLabel}>Webplats</p>
                                            </div>
                                            <div className={style.rightCol}>
                                                <div>
                                                    <CheckCircleTwoTone
                                                        onClick={() => {
                                                            setWebsite("true");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            website == "true" ? "#52c41a" : "#cccccc"
                                                        }
                                                    />
                                                    <CloseCircleTwoTone
                                                        onClick={() => {
                                                            setWebsite("false");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            website == "false" ? "red" : "#cccccc"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${style.selectInput}`}>
                                            <div className={style.select}>
                                                {organisation.websiteURL}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={style.parentRow}>
                                <div className={style.row}>
                                    <div className={style.subCol}>
                                        <div className={style.headingRow}>
                                            <div className={style.leftCol}>
                                                <p className={style.inputLabel}>Adress</p>
                                            </div>
                                            <div className={style.rightCol}>
                                                <div>
                                                    <CheckCircleTwoTone
                                                        onClick={() => {
                                                            setAddress("true");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            streetName == "true" ? "#52c41a" : "#cccccc"
                                                        }
                                                    />
                                                    <CloseCircleTwoTone
                                                        onClick={() => {
                                                            setAddress("false");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            streetName == "false" ? "red" : "#cccccc"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${style.selectInput}`}>
                                            <div className={style.select}>
                                                {organisation.streetName}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style.parentRow}>
                                <div className={style.row}>
                                    <div className={style.subCol}>
                                        <div className={style.headingRow}>
                                            <div className={style.firstCol}>
                                                <p className={style.inputLabel}>Postnummer</p>
                                            </div>
                                            <div className={style.secondCol}>
                                                <div>
                                                    <CheckCircleTwoTone
                                                        onClick={() => {
                                                            setPostalCode("true");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            postalCode == "true" ? "#52c41a" : "#cccccc"
                                                        }
                                                    />
                                                    <CloseCircleTwoTone
                                                        onClick={() => {
                                                            setPostalCode("false");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            postalCode == "false" ? "red" : "#cccccc"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${style.selectInput}`}>
                                            <div className={style.select}>
                                                {organisation.postalCode}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.subCol}>
                                        <div className={style.headingRow}>
                                            <div className={style.firstCol}>
                                                <p className={style.inputLabel}>Postort</p>
                                            </div>
                                            <div className={style.secondCol}>
                                                <div>
                                                    <CheckCircleTwoTone
                                                        onClick={() => {
                                                            setCity("true");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            city == "true" ? "#52c41a" : "#cccccc"
                                                        }
                                                    />
                                                    <CloseCircleTwoTone
                                                        onClick={() => {
                                                            setCity("false");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={city == "false" ? "red" : "#cccccc"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${style.selectInput}`}>
                                            <div className={style.select}>{organisation.city}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style.parentRow}>
                                <div className={style.row}>
                                    <div className={style.subCol}>
                                        <div className={style.headingRow}>
                                            <div className={style.firstCol}>
                                                <p className={style.inputLabel}>Län</p>
                                            </div>
                                            <div className={style.secondCol}>
                                                <div>
                                                    <CheckCircleTwoTone
                                                        onClick={() => {
                                                            setProvince("true");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            province == "true" ? "#52c41a" : "#cccccc"
                                                        }
                                                    />
                                                    <CloseCircleTwoTone
                                                        onClick={() => {
                                                            setProvince("false");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            province == "false" ? "red" : "#cccccc"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${style.selectInput}`}>
                                            <div className={style.select}>
                                                {organisation.province}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.subCol}>
                                        <div className={style.headingRow}>
                                            <div className={style.firstCol}>
                                                <p className={style.inputLabel}>Land</p>
                                            </div>
                                            <div className={style.secondCol}>
                                                <div>
                                                    <CheckCircleTwoTone
                                                        onClick={() => {
                                                            setCountry("true");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            country == "true" ? "#52c41a" : "#cccccc"
                                                        }
                                                    />
                                                    <CloseCircleTwoTone
                                                        onClick={() => {
                                                            setCountry("false");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            country == "false" ? "red" : "#cccccc"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${style.selectInput}`}>
                                            <div className={style.select}>{organisation.country}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={style.parentRow}>
                                <div className={style.row}>
                                    <div className={style.subCol}>
                                        <div className={style.headingRow}>
                                            <div className={style.firstCol}>
                                                <p className={style.inputLabel}>Telefonnummer</p>
                                            </div>
                                            <div className={style.secondCol}>
                                                <div>
                                                    <CheckCircleTwoTone
                                                        onClick={() => {
                                                            setPhoneNumber("true");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            phoneNumber == "true" ? "#52c41a" : "#cccccc"
                                                        }
                                                    />
                                                    <CloseCircleTwoTone
                                                        onClick={() => {
                                                            setPhoneNumber("false");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            phoneNumber == "false" ? "red" : "#cccccc"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${style.selectInput}`}>
                                            <div className={style.select}>
                                                {organisation.phoneNumber}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.subCol}>
                                        <div className={style.headingRow}>
                                            <div className={style.firstCol}>
                                                <p className={style.inputLabel}>Mobil</p>
                                            </div>
                                            <div className={style.secondCol}>
                                                <div>
                                                    <CheckCircleTwoTone
                                                        onClick={() => {
                                                            setMobile("true");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={
                                                            mobile == "true" ? "#52c41a" : "#cccccc"
                                                        }
                                                    />
                                                    <CloseCircleTwoTone
                                                        onClick={() => {
                                                            setMobile("false");
                                                        }}
                                                        className={style.icon}
                                                        twoToneColor={mobile == "false" ? "red" : "#cccccc"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${style.selectInput}`}>
                                            <div className={style.select}>{organisation.mobile}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={style.parentRow}>
                                <div className={style.headingRow}>
                                    <div className={style.leftCol}>
                                        <p className={style.inputLabel}>Beskrivning</p>
                                    </div>
                                    <div className={style.rightCol}>
                                        <div>
                                            <CheckCircleTwoTone
                                                onClick={() => {
                                                    setDescription("true");
                                                }}
                                                className={style.icon}
                                                twoToneColor={
                                                    description == "true" ? "#52c41a" : "#cccccc"
                                                }
                                            />
                                            <CloseCircleTwoTone
                                                onClick={() => {
                                                    setDescription("false");
                                                }}
                                                className={style.icon}
                                                twoToneColor={
                                                    description == "false" ? "red" : "#cccccc"
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={style.StrechBox}>
                                    <div className={style.description}>
                                        {organisation.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={style.buttonContainer}>
                        <div className={style.parentRow}>
                            <div className={style.row}>
                                <button
                                    className={style.btn}
                                    type="submit"
                                    onClick={() => {
                                        handleApprove();
                                    }}
                                >
                                    Approve
                                </button>
                                <button
                                    className={style.btn}
                                    type="submit"
                                    onClick={() => {
                                        handleReject();
                                    }}
                                >
                                    Reject
                                </button>
                                <button
                                    className={style.btn}
                                    type="submit"
                                    onClick={() => {
                                        handleApproveAll();
                                    }}
                                >
                                    Approve All
                                </button>
                            </div>
                        </div>
                    </div>
                </Panel>
            </Collapse>
        </div>
    );
};

export default CollapsePanel;
