import React, { useMemo } from "react";
import { StyleSheet } from "../../../models/StyleSheet";
import { useGetSuntCategory } from "../../../hooks/api/Category";
import { Avatar, Breadcrumb, Layout, Row, Col, Divider } from "antd";
import { OrganisationsData } from "../../../hooks/api/Organisations";
import { useTranslation } from "../../../hooks/useTranslation";
import { InviteSupplier } from "../components/InviteSupplier";
import {
  DefaultButton,
  ProfileModal,
  StarRating,
} from "../../../components/index";
import { useGetAttachmentPdf } from "../../../hooks/api/Attachment";

import cssStyle from "./styles/style.module.css";
import { IdcardOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { colors } from "../../../constants/colors";

export const TopBar = ({
  suntCategoryId,
  organisationId,
  organisation,
}: {
  suntCategoryId: string | undefined;
  organisationId: string | undefined;
  organisation: OrganisationsData | undefined;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("supplierReviews");
  const reviewsNumber = useMemo(
    () => organisation?.reviews.length || 0,
    [organisation]
  );

  const overallRate = useMemo(() => {
    if (organisation?.reviews) {
      return (
        Math.round(
          (organisation?.reviews
            .map((review) => review.overallRating)
            .reduce((a, b) => a + b, 0) /
            organisation?.reviews.length) *
            2
        ) / 2
      );
    }
    return 0;
  }, [organisation]);

  const { data: attachmentData } = useGetAttachmentPdf(
    (organisation?.id || "").toString()
  );
  const { data: categoryData } = useGetSuntCategory(
    parseInt(suntCategoryId || "")
  );

  const SupplierProfile = () => {
    suntCategoryId &&
      organisationId &&
      navigate(
        `/supplier-reviews/categories/${suntCategoryId}/organisations/${organisationId}/supplier-details`
      );
  };

  return (
    <>
      <div style={styles.header}>
        <Row align="middle">
          <Col sm={24} md={24} lg={16}>
            <div>
              <Breadcrumb style={styles.breadcrumbBox}>
                <Breadcrumb.Item>
                  <a href="/categories" style={styles.breadcrumb}>
                    {t("categories")}
                  </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a
                    href={`/categories/${suntCategoryId || ""}`}
                    style={styles.breadcrumb}
                  >
                    {categoryData?.suntCategory.name}
                  </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span style={styles.organisationNameLink}>
                    {organisation?.name}
                  </span>
                </Breadcrumb.Item>
              </Breadcrumb>
              <div style={styles.box}>
                <div className={cssStyle.categoryNameBox}>
                  <div style={styles.img}>
                    <Avatar
                      src={organisation?.logoURL}
                      // shape={organisation?.logoURL ? "square" : "circle"}
                      // style={{ background: "#808795" }}
                      shape="circle"
                      size={40}
                    >
                      <span
                        style={{
                          fontWeight: "700",
                          fontSize: "32px",
                        }}
                      >
                        {organisation?.name[0]}
                      </span>
                    </Avatar>
                  </div>
                  <h1 style={styles.categoryName}>{organisation?.name}</h1>
                  {/* <SearchOutlined className={cssStyle.polygonIcon} /> */}
                </div>
              </div>
            </div>
          </Col>
          <Col sm={24} md={24} lg={8}>
            <div className={cssStyle.containerBtn}>
              <DefaultButton
                text={t("leaveFeedback")}
                style={styles.btnFeedback}
                variant={"primary"}
                onClick={() =>
                  navigate(
                    `/create-review/categories/${parseInt(
                      suntCategoryId ?? ""
                    )}?organisationId=${parseInt(organisationId ?? "")}`
                  )
                }
              />
              <InviteSupplier />
            </div>
          </Col>
        </Row>
      </div>
      <div className={cssStyle.reviewBar}>
        <div style={styles.headerContainer}>
          {
            <>
              <span style={styles.rating}>
                <StarRating
                  disabled={true}
                  value={isNaN(overallRate) ? 0.0 : overallRate}
                  color={colors.primary}
                  size={24}
                />
              </span>
              <span style={styles.heading}>
                {isNaN(overallRate) ? "0" : overallRate}
              </span>
            </>
          }

          <Divider style={styles.divider} type="vertical" />

          <span style={styles.ReviewCount}>
            {reviewsNumber <= 1000
              ? reviewsNumber
              : `${Math.round(reviewsNumber / 1000)}k`}
          </span>
          <span style={styles.heading}>{t("opinion")}</span>

          <Divider style={styles.divider} type="vertical" />
          <IdcardOutlined style={styles.profileIcon} />
          <p onClick={SupplierProfile} style={styles.profileLink}>
            Leverantörsprofil
          </p>
        </div>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  categoryName: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "35px",
    margin: "12px 35px 12px 10px",
    letterSpacing: "0.07em",
    color: "rgba(7, 40, 93, 1)",
  },
  breadcrumb: {
    letterSpacing: "0.07em",
  },
  profileIcon: {
    color: "rgba(7, 40, 93, 1)",
    fontSize: "28px",
  },
  profileLink: {
    fontFamily: "D-DIN",
    fontStyle: "underlined",
    textDecorationLine: "underline",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "22px",
    letterSpacing: "0.07em",
    color: "#07285D",
    marginBottom: "0px",
    marginLeft: "20px",
    cursor: "pointer",
  },
  btnFeedback: {
    fontFamily: "D-Din",
    fontStyle: "normal",
    backgroundColor: "#07285D",
    borderRadius: "25px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#FFFFFF",
    width: "220px",
    border: "0",
    height: "32px",
    marginBottom: "20px",
  },
  box: {
    display: "inline-block",
  },
  header: {
    background: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    padding: "12px 20px 0px 25px",
  },
  breadcrumbBox: {
    marginBottom: "6px",
  },

  organisationNameLink: {
    display: "inline-block",
    maxWidth: "30ch",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    letterSpacing: "0.07em",
  },
  img: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    margin: "5px 25px 5px 23px",
  },
  logo: {
    height: "auto",
    width: "auto",
    maxWidth: "60px",
    maxHeight: "50px",
  },
  divider: {
    border: "0.2px solid #CDD4E2",
    color: "black",
    height: "3em",
    margin: "0px 40px 0px 40px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heading: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "22px",
    letterSpacing: "0.07em",
    color: "#07285D",
  },

  rating: {
    paddingTop: "8px",
    marginRight: "15px",
  },
  ReviewCount: {
    fontFamily: "D-DIN",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "22px",
    color: "#07285D",
    marginRight: "10px",
    // marginBottom: "-5px",
  },
});
