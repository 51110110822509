import React from "react";
import { useParams } from "react-router";
import { useGetOrganisationsIncludeReviews } from "../../../hooks/api/Organisations";
import RatingContainer from "./RatingContainer";
import ReviewContainer from "./ReviewContainer";
import ReviewFilter from "./ReviewFilter";
import style from "./styles/style.module.css";

const Reviews = () => {
  const { organisationId } = useParams();

  const { data, isError } = useGetOrganisationsIncludeReviews({
    id: parseInt(organisationId ?? ""),
    suntCategoryId: 19,
  });

  return (
    <>
      <div>
        <RatingContainer organisation={data} />
        <div className={style.row}>
          <ReviewFilter />
          <div className={style.reviewContainer}>
            <ReviewContainer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Reviews;
