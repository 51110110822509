import React, { useCallback, useMemo, useState } from "react";
import { Avatar, Button, ButtonProps, Col, Row } from "antd";
import { colors } from "../../constants/colors";
import { StyleSheet } from "../../models/StyleSheet";
import { H3 } from "../text/H3";
import { StarRating } from "../StarRating";
import { OrganisationsData } from "../../hooks/api/Organisations";
import { Conditional } from "../Conditional";

export interface CompanyItemExtendedProps extends ButtonProps {
  typeColor?: "primary" | "secondary";
  company: OrganisationsData;
}

export function CompanyItemExtended({
  onClick,
  icon,
  company,
}: CompanyItemExtendedProps) {
  const [hover, setHover] = useState(false);
  const { name, employeesNumber, city, reviews } = company;

  const ratingsNumbers = useMemo(
    () => reviews.map((review) => review.overallRating),
    [reviews]
  );
  const average = useCallback(
    (arr: Array<number>) => arr.reduce((a, b) => a + b, 0) / arr.length,
    []
  );
  const ratingsAverage = useMemo(
    () => average(ratingsNumbers),
    [average, ratingsNumbers]
  );

  //Rounds to nearest 0.5
  const roundedAverageRating = useMemo(
    () => Math.round(ratingsAverage * 2) / 2,
    [ratingsAverage]
  );

  const logoURL = useMemo(() => {
    if (company?.logoURL) {
      return company.logoURL.includes("size")
        ? company.logoURL.replace("size=", "")
        : company.logoURL;
    }
    return "";
  }, [company?.logoURL]);

  return (
    <Button
      style={hover ? styles.hoveredButton : styles.button}
      type="text"
      onClick={onClick}
      icon={icon}
      block={true}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Row style={styles.container}>
        <Col span={2}>
          <div style={styles.imgContainer}>
            <Conditional
              condition={!!logoURL}
              trueRender={
                <img
                  key={logoURL} // Use key to force re-render only when logoURL changes
                  style={styles.img}
                  src={logoURL}
                />
              }
              falseRender={
                <Avatar shape={logoURL ? "square" : "circle"} size={34}>
                  {company.name[0]}
                </Avatar>
              }
            />
          </div>
        </Col>
        <Col span={5} style={styles.companyNameCol}>
          <H3
            text={name}
            style={hover ? styles.hoverCompanyName : styles.companyName}
          />
        </Col>
        <Col span={4}>
          <H3 text={employeesNumber?.toString()} style={styles.info} />
        </Col>
        <Col span={4}>
          <H3 text={city?.toString()} style={styles.info} />
        </Col>
        <Col span={4}>
          <H3
            text={reviews?.length.toString() ?? "0"}
            style={hover ? styles.hoverRatingCount : styles.ratingCount}
          />
        </Col>
        <Col span={5}>
          <Row style={styles.ratingRow}>
            <Col span={20}>
              <StarRating
                disabled={true}
                value={roundedAverageRating || 0}
                style={styles.rate}
                color={hover ? colors.secondary : colors.primary}
                size={24}
              />
            </Col>
            <Col span={4}>
              <H3
                text={
                  isNaN(roundedAverageRating)
                    ? "0"
                    : roundedAverageRating.toString()
                }
                style={hover ? styles.hoverRating : styles.rating}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Button>
  );
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: "white",
    color: "black",
    height: "58px",
    paddingLeft: "0",
  },
  hoveredButton: {
    backgroundColor: colors.primary,
    color: "black",
    height: "58px",
    paddingLeft: "0",
  },
  container: {
    alignItems: "center",
  },
  imgContainer: {
    position: "relative",
    height: "50px",
    marginRight: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  img: {
    height: "auto",
    width: "auto",
    maxWidth: "20px",
    maxHeight: "20px",
  },
  companyNameCol: {
    overflow: "hidden",
    textAlign: "left",
  },
  hoverCompanyName: {
    color: "white",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0.07em",
  },
  companyName: {
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0.07em",
  },
  info: {
    fontSize: "16px",
    color: "#808795",
    letterSpacing: "0.07em",
  },
  ratingRow: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  rate: {
    color: colors.primary,
  },
  ratingCount: {
    fontSize: "16px",
    color: colors.primary,
    letterSpacing: "0.07em",
  },
  hoverRatingCount: {
    fontSize: "16px",
    color: "white",
    letterSpacing: "0.07em",
  },
  rating: {
    fontSize: "20px",
    color: colors.primary,
    letterSpacing: "0.07em",
  },
  hoverRating: {
    fontSize: "20px",
    color: "white",
    letterSpacing: "0.07em",
  },
});
