import { Select } from "antd";
import React, { useState } from "react";
import { useGetOrganisationsByName } from "../../hooks/api/Organisations";
import { StyleSheet } from "../../models/StyleSheet";

export const MultipleSelectInteractStringData = ({
  value,
  setValue,
  placeholder,
  error,
  onBlur,
  setTyped,
  options,
  style,
}: {
  value: string[];
  setValue: React.Dispatch<React.SetStateAction<string[]>>;
  placeholder?: string;
  error?: string;
  onBlur?: React.FocusEventHandler<HTMLElement> | undefined;
  setTyped: React.Dispatch<React.SetStateAction<string>>;
  options?: { value: string; label: string }[];
  style?: React.CSSProperties;
}) => {
  return (
    <>
      <Select
        style={
          error
            ? { ...styles.select, ...styles.errorInput, ...style }
            : { ...styles.select, ...style }
        }
        placeholder={placeholder}
        showSearch
        mode="multiple"
        value={value}
        onChange={setValue}
        options={options}
        virtual={true}
        allowClear={true}
        filterOption={(input, option) => {
          return (option!.label as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
        maxTagTextLength={3}
        maxTagCount={1}
        onBlur={onBlur}
        onSearch={(val) => {
          setTyped(val);
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  errorInput: {
    boxShadow: "0px 1px 10px rgba(256, 0, 0, 0.5)",
  },
  error: {
    textAlign: "left",
    marginLeft: "8px",
    color: "red",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    marginTop: "10px",
  },
  select: {
    width: "300px",
    borderRadius: "4px",
    maxHeight: "32px",
    color: "#000 !important",
  },
});
