import React from "react";
import { Col, Row, Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import InsightsImage from "../../../assets/images/Buyer_Insights_Illustration w_BG.jpg";
import insightStyle from "./style.module.css";
import { StyleSheet } from "../../../models/StyleSheet";
import { DefaultButton } from "../../../components";

const Insights = () => {
  const handleSupport = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const embedURL = "/spaces?layout=default";
      const iframeSrc = `https://community.sunt.tech/api/auth/sso?jwt=${token}&redirect_uri=${encodeURIComponent(
        embedURL
      )}`;
      window.location.href = iframeSrc;
    }
  };
  return (
    <>
      <div className={insightStyle.container}>
        <Row justify="center">
          <Col xs={24} md={12} lg={10}>
            <p className={insightStyle.heading}>Kommer snart...</p>
            <p className={insightStyle.content}>
              Under tiden kan du kika i vårt community för insikter, analyser
              och diskussioner.
            </p>
            <Button
              onClick={() => {
                handleSupport();
              }}
              style={styles.btnFeedback}
            >
              Community
              <RightOutlined className={insightStyle.icon} />
            </Button>
          </Col>
          <Col xs={24} md={12} lg={10}>
            <img
              src={InsightsImage}
              alt="Insights"
              className={insightStyle.insightImage}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  btnFeedback: {
    fontFamily: "D-Din",
    fontStyle: "normal",
    backgroundColor: "#07285D",
    borderRadius: "25px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "17px",
    letterSpacing: "0.07em",
    color: "#FFFFFF",
    width: "220px",
    border: "0",
    height: "32px",
    marginBottom: "20px",
  },
});

export default Insights;
