import { DatePicker, Radio, Space, Slider } from "antd";
import Icon, { HomeOutlined } from "@ant-design/icons";
import { Field, Formik, FormikProps } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { MinMaxSlider } from "../components/MinMaxSlider";
import { useParams } from "react-router";
import {
  useGetCities,
  useGetCompanyType,
  useGetMaximumTurnover,
  useGetOther,
  useGetPosition,
  useGetTags,
} from "../../../hooks/api/Organisations";
import { useTranslation } from "../../../hooks/useTranslation";
import { StyleSheet } from "../../../models/StyleSheet";
import { Dropdown } from "../components/Dropdown";

import { ShowStars } from "../components/ShowStars";
import { SupplierListFilter } from "./components/SupplierListFilter";
import {
  OrganisationFilterInitialValues,
  OrganisationFilterInterface,
} from "./OrganisationFilterForm.utils";
import {
  useGetCategoryCPVCode,
  useGetCategoryNames,
} from "../../../hooks/api/Category";
import {
  ExperienceRadio,
  MultipleSelect,
  MultipleSelectCheckbox,
} from "../../../components";
import { FilterContext } from "../../../context/FilterProvider";
import cssStyle from "./styles/style.module.css";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
// import { useGlobalContext } from "../CategoriesDetails";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import CalendarIcon from "../../../assets/icons/calendarIcon";

export const OrganisationFilterForm = () => {
  const filterContextState = useContext(FilterContext);

  // const {
  //   catName,
  //   setCatName,
  //   cpvCode,
  //   setCpvCode,
  //   city,
  //   setCity,
  //   reviewGrade,
  //   setReviewGrade,
  //   reviewsMin,
  //   setReviewsMin,
  //   employeesMin,
  //   setEmployeesMin,
  //   employeesMax,
  //   setEmployeesMax,
  //   dateFrom,
  //   setDateFrom,
  //   dateTo,
  //   setDateTo,
  //   // turnoverMin,
  //   // setTurnoverMin,
  //   // turnoverMax,
  //   // setTurnoverMax,
  //   turnoverMaxHardCode,
  //   setTurnoverMaxHardCode,
  //   turnoverMaxValueOfSlider,
  //   setTurnoverMaxValueOfSlider,
  //   experience,
  //   setExperience,
  //   role,
  //   setRole,
  //   tags,
  //   setTags,
  //   companyType,
  //   setCompanyType,
  //   other,
  //   setOther,
  //   supplier_Ids,
  //   setSupplier_Ids,
  // } = useGlobalContext();

  const [experience, setExperience] = useState<string[]>([]);
  const [other, setOther] = useState<string[]>([]);
  const [companyType, setCompanyType] = useState<string[]>([]);
  const [catName, setCatName] = useState<string[]>([]);
  const [cpvCode, setCpvCode] = useState<string[]>([]);
  const [role, setRole] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [city, setCity] = useState<string[]>([]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [changes, setChanges] = useState(false);
  const [reviewGrade, setReviewGrade] = useState();
  const [reviewMin, setReviewMin] = useState(0);
  const [newFromdate, setNewFromDate] = useState<string>("");
  const [minimumReviews, setMinimumReviews] = useState(0);
  const [turnoverMaxValueOfSlider, setTurnoverMaxValueOfSlider] = useState(100);
  const { t } = useTranslation("organisationFilter");
  const form = useRef<FormikProps<OrganisationFilterInterface>>(null);

  const { suntCategoryId } = useParams();

  const { data: oldCategoryNames } = useGetCategoryNames(
    parseInt(suntCategoryId || "")
  );
  const [categoryNames, setCategoryNames] = useState(oldCategoryNames);

  const { data: categoryCPV } = useGetCategoryCPVCode(
    parseInt(suntCategoryId || "")
  );
  const { data: organisationCities } = useGetCities(suntCategoryId || "");
  const { data: organisationTags } = useGetTags(suntCategoryId || "");
  const { data: organisationPosition } = useGetPosition(suntCategoryId || "");
  const { data: oldOrganisationOther } = useGetOther(suntCategoryId || "");
  const { data: oldOrganisationType } = useGetCompanyType(suntCategoryId || "");
  const { data: organisationMaximumTurnover } = useGetMaximumTurnover(
    suntCategoryId || ""
  );

  const [organisationOther, setOrganisationOther] =
    useState(oldOrganisationOther);

  const [organisationType, setOrganisationType] = useState(oldOrganisationType);

  const maximumTurnoverValue = organisationMaximumTurnover ?? 0;

  useEffect(() => {
    const timerID = setTimeout(() => {
      setCategoryNames(oldCategoryNames);
      setOrganisationOther(oldOrganisationOther);
      setOrganisationType(oldOrganisationType);
      const dataForm = form?.current?.values;
      if (dataForm) {
        dataForm.dateTo = dateTo;
        dataForm.dateFrom = dateFrom;
        dataForm.city = city;
        dataForm.role = role;
        dataForm.experience = experience;
        dataForm.tags = tags;
        dataForm.name = catName;
        dataForm.cpv = cpvCode;
        dataForm.companyType = companyType;
        dataForm.other = other;
        dataForm.turnoverMax = turnoverMax;
        dataForm.turnoverMaxHardCode = turnoverMaxValueOfSlider;
        dataForm.turnoverMin = turnoverMin;
        dataForm.reviewsMin = minimumReviews;
      }
      filterContextState?.setQuery([dataForm, changes]);
    }, 100);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterContextState?.query,
    experience,
    role,
    city,
    dateFrom,
    dateTo,
    catName,
    cpvCode,
    changes,
    turnoverMaxValueOfSlider,
  ]);

  const onChange = () => {
    setChanges((prevStage) => !prevStage);
  };

  const [turnoverMin, setTurnoverMin] = useState(0);
  const [turnoverMax, setTurnoverMax] = useState(0);

  const [inputFromState, setInputFromState] = useState("text");
  const [inputToState, setInputToState] = useState("text");

  const [resetTkr, setResetTkr] = useState(Math.random());

  const resetTkrHandler = () => {
    setResetTkr(Math.random());
  };

  const handleTurnoverSlider = (newValue: [number, number]) => {
    setTurnoverMaxValueOfSlider(newValue[1]);
    setTurnoverMin(
      Math.round(
        (newValue[0] / 100) * parseInt(maximumTurnoverValue?.toString())
      )
    );
    setTurnoverMax(
      Math.round(
        (newValue[1] / 100) * parseInt(maximumTurnoverValue?.toString())
      )
    );
  };

  const reviewConverter = (value: number): string => {
    let reviewNumber = 0;
    if (value === 0) {
      setMinimumReviews(0);
      setReviewMin(0);
      reviewNumber = 0;
      return reviewNumber.toString();
    } else if (value === 25) {
      setReviewMin(25);
      setMinimumReviews(1);
      reviewNumber = 1;
      return reviewNumber.toString();
    } else if (value === 50) {
      setReviewMin(50);
      setMinimumReviews(2);
      reviewNumber = 2;
      return reviewNumber.toString();
    } else if (value === 75) {
      setReviewMin(75);
      setMinimumReviews(3);
      reviewNumber = 3;
      return reviewNumber.toString();
    } else if (value === 100) {
      setReviewMin(100);
      setMinimumReviews(4);
      reviewNumber = 4;
      return reviewNumber.toString();
    } else {
      return reviewNumber.toString();
    }
  };

  const clearAllFilters = () => {
    const dataForm = form?.current?.values;
    clearCatName(dataForm);
    clearCpv();
    clearCity();
    clearReview(dataForm);
    clearNumOfReviews(dataForm);
    clearRegistrationDate();
    clearOther();
    clearTurnover();
    clearCompanyType();
    clearExperience();
  };

  const clearCatName = (values: any) => {
    setCatName([]);
    setCategoryNames([]);
    values = catName;
  };
  const clearCpv = () => {
    setCpvCode([]);
  };

  const clearReview = (values: any) => {
    values.reviewGrade = "";
  };
  const clearCity = () => {
    setCity([]);
  };

  const clearNumOfReviews = (values: any) => {
    values.reviewsMin = "";
    setReviewMin(0);
    setMinimumReviews(0);
  };

  const clearRegistrationDate = () => {
    setDateFrom("");
    setDateTo("");
    setNewFromDate("");
    setInputFromState("text");
    setInputToState("text");
  };

  const clearOther = () => {
    setOther([]);
    setOrganisationOther([]);
  };

  const clearTurnover = () => {
    setTurnoverMin(0);
    setTurnoverMax(0);
    resetTkrHandler();
  };

  const clearCompanyType = () => {
    setCompanyType([]);
    setOrganisationType([]);
  };

  const clearExperience = () => {
    setExperience([]);
  };

  return (
    <div style={styles.container}>
      <div className={cssStyle.heading}>
        <h2>{t("filter")}</h2>
        <p onClick={clearAllFilters}>Rensa alla</p>
      </div>

      <Formik
        innerRef={form}
        initialValues={OrganisationFilterInitialValues}
        onSubmit={onChange}
      >
        {({ handleChange, values, handleSubmit }) => (
          <form onChange={handleSubmit} className={cssStyle.form}>
            <Dropdown label={t("subcategory")} style={styles.categoryContainer}>
              <>
                <div
                  onClick={() => {
                    clearCatName(values);
                  }}
                  className={cssStyle.clearBtn}
                >
                  <p>{t("clear")}</p>
                </div>

                <MultipleSelectCheckbox
                  options={
                    categoryNames
                      ? categoryNames.map((categoryName) => {
                          return { value: categoryName.suntCategoryDetailed };
                        })
                      : []
                  }
                  setValues={setCatName}
                />
              </>
            </Dropdown>
            <Dropdown label={t("cpv")} style={styles.categoryContainer}>
              <>
                <div onClick={clearCpv} className={cssStyle.clearBtn}>
                  <p>{t("clear")}</p>
                </div>
                <MultipleSelect
                  placeholder={t("cpv")}
                  value={cpvCode}
                  setValue={setCpvCode}
                  options={
                    categoryCPV
                      ? categoryCPV.map((categoryCPV) => {
                          return { value: categoryCPV.cpvCode };
                        })
                      : []
                  }
                />
              </>
            </Dropdown>
            <Dropdown label={t("location")} style={styles.categoryContainer}>
              <>
                <div onClick={clearCity} className={cssStyle.clearBtn}>
                  <p>{t("clear")}</p>
                </div>
                <MultipleSelect
                  placeholder={t("cityPlaceholder")}
                  value={city}
                  setValue={setCity}
                  options={
                    organisationCities
                      ? organisationCities
                          .filter((city) => !!city.city)
                          .map((city) => {
                            return { value: city.city };
                          })
                      : []
                  }
                />
              </>
            </Dropdown>
            <Dropdown label={t("reviewGrade")}>
              <>
                <div
                  onClick={() => {
                    clearReview(values);
                  }}
                  className={cssStyle.clearBtn}
                >
                  <p>{t("clear")}</p>
                </div>
                <Radio.Group
                  style={styles.group}
                  name="reviewGrade"
                  value={values.reviewGrade}
                  onChange={handleChange}
                >
                  <Radio
                    value={"5"}
                    onClick={() => {
                      values.reviewGrade =
                        values.reviewGrade !== "5" ? "5" : "";
                    }}
                  >
                    <ShowStars number={5} />
                  </Radio>
                  <Radio
                    value={"4.5"}
                    onClick={() => {
                      values.reviewGrade =
                        values.reviewGrade !== "4.5" ? "4.5" : "";
                    }}
                  >
                    <ShowStars number={4.5} />
                  </Radio>
                  <Radio
                    value={"4"}
                    onClick={() => {
                      values.reviewGrade =
                        values.reviewGrade !== "4" ? "4" : "";
                    }}
                  >
                    <ShowStars number={4} />
                  </Radio>
                  <Radio
                    value={"3.5"}
                    onClick={() => {
                      values.reviewGrade =
                        values.reviewGrade !== "3.5" ? "3.5" : "";
                    }}
                  >
                    <ShowStars number={3.5} />
                  </Radio>
                  <Radio
                    value={"3"}
                    onClick={() => {
                      values.reviewGrade =
                        values.reviewGrade !== "3" ? "3" : "";
                    }}
                  >
                    <ShowStars number={3} />
                  </Radio>
                  <Radio
                    value={"2.5"}
                    onClick={() => {
                      values.reviewGrade =
                        values.reviewGrade !== "2.5" ? "2.5" : "";
                    }}
                  >
                    <ShowStars number={2.5} />
                  </Radio>
                  <Radio
                    value={"2"}
                    onClick={() => {
                      values.reviewGrade =
                        values.reviewGrade !== "2" ? "2" : "";
                    }}
                  >
                    <ShowStars number={2} />
                  </Radio>
                  <Radio
                    value={"1.5"}
                    onClick={() => {
                      values.reviewGrade =
                        values.reviewGrade !== "1.5" ? "1.5" : "";
                    }}
                  >
                    <ShowStars number={1.5} />
                  </Radio>
                  <Radio
                    value={"1"}
                    onClick={() => {
                      values.reviewGrade =
                        values.reviewGrade !== "1" ? "1" : "";
                    }}
                  >
                    <ShowStars number={1} />
                  </Radio>
                </Radio.Group>
              </>
            </Dropdown>
            <Dropdown label={t("numberReviews")}>
              {/* <div style={styles.inputContainer}>
              <input
                type="text"
                style={styles.input}
                placeholder={t("min")}
                name="reviewsMin"
                value={values.reviewsMin}
                onChange={handleChange}
              />
            </div> */}
              <div className={cssStyle.inputSliderBox}>
                <div
                  onClick={() => {
                    clearNumOfReviews(values);
                  }}
                  className={cssStyle.clearBtn}
                >
                  <p>{t("clear")}</p>
                </div>
                <Slider
                  className={cssStyle.inputSlider}
                  tipFormatter={null}
                  onChange={(value) => {
                    // setReviewMin();
                    values.reviewsMin = parseInt(reviewConverter(value));
                  }}
                  value={reviewMin}
                  marks={{
                    0: { label: <small>0</small> },
                    25: {
                      label: <small>1</small>,
                    },
                    50: {
                      label: <small>2</small>,
                    },
                    75: {
                      label: <small>3</small>,
                    },
                    100: {
                      label: <small>4</small>,
                    },
                  }}
                  step={null}
                />
              </div>
            </Dropdown>
            <Dropdown label={t("dateOfRegister")}>
              <>
                <div
                  onClick={clearRegistrationDate}
                  className={cssStyle.clearBtn}
                >
                  <p>{t("clear")}</p>
                </div>
                <Space
                  style={styles.dateContainer}
                  className={cssStyle.dateContainer}
                >
                  {/* <DatePicker
                    allowClear={true}
                    style={{ ...styles.input, ...styles.dateInput }}
                    placeholder={t("from")}
                    onChange={(v, vString) => {
                      setDateFrom(vString);
                      onChange();
                    }}
                  />
                  <DatePicker
                    style={{ ...styles.input, ...styles.dateInput }}
                    placeholder={t("to")}
                    onChange={(v, vString) => {
                      setDateTo(vString);
                      onChange();
                    }}
                  /> */}
                  <div className={cssStyle.dateInput}>
                    <input
                      type={inputFromState}
                      placeholder="Från"
                      value={dateFrom}
                      onMouseEnter={() => {
                        setInputFromState("date");
                      }}
                      // onMouseLeave={() => {
                      //   setInputFromState(
                      //     dateFrom.length > 0 ? "date" : "text"
                      //   );
                      // }}
                      onBlur={() => {
                        setInputFromState(
                          dateFrom.length > 0 ? "date" : "text"
                        );
                      }}
                      onChange={(e) => {
                        setDateFrom(e.target.value);
                      }}
                    />
                    <div className={cssStyle.dateInputIcon}>
                      <CalendarIcon />
                    </div>
                  </div>
                  <div className={cssStyle.dateInput}>
                    <input
                      type={inputToState}
                      placeholder="Till"
                      value={dateTo}
                      onMouseEnter={() => {
                        setInputToState("date");
                      }}
                      // onMouseLeave={() => {
                      //   setInputToState(dateTo.length > 0 ? "date" : "text");
                      // }}
                      onBlur={() => {
                        setInputToState(dateTo.length > 0 ? "date" : "text");
                      }}
                      onChange={(e) => {
                        setDateTo(e.target.value);
                      }}
                    />
                    <div className={cssStyle.dateInputIcon}>
                      <CalendarIcon />
                    </div>
                  </div>
                </Space>
              </>
            </Dropdown>
            <Dropdown label={t("other")}>
              <>
                <div onClick={clearOther} className={cssStyle.clearBtn}>
                  <p>{t("clear")}</p>
                </div>
                <MultipleSelectCheckbox
                  options={
                    organisationOther
                      ?.filter((other) => other.SME)
                      .map((other) => {
                        return { value: other.SME };
                      }) || []
                  }
                  setValues={setOther}
                />
              </>
            </Dropdown>
            {/* Will use it in future */}

            {/* <Dropdown label={t("numberEmployees")}>
            <div style={styles.inputContainer}>
              <input
                type="text"
                style={{ ...styles.input, ...styles.smallerInput }}
                placeholder={t("min")}
                name="employeesMin"
                value={values.employeesMin}
                onChange={handleChange}
              />
              <span>To</span>
              <input
                type="text"
                style={{ ...styles.input, ...styles.smallerInput }}
                placeholder={t("max")}
                name="employeesMax"
                value={values.employeesMax}
                onChange={handleChange}
              />
            </div>
          </Dropdown> */}

            <Dropdown label={t("turnover")}>
              <>
                <div onClick={clearTurnover} className={cssStyle.clearBtn}>
                  <p>{t("clear")}</p>
                </div>
                <MinMaxSlider
                  reset={resetTkr}
                  style={styles.slider}
                  disabled={maximumTurnoverValue.toString() == ""}
                  maximumValue={maximumTurnoverValue.toString()}
                  onChange={handleTurnoverSlider}
                />
              </>
            </Dropdown>
            <Dropdown label={t("companyType")}>
              <>
                <div onClick={clearCompanyType} className={cssStyle.clearBtn}>
                  <p>{t("clear")}</p>
                </div>
                <MultipleSelectCheckbox
                  options={
                    organisationType?.map((type) => {
                      return { value: type.organisationForm };
                    }) || []
                  }
                  setValues={setCompanyType}
                />
              </>
            </Dropdown>
            <Dropdown label={t("experience")}>
              <>
                <div onClick={clearExperience} className={cssStyle.clearBtn}>
                  <p>{t("clear")}</p>
                </div>
                <Radio.Group
                  style={styles.group}
                  onChange={handleChange}
                  value={values.experience[0]}
                  name="experience"
                >
                  <Radio
                    value="Yes"
                    onClick={(e) => {
                      setExperience(["Yes"]);
                    }}
                    name="experience"
                  >
                    <span>{t("yes")}</span>
                  </Radio>
                  <Radio
                    value="No"
                    onClick={() => {
                      setExperience(["No"]);
                    }}
                    name="experience"
                  >
                    <span>{t("no")}</span>
                  </Radio>
                </Radio.Group>
              </>
            </Dropdown>

            {/* Will use it in future */}

            {/* <Dropdown label={t("role")}>
            <MultipleSelect
              placeholder={t("role")}
              value={role}
              setValue={setRole}
              options={
                organisationPosition
                  ? organisationPosition.map((position) => {
                      return { value: position.position };
                    })
                  : []
              }
            />
          </Dropdown>
          <Dropdown label={t("tags")}>
            <MultipleSelect
              placeholname="experience"der={t("tags")}
              value={tags}
              setValue={setTags}
              options={
                organisationTags
                  ? organisationTags.map((tag) => {
                      return { value: tag.tag };
                    })
                  : []
              }
            />
          </Dropdown> */}

            {/* Will use it in future */}

            {/* <Dropdown label={t("supplierList")}>
            <SupplierListFilter />
          </Dropdown> */}
          </form>
        )}
      </Formik>
    </div>
  );
};

const styles = StyleSheet.create({
  test: {
    border: "1px solid red",
  },
  input: {
    outline: "none",
    backgroundColor: "#F2F2F5",
    height: "32px",
    border: "0",
    borderRadius: "26px",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    letterSpacing: "0.07em",
    color: "#000000",
    // width: "150px",
    width: "100%",
    padding: "5px 8px",
    margin: "10px 0",
    display: "block",
  },
  smallerInput: {
    width: "60px",
  },
  categoryContainer: {
    display: "flex",
    flexDirection: "column",
  },
  group: {
    display: "flex",
    flexDirection: "column",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    // width: "150px",
    alignItems: "center",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    letterSpacing: "0.07em",
  },
  dateContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  dateInput: {
    margin: "1px 0",
    width: "100%",
  },
  switch: {
    backgroundColor: "#4FBFC0",
  },
  checkbox: {
    margin: "3px 0",
    fontFamily: "d-din",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "13px",
    letterSpacing: "0.07em",
  },

  slider: {
    width: "85%",
    marginLeft: "7.5px",
  },
  container: {
    width: "100%",
    minWidth: "240px",
    minHeight: "calc(100vh - 64px)",
    backgroundColor: "#FFFFFF",
    marginBottom: "-24px",
    paddingBottom: "10px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
  },
});
